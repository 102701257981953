.m-product-info__ingredients {
	.m-product-info__box-headline {
		@include mq($until: medium) {
			margin-bottom: $spacing-olive;
		}

		@include mq($from: medium) {
			margin-bottom: $spacing-yellow;
		}
	}

	.c-content-shape__inner {
		@include mq($from: large) {
			padding-bottom: $spacing-softblue;
		}

		@include mq($from: xlarge) {
			padding-bottom: $spacing-darkgrey;
		}
	}
}
