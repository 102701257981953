@import "variant-mixin";

.c-toggle-switch {
	--toggle-switch-width: 50px;
	--toggle-switch-knob-diamter: 30px;

	display: flex;
	align-items: center;
	width: fit-content;
	position: relative;
}

.c-toggle-switch__input {
	opacity: 0;
	z-index: 2;
	cursor: pointer;
}

.c-toggle-switch__label {
	color: $color-haribo-blue;
	margin-left: 10px;
}

.c-toggle-switch__chrome {
	display: flex;
	align-items: center;
	position: relative;
}

.c-toggle-switch__toggle {
	display: flex;
	width: var(--toggle-switch-width);
	height: var(--toggle-switch-knob-diamter);
	position: relative;
}

.c-toggle-switch__background {
	width: calc(100% - 2px);
	height: calc(var(--toggle-switch-knob-diamter) - 40%);
	background-color: $color-haribo-grey-1;
	border-radius: calc(var(--toggle-switch-knob-diamter) / 2);
	box-shadow: inset 2px 2px 5px 0 rgba(0, 0, 0, 0.20);
	margin: auto;
	@include transition(background-color);
}

.c-toggle-switch__knob {
	display: flex;
	width: var(--toggle-switch-knob-diamter);
	height: var(--toggle-switch-knob-diamter);
	border-radius: 50%;
	box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 0;
	left: 0;
	@include transition(transform);

	// white center
	&::after {
		content: "";
		width: 51%;
		height: 51%;
		background-color: $color-white;
		border-radius: 50%;
		margin: auto;
		@include transition(background-color);
	}
}

.c-toggle-switch__knob--default {
	background-color: $color-haribo-grey-3;
}

$colors: (
	"yellow": $color-haribo-yellow,
	"green": $color-haribo-green,
	"red": $color-haribo-red,
);

@each $key, $color in $colors {
	.c-toggle-switch__knob--#{$key} {
		background-color: $color;
	}
}

.c-toggle-switch__input:checked {
	~ .c-toggle-switch__toggle {
		.c-toggle-switch__knob {
			//noinspection CssInvalidFunction IntelliJ
			transform: translateX(calc(var(--toggle-switch-width) - var(--toggle-switch-knob-diamter)));
		}

		.c-toggle-switch__background {
			background-color: $color-haribo-blue;
		}
	}
}

[data-whatinput="keyboard"] .c-toggle-switch:focus-within {
	@include keyboard-focus();
}

.c-toggle-switch__icon-left,
.c-toggle-switch__icon-right {
	width: 20px;
	height: 100%;
	color: $color-haribo-blue;
}

.c-toggle-switch__icon-left {
	margin-right: 8px;
}

.c-toggle-switch__icon-right {
	margin-left: 8px;
}

.c-toggle-switch--big {
	@include toggle-switch--big();
}

.c-toggle-switch--long {
	@include toggle-switch--long();
}

.c-toggle-switch--long-big {
	@include toggle-switch--long-big();
}

.c-toggle-switch--same-background {
	@include toggle-switch--same-background();
}

