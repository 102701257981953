.c-loading-spinner {
	display: flex;
	justify-items: center;
	align-items: center;
	width: 62px;
	height: 50px;
	color: $color-haribo-dark-blue;
	margin: 0 auto;
}

.c-loading-spinner--white {
	color: $color-white;
}

.c-loading-spinner__circle {
	display: block;
	width: 18px;
	height: 18px;
	background-color: currentColor;
	border-radius: 50%;
	animation: loading-spinner-animation 1.4s infinite ease-in-out both;
}

.c-loading-spinner__circle:nth-child(1) {
	animation-delay: -0.32s;
}

.c-loading-spinner__circle:nth-child(2) {
	animation-delay: -0.16s;
}

.c-loading-spinner__circle:nth-child(n + 2) {
	margin-left: 5px;
}

@keyframes loading-spinner-animation {
	0%,
	80%,
	100% {
		transform: scale(0);
	}

	40% {
		transform: scale(1);
	}
}
