.m-raffle-vote-result {
	.m-raffle {
		@include mq($from: large) {
			margin-top: $spacing-lightblue;
		}

		@include mq($from: xlarge) {
			margin-top: $spacing-lightpink;
		}
	}
}

.m-raffle-vote-result__percent,
.m-raffle-vote-result__label {
	color: $color-haribo-blue;

	.has-bg-color & {
		color: $color-white;
	}
}

.m-raffle-vote-result__bar-wrapper {
	max-width: 230px;
	margin-left: auto;
	margin-right: auto;
}

.m-raffle-vote-result__bar {
	height: 10px;
	overflow: hidden;
	background-color: var(--background-color);
	border-radius: 5px;
	position: relative;

	@include mq($from: large) {
		height: 12px;
		border-radius: 6px;
	}
}

.m-raffle-vote-result__bar-inner {
	height: 100%;
	background-color: $color-haribo-blue;

	.has-bg-color & {
		background-color: $color-white;
	}
}

.m-raffle-vote-result__table {
	width: 100%;
	border-collapse: collapse;

	@include mq($from: large) {
		width: 100%;
		max-width: 670px;
	}

	.m-raffle-vote-result__label-column {
		@include mq($from: large) {
			white-space: nowrap;
		}
	}

	.m-raffle-vote-result__bar-column {
		width: 100%;
	}

	td {
		padding: 0;
		margin: 0;
	}

	tr:not(:last-child) td {
		padding-bottom: $spacing-yellow;

		@include mq($from: large) {
			padding-bottom: $spacing-yellow;
		}

		@include mq($from: xlarge) {
			padding-bottom: $spacing-purple;
		}
	}
}

.m-raffle-vote-result.m-raffle-question--default {
	.m-raffle-vote-result__result {
		margin-top: $spacing-yellow;

		@include mq($from: large) {
			margin-top: $spacing-lilac;
		}
	}

	.m-raffle-vote-result__label {
		display: flex;
		align-items: center;
		min-height: 25px;
		font-size: 16px;

		@include mq($until: large) {
			min-width: 35vw;
		}

		@include mq($from: large) {
			max-width: 200px;
		}

		@include mq($from: xlarge) {
			font-size: 26px;
		}
	}

	.m-raffle-vote-result__bar {
		min-width: 100px;
		margin-left: 15px;

		@include mq($from: large) {
			margin-left: 45px;
		}
	}

	.m-raffle-vote-result__percent {
		padding-left: 10px;
	}
}

.m-raffle-question--images {
	.m-raffle-vote-result__bar-wrapper {
		margin-top: $spacing-olive;
	}

	.m-raffle-vote-result__percent {
		text-align: center;
		margin-top: $spacing-olive;
	}
}
