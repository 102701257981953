$font-sizes: (
	small: 16px,
	medium: 18px,
	large: 20px,
	xlarge: 30px
);

$line-heights: (
	small: 24px,
	medium: 30px,
	large: 24px,
	xlarge: 38px
);

.c-button {
	display: inline-flex;
	align-items: center;
	padding: $spacing-smokeblue 15px;
	background-color: $color-haribo-grey-4;
	border-radius: 500px;
	position: relative;
	@include property-breakpoint-map(font-size, $font-sizes);
	@include property-breakpoint-map(line-height, $line-heights);
	@include font-family("VAG Rounded", bold);

	@include mq($from: medium) {
		padding: $spacing-smokeblue 20px;
	}

	@include mq($from: large) {
		padding: $spacing-maroon 25px;
	}

	@include mq($from: xlarge) {
		padding: $spacing-maroon 40px;
	}

	&::after {
		content: "";
		width: 100%;
		height: 100%;
		background-color: inherit;
		border-radius: 500px;
		position: absolute;
		top: 0;
		left: 0;
		transform: scale(0.95);
		@include transition(transform);
	}

	@include hover-focus() {
		&::after {
			transform: scale(1.10);
		}
	}

	&[disabled] {
		background-color: $color-haribo-grey-1;
		cursor: default;
		pointer-events: none;
	}
}

.c-button__label + .c-button__icon {
	margin-left: 10px;

	@include is-rtl() {
		margin-left: 0;
		margin-right: 10px;
	}
}

.c-button--submit {
	@include property-breakpoint-map(height, $input-height-map);
	background-color: $color-haribo-red;
	color: $color-white;

	&[disabled]::after {
		// Remove extra background when disabled so we can show the progress
		display: none;
	}
}

.c-button__label,
.c-button__icon {
	position: relative;
	z-index: 2;
}

.c-button--download {
	display: inline-flex;
	align-items: center;
	height: 35px;
	padding: 0 15px 0 20px;
	color: $color-black;
	position: relative;

	@include mq($from: xlarge) {
		height: 40px;
	}

	.c-button__label {
		@include font-style("headline-o1");
		display: flex;
		align-items: center;

		// file size
		span:nth-child(2) {
			margin-left: 5px;
			@include font-style("copy-b1");

			@include is-rtl() {
				margin-left: 0;
				margin-right: 5px;
			}
		}
	}
}

.c-button--compact {
	height: 35px;
	padding: 0 20px 0 20px;
	font-size: 14px;

	@include mq($from: xlarge) {
		height: 40px;
		font-size: 16px;
	}

	&.c-button--icon-only {
		width: 35px;

		@include mq($from: xlarge) {
			width: 40px;
		}
	}

	.c-button__icon {
		width: 12px;
		height: 14px;
		margin-left: 10px;
	}

	&::after {
		// no hover scale animation
		display: none;
	}
}

.c-button.c-button--icon-only {
	display: flex;
	padding: 0;

	.c-button__icon {
		width: 50%;
		height: 50%;
		margin: auto;
	}
}

.c-button--block {
	justify-content: center;
	width: 100%;
}

.c-button--sub-navigation {
	align-items: center;
	background-color: $color-white;
	border-width: 3px;
	border-style: solid;
	border-color: #E5EEF6;
	color: $color-haribo-blue;
	line-height: 1;
	white-space: nowrap;
	@include transition(background-color, border-color);

	&.is-open,
	&.is-active {
		background-color: $color-haribo-yellow;
		border-color: $color-haribo-yellow;
	}

	@include hover-focus() {
		background-color: $color-haribo-yellow;
		border-color: $color-haribo-yellow;
	}
}

.c-button--no-hover-animation {
	&::after {
		display: none;
	}
}

.c-button--revert-xl-breakpoint {
	@include mq($from: xlarge) {
		font-size: map-get($font-sizes, large);
		line-height: map-get($line-heights, large);

		@include mq($from: xlarge) {
			padding: $spacing-maroon 25px;
		}
	}
}
