.m-raffle-incorrect {
	.m-raffle__headline,
	.m-raffle__copy {
		@include mq($from: large) {
			width: percentage(12/16);
		}

		@include mq($from: xlarge) {
			width: percentage(13/18);
		}
	}
}
