.m-maps {
	overflow: hidden;
	position: relative;
	@extend .u-color-bg-005CA9;
}

@import "components/MapsIntro";

.m-maps__overlay {
	visibility: hidden;
	opacity: 0;
	z-index: 2;
	pointer-events: none;
	@include transition(opacity 0.4s, visibility 0s linear 0.4s);

	&.is-visible {
		visibility: visible;
		opacity: 1;
		pointer-events: all;
		@include transition(opacity 0.4s, visibility 0s linear 0s);
	}
}

.m-maps__close-google-map {
	width: 50px;
	height: 50px;
	background-color: $color-white;
	box-shadow: 0 0 49px 0 rgba(0, 0, 0, 0.4);
	color: $color-haribo-red;
	position: absolute;
	top: $spacing-yellow;
	right: $spacing-yellow;
	z-index: 3;

	@include mq($from: medium) {
		width: 60px;
		height: 60px;
		top: $spacing-purple;
		right: $spacing-purple;
	}

	@include mq($from: xlarge) {
		width: 70px;
		height: 70px;
	}

	[data-whatinput="keyboard"] &:focus {
		@include keyboard-focus();
	}
}

.m-maps__info {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: map-get($grid-width, small);
	height: 100%;
	padding-bottom: $spacing-pink;
	position: absolute;
	left: 0;
	right: 0;
	z-index: 2;
	margin: 0 auto;
	pointer-events: none;

	@include mq($from: medium) {
		align-items: flex-start;
		justify-content: space-between;
		width: map-get($grid-width, medium);
		padding-top: $spacing-purple;
		padding-bottom: $spacing-royalblue;
	}

	@include mq($from: large) {
		align-items: stretch;
		justify-content: flex-start;
		width: 400px;
		padding-left: $spacing-purple;
		margin: 0;
	}
}

.m-maps__boxes-wrapper {
	position: relative;
}

.m-maps__boxes {
	min-width: 320px;
	padding: $spacing-yellow 25px $spacing-lightblue 25px;
	background-color: $color-white;
	border-radius: 35px;
	box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.3);
	position: relative;
	pointer-events: all;

	@include mq($from: medium) {
		padding-top: $spacing-purple;
		padding-bottom: $spacing-lightpink;
	}

	.flickity-page-dots {
		bottom: 10px;
		left: 0;

		.dot {
			background-color: $color-haribo-red;
			opacity: 0.3;
			margin-left: 4px;
			margin-right: 4px;
			transform-origin: center;
			@include transition(transform, opacity);

			&.is-selected {
				opacity: 1;
				transform: scale(1.1);
			}
		}
	}
}

.m-maps__pagination {
	width: fit-content;
	z-index: 2;
	margin: -25px auto 25px;

	&:last-child {
		margin-bottom: 15px;
	}
}

.m-maps__info-copy {
	padding: $spacing-yellow 25px $spacing-yellow 25px;
	background-color: $color-white;
	border-radius: 35px;
	box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.3);
	color: $color-haribo-blue;

	span {
		color: $color-haribo-red;
		font-weight: bold;
	}
}

.m-maps__box {
	color: $color-haribo-blue;
	position: relative;
}

.m-maps__box-headline {
	font-size: 18px;
	font-weight: bold;
}

.m-maps__box-item {
	display: flex;
	align-items: flex-start;
	width: fit-content;
	line-height: 1.4;
	margin-top: $spacing-olive;

	svg {
		width: 16px;
		height: 16px;
		margin-top: 2px;
		margin-right: 8px;
	}
}

.m-maps__box-headline + .m-maps__box-item {
	margin-top: $spacing-yellow;
}

.m-maps__box-phone {
	color: $color-haribo-blue;
}

.m-maps__box-rating {
	margin-top: $spacing-pink;

	.vue-star-rating-rating-text {
		font-weight: bold;
		margin: 0 0 0 8px !important;
	}
}

.m-maps__box-directions {
	width: 50px;
	height: 50px;
	background-color: $color-white;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
	position: absolute;
	bottom: 20px;
	right: 20px;
	z-index: 2;
	pointer-events: all;
	@include transition(box-shadow);

	svg {
		width: 65% !important;
		height: 65% !important;
	}

	path {
		fill: $color-haribo-blue;
	}

	&::after {
		display: none;
	}

	@include hover-focus() {
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
	}
}

.m-maps__detail-button {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: $spacing-olive 20px;
	background-color: $color-haribo-blue;
	border-radius: 500px;
	box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.3);
	color: $color-white;
	line-height: 1.2;
	margin-top: $spacing-olive;
	pointer-events: all;

	@include mq($from: medium) {
		align-self: center;
		width: fit-content;
		padding: $spacing-yellow 40px;
	}

	@include mq($from: large) {
		width: 100%;
	}
}

.m-maps__overlay.a-enter-active,
.m-maps__overlay.a-leave-active {
	transition: opacity 0.5s;
}

.m-maps__overlay.a-enter,
.m-maps__overlay.a-leave-to {
	opacity: 0;
}

.m-map__custom-label {
	@include font-style("copy-a1");
	padding: 2px 10px;
	background-color: $color-white;
	border-radius: 500px;
	box-shadow: 0 0 49px 0 rgba(0, 0, 0, 0.4);
	color: $color-haribo-red;
	font-size: 16px;
	font-weight: bold;
	white-space: nowrap;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	margin-top: -43px;
	cursor: pointer;
	@include transition(opacity);

	@include mq($from: large) {
		padding-left: 15px;
		padding-right: 15px;
		font-size: 24px;
		margin-top: -49px;
	}
}

.m-map__custom-label--right {
	margin-left: 20px;
}

.m-map__custom-label--left {
	margin-left: -20px;
	transform: translateX(-100%);
}

// variants and states
/* stylelint-disable no-duplicate-selectors */
.m-maps__boxes {
	visibility: hidden;
	position: absolute; // just to take it out of document flow
	pointer-events: none;
}

.m-maps__pagination {
	visibility: hidden;
	pointer-events: none;
}

.m-maps__overlay.has-visible-detail {
	.m-maps__boxes {
		visibility: visible;
		position: relative;
		pointer-events: all;
	}

	.m-maps__pagination {
		visibility: visible;
		pointer-events: all;
	}

	.m-maps__info-copy {
		margin-top: $spacing-yellow;
	}

	.m-map__custom-label {
		opacity: 0;
		pointer-events: none;
	}
}
/* stylelint-enable no-duplicate-selectors */
