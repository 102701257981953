/**
 * Input Choice combines checkbox and radio buttons
 */
$size-map: (
	small: 28px,
	medium: 28px,
	large: 32px,
	xlarge: 36px
);

// Extra map to have non-fluid font-style
$input-font-sizes: (
	small: map-deep-get($font-styles, copy-a2, small-font-size),
	medium: map-deep-get($font-styles, copy-a2, medium-font-size),
	large: map-deep-get($font-styles, copy-a2, large-font-size),
	xlarge: map-deep-get($font-styles, copy-a2, xlarge-font-size)
);


.c-input-choice {
	display: block;
	width: 100%;
	// Width/height if no text-label is present
	@include property-breakpoint-map(min-width, $size-map);
	@include property-breakpoint-map(min-height, $size-map);
	position: relative;
	cursor: pointer;
}

// Hide the real input
.c-input-choice__input {
	appearance: none;
	@include property-breakpoint-map(width, $size-map);
	@include property-breakpoint-map(height, $size-map);
	outline: none;
	opacity: 0.0;
	position: absolute;
	top: 0;
	left: 0;

	@include is-rtl() {
		left: auto;
		right: 0;
	}
}

.c-input-choice__replacement {
	content: "";
	display: block;
	@include property-breakpoint-map(width, $size-map);
	@include property-breakpoint-map(height, $size-map);
	background-color: rgba($color-haribo-blue, 0.07);
	border-radius: 5px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	cursor: pointer;

	@include is-rtl() {
		left: auto;
		right: 0;
	}

	&::after,
	.c-input-choice__checkbox-mark {
		content: "";
		display: block;
		opacity: 0.0;
		color: $color-haribo-blue;
		position: absolute;
		@include transition(opacity, color);
	}
}

.c-input-choice__text {
	$padding-left: (
		small: map-get($size-map, small) + 20px,
		medium: map-get($size-map, medium) + 20px,
		large: map-get($size-map, large) + 25px,
		xlarge: map-get($size-map, large) + 30px
	);

	$padding-top: (
		small: 5px,
		medium: 3px,
		large: 5px,
		xlarge: 6px
	);

	display: block;
	color: $color-haribo-blue;
	position: relative;
	@include property-breakpoint-map(font-size, $input-font-sizes);
	@include property-breakpoint-map(padding-top, $padding-top);
	@include property-breakpoint-map(padding-left, $padding-left);
	@include transition(color);

	@include is-rtl() {
		padding-left: 0;
		@include property-breakpoint-map(padding-right, $padding-left);
	}
}

// Checked
.c-input-choice .c-input-choice__input:checked + .c-input-choice__replacement {
	&::after,
	.c-input-choice__checkbox-mark {
		opacity: 1.0 !important;
		color: $color-haribo-blue;
	}
}

// Disabled
.c-input-choice .c-input-choice__input:disabled + .c-input-choice__replacement {
	cursor: default;

	&::after,
	.c-input-choice__checkbox-mark {
		color: $color-haribo-grey-3;
	}
}

// stylelint-disable no-descending-specificity
// -> It's more important to have a consistent order, no conflict potential
[data-whatinput="mouse"] .c-input-choice:hover .c-input-choice__input:not(:disabled),
[data-whatinput="keyboard"] .c-input-choice__input:focus:not(:disabled) {
	+ .c-input-choice__replacement::after,
	+ .c-input-choice__checkbox-mark {
		opacity: 0.2;
	}

	//~ .c-input-choice__text {
	//}
}

[data-whatinput="keyboard"] .c-input-choice__input:focus:not(:disabled) {
	+ .c-input-choice__replacement {
		box-shadow: inset 0 0 0 4px $color-haribo-blue;
	}
}

// Error
.c-input-choice.has-error {
	.c-input-choice__replacement {
		box-shadow: inset 0 0 0 4px $color-haribo-red;
	}
}
// stylelint-enable no-descending-specificity
