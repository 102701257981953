.m-shop-list-highlights {
	display: flex;
	flex-wrap: wrap;
	row-gap: $spacing-olive;
	margin-left: -10px;
	margin-right: -10px;
}

.m-shop-list-highlights__item {
	flex: 0 0 auto;
	width: 25%;
}

.m-shop-list-highlights__image {
	width: 77%;
	margin-left: auto;
	margin-right: auto;
}

.m-shop-list-highlights__text {
	padding-left: 3px;
	padding-right: 3px;
	color: $color-white;
	font-size: 10px;
	line-height: 1.3;
	font-weight: bold;
	text-align: center;
	margin-top: $spacing-smokeblue;

	@include mq($from: large, $until: xlarge) {
		font-size: 9px;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-olive;

		.m-shop-list-item & {
			font-size: 11px;
		}
	}
}
