.c-shaped-icon-button {
	width: 50px;
	height: 50px;
	color: $color-white;
	position: relative;

	@include hover-focus() {
		.c-shaped-icon-button__shape {
			transform: scale(1.1);
		}
	}
}

.c-shaped-icon-button__inner {
	width: 100%;
	height: 100%;
	position: relative;
}

.c-shaped-icon-button__shape {
	color: $color-haribo-red;
	@include transition(transform);
}

.c-shaped-icon-button__icon {
	width: 100%;
	height: 100%;
	padding: 24%;
	position: relative;
	z-index: 2;
	@include transition(transform);
}

.c-shaped-icon-button--inverted {
	color: $color-haribo-red;

	.c-shaped-icon-button__shape {
		color: $color-white;
	}
}
