.l-main {
	&.is-unscrollable {
		overflow: hidden;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

.l-wrapper {
	@include property-breakpoint-map(width, $grid-width);
	max-width: $grid-max-width;
	margin-left: auto;
	margin-right: auto;

	@include mq($from: $module-max-width) {
		width: 100%;
	}
}
