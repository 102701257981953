@keyframes fade-in {
	from {
		opacity: 0;
	}

	to {
		visibility: visible;
		opacity: 1;
	}
}

.a-fade-in {
	animation-name: fade-in;
	animation-duration: $animation-default-duration * 2;
	animation-timing-function: $animation-default-easing;
	animation-fill-mode: forwards;
}
