.m-footer {
	background-image: radial-gradient(circle at 71% 96%, #1177CC, $color-haribo-blue 69%);

	@include is-rtl() {
		background-image: radial-gradient(circle at 29% 96%, #1177CC, $color-haribo-blue 69%);
	}
}

.m-footer__wrapper {
	padding-top: $spacing-ocher;
	padding-bottom: $spacing-royalblue;
	position: relative;

	@include mq($from: medium) {
		padding-top: $spacing-softblue;
	}

	@include mq($from: large) {
		padding-top: $spacing-grey;
	}

	@include mq($from: xlarge) {
		padding-top: $spacing-brown;
		padding-bottom: $spacing-purple;
	}
}

.m-footer__social-list {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	margin-bottom: -$spacing-pink;

	@include mq($from: medium) {
		justify-content: space-between;
	}

	@include mq($from: large) {
		justify-content: flex-start;
	}
}

.m-footer__social-item {
	flex: 0 0 auto;
	position: relative;
	margin-bottom: $spacing-pink;

	@include mq($until: medium) {
		display: flex;
		align-items: center;
		width: 33.33%;
	}

	@include mq($from: medium) {
		width: auto;
	}

	@include mq($from: large) {
		margin-right: 20px;

		@include is-rtl() {
			margin-left: 20px;
			margin-right: 0;
		}
	}

	@include mq($from: xlarge) {
		margin-right: 50px;

		@include is-rtl() {
			margin-left: 50px;
			margin-right: 0;
		}
	}

	&:nth-child(3n+2) {
		@include mq($until: medium) {
			justify-content: center;
		}
	}

	&:nth-child(3n+3) {
		@include mq($until: medium) {
			justify-content: flex-end;
		}
	}
}

.m-footer__social-link {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 auto;
	width: 80px;
	height: 80px;
	outline: none;
	position: relative;

	@include mq($from: medium) {
		width: 65px;
		height: 65px;
	}

	@include mq($from: xlarge) {
		width: 100px;
		height: 100px;
	}

	&::after {
		content: "";
		width: 100%;
		height: 100%;
		background-color: $color-haribo-dark-blue;
		position: absolute;
		top: 0;
		left: 0;
		@include transition(transform);
	}

	@include hover-focus() {
		&::after {
			transform: scale(1.1);
		}
	}
}

.m-footer__social-item:nth-child(6n+1) .m-footer__social-link::after {
	@include svg-mask(h);
}

.m-footer__social-item:nth-child(6n+2) .m-footer__social-link::after {
	@include svg-mask(i);
}

.m-footer__social-item:nth-child(6n+3) .m-footer__social-link::after {
	@include svg-mask(j);
}

.m-footer__social-item:nth-child(6n+4) .m-footer__social-link::after {
	@include svg-mask(k);
}

.m-footer__social-item:nth-child(6n+5) .m-footer__social-link::after {
	@include svg-mask(l);
}

.m-footer__social-item:nth-child(6n+6) .m-footer__social-link::after {
	@include svg-mask(m);
}

.m-footer__social-icon {
	width: 100%;
	height: 100%;
	padding: 26%;
	fill: $color-white;
	position: relative;
	z-index: 2;
}

.m-footer__main-group {
	color: $color-white;
	margin-top: $spacing-lightblue;

	@include mq($from: medium) {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin-top: $spacing-petrol;
	}

	@include mq($from: xlarge) {
		width: percentage(19/$cols-xl);
		margin-top: $spacing-seablue;
	}

	&:first-child {
		margin-top: 0;
	}
}

.m-footer__links {
	@include mq($from: medium) {
		width: percentage(6/$cols-m);
		margin-right: percentage(1/$cols-m);

		@include is-rtl() {
			margin-left: percentage(1/$cols-m);
			margin-right: 0;
		}
	}

	@include mq($from: large) {
		width: percentage(7/$cols-l);
		margin-right: percentage(2/$cols-l);

		@include is-rtl() {
			margin-left: percentage(2/$cols-l);
			margin-right: 0;
		}
	}

	@include mq($from: xlarge) {
		width: percentage(6/19);
		margin-right: percentage(1/19);

		@include is-rtl() {
			margin-left: percentage(1/19);
			margin-right: 0;
		}
	}
}

.m-footer__link-item {
	margin-top: $spacing-olive;

	@include mq($from: large) {
		margin-top: $spacing-yellow;
	}
}

.m-footer__link {
	color: $color-white;
	@include transition(color);

	@include hover-focus() {
		color: $color-haribo-yellow;
	}
}

.m-footer__legal-links {
	margin-top: $spacing-royalblue;

	@include mq($from: medium) {
		width: percentage(6/$cols-m);
		margin-top: 0;
	}

	@include mq($from: large) {
		width: percentage(7/$cols-l);
		margin-right: percentage(10/$cols-l);

		@include is-rtl() {
			margin-left: percentage(10/$cols-l);
			margin-right: 0;
		}
	}

	@include mq($from: large, $until: xlarge) {
		// when no links are present
		&:first-child {
			width: 100%;
		}
	}

	@include mq($from: xlarge) {
		width: percentage(6/19);
		margin-right: 20px;

		@include is-rtl() {
			margin-left: 20px;
			margin-right: 0;
		}
	}
}

.m-footer__language-select {
	width: 100%;
	margin-top: $spacing-lightblue;

	@include mq($from: medium) {
		margin-top: $spacing-petrol;
	}

	@include mq($from: large) {
		width: auto;
		margin-top: $spacing-lightblue;
	}

	@include mq($from: xlarge) {
		max-width: percentage(5/19);
		margin-top: 0;
		margin-left: auto;

		&:nth-child(2) {
			margin-left: 0;
		}

		@include is-rtl() {
			margin-left: 0;
			margin-right: auto;
		}
	}
}

.m-footer__copyright {
	color: $color-white;
	margin-top: $spacing-lightblue;

	@include mq($from: medium) {
		margin-top: $spacing-petrol;
	}

	@include mq($from: large) {
		margin-top: $spacing-lightblue;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-lightpink;
	}
}

.m-footer__character {
	display: none;

	@include mq($from: large) {
		display: block;
		width: calc(#{percentage(7/$cols-l)} + 15px);
		max-width: 250px;
		position: absolute;
		bottom: -15px;
		right: -15px;

		@include is-rtl() {
			left: -15px;
			right: auto;
			transform: scaleX(-1);
		}
	}
}

.m-footer__slogan-outer {
	background-color: $color-haribo-dark-blue;
	border-top: 5px solid $color-white;
}

.m-footer__slogan {
	padding-top: $spacing-yellow;
	padding-bottom: $spacing-yellow;
	color: $color-white;
	text-align: center;
}

.m-footer--no-bear {
	.m-footer__wrapper {
		@include mq($from: large) {
			padding-top: $spacing-lightpink;
		}

		@include mq($from: xlarge) {
			padding-top: $spacing-darkgrey;
		}
	}
}
