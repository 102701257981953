.c-video-modal {
	width: fit-content;
}

.c-video-modal__play-button {
	display: block;
	width: 70px;
	height: 70px;

	@include mq($from: medium) {
		width: 94px;
		height: 94px;
	}

	@include mq($from: xlarge) {
		width: 125px;
		height: 125px;
	}
}

.c-video-modal__video {
	width: 100%;
	max-height: 90vh;
	margin-left: auto;
	margin-right: auto;

	@include mq($until: large) {
		@media (orientation: portrait) {
			width: 100%;
		}
	}

	@include mq($from: xlarge) {
		width: 90%;
	}

	.c-video-component__player {
		pointer-events: all;
	}
}

