.m-content-box__text .c-content-shape__inner {
	padding-top: $spacing-lilac;
	padding-bottom: $spacing-royalblue;

	@include mq($from: medium) {
		padding-top: $spacing-darkgrey;
		padding-bottom: $spacing-darkgrey;
	}

	@include mq($from: large) {
		min-height: 450px;
		padding-top: $spacing-ocher;
		padding-bottom: $spacing-lightblue;
	}

	@include mq($from: xlarge) {
		padding-top: $spacing-lightpink;
		padding-bottom: $spacing-petrol;
	}
}

.m-content-box__text-copy {
	margin-top: $spacing-yellow;

	@include mq($from: medium) {
		margin-top: $spacing-lilac;
	}

	@include mq($from: large) {
		margin-top: $spacing-orange;
	}
}

.m-content-box__text-link {
	margin-top: $spacing-yellow;

	@include mq($from: medium) {
		margin-top: $spacing-petrol;
	}

	@include mq($from: large) {
		margin-top: $spacing-purple;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-lightblue;
	}
}
