.m-raffle-confirmation {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;

	.m-raffle__headline,
	.m-raffle__copy {
		@include mq($from: xlarge) {
			width: percentage(13/18);
		}
	}
}
