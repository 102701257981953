$wacken-custom-color: #A0750D;
$crop-helper-offset: 60px;
$close-button-diameter: (
	small: 45px,
	medium: 50px,
	large: 60px,
	xlarge: 65px
);
$close-button-top-margin: (
	small: 100px,
	medium: 180px,
	large: 210px,
	xlarge: 300px,
);

.m-wacken {
	--gradient-color: #EFD995;
	--background-color: #C09223;
	background-image: radial-gradient(circle at 31% 56%, var(--gradient-color),var(--background-color) 65%);
	position: relative;
}

.m-wacken__outer {
	position: relative;
}

.m-wacken__inner {
	display: flex;
	position: relative;
	z-index: 2;

	@include mq($until: large) {
		flex-direction: column;
		justify-content: flex-end;
	}

	@include mq($from: large) {
		align-items: flex-end;
	}

	// magic numbers to maintain a consistent height of module between steps and the text fits inside, adjust as needed

	// small
	@include fluid(
		min-height,
		350px,
		840px,
		map-get($breakpoints, small),
		map-get($breakpoints, medium),
		false, true
	);

	// medium
	@include fluid(
		min-height,
		840px,
		1130px,
		map-get($breakpoints, medium),
		map-get($breakpoints, large),
		false, true
	);

	// large
	@include fluid(
		min-height,
		620px,
		800px,
		map-get($breakpoints, large),
		map-get($breakpoints, xlarge),
		false, true
	);

	// xlarge
	@include fluid(
		min-height,
		780px,
		950px,
		map-get($breakpoints, xlarge),
		$module-max-width,
		false, true
	);
}

.m-wacken__plektron {
	max-width: 220px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
	pointer-events: all;
	@include transition(transform);

	@include mq($from: small, $until: medium) {
		width: percentage(3/$cols-s);
		left: auto;
		right: 0;
		margin-top: $spacing-olive;
		transform: rotate(27deg);
	}

	@include mq($from: medium, $until: large) {
		width: percentage(3/$cols-m);
		margin-top: $spacing-lilac;
	}

	@include mq($from: large) {
		width: percentage(4/$cols-l);
		margin-top: $spacing-petrol;
		margin-left: -20px;
	}

	@include mq($from: xlarge) {
		width: percentage(4.3/$cols-xl);
		margin-top: $spacing-lilac;
	}
}

.m-wacken__bear {
	max-width: 240px;
	position: absolute;
	bottom: 0;
	z-index: 3;

	@include mq($from: small) {
		width: percentage(5/$cols-s);
		right: -1%;
		transform: translateY(20%);
	}

	@include mq($from: medium) {
		width: percentage(4.5/$cols-m);
		right: -10%;
	}

	@include mq($from: large) {
		width: percentage(5/$cols-l);
		right: -9%;
		transform: translateY(40%);
	}

	@include mq($from: xlarge) {
		width: percentage(5/$cols-xl);
		right: -7%;
	}
}

.m-wacken__close-button {
	display: block;
	background-color: $color-white;
	color: $color-haribo-red;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 3;
	margin-left: auto;
	@include property-breakpoint-map(top, $close-button-top-margin);
	@include property-breakpoint-map(width, $close-button-diameter);
	@include property-breakpoint-map(height, $close-button-diameter);
}

.m-wacken__visual {
	width: percentage(11/$cols-s);
	position: relative;
	z-index: 3;
	margin-top: $spacing-yellow;
	margin-bottom: $spacing-orange;
	margin-left: percentage(1/$cols-s);

	@include mq($until: large) {
		order: 2;
	}

	@include mq($from: medium) {
		width: percentage(12/$cols-m);
		margin-top: $spacing-orange;
		margin-left: percentage(1/$cols-m);
	}

	@include mq($from: large) {
		width: percentage(13/$cols-l);
		margin-top: 0;
		margin-bottom: $spacing-purple;
		margin-left: 0;
	}

	@include mq($from: xlarge) {
		width: percentage(13/$cols-xl);
		margin-bottom: $spacing-softblue;
	}
}

.m-wacken__textual {
	flex: 0 0 auto;
	width: percentage(12/$cols-s);
	position: relative;
	z-index: 2;
	@include transition(opacity 0.5s, transform 0.5s);

	@include mq($from: medium) {
		width: percentage(12/$cols-m);
	}

	@include mq($from: large) {
		align-self: center;
		width: percentage(12/$cols-l);
		margin-left: percentage(1/$cols-xl);
	}

	@include mq($from: xlarge) {
		width: percentage(11/$cols-xl);
		margin-left: percentage(2/$cols-xl);
	}

	&.is-hidden {
		opacity: 0;
		transform: translateY(20px);
		transition-duration: 0s;
	}
}

.m-wacken__headline {
	max-width: 570px;
	color: $color-white;
}

.m-wacken__copy {
	color: $color-white;
	margin-top: $spacing-orange;

	@include mq($from: large) {
		margin-top: $spacing-royalblue;
	}
}

.m-wacken__button {
	color: $color-haribo-red;
	text-align: center;
	margin-top: $spacing-yellow;

	@include mq($from: medium) {
		margin-top: $spacing-orange;
	}

	@include mq($from: large) {
		margin-top: $spacing-lightgreen;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-lightblue;
	}
}

.m-wacken__prices-image-intro {
	width: percentage(10/$cols-s);
	max-width: 320px;
	position: absolute;
	top: 0;
	right: percentage(1/$cols-s);
	z-index: 3;

	@include mq($from: medium) {
		width: percentage(8/$cols-m);
		max-width: 530px;
		right: -(percentage(1/$cols-m));
		margin-top: $spacing-pink;
	}

	@include mq($from: large) {
		width: percentage(10/$cols-l);
		right: -(percentage(2/$cols-l));
		margin-top: $spacing-purple;
	}

	@include mq($from: xlarge) {
		width: percentage(12/$cols-xl);
		right: -(percentage(2/$cols-xl));
		margin-top: 0;
	}
}

.m-wacken__prices-image-success {
	width: 100%;

	&.a-fade-in {
		animation-duration: $animation-default-duration * 12;
	}
}

.m-wacken__image-subtext {
	color: $color-white;
}

.m-wacken__speaker-image {
	width: 100%;
	position: relative;

	@include mq($from: medium, $until: large) {
		width: 85%;
		// max-width: 500px;
		margin-left: 20px;
		margin-right: auto;
	}

	@include mq($from: large) {
		// max-width: 630px;
	}
}

.m-wacken__game-text {
	color: $color-white;
	text-align: center;
	animation-timing-function: ease-out;
	animation-delay: 0s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
	animation-fill-mode: none;
}

.m-wacken__game-text--pulse {
	animation-name: wacken-pulse;
	animation-duration: 1s;
}

.m-wacken__game-text--rock {
	animation-name: wacken-rock;
	animation-duration: 1.2s;
}

.m-wacken__game-text-pre {
	animation-name: wacken-pulse;
	animation-duration: 1s;
	animation-timing-function: ease-in-out;
	animation-fill-mode: none;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
}

// spacial bullets for rich text
.m-wacken__copy ol {
	counter-reset: wacken-ol-list;
	padding: 0;
	list-style: none;
	margin: 0;

	li {
		counter-increment: wacken-ol-list;
		display: flex;
		align-items: flex-start;
		position: relative;
	}

	li:not(:first-child) {
		@include mq($from: medium) {
			margin-top: $spacing-pink;
		}

		@include mq($from: large) {
			margin-top: $spacing-pink;
		}

		@include mq($from: xlarge) {
			margin-top: $spacing-maroon;
		}
	}

	li::before {
		content: counter(wacken-ol-list);
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 auto;
		width: 30px;
		height: 30px;
		background-color: $color-black;
		color: $color-white;
		font-weight: bold;
		margin-top: -0.8%;
		margin-right: 15px;
		@include svg-mask(i);
	}
}

.m-wacken__cover-wave {
	width: 100%;
	fill: $color-white;
	position: absolute;
	top: -2px;
	left: 0;
	z-index: 1;
}

// overflow crop helper, useful on mobile
.m-wacken__guitar-overflow-crop {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	pointer-events: none;

	@include mq($until: large) {
		height: calc(100% + #{$crop-helper-offset});
		// it should only crop left and right because of horizontal scroll
		overflow: hidden;
	}
}

// also needed for overflow crop
.m-wacken__guitar-wrapper {
	height: 100%;
	position: relative;
}

// --------------------------------------- step states paddings, spacings and more

[data-active-step="intro"] {
	.m-wacken__plektron {
		@include mq($until: medium) {
			display: none;
		}
	}

	.m-wacken__headline {
		@include mq($from: xlarge) {
			max-width: 500px;
		}
	}

	.m-wacken__button {
		@include mq($from: medium) {
			margin-top: $spacing-lilac;
		}

		@include mq($from: large) {
			margin-top: $spacing-lightgreen;
		}

		@include mq($from: xlarge) {
			margin-top: $spacing-lightblue;
		}
	}
}

// for centering the textblock in cases where guitar is not in attention mode
[data-active-step="intro"],
[data-active-step="success"] {
	.m-wacken__textual {
		@include mq($from: large) {
			margin-top: 18%;
		}

		@include mq($from: xlarge) {
			margin-top: 17%;
		}
	}
}

.m-wacken:not([data-active-step="intro"]) {
	.m-wacken__plektron {
		// swap side of logo on medium, when NOT intro step
		@include mq($from: medium, $until: large) {
			transform: translateX(60vw) rotate(27deg);
		}
	}
}

// for centering the textblock if guitar is in attention mode
[data-active-step="explanation"],
[data-active-step="failure"] {
	.m-wacken__textual {
		@include mq($from: large) {
			margin-top: 35%;
		}

		@include mq($from: xlarge) {
			margin-top: 23%;
		}
	}
}

[data-active-step="explanation"] {
	.m-wacken__copy {
		width: 82%;

		@include mq($until: medium) {
			max-width: 320px;
		}

		@include mq($from: medium) {
			width: 70%;
			margin-top: $spacing-orange;
		}

		@include mq($from: large) {
			width: 74%;
			margin-top: $spacing-yellow;
		}
	}

	.m-wacken__button {
		@include mq($from: medium) {
			margin-top: $spacing-lilac;
		}

		@include mq($from: large) {
			margin-top: $spacing-yellow;
		}

		@include mq($from: xlarge) {
			margin-top: $spacing-lightblue;
		}
	}
}

[data-active-step="game"] {
	.m-wacken__textual {
		@include mq($until: large) {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 40vw;
		}

		@include mq($from: large) {
			margin-top: 25%;
		}

		@include mq($from: xlarge) {
			margin-top: 15%;
		}
	}
}

[data-active-step="failure"].m-wacken {
	--background-color: #{$color-haribo-red};

	.m-wacken__copy {
		width: 70%;

		@include mq($from: medium) {
			width: 65%;
		}

		@include mq($from: large) {
			width: 100%;
			margin-top: $spacing-yellow;
		}

		@include mq($from: xlarge) {
			width: 70%;
		}
	}

	.m-wacken__button {
		@include mq($from: large) {
			margin-top: $spacing-yellow;
		}
	}
}

[data-active-step="success"].m-wacken--price-type-collection {
	.m-wacken__visual {
		width: percentage(12/$cols-m);
		margin-bottom: 0;

		@include mq($until: large) {
			order: 1;
			margin-left: 0;
		}

		@include mq($from: large) {
			width: percentage(14/$cols-l);
			margin-bottom: $spacing-purple;
			margin-left: -(percentage(1/$cols-l));
		}

		@include mq($from: xlarge) {
			width: percentage(14/$cols-xl);
			margin-left: -(percentage(1/$cols-xl));
		}
	}

	.m-wacken__textual {

		@include mq($until: large) {
			order: 2;
			width: percentage(9/$cols-m);
			margin-top: $spacing-lightblue;
			margin-bottom: $spacing-lightpink;
			margin-left: 0;
		}

		@include mq($until: medium) {
			width: percentage(12/$cols-m);
			max-width: 340px;
		}

		@include mq($from: large) {
			width: percentage(9/$cols-l);
			margin-bottom: 0;
			margin-left: percentage(2/$cols-l);
		}

		@include mq($from: xlarge) {
			max-width: 460px;
		}
	}

	.m-wacken__copy {
		margin-top: $spacing-yellow;
	}

	.m-wacken__button {
		margin-top: $spacing-yellow;

		@include mq($from: medium) {
			margin-top: $spacing-lilac;
		}

		@include mq($from: large) {
			margin-top: $spacing-yellow;
		}
	}
}

// --------------------------------------- animations

// used for pre game text
@keyframes wacken-pulse {
	0% {
		opacity: 0.8;
		transform: scale(1);
	}

	50% {
		opacity: 1;
		transform: scale(1.1);
	}

	100% {
		opacity: 0.8;
		transform: scale(1);
	}
}

// used for ROCK text
@keyframes wacken-rock {
	0% {
		transform: translateY(-45px);
	}

	25% {
		transform: translateY(0) scale(1.2);
	}

	40% {
		transform: translateY(-24px);
	}

	55% {
		transform: translateY(0);
	}

	65% {
		transform: translateY(-12px);
	}

	75% {
		transform: translateY(0);
	}

	82% {
		transform: translateY(-6px);
	}

	87% {
		transform: translateY(0);
	}

	93% {
		transform: translateY(-4px);
	}

	100% {
		transform: translateY(0);
	}
}

@import "components/Guitar";
