.m-maps__intro {
	padding-top: $spacing-yellow;
	padding-bottom: $spacing-yellow;
	background-image: radial-gradient(circle at 65% 50%, var(--gradient-color), var(--background-color) 87%);
	position: relative;

	@include mq($from: medium) {
		padding-top: $spacing-purple;
		padding-bottom: $spacing-purple;
	}

	@include mq($from: large) {
		padding-top: $spacing-petrol;
		padding-bottom: $spacing-petrol;
		background-image: radial-gradient(circle at 65% 50%, var(--gradient-color), var(--background-color) 67%);
	}

	@include mq($from: xlarge) {
		padding-top: $spacing-ocher;
		padding-bottom: $spacing-ocher;
	}

	// country map regions
	.c-map__svg-wrapper path {
		fill: var(--map-color);
	}
}

.m-maps__wrapper {
	display: flex;
	flex-direction: column;
	position: relative;

	@include mq($from: large) {
		flex-direction: row;
		align-items: flex-start;
	}
}

.m-maps__text {
	color: $color-white;

	@include mq($from: large) {
		width: percentage(10/$cols-l);
		margin-top: $spacing-petrol;
	}

	@include mq($from: xlarge) {
		width: percentage(11/$cols-xl);
		margin-top: $spacing-grey;
	}
}

.m-maps__intro-copy {
	margin-top: $spacing-orange;

	@include mq($from: large) {
		width: 80%;
		max-width: 430px;
		margin-top: $spacing-purple;
	}

	span {
		color: $color-haribo-yellow;
	}
}

.m-maps__map {
	align-self: center;
	width: 80%;
	margin-top: $spacing-pink;

	@include mq($from: medium) {
		width: percentage(10/$cols-m);
		max-width: 420px;
		margin-top: $spacing-royalblue;
	}

	@include mq($from: large) {
		align-self: initial;
		width: percentage(12/$cols-l);
		max-width: none;
		margin-top: 0;
		margin-left: percentage(1/$cols-l);
	}

	@include mq($from: xlarge) {
		width: percentage(9/$cols-xl);
		margin-left: percentage(1/$cols-xl);
	}
}

.m-maps__button {
	color: $color-white;
}

// narrow viewports button
.m-maps__wrapper > .m-maps__button {
	margin-top: $spacing-yellow;

	@include mq($until: large) {
		align-self: center;
	}

	@include mq($from: medium) {
		margin-top: $spacing-royalblue;
	}

	@include mq($from: large) {
		display: none;
	}
}

// large viewports button
.m-maps__text > .m-maps__button {
	@include mq($until: large) {
		display: none;
	}

	@include mq($from: large) {
		margin-top: $spacing-lightblue;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-seablue;
	}
}

.m-maps__bear {
	width: 140px;
	position: absolute;
	bottom: 0;
	right: 7%;
	z-index: 2;

	@include mq($until: large) {
		display: none;
	}

	@include mq($from: xlarge) {
		width: 160px;
	}
}
