.m-product-info {
	--background-color: #{$color-haribo-blue};
	--gradient-color: #{lighten($color-haribo-blue, $lighten-amount)};
	padding-top: $spacing-lightpink;
	padding-bottom: $spacing-lightblue;
	background-image: radial-gradient(circle at 51% 96%, var(--gradient-color), var(--background-color) 63%);
	position: relative;

	@include mq($from: medium) {
		padding-top: $spacing-darkgrey;
		padding-bottom: $spacing-softblue;
	}

	@include mq($from: large) {
		padding-top: $spacing-softblue;
		padding-bottom: $spacing-lightpink;
	}

	@include mq($from: xlarge) {
		padding-top: $spacing-darkgrey;
		padding-bottom: $spacing-grey;
	}

	.c-content-box-frame__navigation-wrapper {
		margin-top: $spacing-softblue;

		@include mq($from: large) {
			min-height: 400px;
		}

		@include mq($from: xlarge) {
			min-height: 500px;
			margin-top: $spacing-grey;
		}
	}

	.c-content-box-frame__navigation-button {
		color: $color-white;

		@include mq($from: large) {
			line-height: 1;
		}

		@include mq($from: xlarge) {
			line-height: 1.1;
		}

		+ .c-content-box-frame__navigation-button {
			@include mq($from: large) {
				margin-top: $spacing-olive;
			}

			@include mq($from: xlarge) {
				margin-top: $spacing-pink;
			}
		}

		/**
		* with more than 13 characters and a space, it is quite certain the text will be on 2 lines (see logic in JS)
		* we should reduce the max-width, so that the icon on the right is not too far away from the text
		* it is an imperfect workaround, for a difficult issue to solve in CSS
		* https://stackoverflow.com/questions/69709623/div-spans-consume-max-width-after-implicit-line-break
		*/
		&.has-maybe-multiple-lines {
			@include mq($from: large) {
				max-width: 95%;
			}
			@include mq($from: xlarge) {
				max-width: 300px;
			}
		}
	}

	.c-content-slider__pagination-item {
		background-color: rgba($color-haribo-yellow, 0.2);

		&.is-active {
			background-color: $color-haribo-yellow;
		}
	}

	.c-content-box-frame__arrow {
		color: $color-haribo-yellow;
	}

	.m-product-info__content-shape-svg-small {
		width: calc(100% + 16px);
		margin-left: -8px;
	}
}

.m-product-info__wrapper {
	@include mq($until: large) {
		width: 100%;
	}
}

.m-product-info__box-headline {
	@include mq($until: medium) {
		margin-bottom: $spacing-yellow;
	}

	@include mq($from: medium) {
		margin-bottom: $spacing-orange;
	}

	@include mq($from: large) {
		display: none;
	}
}

.m-product-info .m-product-info__section {
	color: $color-black;

	@include mq($until: medium) {
		width: map-get($grid-width, small);
		margin: 0 auto;
	}

	@include mq($from: medium, $until: large) {
		width: map-get($grid-width, medium);
		margin: 0 auto;
	}

	.c-content-shape {
		min-height: 360px;

		@include mq($from: medium) {
			min-height: 500px;
		}

		@include mq($from: large) {
			width: 100%;
		}
	}

	.c-content-shape__svg {
		@include is-rtl() {
			transform: scaleX(-1);
		}
	}
}

.m-product-info__section .c-content-shape__inner {
	padding-top: $spacing-petrol;
	padding-bottom: $spacing-petrol;

	@include mq($until: medium) {
		padding-left: percentage(1/14);
		padding-right: percentage(1/14);
	}

	@include mq($from: medium) {
		padding: $spacing-softblue percentage(2/14) $spacing-softblue;
	}

	@include mq($from: large) {
		padding-bottom: $spacing-lightblue;
		padding-left: percentage(2/16);
		padding-right: percentage(2/16);
	}

	@include mq($from: xlarge) {
		padding: $spacing-darkgrey percentage(3/15) $spacing-petrol;
	}
}

.m-product-info .flickity-enabled .m-product-info__section {
	@include mq($until: medium) {
		margin-left: 6%;
		margin-right: 6%;
	}

	@include mq($from: medium, $until: large) {
		margin-left: 2%;
		margin-right: 2%;
	}
}

.m-product-info__headline {
	margin-bottom: $spacing-pink;

	@include mq($from: medium) {
		margin-bottom: $spacing-yellow;
	}

	@include mq($from: xlarge) {
		margin-bottom: $spacing-orange;
	}
}

// stuff

.m-product-info__decorative-image {
	display: none;
	position: absolute;
	bottom: $spacing-olive;
	left: 0;
	pointer-events: none;

	@include is-rtl() {
		left: auto;
		right: 0;
		transform: scaleX(-1);
	}

	@include mq($from: large) {
		display: block;
		width: 250px;
		height: 250px;

		// reset aspect ratio styles
		.c-image-component__image-wrapper {
			width: 100%;
			height: 100%;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				object-position: bottom left;
				position: relative;
			}
		}

		.o-aspect-ratio {
			width: 100%;
			height: 100%;
		}

		.o-aspect-ratio::before {
			display: none;
		}

		.c-image-component__aspect-ratio-helper {
			display: none;
		}
	}

	@include mq($from: xlarge) {
		width: 340px;
		height: 340px;
	}
}

// special flexbox hack for active tab handling, because all the tabs should have same height as the highest
/* stylelint-disable-next-line no-duplicate-selectors */
.m-product-info {
	// does not work in mobile phone slider so we have to isolate it
	.c-content-box-frame__content-wrapper:not(.flickity-enabled) {
		display: flex;

		.m-product-info__section {
			display: block;
			flex: 0 0 auto;
			width: 100%;
			visibility: hidden;
			margin-right: -100%;

			&.is-displayed {
				visibility: visible;
			}
		}
	}

	// mobile equal height hack for flickity
	// https://github.com/metafizzy/flickity/issues/534#issuecomment-328859144
	.flickity-resize .m-product-info__section {
		@include mq($until: large) {
			height: 100%;

			.c-content-shape {
				height: 100%;
			}
		}
	}
}


.m-product-info__content-shape-svg-large {
	@include mq($until: medium) {
		display: none;
	}
}

.m-product-info__content-shape-svg-small {
	@include mq($from: medium) {
		display: none;
	}
}


html.no-js .m-product-info {
	.c-content-box-frame__navigation-button {
		opacity: 1;
		color: $color-white;
	}
}

.m-product-info.u-color-bg-maoam {
	color: $color-maoam-blue;

	.m-product-info__image-list-text,
	.m-product-info__image-list-image {
		color: $color-maoam-yellow;
	}

	.c-content-box-frame__navigation-button svg {
		fill: $color-maoam-orange;
	}

	.c-content-slider__pagination-item {
		background-color: $color-maoam-orange;
		opacity: 0.2;

		&.is-active {
			opacity: 1;
		}
	}

	.c-content-box-frame__navigation-button {
		color: $color-maoam-blue;
	}
}

@import "components/ContentNutrition";
@import "components/ContentImages";
@import "components/ContentIngredients";
