// Long and flat
.m-stage-product--long,
.m-stage-product--flat,
.m-stage-product--roulette {
	.m-stage-product__module-headline-wrapper {
		@include mq($from: medium) {
			width: percentage(7/$cols-m);
		}

		@include mq($from: large) {
			width: percentage(13/$cols-l);
		}

		@include mq($from: xlarge) {
			width: percentage(12/$cols-l);
		}
	}

	.m-stage-product__image-links-wrapper {
		position: relative;

		@include mq($until: medium) {
			width: percentage(7/$cols-s);
			bottom: -$spacing-salmon;
			margin-top: $spacing-royalblue - $spacing-salmon; // counter act the negative `bottom`
			margin-left: percentage(5/$cols-s);
		}

		@include mq($from: medium) {
			width: percentage(5/$cols-m);
			bottom: -270px;
			right: -32px;
			margin-top: -230px;
			margin-left: percentage(1/$cols-m);
		}

		@include mq($from: large) {
			width: percentage(9/$cols-l);
			bottom: -490px;
			right: 0;
			margin-top: -340px;
			margin-left: percentage(3/$cols-l);
		}

		@include mq($from: xlarge) {
			width: percentage(7/$cols-l);
			bottom: -500px;
			margin-top: -340px;
			margin-left: percentage(5/$cols-l);
		}
	}

	.m-stage-product__pin {
		width: 1px;
		height: 1px;
		position: absolute;

		.m-stage-product__image-link {
			position: absolute;
			bottom: 100%;
			right: 100%;
			margin-right: -12px;

			&.m-stage-product__image-link--speech-bubble-left {
				margin-left: -12px;
				margin-right: 0;
			}
		}
	}

	.m-stage-product__image {
		transform: rotate(-10deg);

		img {
			filter: drop-shadow(20px 15px 25px rgba($color-black, 0.23));
		}
	}

	.m-stage-product__pin--ingredients {
		top: 70%;
		left: -4%;

		@include mq($from: medium) {
			top: 43%;
			left: -10%;
		}

		@include mq($from: large) {
			top: 47%;
			left: -10%;
		}

		@include mq($from: xlarge) {
			top: 49%;
			left: -7%;
		}
	}

	.m-stage-product__image-link--ingredients {
		transform: rotate(-21deg);

		@include mq($from: medium) {
			transform: rotate(-19deg);
		}

		@include mq($from: large) {
			transform: rotate(-21deg);
		}

		@include mq($from: xlarge) {
			transform: rotate(-26deg);
		}
	}

	.m-stage-product__pin--shop {
		top: 38%;
		left: 95%;

		@include mq($from: medium) {
			top: 34%;
			left: 92%;
		}

		@include mq($from: large) {
			top: 32%;
			left: 92%;
		}

		@include mq($from: xlarge) {
			top: 36%;
			left: 96%;
		}
	}

	.m-stage-product__image-link--shop {
		top: -65px;
		left: 59%;
		transform: rotate(11deg);

		@include mq($from: medium) {
			transform: rotate(11deg);
		}

		@include mq($from: large) {
			transform: rotate(11deg);
		}

		@include mq($from: xlarge) {
			transform: rotate(17deg);
		}
	}
}

// Flat (Bloxxx)
.m-stage-product--flat {
	.m-stage-product__image {
		transform: rotate(-14deg);

		@include mq($until: medium) {
			transform: rotate(-10deg);
		}
	}

	.m-stage-product__image-links-wrapper {
		@include mq($until: medium) {
			width: percentage(5/$cols-s);
			bottom: -70px;
			margin-top: -25px;
			margin-left: percentage(6/$cols-s);
		}

		@include mq($from: medium) {
			width: percentage(3/$cols-m);
			margin-left: percentage(2/$cols-m);
		}

		@include mq($from: large) {
			width: percentage(5/$cols-l);
			bottom: -490px;
			margin-top: -300px;
			margin-left: percentage(4/$cols-l);
		}

		@include mq($from: xlarge) {
			width: percentage(4/$cols-l);
			bottom: -615px;
			margin-top: -449px;
			margin-left: percentage(6/$cols-l);
		}
	}

	.m-stage-product__pin--ingredients {
		@include mq($from: medium) {
			left: -22%;
		}

		@include mq($from: large) {
			left: -13%;
		}

		@include mq($from: xlarge) {
			top: 41%;
			left: -18%;
		}
	}

	.m-stage-product__pin--shop {
		@include mq($from: medium) {
			left: 84%;
		}

		@include mq($from: large) {
			left: 77%;
		}

		@include mq($from: xlarge) {
			top: 28%;
			left: 78%;
		}
	}
}

// special for haribo roulette

.m-stage-product--roulette {
	.m-stage-product__image-links-wrapper {
		@include mq($until: medium) {
			width: percentage(3/$cols-s);
			bottom: -80px;
			margin-top: -65px;
			margin-left: percentage(6/$cols-s);
		}

		@include mq($from: medium) {
			width: percentage(2/$cols-m);
			bottom: -480px;
			margin-top: -360px;
			margin-left: percentage(2/$cols-m);
		}

		@include mq($from: large) {
			width: percentage(3/$cols-l);
			bottom: -530px;
			margin-top: -370px;
			margin-left: percentage(5/$cols-l);
		}

		@include mq($from: xlarge) {
			width: 9%;
			bottom: -640px;
			margin-top: -460px;
			margin-left: percentage(6/$cols-xl);
		}
	}

	.m-stage-product__pin--ingredients {
		@include mq($from: medium) {
			left: -50%;
		}

		@include mq($from: large) {
			top: 40%;
			left: -60%;
		}

		@include mq($from: xlarge) {
			top: 38%;
			left: -70%;
		}
	}
}
