.m-stage-video-background {
	// ...
}

.m-stage-video-background__video-landscape,
.m-stage-video-background__video-portrait {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	object-position: center bottom;
	@include transition(transform);
}

.m-stage-video-background__video-landscape {
	@include mq($until: large) {
		display: none;
	}
}

.m-stage-video-background__video-portrait {
	@include mq($from: large) {
		display: none;
	}
}

// because the whole area reacts to click via JS if a link is given
.m-stage-video-background.has-link {
	cursor: pointer;
}

.m-stage-video-background.has-text-animation {
	.m-stage-b__text {
		opacity: 0;
		transform: translateX(-40px);
		transition: opacity, transform;
		transition-timing-function: $transition-default-easing;
		transition-duration: 0s;
	}
}

.m-stage-video-background.has-text-animation.has-started-video {
	.m-stage-b__text {
		opacity: 1;
		transform: translateX(0);
		transition-delay: var(--text-animation-delay, 3000s);
		transition-duration: 1.2s;
	}
}
