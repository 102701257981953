@import "ShopListHighlights";
@import "ShopListFacts";

.m-shop-list-item {
	--background-color: #{$color-haribo-blue};
	--gradient-color: #{lighten($color-haribo-blue, 12)};
	display: flex;
	flex-direction: column;
	position: relative;
	pointer-events: none;

	@include mq($until: large) {
		max-width: 450px;
		margin-left: auto;
		margin-right: auto;
	}
}

.m-shop-list-item + .m-shop-list-item {
	@include mq($until: large) {
		margin-top: $spacing-orange;
	}
}

.m-shop-list-item__top-cap,
.m-shop-list-item__bottom-cap {
	width: 100%;
	fill: var(--background-color);
}

.m-shop-list-item__inner {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding-left: 22px;
	padding-right: 22px;
	background-color: $color-haribo-blue;
	position: relative;
	z-index: 2;
	margin-top: -2px;
	margin-bottom: -2px;

	@include mq($from: large) {
		padding-left: 18px;
		padding-right: 18px;
	}

	@include mq($from: xlarge) {
		padding-left: 28px;
		padding-right: 28px;
	}

	> *:first-child {
		margin-top: -17%;
	}

	> *:last-child {
		margin-bottom: -21%;

		@include mq($from: xlarge) {
			margin-bottom: -24%;
		}
	}
}

.m-shop-list-item:has(.c-image-component) {
	.m-shop-list-item__inner {
		background-image: linear-gradient(to bottom, var(--background-color) 0%, var(--gradient-color) 100%);
	}

	.m-shop-list-item__bottom-cap {
		fill: var(--gradient-color);
	}
}

.m-shop-list-item__headline {
	color: $color-white;
	margin-top: 0;

	@include mq($until: large) {
		font-size: 22px;
	}

	@include mq($from: large) {
		display: -webkit-box; // stylelint-disable-line value-no-vendor-prefix;
		height: 5vw;
		-webkit-box-orient: vertical;
		max-height: 70px;
		overflow: hidden;
		line-height: 1.2;
		-webkit-line-clamp: 2;
	}
}

.m-shop-list-item__image {
	margin-top: $spacing-yellow;
	aspect-ratio: 16 / 9; // stylelint-disable-line property-no-unknown;

	@include mq($from: large) {
		margin-top: $spacing-pink;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-yellow;
	}
}

.m-shop-list-highlights {
	max-width: 400px;
	margin-top: $spacing-olive;

	@include mq($from: large) {
		max-width: none;
		margin-top: $spacing-pink;
	}
}

.m-shop-list-facts {
	margin-top: $spacing-yellow;
	margin-bottom: $spacing-pink;

	@include mq($from: large) {
		margin-top: $spacing-pink;
		margin-bottom: $spacing-olive;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-lilac;
		margin-bottom: $spacing-pink;
	}

	a {
		pointer-events: all;
	}
}

.m-shop-list__trigger {
	width: 100%;
	height: 100%;
	background-color: transparent;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	cursor: pointer;
	pointer-events: all;

	@include hover-focus() {
		~ .m-shop-list-item__inner {
			.m-shop-list-modal__button::after {
				transform: scale(1.20);
			}
		}
	}
}

// ------------------------------------------------------ Media Slider Override

.m-shop-list-item .c-media-slider {
	pointer-events: all;

	.c-media-slider__pagination {
		margin-left: auto;

		@include mq($from: large) {
			height: 20px;
			padding-left: 5px;
			padding-right: 5px;
		}

		@include is-rtl() {
			margin-left: 0;
			margin-right: auto;
		}
	}

	.c-media-slider__pagination-item {
		@include mq($from: large) {
			width: 5px;
			height: 5px;
			margin-left: 4px;
			margin-right: 4px;
		}
	}

	.c-media-slider__button-prev,
	.c-media-slider__button-next,
	.c-media-slider__pagination-item {
		z-index: 2;
	}

	.c-media-slider__button-prev,
	.c-media-slider__button-next {
		opacity: 0;
		@include transition(opacity 0.4s);

		@include mq($from: large, $until: xlarge) {
			width: 30px;
			height: 30px;
		}

		@include mq($from: xlarge) {
			width: 40px;
			height: 40px;
		}
	}

	.c-media-slider__aspect-ratio-overlay-inner {
		@include mq($from: large) {
			padding: 10px;
		}
	}
}

.m-shop-list-item:hover {
	.c-media-slider__button-prev,
	.c-media-slider__button-next {
		opacity: 1;
	}
}

// ------------------------------------------------------ Modal

.m-shop-list-modal {
	width: fit-content;
	margin-top: auto;
	margin-left: auto;
}

.m-shop-list-modal__button {
	width: 30px;
	height: 30px;
	background-color: $color-haribo-red;
	color: $color-white;
	margin-right: 20px;
	pointer-events: all;

	@include mq($from: xlarge) {
		width: 40px;
		height: 40px;
	}
}

.m-shop-list-modal__headline {
	color: $color-white;
}

.m-shop-list-modal__image {
	width: 100%;
	margin-top: $spacing-yellow;
	aspect-ratio: 16 / 9; // stylelint-disable-line property-no-unknown;
	@include mq($from: large) {
		margin-top: $spacing-orange;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-royalblue;
	}

	.c-media-slider__pagination {
		margin-left: auto;
	}
}

.m-shop-list-modal__split-group {
	margin-top: $spacing-olive;

	@include mq($from: large) {
		display: flex;
		column-gap: 30px;
		align-items: flex-start;
		margin-top: $spacing-lilac;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-purple;
	}

	.m-shop-list-highlights {
		@include mq($from: large) {
			order: 2;
			flex: 0 0 auto;
			width: 50%;
			margin-top: 0;
			margin-left: auto;
		}
	}

	.m-shop-list-facts {
		margin-bottom: 0;

		@include mq($until: large) {
			margin-top: $spacing-yellow;
		}

		@include mq($from: large) {
			flex: 0 0 auto;
			width: 45%;
			margin-top: 0;
		}
	}
}

.m-shop-list-modal__content-items {
	margin-top: $spacing-lightblue;

	@include mq($from: large) {
		margin-top: $spacing-lightblue;
	}

	> [class*="u-color-bg"] {
		padding-top: 0;
		padding-bottom: 0;
		background-color: transparent;
		background-image: none;
	}

	> * + * {
		margin-top: $spacing-lightblue !important;

		@include mq($from: large) {
			margin-top: $spacing-petrol !important;
		}
	}
}
