$duration: 0.3s;
$distance: 12px;
$easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.675);
@mixin icon-text-link-hover() {
	.c-icon-text-link__label {
		&::before,
		&::after {
			opacity: 1;
			transform: translateY(0);
		}

		&::before {
			transition: transform $duration $easeOutBack, opacity $duration;
		}

		&::after {
			transition: transform 0s $duration $easeOutBack, opacity 0s $duration;
		}
	}
}

@mixin icon-text-link-wave-arrow-right-hover($dir: ltr) {
	@if $dir == ltr {
		.c-icon-text-link__icon {
			transform: translateX(5px);
		}
	}

	@if $dir == rtl {
		.c-icon-text-link__icon {
			transform: scaleX(-1) translateX(5px);
		}
	}
}
