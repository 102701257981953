.m-inspiration-overlay {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 0; // trigger layout expansion in combination with min-height
	min-height: 100vh; // fallback
	min-height: 100dvh;
	position: absolute;
	top: 0;
	left: 0;
	clip-path: circle(100% at 50% 50%);
	will-change: clip-path;
	@include z-index(modal);

	// backdrop to prevent appearance of main page on iOS when bounce scroll
	&::after {
		content: "";
		width: 100%;
		height: 100vh;
		background-color: $color-white;
		position: fixed;
		top: 0;
		left: 0;
		z-index: -1;
	}
}

.m-inspiration-overlay__inner {
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 500px;

	@include mq($from: medium) {
		min-height: 650px;
	}
}

// ------------------------------------------------------------------------------------------- piece

.m-inspiration-overlay__piece {
	display: flex;
	flex: 1;
	overflow: hidden;
	background-image: radial-gradient(circle at 50% 50%, var(--gradient-color), var(--background-color) 80%);
	position: relative;
}

.m-inspiration-overlay__piece-image {
	width: 100%;
	height: 100%;
	position: relative;

	.o-aspect-ratio {
		@extend .o-full;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	.o-aspect-ratio::before {
		display: none;
	}

	// override aspect ratio helper
	img {
		width: 70% !important;
		height: auto !important;
		max-height: 100%;
		object-fit: contain;
		position: static !important;
	}
}

.m-inspiration-overlay__piece-text {
	color: $color-white;
	position: absolute;
	bottom: $spacing-orange;
	left: $spacing-orange;

	@include mq($from: medium) {
		bottom: $spacing-lilac;
		left: $spacing-lilac;
	}

	@include mq($from: xlarge) {
		bottom: $spacing-purple;
		left: $spacing-purple;
	}
}

// ------------------------------------------------------------------------------------------- products

.m-inspiration-overlay__products {
	flex: 0 0 auto;
	overflow: hidden;
	background-color: $color-white;

	// overrides for pagination dots when inside flickity on medium and small

	.flickity-page-dots {
		bottom: 15px;

		.dot {
			background-color: $color-white;
			opacity: 0.15;
			margin-left: 5px;
			margin-right: 5px;
			@include transition(opacity);

			@include mq($until: medium) {
				width: 6px;
				height: 6px;
			}
		}

		.dot.is-selected {
			opacity: 1;
		}
	}
}

.m-inspiration-overlay__products-inner {
	@include mq($from: large) {
		display: flex;
	}

	/* enable/disable Flickity */
	&::after {
		content: "flickity";
		display: none;

		@include mq($from: large) {
			content: "";
		}
	}

	.m-inspiration-overlay__product,
	.m-inspiration-overlay__teaser {
		max-height: 310px;
		background-image: radial-gradient(circle at 50% 50%, var(--gradient-color), var(--background-color) 80%);
		position: relative;
		@include aspect-ratio-helper("1x1");

		@include mq($until: medium) {
			width: 70%;
		}

		@include mq($from: medium, $until: large) {
			width: 50%;
		}

		@include mq($from: large) {
			flex: 1;
		}

		&:only-child {
			@include mq($until: large) {
				width: 100%;
			}

			&::before {
				@include mq($until: medium) {
					padding-top: 70%;
				}
			}
		}
	}
}

.m-inspiration-overlay__product--flat {
	img {
		transform: rotate(-15deg);
	}
}

.m-inspiration-overlay__product--roulette {
	img {
		transform: rotate(-15deg);
	}
}

.m-inspiration-overlay__product-image {
	width: 100%;
	height: 100%;
	@include transition(transform);

	.c-image-component__image-wrapper {
		@extend .o-full;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.c-image-component__aspect-ratio-helper {
		display: none;
	}

	img {
		object-fit: contain;
		position: static;

		@include mq($until: medium) {
			width: 64%;
			height: 64%;
		}

		@include mq($from: medium) {
			width: 70%;
			height: 70%;
		}
	}
}

.m-inspiration-overlay__product-link {
	background-color: transparent;
	outline: none;
	z-index: 2;

	@include hover-focus() {
		~ .m-inspiration-overlay__product-image {
			transform: scale(1.1);
		}
	}
}

[data-whatinput="keyboard"] .m-inspiration-overlay__product:focus-within {
	@include keyboard-focus(-3px);
}

.m-inspiration-overlay__close {
	width: 46px;
	height: 46px;
	background-color: $color-white;
	color: $color-haribo-red;
	position: fixed;
	top: $spacing-orange;
	right: $spacing-orange;
	z-index: 2;

	@include mq($from: medium) {
		width: 50px;
		height: 50px;
		top: $spacing-lilac;
		right: $spacing-lilac;
	}

	@include mq($from: large) {
		width: 60px;
		height: 60px;
		top: $spacing-lilac;
		left: $spacing-lilac;
		right: auto;
	}

	@include mq($from: xlarge) {
		top: $spacing-purple;
		left: $spacing-purple;
	}

	[data-whatinput="keyboard"] &:focus {
		@include keyboard-focus();
	}
}

.m-inspiration-overlay__teaser {
	background-image: radial-gradient(circle at 90% 80%, var(--gradient-color) 0.2%, var(--background-color) 100%);
	color: $color-white;
}

.m-inspiration-overlay__teaser-inner {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: $spacing-yellow;

	@include mq($from: large) {
		padding: $spacing-lilac;
	}
}

.m-inspiration-overlay__teaser-link {
	align-items: flex-start;
	outline: none;
	position: static;
	@include font-style(headline-i);

	&::before {
		content: "";
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	svg {
		margin-top: 0.35em;

		@include mq($from: xlarge) {
			margin-top: 0.20em;
		}
	}
}

[data-whatinput="keyboard"] .m-inspiration-overlay__teaser:focus-within {
	@include keyboard-focus(-3px);
}

// ------------------------------------------------------------------------------------------- horizontal layout

@mixin inspiration-overlay-horizontal() {
	.m-inspiration-overlay__inner {
		flex-direction: row;
	}

	.m-inspiration-overlay__piece {
		height: 100%;
	}

	.m-inspiration-overlay__products-inner {
		flex-direction: column;
		height: 100%;
	}

	.m-inspiration-overlay__product,
	.m-inspiration-overlay__teaser {
		flex: 0 0 auto;
		width: 100%;
		height: 50%;
		max-height: none;
	}

	.m-inspiration-overlay__piece-text {
		text-align: right;
		top: $spacing-purple;
		bottom: auto;
		left: auto;
		right: 50px;

		@include mq($from: xlarge) {
			top: $spacing-lightgreen;
			right: 65px;
		}
	}
}

[data-related-count="1"] {
	@include mq($from: large) {
		@include inspiration-overlay-horizontal();

		.m-inspiration-overlay__piece-image img {
			// override aspect ratio helper
			width: 60% !important;
		}

		.m-inspiration-overlay__product-image img {
			width: 55%;
		}

		.m-inspiration-overlay__products {
			flex: 0 0 50%;
		}

		.m-inspiration-overlay__product,
		.m-inspiration-overlay__teaser {
			height: 100%;
		}
	}
}

[data-related-count="2"] {
	@include mq($from: large) {
		@include inspiration-overlay-horizontal();

		.m-inspiration-overlay__products {
			flex: 0 0 33.33%;
		}

		.m-inspiration-overlay__product,
		.m-inspiration-overlay__teaser {
			height: 50%;
		}
	}

	.flickity-page-dots {
		@include mq($from: medium, $until: large) {
			display: none;
		}
	}
}

// ------------------------------------------------------------------------------------------- animation

.m-inspiration-overlay.a-enter-active,
.m-inspiration-overlay.a-leave-active {
	@include transition(clip-path);
}

.m-inspiration-overlay.a-enter,
.m-inspiration-overlay.a-leave-to {
	clip-path: circle(0% at 50% 50%);
}

.m-inspiration-overlay.a-enter-active {
	transition-timing-function: $transition-default-easing;
	transition-duration: 0.8s;
}

.m-inspiration-overlay.a-leave-active {
	transition-duration: 0.4s;
}
