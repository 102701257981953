.m-stage-product--standup {
	.m-stage-product__module-headline-wrapper {
		@include mq($from: medium) {
			width: percentage(6/$cols-m);
		}

		@include mq($from: large) {
			width: percentage(12/$cols-l);
		}
	}

	.m-stage-product__image-links-wrapper {
		position: relative;

		@include mq($until: medium) {
			width: percentage(9/$cols-s);
			bottom: -$spacing-lightpink;
			margin-left: auto;
			margin-right: auto;
		}

		@include mq($from: medium) {
			width: percentage(6/$cols-m);
			bottom: -$spacing-darkgrey;
			right: -64px;
		}

		@include mq($from: large) {
			width: percentage(10/$cols-l);
			bottom: -$spacing-darkgrey;
			right: -130px;
			margin-top: $spacing-darkgrey;
		}

		@include mq($from: xlarge) {
			width: percentage(9/$cols-l);
			bottom: -$spacing-indianred;
			right: -210px;
			margin-top: -$spacing-softblue;
		}
	}

	.m-stage-product__pin {
		width: 1px;
		height: 1px;
		position: absolute;

		.m-stage-product__image-link {
			position: absolute;
			bottom: 100%;
			right: 100%;
			margin-right: -12px;

			&.m-stage-product__image-link--speech-bubble-left {
				margin-left: -12px;
				margin-right: 0;
			}
		}
	}

	.m-stage-product__image {
		img {
			filter: drop-shadow(20px 15px 25px rgba($color-black, 0.23));
		}
	}

	.m-stage-product__pin--ingredients {
		top: 36%;
		left: -2%;

		@include mq($from: medium) {
			top: 35%;
			left: -1%;
		}

		@include mq($from: large) {
			top: 35%;
			left: -1%;
		}

		@include mq($from: xlarge) {
			top: 35%;
			left: -1%;
		}
	}

	.m-stage-product__image-link--ingredients {
		transform: rotate(-21deg);

		@include mq($from: medium) {
			transform: rotate(-19deg);
		}

		@include mq($from: large) {
			transform: rotate(-26deg);
		}
	}

	.m-stage-product__pin--shop {
		top: 17%;
		left: 103%;

		@include mq($from: medium) {
			top: 28%;
			left: 97%;
		}

		@include mq($from: large) {
			top: 28%;
			left: 97%;
		}

		@include mq($from: xlarge) {
			top: 28%;
			left: 97%;
		}
	}

	.m-stage-product__image-link--shop {
		top: -65px;
		left: 59%;
		transform: rotate(11deg);

		@include mq($from: medium) {
			transform: rotate(10deg);
		}

		@include mq($from: large) {
			transform: rotate(17deg);
		}
	}
}
