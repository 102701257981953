.m-teaser-list-b {
	position: relative;
}

.m-teaser-list-b__results {
	display: grid;

	@include mq($until: large) {
		grid-template-columns: 1fr 1fr;
	}

	@include mq($from: large) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}

.m-teaser-list-b__teaser {
	background-image: radial-gradient(circle at 50% 50%, var(--gradient-color), var(--background-color) 80%);
	position: relative;
	@include aspect-ratio-helper("1x1");

	.m-teaser-list-b__image {
		transform: scale(0.92);
	}

	// all grid items
	.m-teaser-list-b__image--bag img {
		width: calc(55% + 19px);
	}

	.m-teaser-list-b__image--box img {
		width: calc(72% + 19px);
	}

	.m-teaser-list-b__image--long img {
		width: 27%;

		@include mq($until: medium) {
			width: calc(18% + 19px);
		}
	}

	.m-teaser-list-b__image--flat img {
		width: calc(14%);
		transform: rotate(-15deg);

		@include mq($until: medium) {
			width: calc(4% + 19px);
		}
	}

	.m-teaser-list-b__image--pillow img {
		width: calc(70% + 19px);
	}

	.m-teaser-list-b__image--standup img {
		width: calc(42% + 19px);
	}

	// roulette special case
	.m-teaser-list-b__image--roulette img {
		width: calc(8.2%);
		transform: rotate(-15deg);

		@include mq($until: medium) {
			width: calc(4% + 15px);
		}
	}
}

.m-teaser-list-b .m-teaser-list-b__image--generic {
	display: flex;
	align-items: center;
	justify-content: center;

	.c-image-component__image-wrapper {
		width: 78%;
		height: auto;
		position: relative;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		position: absolute;
	}

	.o-aspect-ratio::before {
		display: block;
		padding-bottom: 100%;
	}

	.c-image-component__aspect-ratio-helper {
		display: block;
		padding-bottom: 100% !important; // inline override
	}
}

[data-filter-state="is-unfiltered"] {
	/*
		--- large grid items ---
		:not(:nth-last-child()) because we don't want to apply it if the large item would be in the last row
		and as a consequence the large teaser (_teaser.scss) would not fit in height
	*/
	.m-teaser-list-b__teaser:nth-child(1):not(:nth-last-child(-n+#{4})),
	.m-teaser-list-b__teaser:nth-child(12):not(:nth-last-child(-n+#{3})),
	.m-teaser-list-b__teaser:nth-child(19):not(:nth-last-child(-n+#{4})),
	.m-teaser-list-b__teaser:nth-child(30):not(:nth-last-child(-n+#{3})),
	.m-teaser-list-b__teaser:nth-child(37):not(:nth-last-child(-n+#{4})),
	.m-teaser-list-b__teaser:nth-child(48):not(:nth-last-child(-n+#{3})),
	.m-teaser-list-b__teaser:nth-child(55):not(:nth-last-child(-n+#{4})),
	.m-teaser-list-b__teaser:nth-child(66):not(:nth-last-child(-n+#{3})),
	.m-teaser-list-b__teaser:nth-child(73):not(:nth-last-child(-n+#{4})),
	.m-teaser-list-b__teaser:nth-child(84):not(:nth-last-child(-n+#{3})),
	.m-teaser-list-b__teaser:nth-child(91):not(:nth-last-child(-n+#{4})),
	.m-teaser-list-b__teaser:nth-child(102):not(:nth-last-child(-n+#{3})) {
		@include mq($until: large) {
			grid-column: span 2;
		}

		@include mq($from: large) {
			grid-column: span 2;
			grid-row: span 2;
		}

		.m-teaser-list-b__image--bag img {
			width: calc(62% + 19px);
		}

		.m-teaser-list-b__image--flat img {
			width: 14%;
		}

		.m-teaser-list-b__badge {
			top: 3%;
			right: 10%;
		}

		.m-teaser-list-b__badge--new,
		.m-teaser-list-b__badge--available-soon {
			width: 102px;
			height: 98px;
			background-color: $color-white;

			@include mq($from: medium) {
				width: 157px;
				height: 150px;
			}

			@include mq($from: large) {
				width: 125px;
				height: 120px;
			}

			@include mq($from: xlarge) {
				width: 208px;
				height: 200px;
			}
		}

		.m-teaser-list-b__badge--season {
			width: 84px;
			height: 95px;

			@include mq($from: medium) {
				width: 141px;
				height: 160px;
			}

			@include mq($from: large) {
				width: 106px;
				height: 120px;
			}

			@include mq($from: xlarge) {
				width: 176px;
				height: 200px;
			}
		}

		.m-teaser-list-b__badge-label {
			font-size: 17px;

			@include mq($from: medium) {
				font-size: 26px;
			}

			@include mq($from: large) {
				font-size: 20px;
			}

			@include mq($from: xlarge) {
				font-size: 29px;
			}
		}

		.m-teaser-list-b__badge--season .m-teaser-list-b__badge-label {
			padding-top: 36px;

			@include mq($from: medium) {
				padding-top: 58px;
			}

			@include mq($from: large) {
				padding-top: 42px;
			}

			@include mq($from: xlarge) {
				padding-top: 65px;
			}
		}
	}
}

.m-teaser-list-b__ghost-link {
	z-index: 2;

	@include hover-focus() {
		~ .m-teaser-list-b__image {
			transform: scale(1);
		}
	}
}

.m-teaser-list-b__image {
	overflow: hidden;
	@include transition(transform);

	img {
		width: 100%;
		height: auto;
		position: static;
	}

	.c-image-component__image-wrapper {
		@extend .o-full;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.c-image-component__aspect-ratio-helper {
		display: none;
	}
}

.m-teaser-list-b__badge {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 4%;
	right: 4%;
}

.m-teaser-list-b__badge--new,
.m-teaser-list-b__badge--available-soon {
	width: 57px;
	height: 55px;
	background-color: $color-white;

	@include mq($from: medium) {
		width: 83px;
		height: 80px;
	}

	@include mq($from: xlarge) {
		width: 99px;
		height: 95px;
	}
}

.m-teaser-list-b__badge--season {
	width: 60px;
	height: 68px;

	@include mq($from: medium) {
		width: 78px;
		height: 88px;
	}

	@include mq($from: xlarge) {
		width: 92px;
		height: 103px;
	}
}

.m-teaser-list-b__badge-label {
	color: $color-haribo-red;
	font-size: 10px;
	line-height: 1;
	font-weight: bold;
	text-align: center;
	position: relative;
	z-index: 1;
	transform: rotate(14deg);

	@include mq($from: medium) {
		font-size: 14px;
	}

	@include mq($from: xlarge) {
		font-size: 16px;
	}

	.m-teaser-list-b__badge--season & {
		width: 87%;
		padding-top: 25px;
		margin-left: 4px;

		@include mq($from: medium) {
			padding-top: 32px;
		}

		@include mq($from: xlarge) {
			padding-top: 37px;
		}
	}

	.m-teaser-list-b__badge--new &,
	.m-teaser-list-b__badge--available-soon & {
		width: 78%;
	}
}

.m-teaser-list-b:first-child {
	@include mq($until: medium) {
		.m-teaser-list-b__teaser:first-child,
		[data-filter-state="is-filtered"] .m-teaser-list-b__teaser:nth-child(2) {
			padding-top: 75px;

			.m-teaser-list-b__image {
				margin-top: 38px;
			}

			.m-teaser-list-b__badge {
				top: calc(5% + 75px);
			}
		}
	}

	@include mq($from: medium, $until: large) {
		.m-teaser-list-b__teaser:first-child,
		[data-filter-state="is-filtered"] .m-teaser-list-b__teaser:nth-child(2) {
			padding-top: 85px;

			.m-teaser-list-b__image {
				margin-top: 43px;
			}

			.m-teaser-list-b__badge {
				top: calc(4% + 85px);
			}
		}
	}

	@include mq($from: large) {
		.m-teaser-list-b__teaser:first-child,
		.m-teaser-list-b__teaser:nth-child(2),
		.m-teaser-list-b__teaser:nth-child(3),
		[data-filter-state="is-filtered"] .m-teaser-list-b__teaser:nth-child(4) {
			padding-top: 130px;

			.m-teaser-list-b__image {
				margin-top: 65px;
			}
		}

		.m-teaser-list-b__teaser:first-child {
			.m-teaser-list-b__badge {
				top: calc(3% + 130px);
			}
		}

		.m-teaser-list-b__teaser:nth-child(2),
		.m-teaser-list-b__teaser:nth-child(3),
		[data-filter-state="is-filtered"] .m-teaser-list-b__teaser:nth-child(4) {
			.m-teaser-list-b__badge {
				top: calc(5% + 130px);
			}
		}
	}
}

.m-teaser-list-b__teaser-link-wrapper {
	--background-color: #{$color-haribo-blue};
	--gradient-color: #{lighten($color-haribo-blue, $lighten-amount)};
	overflow: hidden;
	background-color: $color-haribo-blue;
	background-image: radial-gradient(circle at 90% 80%, var(--gradient-color) 0.2%, var(--background-color) 100%);
	position: relative;
}

.m-teaser-list-b__teaser-link-inner {
	display: flex;
	align-items: center;
	padding-left: 9%;
	padding-right: 9%;
	color: $color-white;
}

.m-teaser-list-b__teaser-link-main {
	display: flex;
	flex-direction: column;

	@include mq($until: large) {
		margin-bottom: 40px;
	}
}

.m-teaser-list-b__teaser-headline {
	color: $color-white;
}

.m-teaser-list-b__teaser-link {
	width: fit-content;
	position: static;
	margin-top: $spacing-pink;

	&::after {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
}

.m-teaser-list-b.has-single-occasion {
	.m-teaser-list-b__badge {
		display: none;
	}
}

/*
teaser link to fill space
grid span for remaining space in grid is getting calculated with JS because the CSS would be too verbose
*/

.m-teaser-list-b__teaser-link-wrapper[data-grid-span="1"] {
	$custom-breakpoint: 485px;
	grid-column: span 1;

	.m-teaser-list-b__teaser-headline {
		@include font-style(headline-h);

		@include mq($until: $custom-breakpoint) {
			font-size: 17px;
		}
	}

	.c-icon-text-link__icon {
		align-self: flex-start;
		margin-top: 5px;

		@include mq($until: $custom-breakpoint) {
			width: 45px;
			margin-top: 2px;
			margin-right: 7px;
		}

		@include mq($from: large) {
			margin-top: 7px;
		}
	}

	.c-icon-text-link__label {
		line-height: 1.3;
	}

	.m-teaser-list-b__teaser-link {
		@include mq($until: $custom-breakpoint) {
			font-size: 14px;
		}
	}
}

.m-teaser-list-b__teaser-link-wrapper[data-grid-span="2"] {
	grid-column: span 2;

	@include mq($until: medium) {
		min-height: 240px;
	}

	@include mq($until: large) {
		padding-bottom: 50%;
	}

	.m-teaser-list-b__teaser-headline {
		@include font-style(headline-f1);
	}
}

.m-teaser-list-b__teaser-link-wrapper[data-grid-span="3"] {
	grid-column: span 3;

	.m-teaser-list-b__teaser-headline {
		@include font-style(headline-e1);
	}
}

.m-teaser-list-b__teaser-link-wrapper[data-grid-span="4"] {
	grid-column: span 4;

	@include mq($from: large) {
		padding-top: 25%;
	}

	.m-teaser-list-b__teaser-headline {
		@include font-style(headline-e1);
	}
}

// teaser position, if filtered and in first row

@include mq($until: large) {
	.m-teaser-list-b__teaser-link-wrapper:nth-child(-n+2) {
		.m-teaser-list-b__teaser-link-main {
			margin-top: 50px;
			margin-bottom: 0;
		}
	}
}

@include mq($from: large) {
	.m-teaser-list-b__teaser-link-wrapper:nth-child(-n+4) {
		.m-teaser-list-b__teaser-link-main {
			margin-top: 70px;
			margin-bottom: 0;
		}
	}
}

.m-teaser-list-b__teaser-link-wrapper.u-color-bg-maoam {
	color: $color-maoam-yellow;

	.m-teaser-list-b__teaser-headline,
	.c-icon-text-link__label {
		color: $color-maoam-blue;
	}

	.c-icon-text-link__icon {
		fill: $color-maoam-orange;
	}
}
