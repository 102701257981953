.m-sf-job-application.m-form {
	color: $color-haribo-blue;


	.m-form__headline {
		width: percentage(20/$cols-xl);
	}

	.m-form__copy {
		width: percentage(20/$cols-xl);

		@include property-breakpoint-map(margin-top, (
			small: $spacing-pink,
			medium: $spacing-yellow,
			large: $spacing-pink,
			xlarge: $spacing-orange
		));
	}

	.m-form__form {
		/*
	@include property-breakpoint-map(margin-top, (
		small: $spacing-lilac,
		medium: $spacing-lightblue,
		large: $spacing-petrol,
		xlarge: $spacing-softblue
	));
	*/
		margin-top: 20px;
	}

	.m-form__footer {
		@include property-breakpoint-map(margin-top, (
			small: $spacing-pink,
			medium: $spacing-lilac,
			large: $spacing-lilac,
			xlarge: $spacing-lilac,
		));

		@include mq($from: medium) {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
		}
	}

	.c-form__input-wrapper {
		display: flex;
		align-items: flex-end;
	}

	.c-form__input {
		flex: 1 1 auto;
		width: 100%;
	}

	.m-form__error {
		width: 100%;
		color: $color-haribo-red;

		@include property-breakpoint-map(margin-top, (
			small: $spacing-pink,
			medium: $spacing-lilac,
			large: $spacing-lilac,
			xlarge: $spacing-lilac,
		));
	}

	.c-form__tooltip {
		display: flex;
		align-items: center;
		@include property-breakpoint-map(height, $input-height-map);
		flex: 0 0 auto;
		margin-left: 12px;

		@include is-rtl() {
			margin-left: 0;
			margin-right: 12px;
		}
	}

	.m-form__submit-button-wrapper {
		width: 100%;
		margin-top: $spacing-pink;

		@include mq($from: medium) {
			align-self: flex-end;
			width: auto;
			padding-left: $spacing-royalblue;

			@include is-rtl() {
				padding-left: 0;
				padding-right: 35px;
			}
		}
	}

	.m-form__submit-button {
		justify-content: center;
		width: 100%;
	}

	.m-form__submit-success-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 855px;
		margin: auto;
	}

	.m-form__submit-success-image {
		flex: 1 0 auto;

		@include property-breakpoint-map(width, (
			small: 100px,
			medium: 144px,
			large: 204px,
			xlarge: 270px
		));
	}

	.m-form__submit-success-note {
		color: $color-haribo-blue;

		@include property-breakpoint-map(padding-right, (
			small: $spacing-purple,
			medium: $spacing-seablue,
			large: $spacing-grey,
			xlarge: $spacing-fuchsia-dark
		));
	}

	@import "components/FormLayout";


	.sf-job-form--info {
		@include property-breakpoint-map(margin-top, (
			small: $spacing-lilac,
			medium: $spacing-lightblue,
			large: $spacing-petrol,
			xlarge: $spacing-softblue
		));
		padding: 20px 30px;
		background: #EDF3F9A8;
		border-radius: 10px;
		font-size: 1.23rem;
		font-weight: 700;
	}
}
