.m-stage-shape .m-stage-b__slide-inner {
	@include mq($until: large) {
		display: flex;
		flex-direction: column;
		align-items: stretch;
	}

	.m-stage-b__text {
		@include mq($until: large) {
			margin-bottom: auto;
		}

		@include mq($from: large) {
			width: 30%;
		}
	}
}

.m-stage-shape .m-stage-b__visual {
	position: absolute;
	bottom: 0;
	right: 0;

	@include is-rtl() {
		transform: scaleX(-1);

		img {
			transform: scaleX(-1);
		}
	}
}

.m-stage-shape__image-wrapper {
	display: block;
	// to avoid hairline gaps at borders of slide
	top: 2px !important;
	left: 2px !important;
}

.m-stage-shape__image {
	position: relative;
	z-index: 2;
	mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='480' height='480' viewBox='0 0 480 480' preserveAspectRatio='none'%3E%3Cpath d='M59.16 480H480V2.26c-49.25-7.4-117.32 3.97-215.41 25.21C-.11 84.77-46.59 137.08 39.16 420.1c6.64 21.93 13.29 41.84 20 59.9z'/%3E%3C/svg%3E");
	mask-repeat: no-repeat;
	mask-size: 100% auto;

	img {
		object-fit: cover;
		object-position: 50% 80%;
	}
}

.m-stage-shape__image-shadow {
	width: 100%;
	height: auto;
	fill: $color-black;
	opacity: 0.15;
	position: absolute;
	top: 14px;
	left: 0;
	z-index: 1;
	transform: rotate(2deg) scale(1.08);
}

.m-stage-shape__video-modal {
	position: absolute;
	bottom: 14%;
	left: 50%;
	z-index: 3;
	margin-left: -35px;

	@include mq($from: medium) {
		bottom: 20%;
		margin-left: -47px;
	}

	@include mq($from: large) {
		top: 50%;
		bottom: auto;
		left: auto;
		right: 22%;
		margin-top: -47px;

		@include is-rtl() {
			left: 22%;
			right: auto;
		}
	}

	@include mq($from: xlarge) {
		margin-top: -62px;
		margin-left: -62px;
	}
}
