@mixin toggle-switch--big() {
	--toggle-switch-width: 57px;
	--toggle-switch-knob-diamter: 36px;
}

@mixin toggle-switch-long-shared() {
	display: block;

	.c-toggle-switch__label {
		display: block;
		text-align: center;
		margin-left: 0;
	}
}

@mixin toggle-switch--long() {
	--toggle-switch-width: 90px;
	--toggle-switch-knob-diamter: 42px;
	@include toggle-switch-long-shared();

	.c-toggle-switch__icon-left,
	.c-toggle-switch__icon-right {
		width: 30px;
	}

	.c-toggle-switch__label {
		margin-top: $spacing-pink;
	}
}

@mixin toggle-switch--long-big() {
	--toggle-switch-width: 100px;
	--toggle-switch-knob-diamter: 50px;
	@include toggle-switch-long-shared();

	.c-toggle-switch__icon-left,
	.c-toggle-switch__icon-right {
		width: 36px;
	}

	.c-toggle-switch__label {
		margin-top: $spacing-yellow;
	}
}

@mixin toggle-switch--same-background() {
	.c-toggle-switch__background {
		background-color: $color-haribo-blue;
	}
}
