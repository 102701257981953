$drag-knob-height-s: 30px;
$drag-knob-height-m: 40px;
$drag-knob-height-l: 48px;
$drag-knob-height-xl: 54px;

.c-drag-scroller {
	--accent-color: #{$color-haribo-blue};
	position: relative;
}

.c-drag-scroller__controls {
	width: 65%;
	height: $drag-knob-height-s;
	position: relative;
	margin-top: $spacing-lilac;
	margin-left: auto;
	margin-right: auto;

	@include mq($from: medium) {
		width: 75%;
		height: $drag-knob-height-m;
		margin-top: $spacing-orange;
	}

	@include mq($from: large) {
		height: $drag-knob-height-l;
		margin-top: $spacing-purple;
	}

	@include mq($from: xlarge) {
		height: $drag-knob-height-xl + 10px;
	}
}

.c-drag-scroller__wave-svg-front,
.c-drag-scroller__wave-svg-back {
	path {
		stroke-width: 8px;
		fill: none;
		stroke-linecap: round;

		@include mq($from: large) {
			stroke-width: 12px;
		}

		@include mq($from: xlarge) {
			stroke-width: 16px;
		}
	}
}

.c-drag-scroller__wave-svg-front {
	width: 1px;
	overflow: hidden;
	z-index: 2;

	path {
		stroke: var(--accent-color);
	}
}

.c-drag-scroller__wave-svg-back {
	path {
		stroke: rgba(0,0,0,0.15);
	}
}

.c-drag-scroller__knob {
	display: flex;
	align-items: center;
	justify-content: center;
	width: $drag-knob-height-s;
	height: $drag-knob-height-s;
	background-color: var(--accent-color);
	border-color: inherit;
	border-radius: 50%;
	outline: 0;
	box-shadow: 0 0 0 0 var(--accent-color);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
	@include transition(box-shadow);

	@include hover-focus() {
		box-shadow: 0 0 0 3px var(--accent-color);
	}

	@include mq($from: medium) {
		width: $drag-knob-height-m;
		height: $drag-knob-height-m;
	}

	@include mq($from: large) {
		width: $drag-knob-height-l;
		height: $drag-knob-height-l;
	}

	@include mq($from: xlarge) {
		width: $drag-knob-height-xl;
		height: $drag-knob-height-xl;
	}
}

.c-drag-scroller__knob-inner {
	width: 50%;
	height: 50%;
	background-color: $color-white;
	border-radius: 50%;
	z-index: 2;
}


.c-drag-scroller:not(.has-initialized-slider) {
	.c-drag-scroller__content {
		display: flex;
	}

	.c-drag-scroller__controls {
		display: none;
	}
}


.c-drag-scroller.has-initialized-slider {
	.c-drag-scroller__content {
		@include hide-scrollbars();

		// override some plugin styles
		> div {
			display: flex !important;
			padding-right: initial !important;
			vertical-align: initial !important;
		}
	}
}
