.c-video-component {
	position: relative;

	// Handle hover of whole component because the clicks on the image also start the video
	@include hover-focus() {
		.c-video-component__shape {
			transform: scale(1.1);
		}
	}
}

.c-video-component__player {
	visibility: hidden;
	opacity: 0;
	z-index: 3;
}

.c-video-component__button-wrapper {
	display: flex;
}

.c-video-component__consent-prompt-button {
	display: none;
}


.missing-user-consent {
	.c-video-component__consent-prompt-button {
		display: flex;
	}

	.c-video-component__consent-prompt-copy {
		display: flex;
		color: $color-haribo-red;
		font-weight: bold;
		z-index: 3;
		margin: auto;
	}

	.c-video-component__play-button {
		display: none;
	}

	.c-video-component__overlay {
		background-color: $color-white;
		opacity: 0.8;
		@include transition(opacity 0.3s);
	}
}

.c-video-component__play-button.c-shaped-icon-button {
	width: 65px;
	height: 65px;
	z-index: 5;
	margin: auto;

	@include mq($from: medium) {
		width: 95px;
		height: 95px;
	}

	@include mq($from: xlarge) {
		width: 120px;
		height: 120px;
	}
}

.c-video-component__close-button {
	display: none;
	padding: 5px;
	background-color: $color-black;
	border-top-right-radius: 4px;
	color: $color-white;
	font-size: 10px;
	line-height: 1;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 4;
}

.c-video-component__image {
	cursor: pointer;
}

.c-video-component__overlay {
	background-color: $color-black;
	opacity: 0;
	z-index: 2;
	pointer-events: none;
	@include transition(opacity 0.6s 0.3s, background-color 0.6s 0.6s);
}

$yt-top-ui-height: 70px;
$yt-bottom-ui-controls-height: 60px;
$yt-bottom-ui-more-videos-height: 200px;

// captures drag events for flicktiy slider
.c-video-component__interaction-helper {
	display: none;
	width: 100%;
	position: absolute;
	top: $yt-top-ui-height;
	left: 0;
	z-index: 4;
}

// playing state

.c-video-component.is-open {
	.c-video-component__player {
		visibility: visible;
		opacity: 1;
		transition: opacity 0.3s 1s;
	}

	.c-video-component__play-button {
		display: none;
	}

	.c-video-component__close-button {
		display: block;
	}

	.c-video-component__overlay {
		opacity: 0.7;
		@include transition(opacity 1s);
	}

	.c-video-component__interaction-helper {
		display: block;
	}
}

.c-video-component.is-playing {
	.c-video-component__interaction-helper {
		height: calc(100% - #{$yt-top-ui-height + $yt-bottom-ui-controls-height});
	}
}

.c-video-component.is-paused {
	.c-video-component__interaction-helper {
		height: calc(100% - #{$yt-top-ui-height + $yt-bottom-ui-more-videos-height});
	}
}

// variants

.c-video-component--no-thumb {
	.c-video-component__player {
		visibility: visible;
		opacity: 1;
	}
}

