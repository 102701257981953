.m-text-list__headline {
	color: $color-haribo-blue;

	@include mq($from: medium) {
		width: 85%;
	}
}

.m-text-list__filter-container,
.m-text-list__fallback-text {
	margin-top: $spacing-lightblue;

	@include mq($from: medium) {
		margin-top: $spacing-petrol;
	}

	@include mq($from: large) {
		margin-top: $spacing-grey;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-brown;
	}
}

.m-text-list__results {
	scroll-margin-top: 30px;
}

.m-text-list__item + .m-text-list__item {
	padding-top: $spacing-yellow;
	border-top: 2px solid rgba($color-haribo-blue, 0.3);
	margin-top: $spacing-yellow;

	@include mq($from: xlarge) {
		padding-top: $spacing-lilac;
		margin-top: $spacing-lilac;
	}
}

.m-text-list__category-tag {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-height: 26px;
	padding: 5px 15px;
	background-color: $color-haribo-grey-2;
	border-radius: 13px;
	color: $color-white;
	text-align: center;

	@include mq($from: large) {
		min-height: 30px;
		border-radius: 15px;
	}

	@include mq($from: xlarge) {
		min-height: 34px;
		border-radius: 17px;
	}
}

.m-text-list__fact {
	margin-top: $spacing-olive;

	@include mq($from: medium) {
		margin-top: $spacing-maroon;
	}

	@include mq($from: large) {
		position: relative;
	}
}

.m-text-list__link {
	color: $color-haribo-blue;

	@include hover-focus() {
		text-decoration: underline;
	}

	@include mq($from: large) {
		display: block;
		width: fit-content;
		max-width: 80%;
	}
}

.m-text-list__location {
	color: $color-haribo-blue;
	margin-top: $spacing-smokeblue-light;

	@include mq($from: large) {
		margin-top: $spacing-olive;
	}
}

.m-text-list__date {
	color: $color-haribo-grey-2;
	margin-top: $spacing-smokeblue-light;

	@include mq($from: large) {
		position: absolute;
		top: 0;
		right: 0;
		margin-top: 0;
	}
}

.m-text-list .c-filter-panel {
	margin-top: $spacing-softblue;
}

// tag colors

.m-text-list__category-tag--apprenticeship {
	background-color: $color-haribo-orange;
}

.m-text-list__category-tag--internship {
	background-color: $color-haribo-red;
}

.m-text-list__category-tag--trainee {
	background-color: $color-haribo-green;
}

.m-text-list__category-tag--professional {
	background-color: $color-haribo-blue;
}

.m-text-list__category-tag--temporaryjob {
	background-color: $color-haribo-blue;
}

.m-text-list__category-tag--executive {
	background-color: $color-haribo-blue;
}
