.m-stage-product--box {
	.m-stage-product__module-headline-wrapper {
		@include mq($from: medium) {
			width: percentage(6/$cols-m);
		}

		@include mq($from: large) {
			width: percentage(12/$cols-l);
		}

		@include mq($from: xlarge) {
			width: percentage(11/$cols-xl);
			margin-right: percentage(1/$cols-xl);
		}
	}

	.m-stage-product__image-links-wrapper {
		position: relative;

		@include mq($until: medium) {
			width: calc(100% + 6px);
			bottom: -$spacing-lightblue;
			margin-top: $spacing-grey - $spacing-lightblue; // counter act the negative `bottom`
			margin-left: -3px;
		}

		@include mq($from: medium) {
			width: percentage(8/$cols-m);
			bottom: -$spacing-lightpink;
			right: -14px;
			margin-top: $spacing-grey;
		}

		@include mq($from: large) {
			width: percentage(14/$cols-l);
			bottom: -150px;
			right: -62px;
			margin-top: 0;
		}

		@include mq($from: xlarge) {
			bottom: -$spacing-hotpink;
			right: -13px;
			margin-top: $spacing-cadetblue;
		}
	}

	.m-stage-product__pin {
		width: 1px;
		height: 1px;
		position: absolute;

		.m-stage-product__image-link {
			position: absolute;
			bottom: 100%;
			right: 100%;
			margin-right: -12px;

			&.m-stage-product__image-link--speech-bubble-left {
				margin-left: -12px;
				margin-right: 0;
			}
		}
	}

	.m-stage-product__image {
		transform: rotate(-10deg);

		img {
			filter: drop-shadow(20px 15px 25px rgba($color-black, 0.23));
		}
	}

	.m-stage-product__pin--ingredients {
		top: 9%;
		left: 17%;

		@include mq($from: medium) {
			top: 11%;
			left: 13%;
		}

		@include mq($from: large) {
			top: 14%;
			left: 10%;
		}

		@include mq($from: xlarge) {
			top: 10%;
			left: 15%;
		}
	}

	.m-stage-product__image-link--ingredients {
		transform: rotate(-21deg);

		@include mq($from: medium) {
			transform: rotate(-19deg);
		}

		@include mq($from: large) {
			transform: rotate(-21deg);
		}

		@include mq($from: xlarge) {
			transform: rotate(-26deg);
		}
	}

	.m-stage-product__pin--shop {
		top: 3%;
		left: 81%;

		@include mq($from: medium) {
			top: 10%;
			left: 87%;
		}

		@include mq($from: large) {
			top: 6%;
			left: 84%;
		}

		@include mq($from: xlarge) {
			top: 7%;
			left: 93%;
		}
	}

	.m-stage-product__image-link--shop {
		top: -65px;
		left: 59%;
		transform: rotate(11deg);

		@include mq($from: medium) {
			transform: rotate(10deg);
		}

		@include mq($from: large) {
			transform: rotate(11deg);
		}

		@include mq($from: large) {
			transform: rotate(17deg);
		}
	}
}
