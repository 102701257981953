// expand slider items to full width
[data-slides-wrapper] {
	.flickity-slider > * {
		width: 100%;
	}
}

[data-pagination-wrapper-outer].has-overflow {
	overflow: hidden;
}

[data-pagination-wrapper-inner] {
	--offset: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: fit-content;
	padding-top: 5px;
	padding-bottom: 5px;
	transform: translateX(var(--offset));
	transition: transform 0.3s ease-in-out;
}

[data-button-pagination] {
	flex: 0 0 auto;

	&.has-more {
		transform: scale(0.5);
	}
}
