.m-stage-product--bag {
	.m-stage-product__module-headline-wrapper {
		@include mq($from: medium) {
			width: percentage(7/$cols-m);
		}

		@include mq($from: large) {
			width: percentage(12/$cols-l);
		}
	}

	.m-stage-product__image-links-wrapper {
		position: relative;

		@include mq($until: medium) {
			width: percentage(11/$cols-s);
			bottom: -$spacing-lightpink;
			margin-top: $spacing-grey - $spacing-lightpink; // counter act the negative `bottom`
			margin-left: auto;
		}

		@include mq($from: medium) {
			width: percentage(7/$cols-m);
			bottom: -$spacing-softblue;
			right: -22px;
		}

		@include mq($from: large) {
			width: percentage(13/$cols-l);
			bottom: -$spacing-darkgrey;
			right: -53px;
			margin-top: $spacing-darkgrey;
		}

		@include mq($from: xlarge) {
			bottom: -$spacing-indianred;
			right: -64px;
			margin-top: -$spacing-softblue;
		}
	}

	.m-stage-product__pin {
		width: 1px;
		height: 1px;
		position: absolute;

		.m-stage-product__image-link {
			position: absolute;
			bottom: 100%;
			right: 100%;
			margin-right: -12px;

			&.m-stage-product__image-link--speech-bubble-left {
				margin-left: -12px;
				margin-right: 0;
			}
		}
	}

	.m-stage-product__image {
		transform: rotate(-11deg);

		img {
			filter: drop-shadow(20px 15px 25px rgba($color-black, 0.23));
		}
	}

	.m-stage-product__pin--ingredients {
		top: 73%;
		left: 2%;

		@include mq($from: medium) {
			top: 72%;
			left: 6%;
		}

		@include mq($from: large) {
			top: 70%;
			left: 8%;
		}

		@include mq($from: xlarge) {
			top: 67%;
			left: 6%;
		}
	}

	.m-stage-product__image-link--ingredients {
		transform: rotate(-21deg);

		@include mq($from: medium) {
			transform: rotate(-19deg);
		}

		@include mq($from: large) {
			transform: rotate(-26deg);
		}
	}

	.m-stage-product__pin--shop {
		top: -3%;
		left: 60%;

		@include mq($from: medium) {
			top: 53%;
			left: 95%;
		}

		@include mq($from: xlarge) {
			top: 46%;
			left: 95%;
		}
	}

	.m-stage-product__image-link--shop {
		top: -65px;
		left: 59%;
		transform: rotate(11deg);

		@include mq($from: medium) {
			transform: rotate(10deg);
		}

		@include mq($from: large) {
			transform: rotate(17deg);
		}
	}
}
