.m-cookie-policy__inner {
	margin-top: $spacing-lightblue;
}

.m-cookie-policy__header {
	@include mq($from: large) {
		width: percentage(21/$cols-l);
	}

	@include mq($from: xlarge) {
		width: percentage(17/$cols-xl);
	}
}

.m-cookie-policy__headline {
	color: $color-haribo-red;
}

.m-cookie-policy__body ul,
.m-cookie-policy__body ol,
.m-cookie-policy__body blockquote {
	@include mq($from: medium) {
		padding-left: percentage(1/$cols-m);
	}

	@include mq($from: large) {
		padding-left: percentage(1/20);
	}

	@include mq($from: xlarge) {
		padding-left: percentage(1/16);
	}
}

.m-cookie-policy__body ul,
.m-cookie-policy__body ol {
	margin-left: 1px;

	@include mq($from: medium) {
		margin-left: 4px;
	}
}

.m-cookie-policy__body {
	> div:first-child > div:first-child[style] {
		display: none;
	}

	a {
		color: $color-haribo-blue;

		@include hover-focus() {
			text-decoration: underline;
		}
	}

	p,
	dl,
	blockquote,
	pre,
	img,
	figure,
	ul,
	ol {
		margin-top: $spacing-pink;
		margin-bottom: 0;

		@include mq($from: medium) {
			margin-top: $spacing-yellow;
		}

		@include mq($from: large) {
			margin-top: $spacing-orange;
		}

		@include mq($from: xlarge) {
			margin-top: $spacing-lilac;
		}
	}

	.CookieDeclarationType {
		@include mq($from: medium) {
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	.CookieDeclarationTable {
		margin-top: $spacing-royalblue;

		tr:last-child {
			td {
				border-bottom: none;
			}
		}
	}

	br + br {
		display: none;
	}

	/* stylelint-disable */
	#CookieDeclarationUserStatusPanel {
		padding: 30px;
		background-color: $color-haribo-grey-4;
		margin-top: $spacing-purple !important;
	}

	#CookieDeclarationChangeConsent {
		margin-top: $spacing-yellow;
	}
	/* stylelint-enable */
}
