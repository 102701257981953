.m-picoballa {
	--radial-gradient: radial-gradient(circle at 31% 56%, var(--gradient-color), var(--background-color) 65%);
	display: grid;
	grid-template-columns: repeat(4, auto);
	grid-template-rows: repeat(9, auto);
	gap: 0;
	width: 100%;
	height: max-content;
	min-height: 100vh;
	max-height: 1200px;
	overflow: hidden;
	background-image: var(--bg-portrait), var(--radial-gradient);
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
	color: white;
	position: relative;

	@include mq($from: large) {
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(8, 1fr);
		height: 100vh;
		min-height: 800px;
		background-image: var(--bg-big), var(--radial-gradient);
		background-position: bottom right;
	}
}

.m-pb__cover-wave {
	display: block;
	grid-column: span 4 / span 4;
	width: 100%;
	height: 100%;
	fill: $color-white;
	position: relative;
	top: -2px;
	z-index: 2;
}

.pb-rive-container {
	display: flex;

	grid-column: span 4 / span 4;
	grid-row: span 4 / span 4;
	grid-row-start: 2;
	align-items: center;
	justify-content: center;
	width: 90%;
	overflow: visible;
	position: relative;
	margin: 0 5%;

	@include mq($from: large) {
		grid-column: span 2 / span 2;
		grid-row: span 7 / span 7;
		grid-row-start: 2;
		max-height: none;
		margin: -15px 0 0 0;
	}
}

.pb-text-container {
	display: flex;
	grid-column: span 4 / span 4;
	grid-row: span 4 / span 4;
	grid-row-start: 6;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	color: white;
	gap: 8px;
	margin: 0 5%;

	@include mq($from: large) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(5, 1fr);

		grid-column: span 2 / span 2;
		grid-row: span 7 / span 7;
		grid-column-start: 3;
		grid-row-start: 2;
		align-items: flex-start;
		justify-content: flex-start;
		width: 90%;
		margin: 0;
	}

	h2 {
		padding-bottom: 10px;
	}
}

.pb-raffle-intro {
	display: flex;
	grid-column: span 2 / span 2;
	grid-row: span 3 / span 3;
	grid-row-start: 2;
	flex-direction: column;
	width: 80%;
	padding: 0;
	margin: 12px;
	gap: 12px;

	@include mq($from: large) {
		width: 100%;
		margin: 0 50px 50px 50px;
		gap: 24px;
	}
}

.pb-raffle-intro-instructions {
	display: flex;
	align-items: center;
	width: 100%;
	gap: 12px;

	@include mq($from: large) {
		gap: 24px;

		.c-rich-text {
			width: 82.5%;
		}
	}

	.c-rich-text {
		width: 91.25%;
	}
}

.pb-raffle-intro-instructions-img {
	width: 17.5%;
	max-width: 70px;
	height: auto;

	animation: handwave 2s infinite;

	@include mq($from: large) {
		width: 8.75%;
	}

	@keyframes handwave {
		0%,
		100% {
			transform: rotate(0deg);
			transform-origin: bottom center;
		}

		50% {
			transform: rotate(10deg);
			transform-origin: bottom center;
		}
	}
}

.pb-raffle-form {
	grid-column: span 2 / span 2;
	grid-row: span 3 / span 3;
	grid-row-start: 2;
	min-height: 400px;
	padding: 0;
	margin: 0 12px 12px 12px;

	@include mq($from: large) {
		max-width: 75%;
		margin: 0 50px;
	}
}

.pb-loading {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
	color: white;
}

.canvas-container {
	display: block;
	width: 100%;
	max-width: 100%;
	height: auto;
	max-height: 100%;
	margin: 0 auto;
	aspect-ratio: 1; /* 1500:1500 */

	@include mq($from: large) {
		width: auto;
		height: 100%;
	}

	:focus {
		outline: 2px solid #4A90E2;
	}

	:focus:not(:focus-visible) {
		outline: none;
	}
}

.pb-image-win {
	display: none;
	width: 100%;
	height: auto;
	visibility: hidden;
	opacity: 0;
	scale: 0;
	margin: 12px;
	@include mq($from: large) {
		margin: 50px;
	}
}

.pb-images-bottom {
	display: flex;
	grid-column: span 2 / span 2;
	grid-row-start: 5;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	bottom: 0;
	right: 10px;
	z-index: 5;
	gap: 10px;
	pointer-events: none !important;

	@include mq($from: large) {
		max-height: 35vh;
		padding-right: 5%;
		margin-bottom: 20px;
	}
}

.pb-image-goldbear {
	width: 150px;
	max-width: 35vh;
	max-height: 35vh;
	z-index: 5;

	@include mq($until: large) {
		display: none;
	}

	@include mq($from: xlarge) {
		width: 210px;
	}
}

.pb-image-prize {
	width: 410px;
	z-index: 5;
	@include mq($until: large) {
		width: 100vw;
	}

	@include mq($from: large) {
		max-width: 80vh;
		max-height: 80vh;
	}

	@include mq($from: xlarge) {
		width: 550px;
	}
}
