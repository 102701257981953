/**
 * General Layout
 */

/* stylelint-disable selector-max-type */
/* Exception for base styles */
html,
body {
	min-height: 100%;
}

html {
	overflow-y: scroll;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: $color-haribo-grey-5;
	color: $color-black;
	@include font-family("VAG Rounded");

	[lang="ko-KR"] &,
	[lang="zh-CN"] & {
		word-break: keep-all;
	}
}
/* stylelint-enable selector-max-type */

.l-main {
	width: 100%;
	max-width: $module-max-width;
	background-color: $color-white;
	margin-left: auto;
	margin-right: auto;
}
