$dim-opacity: 0.3;

.m-raffle-choice__item {
	width: fit-content;
	position: relative;
}

.m-raffle-choice__item + .m-raffle-choice__item {
	margin-top: $spacing-pink;

	@include mq($from: large) {
		margin-top: $spacing-yellow;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-orange;
	}
}

.m-raffle-choice__input {
	width: 100%;
	height: 100%;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	cursor: pointer;
}

.m-raffle-choice__label {
	display: block;
	color: $color-haribo-blue;
	font-size: 16px;
	line-height: 1.3;
	font-weight: bold;
	text-align: left;
	margin-top: 0;

	@include mq($from: large) {
		font-size: 16px;
		margin-left: -10px;
		margin-right: -10px;
	}

	@include mq($from: xlarge) {
		font-size: 26px;
	}

	.has-bg-color & {
		color: $color-white;
	}

	@include transition(opacity);
}

.m-raffle-choice__image-wrapper {
	width: 100%;
	position: relative;
}

.m-raffle-choice__image {
	width: 100%;
	background-color: rgba($color-white, 0.3);
	@include transition(opacity);
}

.m-raffle-choice__marker-icon {
	flex: 0 0 auto;
	width: 30px;
	height: 30px;
	fill: $color-haribo-grey-1;
	opacity: 0;
	margin-right: 20px;

	@include mq($from: large) {
		width: 45px;
	}

	.has-bg-color & {
		fill: $color-white;
	}

	@include transition(opacity, fill);
}

.m-raffle-choice__like-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 35px;
	height: 35px;
	background-color: $color-white;
	border-radius: 50%;
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 2;
	pointer-events: none;

	@include mq($from: large) {
		width: 45px;
		height: 45px;
	}

	@include mq($from: xlarge) {
		width: 60px;
		height: 60px;
	}

	svg {
		width: 65%;
		height: 65%;
		fill: $color-haribo-blue;
		margin-top: 2px;
		@include transition(fill 0.3s);
	}

	@include transition(background-color 0.3s);
}

.m-raffle-choice--images {
	$l-cols: 16;
	$xl-cols: 18;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 40px;
	row-gap: $spacing-lilac;
	margin-top: $spacing-lilac;

	@include mq($until: large) {
		max-width: 500px;
	}

	@include mq($from: large) {
		row-gap: $spacing-yellow;
	}

	@include mq($from: xlarge) {
		column-gap: 80px;
		margin-top: $spacing-lightblue;
	}

	&[data-option-count="1"],
	&[data-option-count="2"] {
		@include mq($from: large) {
			width: percentage(17/$l-cols);
		}

		@include mq($from: xlarge) {
			width: percentage(17/$xl-cols);
		}

		.m-raffle-choice__item {
			@include mq($from: large) {
				max-width: 250px;
			}
		}
	}

	&[data-option-count="4"] {
		@include mq($from: large) {
			column-gap: 30px;
			width: percentage(12/$l-cols);
			margin-left: percentage(1/$l-cols);
		}

		@include mq($from: xlarge) {
			width: percentage(12/$xl-cols);
			margin-left: percentage(1/$xl-cols);
		}

		.m-raffle-choice__item {
			@include mq($from: large) {
				max-width: 190px;
			}
		}
	}

	&[data-option-count="3"],
	&[data-option-count="5"],
	&[data-option-count="6"] {
		@include mq($from: large) {
			grid-template-columns: repeat(3, 1fr);
			margin-left: -(percentage(1/$l-cols));
			row-gap: 0;
			column-gap: 30px;
		}

		@include mq($from: xlarge) {
			width: 100%;
			margin-left: -(percentage(1/$xl-cols));
			margin-right: 0;
		}

		.m-raffle-choice__item {
			@include mq($from: large) {
				max-width: 190px;
			}
		}

		.m-raffle-choice__item:nth-child(3n + 2) {
			@include mq($from: large) {
				margin-top: $spacing-yellow;
			}
		}
	}

	.m-raffle-choice__item {
		width: 100%;
		margin-top: 0;

		@include mq($until: large) {
			max-width: 230px;
		}
	}

	.m-raffle-choice__label {
		text-align: center;
		margin-top: $spacing-pink;

		@include mq($from: large) {
			margin-top: $spacing-olive;
			margin-left: -10px;
			margin-right: -10px;
		}
		@include font-style(headline-o1);
	}

	.m-raffle-choice__item:nth-child(3n + 1) {
		.m-raffle-choice__image {
			@include svg-mask(b);
		}
	}

	.m-raffle-choice__item:nth-child(3n + 2) {
		.m-raffle-choice__image {
			@include svg-mask(b);
		}
	}

	.m-raffle-choice__item:nth-child(3n + 3) {
		.m-raffle-choice__image {
			@include svg-mask(a);
		}
	}

	&.has-selection {
		.m-raffle-choice__image {
			opacity: $dim-opacity;
		}

		.m-raffle-choice__label {
			opacity: $dim-opacity;
		}
	}

	&.m-raffle-choice--like {
		.m-raffle-choice__image {
			opacity: 1;
		}

		.m-raffle-choice__label {
			opacity: 1;
		}
	}
}

.m-raffle-choice__input:checked {
	~ .m-raffle-choice__marker-icon {
		fill: $color-haribo-blue;
		opacity: 1;

		.has-bg-color & {
			fill: $color-haribo-yellow;
		}
	}

	~ .m-raffle-choice__image-wrapper {
		.m-raffle-choice__image {
			opacity: 1;
		}

		.m-raffle-choice__like-icon {
			background-color: $color-haribo-blue;

			svg {
				fill: $color-white;
			}
		}
	}

	~ .m-raffle-choice__label {
		opacity: 1;
	}
}

.m-raffle-choice--default {
	margin-top: $spacing-lilac;

	@include mq($from: large) {
		margin-top: $spacing-lightblue;
	}

	.m-raffle-choice__item {
		display: flex;
		align-items: center;
	}
}

// ---------------------------------------------- hover & focus

[data-whatintent="mouse"] .m-raffle-choice__input:hover,
[data-whatinput="keyboard"] .m-raffle-choice__input:focus {
	~ .m-raffle-choice__marker-icon {
		opacity: 1;
	}

	~ .m-raffle-choice__image {
		opacity: 1;
	}
}

[data-whatinput="keyboard"] .m-raffle-choice__item:focus-within {
	outline: 2px dashed $color-haribo-yellow;
	outline-offset: 2px;
}

