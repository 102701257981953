@mixin content-box-module-padding {
	padding-top: $spacing-purple;
	padding-bottom: $spacing-purple;

	@include mq($from: medium) {
		padding-top: $spacing-lightpink;
		padding-bottom: $spacing-lightpink;
	}

	@include mq($from: large) {
		padding-top: $spacing-lightblue;
		padding-bottom: $spacing-petrol;
	}

	@include mq($from: xlarge) {
		padding-top: $spacing-petrol;
		padding-bottom: $spacing-softblue;
	}
}

.m-content-box {
	position: relative;

	.c-content-box-frame__navigation-wrapper {
		margin-top: $spacing-petrol;

		@include mq($from: xlarge) {
			margin-top: $spacing-lightpink;
		}
	}

	.l-main & {
		@include has-background-color() {
			@include content-box-module-padding();

			.c-rich-text a {
				box-shadow: inset 0 -1px 0 0 rgba($color-white, 0.8);

				@include hover-focus() {
					box-shadow: inset 0 -3px 0 0 rgba($color-white, 0.5);
				}
			}
		}
	}
}

.m-content-box__headline {
	width: percentage(12/$cols-s);
	position: relative;
	z-index: 2;
	margin-left: percentage(1/$cols-s);

	@include is-rtl() {
		margin-left: 0;
		margin-right: percentage(1/$cols-s);
	}

	@include mq($from: medium) {
		width: percentage(12/$cols-m);
		margin-left: percentage(1/$cols-m);

		@include is-rtl() {
			margin-left: 0;
			margin-right: percentage(1/$cols-m);
		}
	}

	@include mq($from: large) {
		width: percentage(15/$cols-l);
		margin-left: 0;

		@include is-rtl() {
			margin-right: 0;
		}
	}

	@include mq($from: xlarge) {
		width: percentage(13/$cols-l);
	}
}

.m-content-box__wrapper {
	@include mq($until: large) {
		width: 100%;
	}
}

.m-content-box__sections {
	margin-top: $spacing-yellow;

	@include mq($from: medium) {
		margin-top: $spacing-purple;
	}

	@include mq($from: large) {
		margin-top: $spacing-orange;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-pink;
	}
}

.m-content-box .m-content-box__section {
	@include mq($until: medium) {
		width: map-get($grid-width, small);
		margin: 0 auto;
	}

	@include mq($from: medium, $until: large) {
		width: map-get($grid-width, medium);
		margin: 0 auto;
	}
}

// default padding can be overridden from within box styles
.m-content-box__section .c-content-shape__inner {
	padding-top: $spacing-softblue;
	padding-bottom: $spacing-softblue;

	@include mq($until: medium) {
		padding-left: percentage(1/14);
		padding-right: percentage(1/14);
	}

	@include mq($from: medium) {
		padding-left: percentage(2/14);
		padding-right: percentage(2/14);
	}

	@include mq($from: large) {
		padding-left: percentage(2/18);
		padding-right: percentage(2/18);
	}

	@include mq($from: xlarge) {
		padding: $spacing-darkgrey percentage(2/18);
	}
}

.m-content-box .flickity-enabled .m-content-box__section {
	@include mq($until: medium) {
		margin-left: 6%;
		margin-right: 6%;
	}

	@include mq($from: medium, $until: large) {
		margin-left: 3.4%;
		margin-right: 3.4%;
	}
}

.m-content-box__content-shape-svg-large {
	@include mq($until: large) {
		display: none;
	}
}

.m-content-box__content-shape-svg-medium {
	display: none;

	@include mq($from: medium, $until: large) {
		display: block;
	}
}

.m-content-box__content-shape-svg-small {
	@include mq($from: medium) {
		display: none;
	}
}

// colors

.m-content-box__overview-image-shadow {
	background-color: $color-haribo-blue;
	opacity: 0.15;
}

.m-content-box__headline,
.m-content-box__shape-headline {
	color: $color-haribo-blue;
}

/* stylelint-disable no-duplicate-selectors */
.m-content-box {
	.c-content-shape__svg {
		fill: $color-haribo-blue;
		opacity: 0.15;

		@include is-rtl() {
			transform: scaleX(-1);
		}
	}

	.c-content-box-frame__navigation-button {
		color: $color-haribo-blue;
	}

	.c-icon-text-link__icon,
	.c-content-box-frame__arrow {
		color: $color-haribo-red;
	}

	@include has-background-color() {
		color: $color-white;

		.c-content-box-frame__navigation-button,
		.m-content-box__headline,
		.m-content-box__shape-headline {
			color: $color-white;
		}

		.c-content-shape__svg {
			fill: $color-black;
			opacity: 0.15;
		}

		.m-content-box__overview-image-shadow {
			background-color: $color-black;
			opacity: 0.15;
		}

		.c-icon-text-link__icon,
		.c-content-box-frame__arrow {
			color: $color-haribo-yellow;
		}

		.c-content-slider__pagination-item {
			background-color: rgba($color-haribo-yellow, 0.2);

			&.is-active {
				background-color: $color-haribo-yellow;
			}
		}
	}
}
/* stylelint-enable no-duplicate-selectors */

// special flexbox hack for active tab handling, because all the tabs should have same height as the highest
/* stylelint-disable-next-line no-duplicate-selectors */
.m-content-box {
	// does not work in mobile phone slider so we have to isolate it
	.c-content-box-frame__content-wrapper:not(.flickity-enabled) {
		display: flex;

		.m-content-box__section {
			display: block;
			flex: 0 0 auto;
			width: 100%;
			visibility: hidden;
			margin-right: -100%;

			&.is-displayed {
				visibility: visible;
			}
		}
	}

	// mobile equal height hack for flickity
	// https://github.com/metafizzy/flickity/issues/534#issuecomment-328859144
	.flickity-resize .m-content-box__section {
		@include mq($until: large) {
			height: 100%;

			.c-content-shape {
				height: 100%;
			}
		}
	}
}

html.no-js .m-content-box {
	.c-content-box-frame__navigation-button {
		opacity: 1;
	}
}

@import "components/ContentText";
@import "components/ContentQuote";
@import "components/ContentOverview";

.m-content-box.u-color-bg-maoam {
	.c-content-box-frame__arrow,
	.c-icon-text-link__icon {
		fill: $color-maoam-orange;
	}

	.m-content-box__headline,
	.c-content-box-frame__navigation-button,
	.c-content-shape--text,
	.m-content-box__shape-headline,
	.m-content-box__overview-content,
	.m-content-box__quote-content {
		color: $color-maoam-blue;
	}

	.c-content-box-frame__pagination .c-content-slider__pagination-item {
		background-color: $color-maoam-orange;
		opacity: 0.2;

		&.is-active {
			opacity: 1;
		}
	}
}
