.l-grid-overlay {
	direction: ltr;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	z-index: 10000;
	pointer-events: none;

	&.u-hidden {
		display: none;
	}

	.l-wrapper {
		height: 100%;
	}
}

.l-grid-overlay__grid {
	display: flex;
	height: 100%;
}

.l-grid-overlay__item {
	flex-shrink: 0;
	height: 100%;
	border-left: 1px solid red;

	@include mq($until: medium) {
		width: percentage(1/$cols-s);

		&:nth-child(n+#{$cols-s + 1}) {
			display: none;
		}

		&:nth-child(#{$cols-s}) {
			border-right: 1px solid red;
		}
	}

	@include mq($from: medium, $until: large) {
		width: percentage(1/$cols-m);

		&:nth-child(n+#{$cols-m + 1}) {
			display: none;
		}

		&:nth-child(#{$cols-m}) {
			border-right: 1px solid red;
		}
	}

	@include mq($from: large, $until: xlarge) {
		width: percentage(1/$cols-l);

		&:nth-child(n+#{$cols-l + 1}) {
			display: none;
		}

		&:nth-child(#{$cols-l}) {
			border-right: 1px solid red;
		}
	}

	@include mq($from: xlarge) {
		width: percentage(1/$cols-xl);

		&:nth-child(n+#{$cols-xl + 1}) {
			display: none;
		}

		&:nth-child(#{$cols-xl}) {
			border-right: 1px solid red;
		}
	}
}
