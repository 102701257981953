.m-stage-product--generic {
	.m-stage-product__top-inner {
		@include mq($from: medium) {
			align-items: flex-start;
		}
	}

	.m-stage-product__module-headline-wrapper {
		padding-top: 120px;

		@include mq($from: medium) {
			width: percentage(7/$cols-m);
			padding-top: 130px;
		}

		@include mq($from: large) {
			width: percentage(12/$cols-l);
			padding-top: 170px;
		}

		@include mq($from: xlarge) {
			padding-top: 220px;
		}
	}

	.m-stage-product__image-links-wrapper {
		position: relative;

		@include mq($until: medium) {
			width: percentage(12/$cols-s);
			top: 90px;
			margin-bottom: 40px;
			margin-left: auto;
			margin-right: auto;
		}

		@include mq($from: medium) {
			width: percentage(7/$cols-m);
			top: 0;
			margin: 180px 0 -100px 5%;
		}

		@include mq($from: large) {
			width: percentage(13/$cols-l);
			margin-top: 250px;
			margin-bottom: -190px;
			margin-left: percentage(1/$cols-l);
		}

		@include mq($from: xlarge) {
			width: percentage(13/$cols-xl);
			margin-top: 330px;
			margin-bottom: -300px;
			margin-left: percentage(1/$cols-xl);
		}
	}

	.m-stage-product__image {
		transform: rotate(-11deg);

		img {
			object-fit: contain;
			object-position: center top;
			filter: drop-shadow(20px 15px 25px rgba($color-black, 0.23));
		}

		.c-image-component__image-wrapper {
			width: 100%;
			margin-top: 0;
			margin-left: 0;

			&::before,
			.c-image-component__aspect-ratio-helper {
				padding-bottom: 114% !important;
			}
		}
	}

	.m-stage-product__pin {
		width: 5px;
		height: 5px;
		position: absolute;

		.m-stage-product__image-link {
			position: absolute;
			bottom: 100%;
			right: 100%;
			margin-bottom: -9px;
			margin-right: -12px;

			&.m-stage-product__image-link--speech-bubble-left {
				left: 100%;
				right: auto;
				margin-top: -9px;
				margin-left: -14px;
				margin-right: 0;
			}
		}
	}

	.m-stage-product__pin--ingredients {
		top: -8;
		left: 25%;
	}

	.m-stage-product__image-link--ingredients {
		transform: rotate(-21deg);

		@include mq($from: medium) {
			transform: rotate(-10deg);
		}
	}

	.m-stage-product__pin--shop {
		top: -23px;
		left: 48%;
	}

	.m-stage-product__image-link--shop {
		transform: rotate(11deg);

		@include mq($from: medium) {
			transform: rotate(10deg);
		}
	}
}
