@mixin has-background-color() {
	&[class*="u-color-bg"] {
		@content;
	}
}

@mixin parent-has-background-color() {
	[class*="u-color-bg"] & {
		@content;
	}
}
