.m-stage-product {
	--background-color: #{$color-haribo-blue};
	--gradient-color: #{lighten($color-haribo-blue, $lighten-amount)};
	overflow: hidden;
	background-color: transparent !important; // override in-page styles
	position: relative;
}

.m-stage-product__top {
	direction: ltr;
	background-image: radial-gradient(circle at 76% 96%, var(--gradient-color), var(--background-color) 63%);

	@include is-rtl() {
		transform: scaleX(-1);
	}
}

.m-stage-product__module-headline-wrapper,
.m-stage-product__image-links-wrapper {
	flex-shrink: 0;
}

.m-stage-product__module-headline-wrapper {
	padding-top: $spacing-salmon;

	@include mq($from: medium) {
		align-self: center;
		padding-top: $spacing-brown;
		padding-bottom: $spacing-softblue;
	}

	@include mq($from: large) {
		padding-top: $spacing-darkgreen;
		padding-bottom: $spacing-brown;
	}

	@include mq($from: xlarge) {
		padding-top: $spacing-indianred;
		padding-bottom: $spacing-brown;
	}

	@include is-rtl() {
		direction: rtl;
		transform: scaleX(-1);
	}
}

.m-stage-product__module-product-headline {
	margin-top: $spacing-smokeblue-light;

	@include mq($from: medium) {
		margin-top: $spacing-olive;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-pink;
	}
}

@import "StageProductBag";
@import "StageProductLongAndFlat";
@import "StageProductBox";
@import "StageProductPillow";
@import "StageProductStandup";
@import "StageProductGeneric";

.m-stage-product__top-inner {
	@include mq($from: medium) {
		display: flex;
		align-items: flex-end;
	}
}

.m-stage-product__image-links-wrapper {
	img {
		@include is-rtl() {
			transform: scaleX(-1);
		}
	}
}

.m-stage-product__image-link {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 69px;
	height: 60px;
	position: absolute;
	z-index: 1;

	@include mq($from: medium, $until: large) {
		width: 53px;
		height: 46px;
	}

	@include mq($from: xlarge) {
		width: 98px;
		height: 86px;
	}
}

.m-stage-product__image-link-icon {
	fill: $color-haribo-red;
	position: relative;
	z-index: 1;
	margin-top: -15px;

	@include mq($from: medium, $until: large) {
		margin-top: -12px;
	}

	@include mq($from: xlarge) {
		margin-top: -22px;
	}
}

.m-stage-product__image-link-icon--ingredients {
	width: 38px;
	height: 31px;

	@include mq($from: medium, $until: large) {
		width: 28px;
		height: 23px;
	}

	@include mq($from: xlarge) {
		width: 53px;
		height: 42px;
	}
}

.m-stage-product__image-link-icon--shop {
	width: 27px;
	height: 35px;

	@include mq($from: medium, $until: large) {
		width: 19px;
		height: 25px;
	}

	@include mq($from: xlarge) {
		width: 40px;
		height: 52px;
	}
}

.m-stage-product__speech-bubble {
	width: 100%;
	height: 100%;
	fill: $color-white;
	position: absolute;
	@include transition(transform);

	@include hover-focus(".m-stage-product__image-link") {
		transform: scale(1.15);
	}
}

.m-stage-product__bottom {
	padding-top: $spacing-darkgrey;
	padding-bottom: $spacing-softblue;

	@include mq($from: medium) {
		padding-top: $spacing-softblue;
		padding-bottom: $spacing-softblue;
	}

	@include mq($from: large) {
		padding-top: $spacing-softblue;
		padding-bottom: $spacing-ocher;
	}

	@include mq($from: xlarge) {
		padding-top: $spacing-salmon;
		padding-bottom: $spacing-grey;
	}
}

.m-stage-product__module-headline-wrapper,
.m-stage-product__box {
	color: $color-white;
}

.m-stage-product__box {
	display: flex;
	align-items: center;
	position: relative;

	@include mq($until: medium) {
		min-height: 345px;
		padding-left: 13px;
		padding-right: 9px;
		margin-left: -13px;
		margin-right: -9px;

		@include is-rtl() {
			padding-left: 9px;
			padding-right: 13px;
			margin-left: -9px;
			margin-right: -13px;
		}
	}

	@include mq($from: medium) {
		width: percentage(10/$cols-m);
		min-height: 415px;
		padding-left: 5px;
		padding-right: 11px;
		margin-left: -5px;
		margin-right: -11px;

		@include is-rtl() {
			padding-left: 11px;
			padding-right: 5px;
			margin-left: -11px;
			margin-right: -5px;
		}
	}

	@include mq($from: large) {
		width: percentage(16/$cols-l);
		min-height: 410px;
		padding-left: 9px;
		padding-right: 9px;
		margin-left: -9px;
		margin-right: -9px;

		@include is-rtl() {
			padding-left: 9px;
			padding-right: 9px;
			margin-left: -9px;
			margin-right: -9px;
		}
	}

	@include mq($from: xlarge) {
		width: percentage(15/$cols-xl);
		min-height: 575px;
		padding-left: 10px;
		padding-right: 16px;
		margin-left: -10px;
		margin-right: -16px;

		@include is-rtl() {
			padding-left: 16px;
			padding-right: 10px;
			margin-left: -16px;
			margin-right: -10px;
		}
	}
}

.m-stage-product__box-inner {
	padding: $spacing-orange percentage(1/$cols-s);
	z-index: 1;

	@include mq($from: medium) {
		padding: $spacing-purple percentage(1/10);
	}

	@include mq($from: large) {
		padding: $spacing-lightgreen percentage(2/16) $spacing-lightblue;
	}

	@include mq($from: xlarge) {
		padding: $spacing-grey percentage(2/15);
	}
}

.m-stage-product__box-shape {
	fill: var(--background-color);

	@include is-rtl() {
		transform: scaleX(-1);
	}
}

.m-stage-product__copy {
	margin-top: $spacing-olive;

	@include mq($from: medium) {
		margin-top: $spacing-pink;
	}

	@include mq($from: large) {
		margin-top: $spacing-pink;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-orange;
	}
}

.m-stage-product__link {
	margin-top: $spacing-pink;

	@include mq($from: medium) {
		margin-top: $spacing-yellow;
	}

	@include mq($from: large) {
		margin-top: $spacing-orange;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-royalblue;
	}
}

.m-stage-product__decorative-image {
	width: 135px;
	height: 135px;
	position: absolute;
	bottom: 0;
	right: 0;
	pointer-events: none;

	@include is-rtl() {
		left: 0;
		right: auto;
		transform: scaleX(-1);
	}

	@include mq($from: medium) {
		width: 180px;
		height: 180px;
	}

	@include mq($from: large) {
		width: 250px;
		height: 250px;
	}

	@include mq($from: xlarge) {
		width: 340px;
		height: 340px;
	}

	// reset aspect ratio styles
	.c-image-component__image-wrapper {
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			object-position: bottom right;
			position: relative;
		}
	}

	.o-aspect-ratio {
		width: 100%;
		height: 100%;
	}

	.o-aspect-ratio::before {
		display: none;
	}

	.c-image-component__aspect-ratio-helper {
		display: none;
	}

	.m-stage-product--long &,
	.m-stage-product--flat & {
		@include mq($from: large) {
			display: none;
		}
	}
}

.m-stage-product__image .c-image-component__image-wrapper {
	width: 90%;
	margin-top: 5%;
	margin-left: 5%;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
}

.m-stage-product.u-color-bg-maoam {
	.m-stage-product__module-headline-wrapper,
	.m-stage-product__box-inner {
		color: $color-maoam-blue;
	}

	.m-stage-product__image img {
		filter: none;
	}

	.m-stage-product__image-link-icon,
	.c-icon-text-link__icon {
		fill: $color-maoam-orange;
	}
}
