.c-filter-cluster__group {
	&:not(:first-child) {
		@include mq($from: large) {
			margin-top: $spacing-orange;
		}

		@include mq($from: xlarge) {
			margin-top: $spacing-lilac;
		}
	}
}
