$icon-button-click-area-spacing: 5px;

.c-icon-button {
	display: inline-block;
	color: currentColor;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: -#{$icon-button-click-area-spacing};
		bottom: -#{$icon-button-click-area-spacing};
		left: -#{$icon-button-click-area-spacing};
		right: -#{$icon-button-click-area-spacing};
	}
}

.c-icon-button__icon {
	@include transition(transform);

	@include hover-focus(".c-icon-button") {
		transform: scale(1.2);
	}
}
