.m-raffle-intro {
	.m-raffle__headline,
	.m-raffle__copy {
		@include mq($from: large) {
			width: percentage(13/16);
		}
	}
}

.m-raffle-intro__image {
	margin-top: $spacing-lightblue;

	@include mq($from: large) {
		max-width: 570px;
		margin-top: $spacing-lilac;
	}

	@include mq($from: xlarge) {
		width: 100%;
		margin-top: $spacing-lightpink;
		margin-left: 0;
	}
}
