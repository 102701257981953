$filter-switch-height: 30px;
$filter-switch-height-xl: 35px;

.c-filter-switch {
	display: block;
	width: fit-content;
	position: relative;
}

.c-filter-switch__input {
	display: block;
	opacity: 0;
	z-index: 2;
	cursor: pointer;
}

.c-filter-switch__chrome {
	display: flex;
	align-items: center;
	height: $filter-switch-height;
	padding-left: 15px;
	padding-right: 15px;
	background-color: rgba($color-haribo-blue, 0.1);
	border-radius: $filter-switch-height / 2;

	@include mq($from: xlarge) {
		height: $filter-switch-height-xl;
		border-radius: $filter-switch-height-xl / 2;
	}
}

.c-filter-switch__label {
	color: $color-haribo-blue;
	font-size: 14px;
	white-space: nowrap;

	@include mq($from: xlarge) {
		font-size: 16px;
	}
}

.c-filter-switch__count {
	display: none;
	min-width: 20px;
	text-align: center;
	margin-left: 3px;

	@include is-rtl() {
		margin-left: 0;
		margin-right: 3px;
	}
}

.c-filter-switch__input:checked {
	~ .c-filter-switch__chrome {
		background-color: $color-haribo-yellow;

		[data-count="0"] & {
			.c-filter-switch__label {
				opacity: 0.2;
			}
		}
	}
}

.c-filter-switch__input:disabled {
	cursor: default;
	pointer-events: none;

	~ .c-filter-switch__chrome {
		.c-filter-switch__label {
			opacity: 0.2;
		}
	}
}

.c-filter-switch.is-hidden {
	display: none;
}

[data-whatinput="keyboard"] {
	.c-filter-switch:focus-within {
		@include keyboard-focus();
	}
}
