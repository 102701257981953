.m-job-benefits__headline {
	margin-bottom: $spacing-orange;

	@include mq($from: large) {
		margin-bottom: $spacing-lilac;
		margin-left: percentage(1/$cols-l);
	}

	@include mq($from: xlarge) {
		margin-bottom: $spacing-purple;
		margin-left: percentage(1/$cols-xl);
	}
}

.m-job-benefits__list {
	--num-cols: 3;
	display: grid;
	grid-template-columns: repeat(var(--num-cols), minmax(0, 1fr));
	column-gap: 25px;
	row-gap: $spacing-royalblue;

	@include mq($from: medium) {
		--num-cols: 4;
	}

	@include mq($from: large) {
		column-gap: 40px;
		row-gap: $spacing-purple;
	}

	@include mq($from: xlarge) {
		--num-cols: 5;
		column-gap: 65px;
		row-gap: $spacing-petrol;
	}
}

.m-job-benefits__item {
	display: flex;
	flex-direction: column;
}

.m-job-benefits__item-image-wrapper {
	padding-right: 10%;
	position: relative;

	@include hover-focus() {
		.m-job-benefits__icon-plus {
			background-color: $color-haribo-red;
		}
	}
}

.m-job-benefits__item-image {
	width: 100%;
}

.m-job-benefits__icon-plus {
	width: 25%;
	background-color: $color-haribo-grey-1;
	border-radius: 50%;
	position: absolute;
	bottom: 0;
	right: 0;
	pointer-events: none;
	@include transition(background-color);
	@include aspect-ratio-helper("1x1");
}

.m-job-benefits__icon-plus-inner {
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		display: block;
		width: 60%;
		height: 60%;
		fill: $color-white;
	}
}

.m-job-benefits__item-label {
	order: 2;
	color: $color-haribo-blue;
	font-size: 12px;
	line-height: 1.3;
	text-align: center;
	margin-top: $spacing-yellow;
	@include hyphenate();

	@include mq($from: medium) {
		font-size: 14px;
	}

	@include mq($from: large) {
		font-size: 18px;
		margin-top: $spacing-purple;
	}
}

[data-whatinput="keyboard"] {
	.m-job-benefits__item-button:focus {
		@include keyboard-focus();
	}
}

.m-job-benefits__modal-image {
	width: 100px;
	margin-left: auto;
	margin-right: auto;
}

.m-job-benefits__modal-label {
	color: $color-haribo-blue;
	text-align: center;
	margin-top: $spacing-lilac;

	@include mq($from: medium) {
		margin-top: $spacing-yellow;
	}
}

.m-job-benefits__modal-copy {
	font-size: 16px;
	text-align: center;
	margin-top: $spacing-yellow;

	@include mq($from: medium) {
		font-size: 18px;
		margin-top: $spacing-lilac;
	}

	@include mq($from: large) {
		margin-top: $spacing-yellow;
	}

	ul {
		width: fit-content;
		text-align: left;
		margin-left: auto;
		margin-right: auto;
	}
}


