$base-spacing: (
	small: 50px,
	medium: 110px,
	large: 120px,
	xlarge: 150px,
);

.l-main,
.m-sub-navigation__content-pane {
	// Set a basic margin-top for all modules
	> *:not(:first-child) {
		@include property-breakpoint-map(margin-top, $base-spacing);
	}

	// Set padding to 0 on all modules, that don't have a background color
	> *:not([class*="u-color-bg"]) {
		padding-top: 0;
		padding-bottom: 0;
	}

	> [class*="u-color-bg"] + [class*="u-color-bg"] {
		margin-top: 0;
	}

	// after module from type m006, variant "overview" there should not be a margin
	> .m-content-box--overview + [class*="u-color-bg"] {
		@include mq($from: large) {
			margin-top: 0;
		}
	}

	// after a module which has a opened filter, there should not be a margin
	> [data-has-filter-panel] + [class*="u-color-bg"] {
		transition: margin-top 0.2s;
	}

	> [data-has-filter-panel="open"] + [class*="u-color-bg"] {
		margin-top: 0;
		transition-duration: 0.3s;
	}
}

.l-main {
	// SectionText is first module in main
	> .m-section-text:first-child {
		padding-top: $spacing-salmon;
		margin-top: 0;

		@include mq($from: medium) {
			padding-top: $spacing-cadetblue;
		}

		@include mq($from: large) {
			padding-top: $spacing-darkgreen;
		}

		@include mq($from: xlarge) {
			padding-top: $spacing-indianred;
		}
	}

	// InspirationMachine is first module in main
	> .m-inspiration-machine:first-child .m-inspiration-machine__intro {
		padding-top: $spacing-salmon;

		@include mq($from: medium) {
			padding-top: $spacing-fuchsia-dark;
		}

		@include mq($from: large) {
			padding-top: $spacing-darkgreen;
		}

		@include mq($from: xlarge) {
			padding-top: $spacing-indianred;
		}
	}

	// no margins for raffle module in small viewports when it follows a module with a bg-color
	> [class*="u-color-bg"] + .m-raffle {
		@include mq($until: medium) {
			margin-top: 0;
		}
	}

	// no margins for module with a bg-color after raffle module in small viewports
	> .m-raffle + [class*="u-color-bg"] {
		@include mq($until: medium) {
			margin-top: 0;
		}
	}

	> .m-external-app {
		margin-top: 0;
	}

	> .u-omit-footer-margin {
		margin-top: 0;
	}

	/**
     * for elements which are not in document flow and at the end of .l-main but before <footer> we use the
     * .u-omit-footer-margin util. In this case the footer should not render a margin, also modules with
     * a background color .u-color-bg have to be considered
     */
	[class*="u-color-bg"] + .u-omit-footer-margin ~ footer {
		margin-top: 0;
	}

	// raffle module is an special case because technically it is u-color-bg in small viewports
	.m-raffle + .u-omit-footer-margin ~ footer {
		@include mq($until: medium) {
			margin-top: 0;
		}
	}

	// wheel of fortune m066
	> .m-wof + [class*="u-color-bg"] {
		margin-top: 0;
	}

	// m067 JumpLinks
	> [class*="u-color-bg"] + .m-jump-links,
	> .m-jump-links + [class*="u-color-bg"] {
		margin-top: $spacing-lilac;

		@include mq($from: large) {
			margin-top: $spacing-lightgreen;
		}

		@include mq($from: xlarge) {
			margin-top: $spacing-petrol;
		}
	}

	// m068 ShopList
	.m-maps + .m-shop-list {
		margin-top: $spacing-lightgreen;

		@include mq($from: xlarge) {
			margin-top: $spacing-grey;
		}
	}

	// m069 Promotion Calendar
	.m-promotion-calendar + [class*="u-color-bg"],
	.m-promotion-calendar-form + [class*="u-color-bg"],
	[class*="u-color-bg"] + .m-promotion-calendar.has-no-headline {
		margin-top: 0;

		@include mq($from: xlarge) {
			margin-top: 0;
		}
	}
}

// Gallery or Video after Section Text (One unit with regards to content)
.m-section-text + .m-gallery--no-headline,
.m-section-text + .m-video-full {
	margin-top: $spacing-petrol;

	@include mq($from: medium) {
		margin-top: $spacing-lightblue;
	}

	@include mq($from: large) {
		margin-top: $spacing-lightpink;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-softblue;
	}
}

// Set a basic margin-top for the footer
.m-footer {
	@include property-breakpoint-map(margin-top, $base-spacing);
}

/**
 * handle offset if m-rerouting-popup is visually visible above the header (because it is fixed)
 * --rerouting-popup-height variable should be available inline on body
 */
body.has-displayed-rerouting-popup main {
	> *:first-child:not(.m-rerouting-popup) {
		margin-top: var(--rerouting-popup-height);
	}
}
