.m-teaser-a {
	@include has-background-color() {
		padding-top: $spacing-purple;
		padding-bottom: $spacing-purple;
		background-image: radial-gradient(circle at 38% 96%, var(--gradient-color), var(--background-color) 63%);
		color: $color-white;

		@include mq($from: medium) {
			padding-top: $spacing-petrol;
			padding-bottom: $spacing-petrol;
		}

		@include mq($from: xlarge) {
			padding-top: $spacing-softblue;
			padding-bottom: $spacing-softblue;
		}

		.m-teaser-a__headline {
			color: $color-white;
		}

		.c-icon-text-link__icon {
			fill: $color-haribo-yellow;
		}
	}
}

.m-teaser-a__wrapper {
	@include mq($from: large) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.m-teaser-a--no-image {
	@include mq($from: large) {
		justify-content: flex-start;
	}
}

.m-teaser-a--mirrored {
	@include mq($from: large) {
		flex-direction: row-reverse;
	}
}

.m-teaser-a__image {
	@include svg-mask(a);

	@include is-rtl() {
		transform: scaleX(-1);

		img {
			transform: scaleX(-1);
		}
	}

	@include mq($until: medium) {
		margin-bottom: $spacing-pink;
	}

	@include mq($from: medium, $until: large) {
		margin-bottom: $spacing-lightgreen;
	}

	@include mq($from: large) {
		width: percentage(12/$cols-l);
	}

	@include mq($from: xlarge) {
		width: percentage(13/$cols-xl);
	}
}

.m-teaser-a__text {
	@include mq($from: large) {
		width: percentage(13/$cols-l);
	}

	@include mq($from: xlarge) {
		width: percentage(12/$cols-xl);
	}

	.m-teaser-a--no-image & {
		@include mq($from: large) {
			width: percentage(18/$cols-l);
			margin-left: percentage(4/$cols-l);

			@include is-rtl() {
				margin-left: 0;
				margin-right: percentage(4/$cols-l);
			}
		}

		@include mq($from: xlarge) {
			width: percentage(13/$cols-xl);
			margin-left: percentage(4/$cols-xl);

			@include is-rtl() {
				margin-left: 0;
				margin-right: percentage(4/$cols-xl);
			}
		}
	}
}

.m-teaser-a__headline {
	color: $color-haribo-blue;
}

.m-teaser-a__subline {
	margin-top: $spacing-orange;

	@include mq($from: medium) {
		margin-top: $spacing-lilac;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-purple;
	}
}

.m-teaser-a__copy {
	.m-teaser-a--image & {
		margin-top: $spacing-pink;

		@include mq($from: large) {
			margin-top: $spacing-olive;
		}

		@include mq($from: xlarge) {
			margin-top: $spacing-lilac;
		}
	}

	.m-teaser-a--no-image & {
		margin-top: $spacing-pink;

		@include mq($from: medium) {
			margin-top: $spacing-yellow;
		}

		@include mq($from: large) {
			margin-top: $spacing-orange;
		}

		@include mq($from: xlarge) {
			margin-top: $spacing-lilac;
		}
	}
}

.m-teaser-a__link {
	.m-teaser-a--image & {
		margin-top: $spacing-orange;

		@include mq($from: large) {
			margin-top: $spacing-royalblue;
		}

		@include mq($from: xlarge) {
			margin-top: $spacing-lightblue;
		}
	}

	.m-teaser-a--no-image & {
		margin-top: $spacing-orange;

		@include mq($from: medium) {
			margin-top: $spacing-lightblue;
		}
	}
}

.m-teaser-a__addons {
	display: flex;


	@include mq($until: medium) {
		flex-direction: column;
		margin-top: $spacing-orange;
	}

	@include mq($from: medium) {
		flex-wrap: wrap;
		margin-top: $spacing-lightgreen;
	}
}

.m-teaser-a__addon-link {
	@include mq($until: medium) {
		&:not(:first-child) {
			margin-top: $spacing-olive;
		}
	}

	@include mq($from: medium) {
		&:nth-child(even) {
			margin-left: 35px;

			@include is-rtl() {
				margin-left: 0;
				margin-right: 35px;
			}
		}
	}

	svg {
		@include mq($until: medium) {
			width: 23px;
			height: 23px;
		}

		@include mq($from: medium) {
			width: 20px;
			height: 20px;
		}
	}
}

.m-teaser-a.u-color-bg-maoam {
	color: $color-maoam-blue;

	.m-teaser-a__headline {
		color: $color-maoam-blue;
	}

	.c-icon-text-link__icon {
		fill: $color-maoam-orange;
	}
}
