.c-text-link {
	box-shadow: inset 0 -1px 0 0 rgba($color-black, 0.8);
	color: currentColor;
	@include transition(box-shadow 0.2s);

	@include hover-focus() {
		box-shadow: inset 0 -3px 0 0 rgba($color-black, 0.5);
	}
}

.c-text-link--blue {
	box-shadow: inset 0 -1px 0 0 rgba($color-haribo-blue, 0.8);

	@include hover-focus() {
		box-shadow: inset 0 -3px 0 0 rgba($color-haribo-blue, 0.5);
	}
}

.c-text-link--white {
	box-shadow: inset 0 -1px 0 0 rgba($color-white, 0.8);

	@include hover-focus() {
		box-shadow: inset 0 -3px 0 0 rgba($color-white, 0.5);
	}
}
