/**
Please note: The browser-update-info.ts needs to keep in sync with this component.
 */

$close-button-diameter: (
	small: 30px,
	medium: 50px,
	large: 60px,
	xlarge: 65px
);

$close-button-margin: (
	small: $spacing-olive,
	medium: $spacing-pink,
	large: $spacing-yellow,
	xlarge: $spacing-orange,
);

.c-modal__pane {
	display: none;
	width: 100%;
	height: 100vh; // fallback;
	height: 100dvh;
	padding: $spacing-olive;
	opacity: 0;
	position: fixed;
	top: 0;
	left: 0;
	@include z-index(modal);
	@include transition(opacity 0.2s);

	@include mq($from: large) {
		padding: $spacing-pink;
	}

	@include mq($from: xlarge) {
		padding: $spacing-yellow;
	}
}

.c-modal__outer {
	width: 100%;
	position: relative;
	z-index: 2;
}

.c-modal__inner {
	width: 100%;
	max-height: calc(100vh - #{$spacing-olive * 2});
	max-height: calc(100dvh - #{$spacing-olive * 2});
	padding-top: $spacing-lightblue;
	padding-bottom: $spacing-lightblue;
	overflow-y: auto;
	background-color: $color-white;

	@include mq($from: medium) {
		padding-top: $spacing-petrol;
		padding-bottom: $spacing-petrol;
	}

	@include mq($from: large) {
		max-height: calc(100vh - #{$spacing-pink * 2});
		max-height: calc(100dvh - #{$spacing-pink * 2});
		padding-top: $spacing-seablue;
		padding-bottom: $spacing-seablue;
	}

	@include mq($from: xlarge) {
		max-height: calc(100vh - #{$spacing-yellow * 2});
		max-height: calc(100dvh - #{$spacing-yellow * 2});
		padding-top: $spacing-grey;
		padding-bottom: $spacing-grey;
	}
}

.c-modal__content-wrapper {
	width: 90.8%;

	@include mq($from: medium) {
		width: 83.3%;
	}

	@include mq($from: large) {
		width: 79%;
	}

	@include mq($from: xlarge) {
		width: 83.1%;
	}
}

.c-modal__close-button {
	display: block;
	background-color: $color-haribo-red;
	color: $color-white;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 3;
	margin-left: auto;
	@include property-breakpoint-map(top, $close-button-margin);
	@include property-breakpoint-map(right, $close-button-margin);
	@include property-breakpoint-map(width, $close-button-diameter);
	@include property-breakpoint-map(height, $close-button-diameter);

	@include is-rtl() {
		right: auto;
		@include property-breakpoint-map(left, $close-button-margin);
	}
}

.c-modal__backdrop {
	height: 100vh; // fallback
	height: 100dvh;
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	z-index: 1;
}

[data-modal-placeholder] {
	display: none;
}

.c-modal__pane.is-displayed {
	display: flex;
	align-items: center;
}

.c-modal__pane.is-visible {
	opacity: 1;
	@include transition(opacity 0.4s);
}

.c-modal__pane--video {
	padding: 0;

	.c-modal__outer {
		pointer-events: none;
	}

	.c-modal__inner {
		width: 100%;
		height: 100%;
		max-height: none;
		padding: 0;
		overflow-y: hidden;
		background-color: transparent;
	}

	.c-modal__backdrop {
		background-color: rgba(0, 0, 0, 0.8);
	}

	.c-modal__close-button {
		position: fixed;
		pointer-events: all;
	}
}

.c-modal__pane--small {
	.c-modal__outer {
		max-width: 1100px;
		margin-left: auto;
		margin-right: auto;

		@include mq($from: medium) {
			width: 80%;
		}

		@include mq($from: large) {
			width: 70%;
		}

		@include mq($from: xlarge) {
			width: 60%;
		}
	}

	.c-modal__inner {
		@include mq($from: medium) {
			height: 95vh;
			max-height: 700px;
		}
	}
}

.c-modal__pane--medium-blue {
	.c-modal__outer {
		@include mq($from: large) {
			width: 80%;
			max-width: 1200px;
			margin-left: auto;
			margin-right: auto;
		}

		@include mq($from: xlarge) {
			width: 64%;
		}
	}

	.c-modal__inner {
		--background-color: #{$color-haribo-blue};
		--gradient-color: #{lighten($color-haribo-blue, $lighten-amount)};
		max-height: calc(100dvh - 30px);
		padding-top: 40px;
		background-color: $color-haribo-blue;
		background-image: radial-gradient(circle at 80% 50%, var(--gradient-color), var(--background-color) 100%);
		background-size: 100% 100%;
	}

	.c-modal__content-wrapper {
		width: calc(100% - 70px);
		margin-left: 20px;

		@include mq($from: large) {
			width: calc(100% - 180px);
			margin-left: 90px;
		}
	}

	// ----------------------------------------------------------- M009

	.m-section-text {
		--headline-color: #{$color-haribo-yellow};
		--text-color: #{$color-white};
		background-color: transparent;
		background-image: none;

		a {
			box-shadow: none;
			text-decoration: underline;
			text-underline-offset: 2px;

			@include hover-focus() {
				box-shadow: none;
				text-decoration-color: $color-haribo-yellow;
			}
		}

		.c-rich-text {
			@include font-style(copy-a1);
		}
	}

	.m-section-text__wrapper {
		width: 100%;
	}

	.m-section-text__header {
		width: 100%;
	}

	.m-section-text__headline {
		@include font-style(headline-f2);
	}

	.c-icon-text-link__icon {
		fill: $color-haribo-yellow;
	}

	.m-section-text__inner,
	.m-section-text__date,
	.m-section-text__subline {
		@include mq($until: large) {
			width: 100%;
		}

		@include mq($from: large) {
			width: 90%;
			margin-left: 0;
		}
	}

	// ----------------------------------------------------------- M039

	.m-gallery {
		--headline-color: #{$color-haribo-yellow};
		--text-color: #{$color-white};

		.l-wrapper {
			width: 100%;
		}

		.c-image-component__caption {
			color: $color-white;
			font-size: 12px;
			line-height: 1.3;
			margin-top: 10px;
			@include font-style(copy-a1);
		}
	}

	.m-gallery__headline {
		width: 90%;
		max-width: none;
		@include font-style(headline-f2);
	}

	// ----------------------------------------------------------- M034

	.m-video-full {
		.l-wrapper {
			width: 100%;
		}
	}

	// ----------------------------------------------------------- M007

	.m-teaser-a {
		.l-wrapper {
			width: 100%;
		}
	}

	.m-teaser-a__text {
		color: $color-white;
	}

	.m-teaser-a__headline {
		color: $color-white;
		@include font-style(headline-f2);
	}

	.m-teaser-a__copy {
		@include font-style(copy-a1);
	}

	// ----------------------------------------------------------- M029

	.m-teaser-list-c__teaser {
		@include mq($until: large) {
			width: 100%;
		}
	}

	.m-teaser-list-c {
		.l-wrapper {
			width: 100%;
		}
	}

	.m-teaser-list-c__teaser-headline,
	.m-teaser-list-c__teaser-subline {
		color: $color-white;
	}

	// ----------------------------------------------------------- M044

	.m-iframe-container {
		.l-wrapper {
			width: 100%;
		}
	}
}
