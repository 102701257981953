.m-sub-navigation {
	scroll-margin-top: 30px;
}

.m-sub-navigation__wrapper {
	position: relative;

	@include mq($until: medium) {
		width: 100%;
	}
}

.m-sub-navigation__navigation {
	position: relative;

	&::before,
	&::after {
		content: "";
		width: 50px;
		height: 35px;
		opacity: 0;
		position: absolute;
		top: 0;
		z-index: 2;
		pointer-events: none;
		@include transition(opacity);

		@include mq($until: medium) {
			display: none;
		}

		@include mq($from: medium) {
			width: 90px;
			height: 40px;
		}

		@include mq($from: xlarge) {
			width: 90px;
		}
	}

	&::before {
		background-image: linear-gradient(to right, rgba($color-white, 1), rgba($color-white, 0));
		left: 0;
	}

	&::after {
		background-image: linear-gradient(to left, rgba($color-white, 1), rgba($color-white, 0));
		right: 0;
	}
}

.m-sub-navigation__scroll-list {
	display: flex;
	align-items: flex-start;
	overflow-x: auto;
	overflow-y: hidden;
	position: relative;
	scrollbar-width: none;
	-ms-overflow-style: none;

	@include mq($until: medium) {
		padding-left: 6.3%;
		padding-right: 6.3%;
	}

	&::-webkit-scrollbar {
		height: 0;
		background: transparent;
	}
}

.m-sub-navigation__left,
.m-sub-navigation__right {
	border-color: $color-haribo-blue;
	visibility: hidden;
	opacity: 0;
	position: absolute;
	top: 0;
	z-index: 3;
	pointer-events: none;
	@include transition(opacity 0.3s, visibility 0s linear 0.3s);

	[data-whatintent="touch"] & {
		@include mq($until: large) {
			display: none;
		}
	}
}

.m-sub-navigation__left {
	left: 0;
}

.m-sub-navigation__right {
	right: 0;
}

.m-sub-navigation__main-item {
	position: relative;
	margin-right: 15px;

	@include is-rtl() {
		margin-left: 15px;
		margin-right: 0;
	}

	&:last-of-type {
		// fix padding for last flex item in scroll container
		@include mq($until: medium) {
			border-right: 20px solid transparent;

			@include is-rtl() {
				border-left: 20px solid transparent;
				border-right: none;
			}
		}
	}
}

.m-sub-navigation__main-item .c-button__icon {
	@include is-rtl() {
		margin-left: 0;
		margin-right: 10px;
	}
}

.m-sub-navigation__main-link {
	.c-button__label {
		@include font-style(headline-o1);
	}
}

.m-sub-navigation__nested-navigation {
	visibility: hidden;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	pointer-events: none;
	@include transition(opacity 0.3s, visibility 0s linear 0.2s);

	&.is-visible {
		visibility: visible;
		opacity: 1;
		pointer-events: all;
		@include transition(opacity 0.3s);
	}
}

.m-sub-navigation__nested-list {
	padding: $spacing-pink 35px $spacing-pink 20px;
	background-color: var(--custom-background-color, #{$color-haribo-yellow});
	border-radius: 20px;

	@include is-rtl() {
		padding-left: 35px;
		padding-right: 20px;
	}
}

.m-sub-navigation__nested-item + .m-sub-navigation__nested-item {
	margin-top: $spacing-olive;
}

.m-sub-navigation__nested-link {
	color: $color-haribo-blue;
	@include transition(color);

	&.is-active {
		color: $color-haribo-red;
	}

	@include hover-focus() {
		color: $color-haribo-red;
	}
}

.m-sub-navigation__content {
	margin-top: $spacing-purple;

	@include mq($from: medium) {
		margin-top: $spacing-lightblue;
	}

	@include mq($from: large) {
		margin-top: $spacing-lightpink;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-softblue;
	}
}

.m-sub-navigation__content-pane {
	display: none;

	&.is-visible {
		display: block;
		animation: fade-in 0.4s both;
	}
}

.m-sub-navigation__bottom-navigation {
	display: flex;
	justify-content: center;
	margin-top: $spacing-yellow;

	@include mq($from: medium) {
		margin-top: $spacing-orange;
	}

	@include mq($from: large) {
		margin-top: $spacing-lilac;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-lightblue;
	}
}

.m-sub-navigation__up {
	display: flex;
	align-items: center;

	svg {
		width: 20px;
		color: $color-haribo-red;
		margin-right: 10px;

		@include is-rtl() {
			margin-left: 10px;
			margin-right: 0;
		}

		@include mq($from: large) {
			width: 25px;
		}
	}
}

.m-sub-navigation__wrapper.has-custom-bg-color {
	.c-button--sub-navigation {
		border-color: var(--custom-background-color);
		color: var(--custom-background-color);
		@include transition(color, background-color, border-color);

		&.is-open,
		&.is-active {
			background-color: var(--custom-background-color);
			border-color: var(--custom-background-color);
			color: $color-white;
		}

		@include hover-focus() {
			background-color: var(--custom-background-color);
			border-color: var(--custom-background-color);
			color: $color-white;
		}
	}

	.m-sub-navigation__nested-link {
		color: $color-white;

		&.is-active {
			color: $color-haribo-red;
		}

		@include hover-focus() {
			color: $color-haribo-red;
		}
	}
}


html[dir="ltr"] .m-sub-navigation.has-scrolled-max-left,
html[dir="rtl"] .m-sub-navigation.has-scrolled-max-right {
	.m-sub-navigation__left {
		visibility: visible;
		opacity: 1;
		pointer-events: all;
		@include transition(opacity);
	}

	.m-sub-navigation__navigation::before {
		opacity: 1;
	}
}

html[dir="ltr"] .m-sub-navigation.has-scrolled-max-right,
html[dir="rtl"] .m-sub-navigation.has-scrolled-max-left {
	.m-sub-navigation__right {
		visibility: visible;
		opacity: 1;
		pointer-events: all;
		@include transition(opacity);
	}

	.m-sub-navigation__navigation::after {
		opacity: 1;
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
}
