$spacings: (
	smokeblue-light: (
		value: 5px,
		color: #B5C8CD
	),
	smokeblue: (
		value: 8px,
		color: #657B81
	),
	olive: (
		value: 10px,
		color: #818165
	),
	maroon: (
		value: 12px,
		color: #800102
	),
	pink: (
		value: 15px,
		color: #FF006E
	),
	yellow: (
		value: 20px,
		color: #EEFF00
	),
	orange: (
		value: 25px,
		color: #FF9400
	),
	lilac: (
		value: 30px,
		color: #DFBEFF
	),
	royalblue: (
		value: 35px,
		color: #0800FF
	),
	purple: (
		value: 40px,
		color: #CB00FF
	),
	lightgreen: (
		value: 45px,
		color: #00FFBF
	),
	lightblue: (
		value: 50px,
		color: #01CFED
	),
	ocher: (
		value: 55px,
		color: #A69F72
	),
	petrol: (
		value: 60px,
		color: #41A7B6
	),
	lightpink: (
		value: 70px,
		color: #FFB6C1
	),
	seablue: (
		value: 75px,
		color: #71BBFB
	),
	softblue: (
		value: 80px,
		color: #93C3DD
	),
	grey: (
		value: 90px,
		color: #B5B5B5
	),
	darkgrey: (
		value: 100px,
		color: #3B3B3B
	),
	salmon: (
		value: 120px,
		color: #FF8A8A
	),
	brown: (
		value: 135px,
		color: #793535
	),
	hotpink: (
		value: 150px,
		color: #FF69B4
	),
	fuchsia-dark: (
		value: 140px,
		color: #80245F
	),
	retro-green: (
		value: 160px,
		color: #A49F15
	),
	cadetblue: (
		value: 180px,
		color: #5F9EA0
	),
	darkgreen: (
		value: 200px,
		color: #427935
	),
	indianred: (
		value: 250px,
		color: #CD5C5C
	),
	coral: (
		value: 300px,
		color: #FF7F50
	)
);
$spacing-smokeblue: map-get(map-get($spacings, smokeblue), value);
$spacing-smokeblue-light: map-get(map-get($spacings, smokeblue-light), value);
$spacing-olive: map-get(map-get($spacings, olive), value);
$spacing-maroon: map-get(map-get($spacings, maroon), value);
$spacing-pink: map-get(map-get($spacings, pink), value);
$spacing-yellow: map-get(map-get($spacings, yellow), value);
$spacing-orange: map-get(map-get($spacings, orange), value);
$spacing-lilac: map-get(map-get($spacings, lilac), value);
$spacing-royalblue: map-get(map-get($spacings, royalblue), value);
$spacing-purple: map-get(map-get($spacings, purple), value);
$spacing-lightgreen: map-get(map-get($spacings, lightgreen), value);
$spacing-lightblue: map-get(map-get($spacings, lightblue), value);
$spacing-ocher: map-get(map-get($spacings, ocher), value);
$spacing-lightpink: map-get(map-get($spacings, lightpink), value);
$spacing-seablue: map-get(map-get($spacings, seablue), value);
$spacing-petrol: map-get(map-get($spacings, petrol), value);
$spacing-softblue: map-get(map-get($spacings, softblue), value);
$spacing-grey: map-get(map-get($spacings, grey), value);
$spacing-darkgrey: map-get(map-get($spacings, darkgrey), value);
$spacing-salmon: map-get(map-get($spacings, salmon), value);
$spacing-brown: map-get(map-get($spacings, brown), value);
$spacing-hotpink: map-get(map-get($spacings, hotpink), value);
$spacing-fuchsia-dark: map-get(map-get($spacings, fuchsia-dark), value);
$spacing-retro-green: map-get(map-get($spacings, retro-green), value);
$spacing-cadetblue: map-get(map-get($spacings, cadetblue), value);
$spacing-darkgreen: map-get(map-get($spacings, darkgreen), value);
$spacing-indianred: map-get(map-get($spacings, indianred), value);
$spacing-coral: map-get(map-get($spacings, coral), value);
