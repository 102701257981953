// override aspect ratio stuff

@mixin reset-aspect-ratio-styles() {
	.c-image-component__image-wrapper {
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			position: relative;
		}
	}

	.o-aspect-ratio {
		width: 100%;
		height: 100%;
	}

	.o-aspect-ratio::before {
		display: none;
	}

	.c-image-component__aspect-ratio-helper {
		display: none;
	}
}
