
.c-captcha.frc-captcha,
.c-captcha.frc-captcha.dark {
	width: 100% !important;
	padding-bottom: 0 !important;
	background: transparent;
	border: 0;

	* {
		color: inherit;
		font-size: inherit;
	}

	.frc-button {
		display: none;
	}

	.frc-banner {
		display: none;
	}

	.frc-icon {

		$margin-right: (
			small: map-get($size-map, small) - 12px,
			medium: map-get($size-map, medium) - 12px,
			large: map-get($size-map, large) - 7px,
			xlarge: map-get($size-map, large) - 2px
		);


		width: 32px;
		fill: $color-haribo-blue;
		stroke: $color-haribo-blue;
		margin: 0;
		@include property-breakpoint-map(margin-right, $margin-right);
	}

	.frc-content {
		overflow: hidden;
	}

	.frc-progress {
		max-width: 300px;
	}


	.frc-progress::-webkit-progress-bar {
		background: $color-haribo-grey-2;
	}

	.frc-progress::-webkit-progress-value {
		background: $color-haribo-blue;
	}

	.frc-progress::-moz-progress-bar {
		background: $color-haribo-blue;
	}

	&.dark {
		background: transparent;
		border: 0;

		.frc-icon {
			fill: $color-white;
			stroke: $color-white;
		}

		.frc-progress::-webkit-progress-bar {
			background: rgba(255,255,255,0.7);
		}

		.frc-progress::-webkit-progress-value {
			background: $color-white;
		}

		.frc-progress::-moz-progress-bar {
			background: $color-white;
		}
	}
}


