.m-inspiration-image-list {
	--background-color: #{$color-haribo-blue};
	--gradient-color: #{lighten($color-haribo-blue, $lighten-amount)};
	display: flex;
	flex-wrap: wrap;
	color: $color-white;

	.l-wrapper {
		@include mq($from: large) {
			width: auto;
			max-width: none;
			padding-left: 29px;
			padding-right: 29px;
		}

		@include mq($from: xlarge) {
			padding-left: 45px;
			padding-right: 45px;
		}
	}
}

.m-inspiration-image-list__subline {
	margin-top: $spacing-olive;

	@include mq($from: large) {
		margin-top: $spacing-pink;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-purple;
	}
}

.m-inspiration-image-list__item {
	flex-shrink: 0;
	overflow: hidden;
}

.m-inspiration-image-list__item--1 {
	order: 1;

	@include mq($from: large) {
		order: 3;
	}
}

.m-inspiration-image-list__item--2 {
	order: 2;

	@include mq($from: large) {
		order: 1;
	}
}

.m-inspiration-image-list__item--3 {
	order: 3;

	@include mq($from: large) {
		order: 2;
	}
}

.m-inspiration-image-list__item--4 {
	order: 4;

	@include mq($from: medium) {
		order: 5;
	}
}

.m-inspiration-image-list__item--5 {
	order: 5;

	@include mq($from: medium) {
		order: 4;
	}
}

.m-inspiration-image-list__item--6 {
	order: 6;
}

.m-inspiration-image-list__item--small {
	width: 50%;

	@include mq($from: medium) {
		width: 33.33%;
	}

	@include mq($from: large) {
		width: 25%;
	}
}

.m-inpspiration-image-list__item--with-sub-items {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;

	.m-inspiration-image-list__item {
		width: 100%;
	}
}

.m-inspiration-image-list__item--large {
	width: 100%;

	@include mq($from: medium) {
		width: 66.66%;
	}

	@include mq($from: large) {
		width: 50%;
	}
}

.m-inpspiration-image-list__item--with-sub-items .m-inspiration-image-list__item--link-inside {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100% !important;
	height: 50% !important;
	position: relative;
}

.m-inspiration-image-list__item--link-inside {
	background-image: radial-gradient(circle at 90% 80%, var(--gradient-color) 0.2%, var(--background-color) 100%);
}

.m-inspiration-image-list__item--headline {
	width: 100%;
	background-image: radial-gradient(circle at 90% 80%, var(--gradient-color) 0.2%, var(--background-color) 100%);
	@include aspect-ratio-helper("2x1");
	position: relative;

	@include mq($from: medium) {
		@include aspect-ratio-helper("3x1");
	}

	@include mq($from: large) {
		width: 50%;
		@include aspect-ratio-helper("2x1");
	}

	.l-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
	}
}

.m-inspiration-image-list__item-link {
	display: block;
}

a.m-inspiration-image-list__item-link {
	img {
		@include transition(transform);
	}

	@include hover-focus() {
		img {
			transform: scale(1.1);
		}
	}
}

.m-inspiration-image-list__item--product-gradient .m-inspiration-image-list__item-link {
	background-image: radial-gradient(circle at 50% 70%, var(--gradient-color) 30%, var(--background-color) 70%);
	background-position: center;
	background-size: 100% 100%;
	@include transition(background-size 0.8s);
}

.m-inspiration-image-list__item--product-gradient a.m-inspiration-image-list__item-link {
	@include hover-focus() {
		background-size: 180% 180%;
	}
}

.m-inspiration-image-list__link {
	padding-left: 10px;
	padding-right: 10px;
	position: static;

	&::before {
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.c-icon-text-link__icon {
		@include mq($until: medium) {
			width: 53px;
		}
	}
}

.m-inspiration-image-list.u-color-bg-maoam {
	color: $color-maoam-blue;

	.c-icon-text-link__icon {
		fill: $color-maoam-orange;
	}
}
