.m-iframe-container {
	&:not(.has-fixed-aspect-ratio):not(.is-loaded) .l-wrapper {
		min-height: 300px;
	}
}

.m-iframe-container .l-wrapper {
	position: relative;
}

.m-iframe-container__loader {
	display: flex;
	align-items: center;
	justify-content: center;
}

.m-iframe-container__iframe {
	width: 1px;
	// Note: Using min-width to set the width of the Iframe, works around an issue in iOS that can prevent the iFrame from sizing correctly.
	min-width: 100%;
	border: 0;
	opacity: 0.0;
	@include transition(opacity);

	&.o-full {
		height: 100% !important;
	}
}

.m-iframe-container.is-loaded {
	.m-iframe-container__loader {
		display: none;
	}

	.m-iframe-container__iframe {
		opacity: 1.0;
	}
}
