.m-promotion-calendar-form__inner {
	> *:not(:first-child) {
		@include property-breakpoint-map(margin-top, $base-spacing);
	}
}

.m-promotion-calendar-form__aspect-placeholder {
	width: 100%;
	height: auto;

	@include mq($until: large) {
		aspect-ratio: 4 / 3; // stylelint-disable-line property-no-unknown
	}

	@include mq($from: large) {
		aspect-ratio: 16 / 9; // stylelint-disable-line property-no-unknown
	}
}
