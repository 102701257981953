.m-wof {
	--radial-gradient: radial-gradient(circle at 31% 56%, var(--gradient-color),var(--background-color) 65%);
	background-image: var(--bg-portrait), var(--radial-gradient);
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
	position: relative;

	@include mq($from: large) {
		background-image: var(--bg-big), var(--radial-gradient);
		background-position: bottom right;
	}

	.c-raffle-form {
		--text-color: #{$color-white};
		--accent-color: var(--background-color);
	}
}

.m-wof__outer {
	position: relative;
	z-index: 3;
}

.m-wof__inner {
	padding-bottom: $spacing-lightblue;
	position: relative;
	z-index: 2;

	@include mq($from: large) {
		display: flex;
		align-items: flex-start;
	}
}

.m-wof__wheel-wrapper {
	width: 100%;
	margin-top: -10%;

	@include mq($from: large) {
		$offset: 2.5;
		width: percentage((11 + $offset)/$cols-l);
		margin-top: -7%;
		margin-left: percentage($offset/$cols-l) * -1;
		margin-right: percentage(2/$cols-l);
	}

	@include mq($from: xlarge) {
		$offset: 1.5;
		width: percentage((12 + $offset)/$cols-xl);
		margin-left: percentage($offset/$cols-xl) * -1;
		margin-right: percentage(2/$cols-xl);
	}
}

.m-wof__content {
	width: 100%;

	@include mq($until: large) {
		margin-top: $spacing-lilac;
	}

	@include mq($from: large) {
		align-self: center;
		width: percentage(13/$cols-l);
	}

	@include mq($from: xlarge) {
		width: percentage(10/$cols-xl);
	}
}

.m-wof__headline {
	color: $color-white;
}

.m-wof__copy {
	color: $color-white;
	margin-top: $spacing-yellow;
}

.m-wof__image {
	margin-top: $spacing-lilac;

	@include mq($from: large) {
		margin-top: $spacing-lightblue;
	}
}

.m-wof__button {
	color: var(--background-color);
	margin-top: $spacing-yellow;

	@include mq($from: xlarge) {
		margin-top: $spacing-lightblue;
	}
}

.m-wof__link {
	color: $color-white;
	margin-top: $spacing-yellow;

	@include mq($from: xlarge) {
		margin-top: $spacing-lightblue;
	}
}

.m-wof__cover-wave {
	width: 100%;
	fill: $color-white;
	position: relative;
	top: -2px;
	z-index: 2;
}

.m-wof__step {
	animation-name: step-reveal-animation;
	animation-duration: 1s;
	animation-timing-function: $animation-default-easing;
	animation-fill-mode: forwards;
}

.m-wof__decorative-image {
	width: 103px;
	position: absolute;
	bottom: 0;
	right: 10px;
	z-index: 5;

	@include mq($until: large) {
		display: none;
	}

	@include mq($from: xlarge) {
		width: 210px;
	}
}

// ---------------------------------- intro step

.m-wof__step-intro {
	@include mq($until: large) {
		display: flex;
		flex-direction: column;

		.m-wof__headline,
		.m-wof__image {
			order: 2;
		}

		.m-wof__headline {
			margin-top: $spacing-yellow;
		}

		.m-wof__copy {
			order: 1;
			margin-top: 0;
		}
	}
}

.m-wof__swipe-hint {
	display: flex;
	align-items: center;

	@include mq($from: large) {
		margin-top: $spacing-yellow;
	}
}

.m-wof__swipe-icon {
	width: 35px;
	fill: $color-white;
	margin-right: 20px;
	transform-origin: center bottom;
	animation-name: swipe-hint-animation;
	animation-duration: 3s;
	animation-timing-function: $animation-default-easing;
	animation-iteration-count: infinite;

	@include mq($from: large) {
		width: 45px;
		margin-right: 30px;
	}

	@include mq($from: xlarge) {
		width: 57px;
		margin-right: 32px;
	}
}

.m-wof__swipe-copy {
	color: $color-white;
}

.m-wof__wheel-confetti-video {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	transform: translate3d(0, 0, 0);
	pointer-events: none;
	mix-blend-mode: screen;
	-webkit-backface-visibility: visible;
}

// ---------------------------------- form step

.m-wof__form {
	margin-top: $spacing-yellow;

	@include mq($from: xlarge) {
		margin-top: $spacing-lilac;
	}
}

// ---------------------------------- animation

@keyframes swipe-hint-animation {
	0% {
		transform: rotate(0deg);
	}

	50% {
		transform: rotate(13deg);
	}

	100% {
		transform: rotate(0deg);
	}
}

@keyframes step-reveal-animation {
	0% {
		opacity: 0;
		transform: translateX(-20px);
	}

	20% {
		opacity: 0;
		transform: translateX(-20px);
	}

	100% {
		opacity: 1;
	}
}

@import "components/Wheel";
