@keyframes fade-out {
	from {
		visibility: visible;
		opacity: 1;
	}

	to {
		visibility: hidden;
		opacity: 0;
	}
}

.a-fade-out {
	animation-name: fade-out;
	animation-duration: $animation-default-duration;
	animation-timing-function: $animation-default-easing;
	animation-fill-mode: forwards;
}
