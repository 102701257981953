$filter-group-margin: 10px;
$filter-group-margin-xl: 15px;

.c-filter-group {
	display: flex;
	max-height: 500px;
	flex-direction: column;
	margin-bottom: -$filter-group-margin; // counter against single filter elements
	transition: max-height 500ms ease-in-out;

	@include mq($from: large) {
		flex-direction: row;
		align-items: flex-start;
		width: fit-content;
	}

	&.is-hidden {
		overflow: hidden;
		max-height: 0;
		transition: max-height 500ms ease-in-out;
	}
}

.c-filter-group__label {
	color: $color-haribo-red;
	white-space: nowrap;

	@include mq($from: large) {
		min-width: 160px;
		line-height: $filter-switch-height;
		white-space: nowrap;
	}

	@include mq($from: xlarge) {
		line-height: $filter-switch-height-xl;
	}
}

.c-filter-group__filters {
	display: flex;
	flex-wrap: wrap;

	@include mq($until: large) {
		margin-top: $spacing-yellow;
	}

	@include mq($until: xlarge) {
		margin-left: -$filter-group-margin; // counter against single filter elements

		@include is-rtl() {
			margin-left: 0;
			margin-right: -$filter-group-margin;
		}
	}

	@include mq($from: xlarge) {
		margin-left: -$filter-group-margin-xl;

		@include is-rtl() {
			margin-left: 0;
			margin-right: -$filter-group-margin-xl;
		}
	}
}

.c-filter-group__filter {
	margin-bottom: $filter-group-margin;
	margin-left: $filter-group-margin;

	@include is-rtl() {
		margin-left: 0;
		margin-right: $filter-group-margin;
	}
}
