.m-wof__wheel {
	width: 100%;
	overflow: hidden;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	// reserve space for the wheel before it loads lazy, this prevents layout shift
	aspect-ratio: 428 / 556; // stylelint-disable-line property-no-unknown;

	@include mq($until: large) {
		max-width: 480px;
	}

	@include mq($from: large) {
		max-width: 600px;
	}

	* {
		user-select: none;
	}
}

.m-wof__wheel-back {
	width: 100%;
	pointer-events: none;
}

.m-wof__wheel-main {
	width: 84%;
	overflow: hidden;
	border-radius: 50%;
	position: absolute;
	top: 15.3%;
	left: 0;
	right: 0;
	z-index: 2;
	margin-left: auto;
	margin-right: auto;
	cursor: grab;
}

.m-wof__wheel-images {
	$width: 62%;
	width: $width;
	border-radius: 50%;
	position: absolute;
	top: (100% - $width) / 2;
	left: (100% - $width) / 2;
	aspect-ratio: 1 // stylelint-disable-line property-no-unknown;
}

.m-wof__wheel-holder {
	$holderDiameter: 50%;
	--glow-dim: 0.3;
	display: flex;
	align-items: center;
	justify-content: center;
	width: $holderDiameter;
	height: $holderDiameter;
	background-image: radial-gradient(circle, rgba(255,255,255, var(--glow-dim)) 0%, rgba(255,255,255, 0) 75%);
	position: absolute;
	transform: translate(-50%, -50%);
	transform-origin: center center;

	&:nth-child(1) {
		top: 0;
		left: 50%;
	}

	&:nth-child(2) {
		top: 14%;
		left: 86%;

		.m-wof__wheel-image {
			transform: rotate(45deg);
		}
	}

	&:nth-child(3) {
		top: 50%;
		left: 100%;

		.m-wof__wheel-image {
			transform: rotate(90deg);
		}
	}

	&:nth-child(4) {
		top: 86%;
		left: 86%;

		.m-wof__wheel-image {
			transform: rotate(135deg);
		}
	}

	&:nth-child(5) {
		top: 100%;
		left: 50%;

		.m-wof__wheel-image {
			transform: rotate(180deg);
		}
	}

	&:nth-child(6) {
		top: 86%;
		left: 14%;

		.m-wof__wheel-image {
			transform: rotate(225deg);
		}
	}

	&:nth-child(7) {
		top: 50%;
		left: 0;

		.m-wof__wheel-image {
			transform: rotate(270deg);
		}
	}

	&:nth-child(8) {
		top: 14%;
		left: 14%;

		.m-wof__wheel-image {
			transform: rotate(315deg);
		}
	}

	&.is-selected {
		// z-index: 2;
		// --glow-dim: 0.7;
		// transform: translate(-50%, -50%) scale(1.3);
	}
}

.m-wof__wheel-image {
	width: 100%;
	height: 100%;
	@include reset-aspect-ratio-styles ();

	img {
		pointer-events: none;
	}
}

.m-wof__segment {
	transition: fill 0.3s ease-out;
}

.m-wof__wheel-front {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
	pointer-events: none;
}
