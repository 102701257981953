$arrow-button-click-area-spacing: 15px;

.c-icon-text-link {
	display: inline-flex;
	align-items: center;
	color: currentColor;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: -#{$arrow-button-click-area-spacing};
		bottom: -#{$arrow-button-click-area-spacing};
		left: -#{$arrow-button-click-area-spacing};
		right: -#{$arrow-button-click-area-spacing};
	}
}

.c-icon-text-link--top-aligned {
	align-items: flex-start;

	&.fs-headline-j3 .c-icon-text-link__icon {
		margin-top: 5px;

		@include mq($from: 1200px, $until: xlarge) {
			margin-top: 10px;
		}
	}
}

.c-icon-text-link__icon {
	flex-shrink: 0;
	fill: currentColor;
	margin-right: 15px;

	@include is-rtl() {
		margin-left: 15px;
		margin-right: 0;
		transform: scaleX(-1);
	}
}

.c-icon-text-link--red .c-icon-text-link__icon {
	fill: $color-haribo-red;
}

.c-icon-text-link--yellow .c-icon-text-link__icon {
	fill: $color-haribo-yellow;
}

.c-icon-text-link--blue .c-icon-text-link__icon {
	fill: $color-haribo-blue;
}

.c-icon-text-link--wave-arrow-right .c-icon-text-link__icon {
	@include transition(transform);

	@include mq($until: medium) {
		width: 53px;
		height: 15px;
	}

	@include mq($from: medium) {
		width: 64px;
		height: 18px;
	}

	@include mq($from: xlarge) {
		width: 90px;
		height: 25px;
	}
}

// make hover styles more flexible to be useful in edge case scenarios (M005)

@import "icon-text-link-hover";

.c-icon-text-link:not(.c-icon-text-link--wave-arrow-right) {
	@include hover-focus() {
		@include icon-text-link-hover();
	}
}

.c-icon-text-link--wave-arrow-right {
	[data-whatintent="mouse"] &:hover,
	[data-whatinput="keyboard"] &:focus {
		@include icon-text-link-wave-arrow-right-hover();
	}

	[dir="rtl"][data-whatintent="mouse"] &:hover,
	[dir="rtl"][data-whatinput="keyboard"] &:focus {
		@include icon-text-link-wave-arrow-right-hover(rtl);
	}
}

.c-icon-text-link__label {
	position: relative;

	&::before,
	&::after {
		content: "";
		height: 2px;
		background-color: currentColor;
		position: absolute;
		bottom: -2px;
		left: 0;
		right: 0;
	}

	&::before {
		opacity: 0;
		transform: translateY(- $distance);
		transition: transform 0s $easeOutBack, opacity 0s;
	}

	&::after {
		opacity: 0;
		transform: translateY($distance/2);
		transition: transform $duration $easeOutBack, opacity $duration;
	}
}

