// Predefined background colors
$background-colors: $color-haribo-blue, $color-haribo-dark-blue, $color-haribo-green, $color-haribo-orange,
	$color-haribo-red, $color-haribo-pink, $color-haribo-yellow,
	// Custom:
	#169746, #00993D, #871F40, #008237, #BD8E1D, #AAACAD, #000000, #11A4C1, #8D53D6;

@each $color in $background-colors {
	$color-string: to-upper-case(str-slice(inspect($color), 2));

	.u-color-bg-#{$color-string} {
		--background-color: #{$color};
		--gradient-color: #{lighten($color, 15%)};
		--pieces-hue-color: #{adjust-hue($color, -10deg)};
		--pieces-hue-color-transparent: #{rgba(adjust-hue($color, -10deg), 0)};
		background-color: $color;
	}
}

// Predefined text colors
$text-colors: $color-haribo-blue, $color-haribo-dark-blue, $color-haribo-green, $color-haribo-orange, $color-haribo-red,
	// Custom:
	#169746;

@each $color in $text-colors {
	$color-string: to-upper-case(str-slice(inspect($color), 2));

	.u-color-text-#{$color-string} {
		color: $color;
	}
}

// Brand and context related colors
.u-color-bg-maoam {
	--background-color: #{$color-maoam-yellow};
	--gradient-color: #{$color-maoam-yellow-light};
	background-color: $color-maoam-yellow;
}

.u-color-text-maoam {
	color: $color-maoam-blue;
}
