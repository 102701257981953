@import "components/shared-mixin";

.m-inspiration-machine__intro {
	padding-top: $spacing-lightblue;
	padding-bottom: $spacing-lightblue;
	background-image: radial-gradient(circle at 90% 80%, var(--gradient-color) 0.2%, var(--background-color) 100%);
	color: $color-white;
	position: relative;

	@include mq($from: medium) {
		padding-top: $spacing-petrol;
		padding-bottom: $spacing-petrol;
	}

	@include mq($from: large) {
		padding-top: $spacing-grey;
		padding-bottom: $spacing-grey;
	}

	@include mq($from: xlarge) {
		padding-top: $spacing-salmon;
		padding-bottom: $spacing-salmon;
	}
}

.m-inspiration-machine__intro-text {
	width: percentage(11/$cols-s);
	margin-left: percentage(1/$cols-s);

	@include mq($from: medium) {
		width: percentage(9/$cols-m);
		margin-left: percentage(1/$cols-m);
	}

	@include mq($from: large) {
		width: percentage(14/$cols-l);
		margin-left: 0;
	}

	@include mq($from: xlarge) {
		width: percentage(16/$cols-l);
		max-width: 880px;
	}
}

.m-inspiration-machine__headline {
	@include overflow-wrap();
}

.m-inspiration-machine__copy {
	width: 83%;
	margin-top: $spacing-pink;

	@include mq($from: medium) {
		margin-top: $spacing-orange;
	}

	@include mq($from: xlarge) {
		width: 80%;
		margin-top: $spacing-lilac;
	}
}

.m-inspiration-machine__image-default,
.m-inspiration-machine__image-portrait {
	display: none;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	@include reset-aspect-ratio-styles();

	img {
		object-fit: cover;
		object-position: center left;
	}
}

.m-inspiration-machine__image-portrait {
	width: 25%;

	@include mq($from: medium) {
		width: 29%;
	}

	@include mq($until: large) {
		display: block;
	}
}

.m-inspiration-machine__image-default {
	@include mq($from: large) {
		display: block;
		width: 45%;
	}

	@include mq($from: xlarge) {
		width: 40%;
	}
}

// ------------------------------------------------------------------------------------------- product pieces

.m-inspiration-machine__results {
	position: relative;
}

// default dimension (error teaser)
.m-inspiration-machine__results-observer {
	width: 100%;
	height: 1px;
	opacity: 0;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 2;
	pointer-events: none;
}

// dimensions when results available and at least first row is in viewport
.m-inspiration-machine__product-pieces + .m-inspiration-machine__results-observer {
	@include aspect-ratio-helper("1x1");
	width: 50%;
	height: auto;
	top: 0;
	bottom: auto;

	@include mq($from: medium) {
		width: 33.33%;
	}

	@include mq($from: large) {
		width: 25%;
	}

	@include mq($from: xlarge) {
		width: 20%;
	}
}

.m-inspiration-machine__product-pieces {
	display: grid;
	grid-template-columns: repeat(2, 50%);
	overflow: hidden;

	@include mq($from: medium) {
		grid-template-columns: repeat(3, 33.33%);
	}

	@include mq($from: large) {
		grid-template-columns: repeat(4, 25%);
	}

	@include mq($from: xlarge) {
		grid-template-columns: repeat(5, 20%);
	}
}

.m-inspiration-machine__piece-image {
	width: 100%;
}

.m-inspiration-machine__product-piece {
	overflow: hidden;
	background-image: linear-gradient(135deg, var(--pieces-hue-color), var(--pieces-hue-color-transparent)), radial-gradient(circle farthest-corner, $color-white, var(--background-color) 150%);

	img {
		&.lazyload,
		&.lazyloading {
			opacity: 0;
		}

		&.lazyloaded {
			opacity: 1;
		}

		@include transition(transform, opacity 0.4s);
	}
}

.m-inspiration-machine__piece-button {
	background-color: transparent;
	outline: none;
	z-index: 2;

	@include hover-focus() {
		~ .m-inspiration-machine__piece-image img {
			transform: scale(1.1);
		}
	}
}

.m-inspiration-machine__pagination-tile {
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: radial-gradient(circle at 90% 80%, var(--gradient-color) 0.2%, var(--background-color) 100%);
	color: $color-white;
	@include transition(color);

	@include hover-focus() {
		color: $color-haribo-yellow;
	}
}

[data-whatinput="keyboard"] {
	.m-inspiration-machine__product-piece:focus-within,
	.m-inspiration-machine__pagination-tile:focus {
		@include keyboard-focus(-3px);
	}
}

// ------------------------------------------------------------------------------------------- filters

.m-inspiration-machine__filters {
	width: 100%;
	background-color: $color-white;
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
	position: sticky;
	bottom: 0;
	left: 0;
	z-index: 2;
	transform: translateY(100%);
	@include transition(transform 0.4s);

	&.is-visible {
		transform: translateY(0);
	}

	// overrides for pagination dots when inside flickity on medium and small

	.flickity-page-dots {
		bottom: 5px;

		@include mq($from: medium) {
			bottom: 20px;
		}

		.dot {
			background-color: $color-haribo-blue;
			opacity: 0.15;
			margin-left: 5px;
			margin-right: 5px;
			@include transition(opacity);

			@include mq($until: medium) {
				width: 6px;
				height: 6px;
			}
		}

		.dot.is-selected {
			opacity: 1;
		}
	}
}

.m-inspiration-machine__filters-inner {
	width: 100%;
	padding-top: 0;
	padding-bottom: $spacing-orange;
	position: relative;

	@include mq($from: medium) {
		padding-top: $spacing-orange;
		padding-bottom: $spacing-lightgreen;
	}

	@include mq($from: large) {
		display: flex;
		justify-content: space-evenly;
		padding-bottom: $spacing-orange;
		padding-left: 0;
		padding-right: 0;
	}

	/* enable/disable Flickity */
	&::after {
		content: "flickity";
		display: none;

		@include mq($from: large) {
			content: "";
		}
	}
}

.m-inspiration-machine__filters:not(.slider-intro-finished) {
	overflow: hidden;
	pointer-events: none;

	.m-inspiration-machine__filters-inner {
		display: flex;
	}

	.m-inspiration-machine__filter-block {
		@include mq($until: medium) {
			flex: 0 0 100%;
		}
	}
}

.m-inspiration-machine__filter-block {
	width: 100% !important; // override flicktiy base
	position: relative;

	@include mq($until: large) {
		padding-left: 25px;
		padding-right: 25px;

		&:first-child {
			@include mq($from: medium) {
				padding-left: 50px;
			}
		}

		&:last-child {
			@include mq($from: medium) {
				padding-right: 50px;
			}
		}
	}

	@include mq($from: medium) {
		width: auto !important; // override flicktiy base
	}

	@include mq($from: large) {
		padding-left: 0;
		padding-right: 0;
	}
}

.m-inspiration-machine__filter-block-inner {
	width: fit-content;
	margin: 0 auto;

	@include mq($until: medium) {
		display: flex;
		flex-direction: column;
		height: 125px; // defined height, because of Flickity
	}

	@include mq($from: medium, $until: large) {
		height: 155px; // defined height, because of Flickity
	}

	@include mq($from: large) {
		height: 100%;
	}
}

.m-inspiration-machine__filter-headline {
	color: $color-haribo-blue;
	text-align: center;
	text-transform: uppercase;

	@include mq($until: medium) {
		display: none;
	}
}

.m-inspiration-machine__filter-controls {
	width: fit-content;
	margin-top: $spacing-orange;
	margin-left: auto;
	margin-right: auto;

	@include mq($until: medium) {
		margin-top: auto;
		margin-bottom: auto;
	}
}

// filter types

.m-inspiration-machine__taste-filter {
	.c-toggle-switch {
		@include mq($from: xlarge) {
			@include toggle-switch--big();
		}

		&:not(:first-child) {
			margin-top: 6px;

			@include mq($from: xlarge) {
				margin-top: 8px;
			}
		}
	}

	.m-inspiration-machine__filter-controls {
		padding-top: 3px;

		@include mq($from: xlarge) {
			padding-top: 0;
		}
	}
}

.m-inspiration-machine__consistency-filter {
	.c-dial-radio-group {
		@include mq($from: xlarge) {
			@include dial-radio-group--big();
		}
	}

	.c-dial-radio-group__options-left,
	.c-dial-radio-group__options-right {
		width: 8ch;
	}
}

.m-inspiration-machine__elasticity-filter,
.m-inspiration-machine__volume-filter {
	.m-inspiration-machine__filter-block-inner {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-bottom: 8px;

		@include mq($until: medium) {
			padding-bottom: 0;
		}

		@include mq($from: medium, $until: large) {
			padding-bottom: 16px;
		}
	}
}

.m-inspiration-machine__elasticity-filter {
	.c-toggle-switch {
		@include toggle-switch--long();
		@include toggle-switch--same-background();

		@include mq($from: xlarge) {
			@include toggle-switch--long-big();
		}
	}
}

.m-inspiration-machine__volume-filter {
	.c-toggle-scale {
		@include mq($from: xlarge) {
			@include toggle-scale--big();
		}
	}
}

.m-inspiration-machine__color-filter {
	.c-toggle-circle {
		margin-left: 3px;
		margin-right: 3px;

		@include mq($from: xlarge) {
			@include toggle-circle--big();
		}
	}
}

.m-inspiration-machine__color-row {
	display: flex;
}

.m-inspiration-machine__color-row + .m-inspiration-machine__color-row {
	margin-top: $spacing-smokeblue-light;
}

@import "components/InspirationOverlay";
@import "components/teaser";

.debug-json {
	margin-top: 50px;
}
