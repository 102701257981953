$grid-width: (
	small: 87.5%,
	medium: 81.15%,
	large: 76.86%,
	xlarge: 81.19%
);

$grid-max-width: 1600px;
$module-max-width: 1920px;

$cols: (
	small: 14,
	medium: 14,
	large: 26,
	xlarge: 26
);

// Usage: `percentage(2/$cols-m)` for 2 cols %-width on M breakpoint
$cols-s: map-get($cols, small);
$cols-m: map-get($cols, medium);
$cols-l: map-get($cols, large);
$cols-xl: map-get($cols, xlarge);

$input-height-map: (
	small: 44px,
	medium: 48px,
	large: 52px,
	xlarge: 70px
);

$input-side-padding-map: (
	small: 20px,
	medium: 25px,
	large: 25px,
	xlarge: 40px
);

$input-border-size: 4px;

// header related
$header-meta-links-height: 30px;
$header-meta-links-margin-bottom: 5px;
$header-main-links-height: 52px;

$header-padding-top: (
	small: $spacing-orange,
	medium: $spacing-royalblue,
	large: $spacing-royalblue,
	xlarge: $spacing-lightgreen
);

$padding-top-aligned-with-main-navigation: map-merge(
	$header-padding-top,
	(
		large: map-get($header-padding-top, large) + $header-meta-links-height + $header-meta-links-margin-bottom,
		xlarge: map-get($header-padding-top, xlarge) + $header-meta-links-height + $header-meta-links-margin-bottom
	)
);
