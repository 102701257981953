.c-content-slider {
	position: relative;
}

.c-content-slider__pagination {
	display: flex;
	align-items: center;
	width: fit-content;
	height: 22px;
	padding-left: 8px;
	padding-right: 8px;
	background-color: $color-white;
	border-radius: 12px;
	margin-top: $spacing-olive;
	margin-left: auto;
	margin-right: auto;

	@include mq($from: large) {
		height: 25px;
	}
}

.c-content-slider__slide {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 300px;
	background-color: lightblue;
}

.c-content-slider__pagination-item {
	width: 8px;
	height: 8px;
	background-color: rgba($color-haribo-red, 0.2);
	border-radius: 50%;
	pointer-events: all;
	@include transition(opacity);

	@include mq($from: large) {
		width: 12px;
		height: 12px;
	}

	&:not(:last-child) {
		margin-right: 5px;

		@include is-rtl() {
			margin-left: 5px;
			margin-right: 0;
		}
	}

	&.is-active {
		background-color: $color-haribo-red;
	}
}

.c-content-slider__pagination--a {
	padding: 0;
	background-color: transparent;

	.c-content-slider__pagination-item {
		width: 16px;
		height: 16px;
		background-color: rgba($color-haribo-red, 0.2);

		&:not(:last-child) {
			margin-right: 10px;

			@include is-rtl() {
				margin-left: 10px;
				margin-right: 0;
			}
		}

		&.is-active {
			background-color: $color-haribo-red;
		}
	}
}

html.no-js {
	.c-content-slider__pagination {
		display: none;
	}
}
