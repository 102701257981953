$form-vertical-margin: (
	small: $spacing-royalblue,
	medium: $spacing-royalblue,
	large: $spacing-lightblue,
	xlarge: $spacing-ocher
);

.sf-job-application-layout {

	@include mq($from: large) {
		.m-form-layout {
			flex-wrap: nowrap;
			margin-left: -$spacing-ocher; // Equalize margin
		}

		.m-form-layout__item {
			flex: 1 1 auto;
			margin-left: $spacing-ocher;

			// Nested form layouts
			.m-form-layout {
				width: calc(100% - #{$spacing-ocher});

				.m-form-layout {
					width: calc(100% - #{$spacing-orange});
					margin-left: -$spacing-orange; // Equalize margin

					.m-form-layout__item {
						margin-left: $spacing-orange;
					}
				}
			}
		}

		.m-form-layout--30-30-30 {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;

			> .m-form-layout__item {
				flex: 0 0 33.333%;
				padding-right: $spacing-ocher;
			}
		}

		.m-form-layout--35-65 {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;

			> .m-form-layout__item:nth-child(1) {
				flex: 0 0 35%;
			}

			> .m-form-layout__item:nth-child(2) {
				flex: 0 0 65%;
			}
		}

		.m-form-layout--50-50 {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;

			> .m-form-layout__item {
				flex: 0 0 50%;
			}
		}

		.m-form-layout--80-20 {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;

			> .m-form-layout__item:nth-child(1) {
				flex: 0 0 80%;
			}

			> .m-form-layout__item:nth-child(2) {
				flex: 0 0 20%;
			}
		}
	}

	.m-form-layout__item--input {
		&[data-type="DateInput"],
		&[data-type="TextInput"],
		&[data-type="SelectInput"],
		&[data-type="RatingInput"],
		&[data-type="StaticInput"] {
			// Subtract height + margin-bottom of form label
			@each $breakpoint, $value in (small, medium, large, xlarge) {
				@if $breakpoint == small {
					// Mobile first, so no media query needed for "small"
					margin-top: map-get($form-vertical-margin, $breakpoint) - map_get($form-label-height-map, $breakpoint) - map-get($form-label-margin-bottom-map, $breakpoint);

					&:has(>.hidden) {
						margin-top: 0;
					}
				} @else {
					@include mq($from: $breakpoint) {
						margin-top: map-get($form-vertical-margin, $breakpoint) - map_get($form-label-height-map, $breakpoint) - map-get($form-label-margin-bottom-map, $breakpoint);

						&:has(>.hidden) {
							margin-top: 0;
						}
					}
				}
			}
		}

		&[data-type="Checkbox"] {
			& + & {
				margin-top: $spacing-royalblue; // Same on all viewports

				&:has(>.hidden) {
					margin-top: 0;
				}
			}
			@include property-breakpoint-map(margin-top, (
				small: $spacing-royalblue,
				medium: $spacing-lightblue,
				xlarge: $spacing-grey
			));
		}

		// "TextInputExtra" is the replacement for "Honeypot"
		&[data-type="TextInputExtra"] {
			clip: auto; // Overwrite clip from visuallyhidden because causes problems in forms inside M040 SubNavigation
			@include visuallyhidden();
		}

		&[data-type="HiddenInput"] {
			display: none;
		}

		&[data-type="SfJobSelection"] {
			margin-top: 0;
		}
		@include property-breakpoint-map(margin-top, $form-vertical-margin);
	}

	.m-form-layout__captcha {
		margin-top: $spacing-royalblue; // Same on all viewports
	}

	.c-form__input-field {
		&.hidden {
			@include visuallyhidden();
		}
	}

	// stylelint-disable
	label {
		.hidden-label {
			display: none;
		}

		.no-transform {
			transform: none !important;
		}
	}
}
