.c-input-choice--radio {
	.c-input-choice__replacement {
		border-radius: 50%;

		// Radio button dot with border-radius
		&::after {
			width: 50%;
			height: 50%;
			background-color: currentColor;
			border: none;
			border-radius: 50%;
			top: 25%;
			left: 25%;
		}
	}
}

.c-radio-group {
	display: flex;
	flex-wrap: wrap;
}

.c-radio-group__radio {
	$margin-right: (
		small: 50px,
		large: 60px,
		xlarge: 70px
	);

	width: auto;
	margin-top: 0 !important;
	@include property-breakpoint-map(margin-right, $margin-right);

	@include is-rtl() {
		margin-right: 0;
		@include property-breakpoint-map(margin-left, $margin-right);
	}
}
