.c-image-component__image-wrapper {
	position: relative;
}

.c-image-component__aspect-ratio-helper {
	width: 100%;
}

.js .lazyload,
.js .lazyloading {
	background-color: transparent;
}
