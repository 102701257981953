$stage-padding-bottom: (
	large: $spacing-darkgrey,
	xlarge: $spacing-grey
);

.m-stage {
	overflow: hidden;
	position: relative;

	// make it more specific, for overriding global module spacings, it is for the whitespace at the bottom of the bubble
	.l-main & {
		@include property-breakpoint-map(padding-bottom, $stage-padding-bottom);
		background-color: transparent;
	}
}

.m-stage__text-slider {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 3;
	pointer-events: none;

	.flickity-slider {
		display: flex;
		align-items: flex-end;
	}
}

.m-stage__text-slide {
	@include mq($until: large) {
		padding-bottom: $spacing-royalblue;
	}

	@include mq($from: large) {
		padding-bottom: 0;
	}

	.m-stage--single-slide & {
		@include mq($until: large) {
			padding-bottom: 0;
		}
	}
}

.m-stage__text-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 250px;
	padding: $spacing-purple percentage(1/$cols-s) $spacing-purple;
	color: $color-white;
	position: relative;
	transition-delay: 0.6s;

	@include mq($from: medium) {
		min-height: 340px;
		padding: $spacing-petrol percentage(1/$cols-m) $spacing-petrol;
	}

	@include mq($from: large) {
		width: percentage(15/$cols-l);
		min-height: 400px;
		padding: $spacing-lightblue percentage(1/$cols-l) $spacing-petrol;
		margin-left: percentage(1/$cols-l * -1);
	}

	@include mq($from: xlarge) {
		width: percentage(13/$cols-xl);
		min-height: 500px;
		padding: $spacing-lightpink percentage(1/$cols-xl) $spacing-lightpink;
		margin-left: 0;
	}
}

.m-stage__copy {
	@include mq($until: medium) {
		margin-top: $spacing-olive;
	}

	@include mq($from: medium) {
		margin-top: $spacing-yellow;
	}

	@include mq($from: large) {
		margin-top: $spacing-pink;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-purple;
	}

	@include mq($from: large, $until: xlarge) {
		padding-right: 40px;
	}
}

.m-stage__link {
	pointer-events: all;

	@include mq($until: medium) {
		margin-top: $spacing-yellow;
	}

	@include mq($from: medium) {
		margin-top: $spacing-orange;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-lilac;
	}
}

.m-stage__copy + .m-stage__link {
	@include mq($until: medium) {
		margin-top: $spacing-maroon;
	}
}

.m-stage__shape {
	width: calc(100% + 20px);
	color: $color-haribo-red;
	left: -10px;
	z-index: -1;

	@include is-rtl() {
		transform: scaleX(-1);
	}
}

.m-stage__text-slide.is-selected,
.m-stage__text-slide:only-child {
	.m-stage__text-box {
		opacity: 1;
	}
}

.m-stage__video-modal {
	position: absolute;
	z-index: 5;

	@include mq($until: medium) {
		left: 50%;
		margin-top: -33px;
		margin-left: -33px;
	}

	@include mq($until: 321px) {
		top: 30%;
	}

	@include mq($from: 321px) {
		top: 40%;
	}

	@include mq($from: medium) {
		top: 39%;
		left: 50%;
		margin-top: -47px;
		margin-left: -47px;
	}

	@include mq($from: large) {
		top: 50%;
		left: auto;
		right: 23%;

		@include is-rtl() {
			right: 70%;
		}
	}

	@include mq($from: xlarge) {
		margin-top: -62px;
	}
}

// media slider overrides

.m-stage__media-slider {
	@include mq($until: large) {
		.c-media-slider__aspect-ratio-overlay {
			@include aspect-ratio-helper("1x1");
		}

		.c-media-slider__aspect-ratio-overlay-inner {
			padding-bottom: 0;
		}
	}

	@include mq($until: large) {
		.c-media-slider__pagination {
			transform: translateY(260px);
		}
	}

	@include mq($from: large) {
		.c-media-slider__pagination {
			align-self: flex-start;
			margin-left: 55%;

			@include is-rtl() {
				align-self: flex-end;
				margin-left: 35%;
			}
		}

		.c-video-component__button-wrapper {
			display: flex;
			align-items: center;
		}

		.c-video-component__video-modal {
			margin: 0 0 0 58%;
		}
	}

	@include mq($from: xlarge) {
		.c-media-slider__pagination {
			margin-left: 51%;

			@include is-rtl() {
				margin-left: 42%;
			}
		}
	}
}

.c-media-slider__pagination {
	pointer-events: all;
}

.m-stage__media-slide {
	@include mq($until: large) {
		padding-bottom: 260px;
	}

	.m-stage--single-slide & {
		@include mq($until: large) {
			padding-bottom: 225px;
		}
	}
}

.m-stage__image {
	img {
		@include mq($from: medium) {
			object-fit: cover;
		}
	}
}

.m-stage__image-landscape {
	display: none;

	@include mq($from: large) {
		display: block;
	}

	img {
		@include mq($from: large) {
			object-position: 75% 30%;
		}
	}
}

.m-stage__image-portrait {
	@include mq($from: large) {
		display: none;
	}
}

.m-stage__bear {
	display: none;
	width: 160px;
	height: auto;
	position: absolute;
	bottom: 0;
	right: -30px;
	z-index: 3;

	@include is-rtl() {
		left: -30px;
		right: auto;
		transform: scaleX(-1);
	}

	@include mq($from: large) {
		display: block;
	}

	@include mq($from: xlarge) {
		width: 200px;
	}
}

// before flickity is ready, show only the first slide
html.js .m-stage__text-slider [data-slides-wrapper]:not(.flickity-enabled) > *:nth-child(n+2) {
	display: none;
}

// for taller viewports than 16:9 can fit in
/* stylelint-disable-next-line no-duplicate-selectors */
.m-stage {
	// this targets the image component and the navigation/pagination overlay which have to stay the same size
	.o-aspect-ratio {
		@include mq($from: large) {
			min-height: 550px;
			max-height: calc(100vh - #{map-get($stage-padding-bottom, large)});
		}

		@include mq($from: xlarge) {
			min-height: 700px;
			max-height: calc(100vh - #{map-get($stage-padding-bottom, xlarge)});
		}
	}
}

.m-stage.u-color-bg-maoam {
	.m-stage__shape {
		color: $color-maoam-yellow !important; // override inline styles
	}

	.m-stage__text-box {
		color: $color-maoam-blue;

		.c-icon-text-link__icon {
			fill: $color-maoam-orange;
		}
	}
}
