[data-tabs-pane] {
	display: none;
	opacity: 0;
	will-change: transform, opacity;
	@include transition(opacity 0.3s);

	&.is-displayed {
		display: block;
	}

	&.is-active {
		opacity: 1;
		transform: translateY(0);
	}
}

[data-tabs-trigger] {
	&.is-active {
		color: $color-haribo-red;
	}
}

html.no-js {
	[data-tabs-pane] {
		display: block;
		opacity: 1;
		transform: translateY(0);
	}
}

// override tab styles inside flickity
.flickity-enabled {
	[data-tabs-pane] {
		display: block;
		opacity: 1;
		transform: translateY(0);
	}
}
