@each $name, $value in $svg-masks {
	.u-svg-mask-#{$name} {
		mask: url($value);
	}
}

[class*="u-svg-mask"] {
	mask-repeat: no-repeat;
	mask-size: 100% 100%;
}
