@mixin hide-scrollbars() {
	scrollbar-width: none;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
		background: transparent;
	}
}
