.m-quiz-questions__buttons {
	display: flex;
	align-items: center;
	column-gap: 25px;

	@include mq($from: xlarge) {
		column-gap: 40px;
	}

	.has-bg-color & {
		.m-quiz-questions__back-button {
			background-color: transparent;

			&::after {
				border: 4px solid $color-white;
			}

			.c-button__label {
				color: $color-white;
			}
		}
	}
}
