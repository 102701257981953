.c-show-more-list__button-wrapper {
	display: none;
	align-items: center;
	margin-top: $spacing-lightblue;

	@include mq($from: medium) {
		margin-top: $spacing-petrol;
	}

	@include mq($from: large) {
		margin-top: $spacing-softblue;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-grey;
	}

	@include mq($from: medium) {
		justify-content: center;
	}

	.c-show-more-list__arrow {
		margin-right: 10px;

		@include is-rtl() {
			margin-left: 10px;
			margin-right: 0;
		}

		@include mq($from: xlarge) {
			margin-right: 15px;

			@include is-rtl() {
				margin-left: 15px;
				margin-right: 0;
			}
		}

		svg {
			@include mq($from: large) {
				margin-top: 1px;
			}
		}
	}

	.js & {
		display: flex;
	}

	&.is-hidden {
		display: none;
	}
}

.c-show-more-list__button {
	display: flex;
	align-items: center;
	flex: 0 0 auto;
	font-weight: bold;
	margin-right: 10px;

	@include is-rtl() {
		margin-left: 10px;
		margin-right: 0;
	}

	@include hover-focus() {
		.c-show-more-list__arrow {
			&::after {
				transform: scale(1.1);
			}
		}
	}

	@include mq($from: medium) {
		margin-right: 15px;

		@include is-rtl() {
			margin-left: 15px;
			margin-right: 0;
		}
	}

	@include mq($from: xlarge) {
		margin-right: 25px;

		@include is-rtl() {
			margin-left: 25px;
			margin-right: 0;
		}
	}
}

.c-show-more-list__arrow {
	display: block;
	flex: 0 0 auto;
	width: 30px;
	height: 30px;
	color: $color-white;
	position: relative;
	margin-left: auto;

	@include mq($from: medium) {
		width: 34px;
		height: 34px;
	}

	@include mq($from: xlarge) {
		width: 46px;
		height: 46px;
	}

	&::after {
		content: "";
		width: 100%;
		height: 100%;
		background-color: $color-haribo-red;
		border-radius: 50%;
		position: absolute;
		top: 0;
		left: 0;
		@include transition(transform);
	}

	svg {
		width: 100%;
		height: 100%;
		padding: 27%;
		position: relative;
		z-index: 2;
		margin: auto;
	}
}

.c-show-more-list__result-text {
	color: $color-haribo-grey-2;
}

[data-show-more-item] {
	&.is-not-displayed {
		display: none;
	}

	&.is-hidden {
		opacity: 0;
		transition: none;
	}
}
