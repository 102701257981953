/* stylelint-disable selector-max-type */
/* Exception for rich-text element styles */
.c-rich-text {
	* {
		font-size: inherit;
		line-height: inherit;
		font-weight: inherit;
		font-style: inherit;
	}

	a {
		@extend .c-text-link;
	}

	strong {
		font-weight: 700;
	}

	em {
		font-style: italic;
	}

	.fs-small {
		font-size: 12px;

		@include mq($from: xlarge) {
			font-size: 14px;
		}
	}

	ul,
	ol {
		li {
			margin-left: 15px;

			@include is-rtl() {
				margin-left: 0;
				margin-right: 15px;
			}

			&:not(:first-child) {
				margin-top: $spacing-olive;
			}
		}

		ul,
		ol {
			margin-top: $spacing-olive;
		}
	}

	> h2,
	> h3,
	> h4,
	> h5 {
		@include font-style("headline-j2");
		margin-top: $spacing-lightblue;
	}

	&.fs-copy-a1 {
		> p,
		> dl,
		> blockquote,
		> pre,
		> img,
		> figure,
		> ul,
		> ol {
			margin-top: $spacing-pink;

			@include mq($from: large) {
				margin-top: $spacing-yellow;
			}

			@include mq($from: xlarge) {
				margin-top: $spacing-orange;
			}
		}
	}

	&.fs-copy-a3 {
		> p,
		> dl,
		> pre,
		> img,
		> figure {
			margin-top: $spacing-orange;

			@include mq($from: medium) {
				margin-top: $spacing-yellow;
			}

			@include mq($from: large) {
				margin-top: $spacing-orange;
			}

			@include mq($from: xlarge) {
				margin-top: $spacing-lilac;
			}
		}

		> ul,
		> ol {
			margin-top: $spacing-yellow;

			@include mq($from: medium) {
				margin-top: $spacing-orange;
			}

			@include mq($from: xlarge) {
				margin-top: $spacing-lilac;
			}
		}

		> h2,
		> h3,
		> h4,
		> h5 {
			@include font-style("headline-j2");

			@include mq($until: medium) {
				margin-top: $spacing-orange;
			}

			+ * {
				@include mq($until: medium) {
					margin-top: $spacing-pink;
				}
			}
		}

		> small {
			display: block;
			font-size: 0.75em;
			margin-top: $spacing-olive;
		}

		> blockquote {
			@extend .fs-headline-i;

			@include mq($until: medium) {
				margin-top: $spacing-lilac;

				+ * {
					margin-top: $spacing-lilac;
				}
			}

			@include mq($from: medium) {
				margin-top: $spacing-orange;

				+ * {
					margin-top: $spacing-orange;
				}
			}

			@include mq($from: large) {
				margin-top: $spacing-lilac;

				+ * {
					margin-top: $spacing-lilac;
				}
			}

			@include mq($from: xlarge) {
				margin-top: $spacing-royalblue;

				+ * {
					margin-top: $spacing-royalblue;
				}
			}
		}
	}

	/**
	 * Take the hammer and make sure the first element in c-rich-text
	 * never has margin-top.
	 */
	> :first-child {
		margin-top: 0 !important;
	}
}

.c-rich-text--blue {
	color: $color-haribo-blue;

	a {
		@extend .c-text-link--blue;
	}
}

.c-rich-text--white {
	color: $color-white;

	a {
		@extend .c-text-link--white;
	}
}
