$drag-knob-height-s: 34px;
$drag-knob-height-m: 44px;
$drag-knob-height-l: 52px;
$drag-knob-height-xl: 60px;
$item-buffer-padding: 30px;

.m-teaser-list-d__headline {
	color: $color-haribo-blue;
}

.m-teaser-list-d__slider {
	position: relative;
	margin-top: $spacing-lilac - $item-buffer-padding;
	direction: ltr;

	@include is-rtl() {
		transform: scaleX(-1);
	}

	@include mq($from: medium) {
		margin-top: $spacing-purple - $item-buffer-padding;
	}

	@include mq($from: large) {
		margin-top: $spacing-lightblue - $item-buffer-padding;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-softblue - $item-buffer-padding;
	}

	// gradients
	@include mq($from: medium) {
		&::before,
		&::after {
			content: "";
			width: 40px;
			height: 100%;
			position: absolute;
			top: 0;
			z-index: 2;

			// due to a render glitch with all the horizontal flipping going on, we don't show gradients in rtl
			@include is-rtl() {
				display: none;
			}
		}

		&::before {
			background-image: linear-gradient(to right, rgba($color-white, 1), rgba($color-white, 0));
			left: 0;
		}

		&::after {
			background-image: linear-gradient(to left, rgba($color-white, 1), rgba($color-white, 0));
			right: 0;
		}
	}
}

.m-teaser-list-d__item {
	display: flex;
	flex: 0 0 auto;
	width: percentage(5/$cols-s);
	margin-right: percentage(2/$cols-s);

	@include mq($from: medium) {
		width: percentage(4/$cols-m);
		margin-right: percentage(1/$cols-m);
	}

	@include mq($from: large) {
		width: percentage(5/$cols-l);
		margin-right: percentage(2/$cols-l);
	}

	@include mq($from: xlarge) {
		width: percentage(5/$cols-xl);
		margin-right: percentage(2/$cols-xl);
	}

	&:last-child {
		margin-right: 0;
	}
}

.m-teaser-list-d__link {
	display: flex;
	align-items: center;
	width: 100%;
	outline: none;
}

.m-teaser-list-d__image {
	width: 100%;
	will-change: transform;
	@include transition(transform);

	@include hover-focus(".m-teaser-list-d__link") {
		transform: scale(1.15);
	}

	.c-image-component__image-wrapper {
		@include is-rtl() {
			transform: scaleX(-1);
		}
	}
}

// ------------------------------------------------- DragScroll overrides

.m-teaser-list-d .c-drag-scroller__content {
	padding: $item-buffer-padding $item-buffer-padding $item-buffer-padding 8.7%;
}

.m-teaser-list-d .c-drag-scroller__controls {
	width: 65%;
	height: 10vw;
	margin-top: 0;
}

.m-teaser-list-d .c-drag-scroller__wave-svg-front,
.m-teaser-list-d .c-drag-scroller__wave-svg-back {
	path {
		@include mq($from: medium) {
			stroke-width: 12px;
		}

		@include mq($from: large) {
			stroke-width: 16px;
		}

		@include mq($from: xlarge) {
			stroke-width: 18px;
		}
	}
}

.m-teaser-list-d .c-drag-scroller__knob {
	width: $drag-knob-height-s;
	height: $drag-knob-height-s;

	@include mq($from: medium) {
		width: $drag-knob-height-m;
		height: $drag-knob-height-m;
	}

	@include mq($from: large) {
		width: $drag-knob-height-l;
		height: $drag-knob-height-l;
	}

	@include mq($from: xlarge) {
		width: $drag-knob-height-xl;
		height: $drag-knob-height-xl;
	}
}

.m-teaser-list-d .c-drag-scroller__knob-inner {
	width: 18px;
	height: 18px;
	background-color: $color-white;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 3;
	margin-top: -9px;
	margin-left: -9px;

	@include mq($from: medium) {
		width: 24px;
		height: 24px;
		margin-top: -12px;
		margin-left: -12px;
	}

	@include mq($from: large) {
		width: 30px;
		height: 30px;
		margin-top: -15px;
		margin-left: -15px;
	}

	@include mq($from: xlarge) {
		width: 36px;
		height: 36px;
		margin-top: -18px;
		margin-left: -18px;
	}
}

// ------------------------------------------------- rotations for variants

.m-teaser-list-d__item--bag {
	.m-teaser-list-d__image {
		transform: rotate(4deg);
	}
}

.m-teaser-list-d__item--bag:nth-child(even) {
	.m-teaser-list-d__image {
		transform: rotate(-4deg);
	}
}

.m-teaser-list-d__item--box {
	.m-teaser-list-d__image {
		transform: rotate(2deg);
	}
}

.m-teaser-list-d__item--box:nth-child(even) {
	.m-teaser-list-d__image {
		transform: rotate(-2deg);
	}
}

.m-teaser-list-d__item--long {
	.m-teaser-list-d__image {
		width: 42%;
		margin: 0 auto;
		transform: rotate(3deg);
	}
}

.m-teaser-list-d__item--long:nth-child(even) {
	.m-teaser-list-d__image {
		transform: rotate(-3deg);
	}
}

.m-teaser-list-d__item--flat {
	.m-teaser-list-d__image {
		width: 20%;
		margin: 0 auto;
		transform: rotate(15deg);
	}
}

.m-teaser-list-d__item--flat:nth-child(even) {
	.m-teaser-list-d__image {
		transform: rotate(-15deg);
	}
}

.m-teaser-list-d__item--generic {
	width: 46.1%;

	@include mq($from: medium) {
		width: 36.9%;
	}

	@include mq($from: large) {
		width: 24.83%;
	}

	.m-teaser-list-d__image {
		width: 100%;
		height: 100%;
		transform: rotate(6deg);
	}

	// reset aspect ratio styles
	.c-image-component__image-wrapper {
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.o-aspect-ratio {
		width: 100%;
		height: 100%;
	}

	.o-aspect-ratio::before {
		display: none;
	}

	.c-image-component__aspect-ratio-helper {
		display: none;
	}
}

.m-teaser-list-d__item--generic:nth-child(even) {
	.m-teaser-list-d__image {
		transform: rotate(-6deg);
	}
}

// roulette special case
.m-teaser-list-d__item--roulette {
	.m-teaser-list-d__image {
		width: 14%;
		margin: 0 auto;
		transform: rotate(15deg);
	}
}

// pillow special case
.m-teaser-list-d__item--pillow {
	.m-teaser-list-d__image {
		width: 100%;
	}
}

// standup special case
.m-teaser-list-d__item--standup {
	.m-teaser-list-d__image {
		width: 68%;
		margin: 0 auto;
	}
}

// states

.has-initialized-slider {
	.m-teaser-list-d__item {
		&:last-child {
			// workaround flexbox to give the last item some space
			border-right: solid 8.4vw transparent;
		}
	}
}

.m-teaser-list-d--maoam {
	.m-teaser-list-d__headline {
		color: $color-maoam-blue;
	}

	.m-teaser-list-d__slider {
		--accent-color: #{$color-maoam-orange};
	}
}
