@import "variant-mixin";

.c-toggle-scale {
	--toggle-scale-bar-width: 19px;
	--toggle-scale-bar-base-height: 30px;
	--toggle-scale-bar-height-increment: 10px;
	--toggle-scale-bar-spacing: 4px;
	--toggle-scale-icon-dimension: 36px;

	display: block;
	width: fit-content;
}

.c-toggle-scale__input {
	opacity: 0;
	z-index: 2;
	cursor: pointer;
}

.c-toggle-scale__chrome {
	display: flex;
	align-items: flex-end;
	position: relative;
}

.c-toggle-scale__scale {
	display: flex;
	align-items: flex-end;
}

.c-toggle-scale__bar {
	display: block;
	width: var(--toggle-scale-bar-width);
	background-color: $color-haribo-grey-1;
	border-radius: calc(var(--toggle-scale-bar-width) / 2);
	outline: none;
	box-shadow: inset 2px 2px 5px 0 rgba(0, 0, 0, 0.20);
	position: relative;
	@include transition(background-color);
	transition-duration: 0.2s;

	&:nth-child(1) {
		background-color: $color-haribo-green;
	}
}

@for $index from 1 through 4 {
	.c-toggle-scale__bar:nth-child(#{$index}) {
		height: calc(var(--toggle-scale-bar-base-height) + var(--toggle-scale-bar-height-increment) * #{$index - 1});
	}
}

@for $index from 1 through 3 {
	.c-toggle-scale__bar:nth-last-child(#{$index}) {
		transition-delay: 0.1s * ($index - 1);
	}
}

.c-toggle-scale__bar + .c-toggle-scale__bar {
	margin-left: var(--toggle-scale-bar-spacing);
}

[data-whatinput="keyboard"] .c-toggle-scale:focus-within {
	@include keyboard-focus();
}

.c-toggle-scale__icon-wrapper {
	width: var(--toggle-scale-icon-dimension);
	height: var(--toggle-scale-icon-dimension);
	padding: 5px;
	margin-right: 12px;
}

.c-toggle-scale__icon-left {
	width: 100%;
	height: 100%;
	color: $color-haribo-blue;
}

.c-toggle-scale__label {
	display: flex;
	align-items: center;
	justify-content: center;
	color: $color-haribo-blue;
	margin-top: $spacing-yellow;
}

.c-toggle-scale__label-checked,
.c-toggle-scale__label-unchecked {
	padding-left: 5px;
	padding-right: 5px;
	opacity: 0.5;
	@include transition(opacity);
}

.c-toggle-scale__input:checked {
	~ .c-toggle-scale__scale {
		.c-toggle-scale__bar {
			background-color: $color-haribo-green;
		}

		@for $index from 2 through 4 {
			.c-toggle-scale__bar:nth-child(#{$index}) {
				transition-delay: 0.1s * ($index - 2);
			}
		}
	}

	~ .c-toggle-scale__icon-wrapper {
		padding: 0;
	}
}

.c-toggle-scale--big {
	@include toggle-scale--big();
}

.c-toggle-scale.is-checked {
	.c-toggle-scale__label-checked {
		opacity: 1;
	}
}

.c-toggle-scale:not(.is-checked) {
	.c-toggle-scale__label-unchecked {
		opacity: 1;
	}
}
