/**
Please note: The browser-update-info.ts needs to keep in sync with this component.
 */

// override of modules inside the modal

$announcement-sub-module-spacing: (
	small: $spacing-ocher,
	medium: $spacing-petrol,
	large: $spacing-lightpink,
	xlarge: $spacing-softblue
);

.m-announcement-layer {
	display: none;
}

/* stylelint-disable-next-line no-duplicate-selectors */
.m-announcement-layer__modal-content {

	// override nested wrappers
	.l-wrapper {
		width: 100%;
	}

	// Set a basic margin-top for all modules
	> *:not(:first-child) {
		@include property-breakpoint-map(margin-top, $announcement-sub-module-spacing);
	}

	// Set padding to 0 on all modules, that don't have a background color
	> *:not([class*="u-color-bg-"]) {
		padding-top: 0;
		padding-bottom: 0;
	}
}
