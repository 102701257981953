.c-rating-input {
	position: relative;
}

.c-rating-input__rating-wrapper {
	display: inline-block;
	width: 100%;
	padding: 15px 0 15px 35px;
	background-color: rgba(235, 235, 235, 0.5);
	border: $input-border-size solid transparent;
	border-radius: 35px;
	color: $color-haribo-blue;
	font-size: 30px;
	position: relative;
	transition: border-color 180ms cubic-bezier(0, 0, 0.2, 1);

	@include property-breakpoint-map(height, $input-height-map);
	@include property-breakpoint-map(padding-left, $input-side-padding-map, 1, -$input-border-size);
	@include property-breakpoint-map(padding-right, $input-side-padding-map, 1, -$input-border-size);
	@include property-breakpoint-map(border-radius, $input-height-map, 0.5);
	@include property-breakpoint-map(font-size, $input-font-sizes);
	@include font-family("VAG Rounded", bold);
}

.c-rating-input__rating {
	@include property-breakpoint-map(padding-left, $input-side-padding-map);
	@include property-breakpoint-map(padding-right, $input-side-padding-map, 2);
	cursor: pointer;

	@include is-rtl() {
		@include property-breakpoint-map(padding-right, $input-side-padding-map);
		@include property-breakpoint-map(padding-left, $input-side-padding-map, 2);
	}
}

.c-rating-input__arrow {
	width: 16px;
	color: $color-haribo-blue;
	position: absolute;
	bottom: calc(50% - 11px);
	@include property-breakpoint-map(right, $input-side-padding-map);
	margin-right: -1px;
	pointer-events: none;

	@include is-rtl() {
		@include property-breakpoint-map(left, $input-side-padding-map);
		right: auto;
	}
}

.c-rating-input--block {
	.c-rating-input__rating-wrapper,
	.c-rating-input__rating {
		width: 100%;
	}
}

[data-whatintent="mouse"] .c-rating-input__rating:hover,
.c-rating-input__rating:focus {
	border-color: $color-haribo-blue;
	outline: none;
}

// Disabled state
.c-rating-input__rating[disabled] {
	background-color: rgba($color-haribo-grey-4, 0.5);
	border-color: transparent !important;
	color: rgba($color-haribo-blue, 0.3);
	cursor: default;
	pointer-events: none;

	+ .c-rating-input__arrow {
		color: rgba($color-haribo-blue, 0.3);
	}
}

// Variants

.c-rating-input--light-bg {
	.c-rating-input__rating {
		background-color: rgba($color-white, 0.2);
	}

	.c-text-input__input {
		color: $color-white;
	}

	.c-rating-input__arrow {
		color: $color-white;
	}

	option {
		color: $color-haribo-blue;
	}
}

[data-whatintent="mouse"] .c-rating-input--light-bg .c-rating-input__rating:hover,
.c-rating-input--light-bg .c-rating-input__rating:focus {
	border-color: $color-haribo-yellow;
}

.c-rating-input--full {
	.c-rating-input__rating-wrapper {
		width: 100%;
	}
}

.c-rating-input--revert-xl-breakpoint {
	@include mq($from: xlarge) {
		.c-text-input__input {
			height: map-get($input-height-map, large);
			padding-left: map-get($input-side-padding-map, large);
			padding-right: map-get($input-side-padding-map, large) * 2;
			border-radius: map-get($input-height-map, large) * 0.5;
			font-size: map-get($input-font-sizes, large);

			@include is-rtl() {
				padding-left: map-get($input-side-padding-map, large) * 2;
				padding-right: map-get($input-side-padding-map, large);
			}
		}

		.c-rating-input__arrow {
			right: map-get($input-side-padding-map, large);

			@include is-rtl() {
				left: map-get($input-side-padding-map, large);
				right: auto;
			}
		}
	}
}

// Error styling
.c-rating-input.has-error .c-rating-input__rating {
	border-color: $color-haribo-red;
}


