.m-video {
	--background-color: #{$color-haribo-blue};
	--gradient-color: #{lighten($color-haribo-blue, $lighten-amount)};
	display: flex;
	background-image: radial-gradient(circle at 71% 96%, var(--gradient-color), var(--background-color) 46%);
	color: $color-white;

	@include is-rtl() {
		background-image: radial-gradient(circle at 29% 96%, var(--gradient-color), var(--background-color) 46%);
	}

	@include mq($until: large) {
		flex-direction: column;
	}
}

.m-video__text {
	padding-top: $spacing-purple;
	padding-bottom: $spacing-purple;

	@include mq($from: medium) {
		padding-top: $spacing-ocher;
		padding-bottom: $spacing-lightpink;
	}

	@include mq($from: large) {
		display: flex;
		align-items: center;
		height: auto;
		padding-top: 0;
		padding-bottom: 0;
	}
}

.m-video__text-inner {
	@include mq($from: medium) {
		width: percentage(12/$cols-m);
		margin-left: auto;
		margin-right: auto;
	}

	@include mq($from: large) {
		margin-left: 7.3%;
		margin-right: 7.3%;
	}
}

.m-video__copy {
	margin-top: $spacing-pink;

	@include mq($from: large) {
		margin-top: $spacing-orange;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-lilac;
	}
}

.m-video__video {
	@include mq($from: large) {
		flex: 0 0 auto;
		width: 59%;
	}

	@include mq($from: xlarge) {
		width: 56.3%;
	}
}

.m-video--mirrored {
	background-image: radial-gradient(circle at 29% 96%, var(--gradient-color), var(--background-color) 46%);

	@include is-rtl() {
		background-image: radial-gradient(circle at 71% 96%, var(--gradient-color), var(--background-color) 46%);
	}

	.m-video__video {
		order: 2;
	}
}

.m-video.u-color-bg-maoam {
	color: $color-maoam-blue;
}
