.m-shop-list__headline {
	color: $color-haribo-blue;
}

.m-shop-list__search {
	margin-top: $spacing-lightgreen;

	@include mq($from: large) {
		margin-top: $spacing-lightpink;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-grey;
	}
}

.m-shop-list {
	.c-show-more-list,
	.m-shop-list__list {
		margin-top: $spacing-orange;

		@include mq($from: large) {
			margin-top: $spacing-lightpink;
		}

		@include mq($from: xlarge) {
			margin-top: $spacing-softblue;
		}
	}

	.c-show-more-list__list,
	.m-shop-list__list {
		width: 100%;

		@include mq($from: large) {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			column-gap: percentage(1/$cols-l);
			row-gap: 30px;

			@include mq($from: xlarge) {
				column-gap: percentage(1/$cols-l);
				row-gap: 45px;
			}
		}

		@include mq($from: xlarge) {
			--minWidth: 400px;
		}

		@include mq($from: $module-max-width) {
			--minWidth: 400px;
		}
	}

	.c-show-more-list__button-wrapper {
		margin-top: $spacing-orange;

		@include mq($until: large) {
			justify-content: center;
		}

		@include mq($from: large) {
			margin-top: $spacing-lightblue;
		}
	}
}

@import "./components/ShopListItem";
