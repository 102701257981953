.m-raffle-form {
	.m-raffle__headline,
	.m-raffle__copy {
		@include mq($from: xlarge) {
			width: percentage(13/18);
		}
	}
}

.m-raffle-form__form {
	--text-color: #{$color-white};
	--accent-color: var(--background-color);
	display: block;
	max-width: 500px;
	margin-top: $spacing-lightblue - 30px;

	.c-raffle-form__button .c-button__label {
		color: $color-haribo-blue;
	}
}
