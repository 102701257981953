.c-image-zoom__wrapper {
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: $color-haribo-blue;
	position: fixed;
	top: 0;
	left: 0;
	touch-action: none;
	@include z-index(modal);

	.zoomer {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}

.c-image-zoom__container {
	width: 100%;
	height: 100%;

	img {
		width: auto;
		max-width: 100%;
		height: auto;
		max-height: 100%;
		box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4);
	}
}

.c-image-zoom__buttons {
	display: flex;
	align-items: center;
	position: absolute;
	bottom: 20px;
	right: 20px;
	z-index: 2;

	@include is-rtl() {
		left: 20px;
		right: auto;
	}

	.c-image-zoom__button {
		box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4);

		&:first-child {
			margin-right: 20px;

			@include is-rtl() {
				margin-left: 20px;
				margin-right: 0;
			}
		}
	}
}

.c-image-zoom__button {
	width: 50px;
	height: 50px;
	background-color: $color-white;
	color: $color-haribo-red;
}

.c-image-zoom__close {
	box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.4);
	position: fixed;
	top: 20px;
	right: 20px;
	z-index: 2;

	@include is-rtl() {
		left: 20px;
		right: auto;
	}
}

.c-image-zoom__wrapper.a-enter-active,
.c-image-zoom__wrapper.a-leave-active {
	transition: opacity 0.5s;
}

.c-image-zoom__wrapper.a-enter,
.c-image-zoom__wrapper.a-leave-to {
	opacity: 0;
}
