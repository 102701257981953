.c-filter-panel {
	width: 100%;
	max-width: $module-max-width;
	min-height: 1px; // to prevent margin collapse issues after panel leave animation
	opacity: 0;
	position: sticky;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;
	margin-bottom: -1px; // counter min-height
	margin-left: auto;
	margin-right: auto;
	pointer-events: none;
	@include transition(opacity);
}

// just for working component inside fractal
.dev-fractal .l-main > .c-filter-panel {
	position: relative;
	margin-top: 100px;
}

.c-filter-panel__filters {
	max-height: 50vh;
	overflow: hidden;
	overflow-y: scroll;
	background-color: $color-white;
	border-radius: 23px 23px 0 0;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.33);
	position: relative;
	will-change: height;
	@include hide-scrollbars();

	@include mq($from: medium) {
		max-height: 60vh;
	}
}

.c-filter-panel__filters-inner {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0 20px;

	@include mq($from: medium) {
		padding-left: 30px;
		padding-right: 30px;
	}

	@include mq($from: large) {
		align-items: center;
		padding-top: $spacing-yellow;
		flex-direction: row-reverse;
	}

	@include mq($from: xlarge) {
		padding-top: $spacing-orange;
	}

	.c-filter-group__label {
		@include mq($until: large) {
			padding-top: $spacing-orange;
			padding-bottom: $spacing-olive;
			background-color: $color-white;
			border-bottom: 1px solid $color-haribo-grey-4;
			position: sticky;
			top: 0;
			left: 0;
			z-index: 2;
			pointer-events: none;
		}
	}
}

.c-filter-panel__cluster {
	width: 100%;

	@include mq($from: large) {
		padding-right: 30px;

		@include is-rtl() {
			padding-left: 30px;
			padding-right: 0;
		}
	}
}

.c-filter-panel__bottom-controls {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: inherit;
	position: sticky;
	top: 0;
	bottom: 0;
	z-index: 2;
	margin-top: $spacing-yellow;

	@include mq($until: medium) {
		border-top: 1px solid $color-haribo-grey-4;
	}

	@include mq($from: large) {
		margin-top: $spacing-olive;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-yellow;
	}
}

.c-filter-panel__reset-button {
	display: flex;
	align-items: center;
	flex: 0 0 auto;
	padding: 10px 0;
	color: $color-haribo-red;

	svg {
		width: 23px;
		height: 20px;
		margin-right: 15px;

		@include is-rtl() {
			margin-left: 15px;
			margin-right: 0;
		}

		@include mq($from: xlarge) {
			width: 28px;
			height: 24px;
		}
	}

	&[disabled] {
		opacity: 0.25;
		pointer-events: none;
	}
}

.c-filter-panel__toggle {
	display: flex;
	align-items: center;
	width: fit-content;
	height: 46px;
	background-color: $color-white;
	border-radius: 23px;
	box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.21);
	position: absolute;
	bottom: calc(100% + 15px);
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;

	@include mq($from: large) {
		height: 50px;
		border-radius: 25px;
		bottom: calc(100% + 25px);
		left: 25px;
		right: initial;

		@include is-rtl() {
			left: auto;
			right: 25px;
		}
	}
}

.c-filter-panel__count {
	padding-left: 10px;
	padding-right: 10px;
	background-color: $color-haribo-red;
	border-radius: 20px;
	color: $color-white;
	font-size: 14px;
	line-height: 36px;
	white-space: nowrap;
	margin-left: 5px;

	@include is-rtl() {
		margin-left: 0;
		margin-right: 5px;
	}

	@include mq($from: large) {
		line-height: 40px;
	}

	@include mq($from: xlarge) {
		font-size: 16px;
	}
}

.c-filter-panel__toggle-label {
	color: $color-haribo-blue;
	font-size: 20px;
	margin-right: 15px;

	@include is-rtl() {
		margin-left: 15px;
		margin-right: 0;
	}
}

.c-filter-panel__toggle-icon {
	width: 26px;
	color: $color-haribo-blue;
	margin-left: 15px;
	margin-right: 15px;

	@include mq($from: large) {
		width: 30px;
	}
}

.c-filter-panel__count + .c-filter-panel__toggle-icon {
	margin-left: 10px;

	@include is-rtl() {
		margin-left: 15px;
		margin-right: 10px;
	}
}

.c-filter-panel__close {
	width: 50px;
	height: 50px;
	background-color: $color-haribo-red;
	color: $color-white;
	position: absolute;
	top: 15px;
	right: 15px;
	z-index: 2;

	@include is-rtl() {
		left: 15px;
		right: auto;
	}

	// override cascade
	.c-filter-panel & {
		@include mq($until: large) {
			display: none;
		}
	}

	.c-button__icon {
		width: 40% !important;
		height: 40% !important;
	}
}

[data-whatinput="keyboard"] {
	.c-filter-panel__toggle:focus,
	.c-filter-panel__reset-button:focus,
	.c-filter-panel__close:focus {
		@include keyboard-focus();
	}
}

// ------------------------------------------------------------------------------------------- state

.c-filter-panel.is-visible {
	opacity: 1;
	pointer-events: all;
}

// ------------------------------------------------------------------------------------------- variants

.c-filter-panel--blue {
	.c-filter-panel__filters {
		background-color: $color-haribo-blue;

		@include mq($from: large) {
			background-image: radial-gradient(circle at 82% 98%, lighten($color-haribo-blue, $lighten-amount), $color-haribo-blue 50%);
		}
	}

	.c-filter-panel__toggle {
		background-color: $color-haribo-blue;
	}

	.c-filter-panel__toggle-icon {
		color: $color-white;
	}

	.c-filter-group__label {
		color: $color-haribo-yellow;

		@include mq($until: large) {
			background-color: $color-haribo-blue;
			border-bottom: 1px solid rgba($color-white, 0.2);
		}
	}

	.c-filter-panel__bottom-controls {
		@include mq($until: medium) {
			border-top: 1px solid rgba($color-white, 0.2);
		}

		@include mq($from: large) {
			background-color: transparent;
		}
	}

	.c-filter-panel__toggle-label {
		color: $color-white;
	}

	.c-filter-panel__reset-button {
		color: $color-haribo-yellow;
	}

	.c-filter-switch__chrome {
		background-color: rgba($color-white, 0.35);
	}

	.c-filter-switch__label {
		color: $color-white;
	}

	.c-filter-switch__input:disabled {
		~ .c-filter-switch__chrome {
			background-color: rgba($color-black, 0.25);
		}
	}

	.c-filter-switch__input:checked:not(:disabled) {
		~ .c-filter-switch__chrome {
			.c-filter-switch__label {
				color: $color-haribo-blue;
			}
		}
	}
}

.c-filter-panel--show-counter {
	.c-filter-switch__count {
		display: inline-block;
	}
}


.c-filter-panel__search {
	display: flex;
	align-items: center;
	border-bottom: 2px solid $color-haribo-grey-1;
	position: relative;
	max-width: 500px;
	margin-top: 10px;

	@include mq($from: large) {
		margin-right: 60px;
		align-self: flex-start;
		margin-top: -10px;
		max-width: inherit;
	}
}

.c-filter-panel__search-input {
	display: block;
	width: 100%;
	outline: none;
	color: $color-black;
	line-height: 1;
}

.c-filter-panel__search-input::placeholder {
	@include transition(color);
	color: $color-haribo-grey-1;
}

.c-filter-panel__buttons {
	display: flex;
	align-items: center;
	column-gap: 5px;
	z-index: 2;

	@include mq($from: large) {
		column-gap: 10px;
	}

	> * {
		flex: 0 0 auto;
		width: 20px;
		height: 20px;

		@include mq($from: large) {
			width: 30px;
			height: 30px;
		}

		svg {
			width: 100%;
			height: 100%;
		}
	}
}

.c-filter-panel__search-icon {
	/* display: none; */
	color: $color-haribo-grey-1;
	@include transition(color);
}

.c-filter-panel__close-button {
	padding: 3%;
	color: $color-black;

	@include transition(color);

	@include hover-focus() {
		color: $color-haribo-red;
	}
}

.c-filter-panel__search-input:focus ~ .c-filter-panel__buttons {
	.c-filter-panel__search-icon {
		color: $color-black;
	}

	.c-filter-panel__search-icon:hover {
		color: $color-haribo-green;
	}

	&::placeholder {
		color: $color-black;
	}
}

.c-filter-panel__search-input:placeholder-shown ~ .c-filter-panel__buttons {
	.c-filter-panel__search-icon {
		display: block;
	}

	.c-filter-panel__search-icon:hover {
		color: $color-haribo-grey-1;
	}

	.c-filter-panel__close-button {
		display: none;
	}
}

#filterpanel-search.has-no-results {
	color: $color-haribo-red !important;
}
