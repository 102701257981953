.m-history__entry {
	display: none;
	width: 100%;
}

.m-history__entry.is-active {
	display: block;
	animation: fade-in 0.8s both;
}

.m-history__entry-inner {
	@include mq($from: large) {
		display: flex;
		align-items: flex-start;
		width: 87%;
	}

	@include mq($from: xlarge) {
		width: 84%;
	}
}

.m-history__visual {
	max-width: 520px;
	position: relative;
	margin-bottom: $spacing-orange;

	@include mq($from: large) {
		flex: 0 0 auto;
		width: percentage(11/$cols-l);
		max-width: none;
	}

	@include mq($from: xlarge) {
		width: percentage(12/$cols-xl);
	}
}

.m-history-entry__play-button-wrapper {
	display: flex;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
}

.m-history-entry__video-modal {
	margin: auto;

	.c-video-modal__play-button {
		width: 65px;
		height: 65px;

		@include mq($from: medium) {
			width: 130px;
			height: 130px;
		}

		@include mq($from: large) {
			width: 80px;
			height: 80px;
		}

		@include mq($from: xlarge) {
			width: 120px;
			height: 120px;
		}
	}
}

.m-history__text {
	width: percentage(12/$cols-s);
	color: $color-white;
	margin-top: $spacing-olive;
	margin-left: percentage(1/$cols-s);

	@include is-rtl() {
		margin-left: 0;
		margin-right: percentage(1/$cols-s);
	}

	@include mq($from: medium) {
		width: percentage(12/$cols-m);
		margin-top: $spacing-orange;
		margin-left: percentage(1/$cols-m);

		@include is-rtl() {
			margin-left: 0;
			margin-right: percentage(1/$cols-m);
		}
	}

	@include mq($from: large) {
		flex: 0 0 auto;
		width: percentage(13/$cols-l);
		margin-top: 0;
		margin-left: percentage(2/$cols-l);

		@include is-rtl() {
			margin-left: 0;
			margin-right: percentage(2/$cols-l);
		}
	}

	@include mq($from: xlarge) {
		margin-left: percentage(1/$cols-xl);

		@include is-rtl() {
			margin-left: 0;
			margin-right: percentage(1/$cols-xl);
		}
	}
}

.m-history__year {
	color: $color-white;
}

.m-history__entry.is-highlight {
	.m-history__year {
		color: $color-haribo-yellow;
	}
}

.m-history__subline {
	margin-top: $spacing-olive;

	@include mq($from: medium) {
		margin-top: $spacing-pink;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-lilac;
	}
}

.m-history__copy {
	margin-top: $spacing-olive;

	@include mq($from: medium) {
		margin-top: $spacing-pink;
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
}
