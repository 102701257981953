.c-content-shape {
	position: relative;
}

.c-content-shape__inner {
	padding: 10% 12.5%;
	position: relative;
	z-index: 2;
}

.c-content-shape__svg {
	width: calc(100% + 20px);
	margin-left: -10px;
}

.c-content-shape__svg:not([class*="u-color-fill"]) {
	fill: $color-white;
}
