$table-heading-bottom-padding: (
	small: $spacing-yellow,
	xlarge: $spacing-orange
);

$table-vertical-padding: (
	small: $spacing-olive,
	xlarge: $spacing-yellow
);

.m-product-info__nutrition {
	.c-content-shape__inner {
		padding: $spacing-petrol percentage(1/$cols-s);

		@include mq($from: medium) {
			padding: $spacing-softblue percentage(2/$cols-m);
		}

		@include mq($from: large) {
			padding-left: percentage(2/16);
			padding-right: percentage(2/16);
		}

		@include mq($from: xlarge) {
			padding-left: percentage(3/15);
			padding-right: percentage(3/15);
		}
	}
}

.m-product-info__table {
	width: 100%;
	border-spacing: 0;
	border-collapse: collapse;
}

.m-product-info__table-headlines {
	.m-product-info__table-label {
		font-weight: bold;
	}

	.m-product-info__table-col {
		vertical-align: top;
	}

	> * {
		@include property-breakpoint-map(padding-bottom, $table-heading-bottom-padding);
	}
}

.m-product-info__table-main-row {
	> * {
		@include property-breakpoint-map(padding-top, $table-vertical-padding);
	}

	&:not(.has-sub-items) {
		> * {
			@include property-breakpoint-map(padding-bottom, $table-vertical-padding);
		}
	}

	&:not(:nth-child(2)) {
		> * {
			border-top: 1px solid $color-black;
		}
	}

	&:nth-child(2) {
		> * {
			padding-top: 0;
		}
	}
}

.m-product-info__table-sub-row {
	&.is-last {
		> * {
			@include property-breakpoint-map(padding-bottom, $table-vertical-padding);
		}
	}
}


.m-product-info__table-main-row,
.m-product-info__table-sub-row {
	.m-product-info__table-label {
		font-weight: normal;
	}

	.m-product-info__table-col {
		vertical-align: bottom;

		@include mq($from: medium) {
			white-space: nowrap;
		}
	}

	&:last-child {
		> * {
			padding-bottom: 0;
		}
	}
}

.m-product-info__table-label {
	text-align: left;
	vertical-align: top;

	@include is-rtl() {
		text-align: right;
	}
}

.m-product-info__table-col {
	text-align: right;

	@include is-rtl() {
		text-align: left;
	}

	&:not(:empty) {
		padding-left: 15px;

		@include is-rtl() {
			padding-left: 0;
			padding-right: 15px;
		}

		@include mq($from: medium) {
			padding-left: 20px;

			@include is-rtl() {
				padding-left: 0;
				padding-right: 20px;
			}
		}
	}
}

.m-product-info__nutrition-info {
	margin-top: $spacing-yellow;

	@include mq($from: xlarge) {
		margin-top: $spacing-orange;
	}
}

.m-product-info__nutrition.has-two-columns {
	.c-content-shape__inner {
		@include mq($from: medium) {
			padding-left: percentage(1/$cols-m);
			padding-right: percentage(1/$cols-m);
		}

		@include mq($from: large) {
			padding-left: calc(#{percentage(1/16)} + 10px);
			padding-right: calc(#{percentage(1/16)} + 10px);
		}

		@include mq($from: xlarge) {
			padding-left: percentage(2/15);
			padding-right: percentage(2/15);
		}
	}

	.m-product-info__nutrition-info {
		@include mq($from: medium) {
			margin-left: 8.4%;
			margin-right: 8.4%;
		}

		@include mq($from: large) {
			margin-left: 7.2%;
			margin-right: 7.2%;
		}

		@include mq($from: xlarge) {
			margin-left: 0;
			margin-right: 0;
		}
	}
}
