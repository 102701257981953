$custom-breakpoint: 1200px;

@mixin sticky-wrapper() {
	position: fixed;
	left: 0;
	right: 0;
	pointer-events: none;
}

@mixin hide-navigation-bar-animation() {
	$offset: 20px;
	transform: translateY(calc(-100% - #{map-get($header-padding-top, small) + $offset}));

	@include mq($from: medium) {
		transform: translateY(calc(-100% - #{map-get($header-padding-top, medium) + $offset}));
	}

	@include mq($from: large) {
		transform: translateY(calc(-100% - #{map-get($header-padding-top, large) + $offset}));
	}

	@include mq($from: xlarge) {
		transform: translateY(calc(-100% - #{map-get($header-padding-top, xlarge) + $offset}));
	}
}

.m-header__logo-wrapper {
	position: absolute;
	left: 0;
	right: 0;
	pointer-events: none;
	@include z-index(top-navigation);
	@include property-breakpoint-map(top, $padding-top-aligned-with-main-navigation);
	@include transition(opacity 0.4s, transform 0.4s);

	@include mq($from: medium) {
		@include z-index(logo);
	}

	&.is-hidden {
		opacity: 0;
		transition-duration: 0s;
	}
}

.m-header__logo {
	width: auto;
	height: $header-main-links-height + 2px;
	margin-top: -2px;
	margin-left: -6px;
	pointer-events: all;

	@include is-rtl() {
		margin-left: 0;
		margin-right: -6px;
	}

	@include mq($from: medium) {
		height: $header-main-links-height + 9px;
		margin-top: -5px;
	}

	@include mq($from: large, $until: $custom-breakpoint) {
		height: 47px;
		margin-top: 3px;
	}

	@include mq($from: xlarge) {
		height: $header-main-links-height + 23px;
		margin-top: -12px;
	}
}

.m-header__maoam-logo-claim {
	display: flex;
	align-items: center;
	width: 155px;
	position: absolute;
	top: 100%;
	left: -2px;
	pointer-events: none;

	@include mq($from: medium) {
		width: 174px;
	}

	@include mq($from: xlarge) {
		width: 219px;
	}
}

.m-header__maoam-logo {
	flex: 0 0 auto;
	width: 40px;
	height: auto;
	margin-right: 2px;

	@include mq($from: medium) {
		width: 50px;
		margin-right: 3px;
	}

	@include mq($from: xlarge) {
		width: 55px;
		margin-right: 5px;
	}
}

.m-header__maoam-claim {
	flex: 1;
	margin-bottom: 3px;

	@include mq($from: xlarge) {
		margin-bottom: 5px;
	}
}

.m-header__navigation-wrapper {
	display: flex;
	flex-direction: column;
	@include sticky-wrapper();
	@include z-index(top-navigation);
	@include property-breakpoint-map(top, $header-padding-top);
	@include transition(transform 0.4s, visibility 0s linear 0s);
}

.m-header.has-hidden-navigation-bar {
	.m-header__navigation-wrapper {
		visibility: hidden;
		pointer-events: none;
		@include transition(transform 0.4s, visibility 0s linear 0.4s);
		@include hide-navigation-bar-animation();

		.m-header__meta-links,
		.m-header__main-links {
			pointer-events: none;
		}
	}
}

.m-header__meta-links,
.m-header__main-links {
	display: inline-flex;
	align-items: center;
	background-color: $color-white;
	box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.22);
	margin-left: auto;
	pointer-events: all;

	@include is-rtl() {
		margin-left: 0;
		margin-right: auto;
	}
}

.m-header__meta-link,
.m-header__main-link {
	outline: none;
	color: $color-haribo-red;
	@include transition(color);

	@include hover-focus() {
		color: $color-haribo-blue;
	}

	&.is-active {
		color: $color-haribo-blue;
	}

	&.has-open-link-group {
		color: $color-haribo-blue;
	}
}

.m-header__meta-links {
	display: none;
	order: -1;
	height: $header-meta-links-height;
	padding: 0 10px;
	border-radius: $header-meta-links-height/2;
	margin-bottom: $spacing-smokeblue-light;
	margin-right: 40px;

	@include is-rtl() {
		margin-left: 40px;
		margin-right: auto;
	}

	@include mq($from: large) {
		display: inline-flex;
	}
}

.m-header__meta-link {
	padding-left: 5px;
	padding-right: 5px;
}

.m-header__main-links {
	height: $header-main-links-height;
	padding: 0 15px 0 15px;
	border-radius: $header-main-links-height/2;
	position: relative;

	@include mq($until: medium) {
		justify-content: flex-end;
	}

	@include mq($from: medium) {
		padding-right: 45px;

		@include is-rtl() {
			padding-left: 45px;
			padding-right: 15px;
		}
	}

	> * {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.m-header__main-link {
	@include mq($until: large) {
		display: none;
	}

	@include mq($from: large, $until: $custom-breakpoint) {
		padding-left: 8px;
		padding-right: 8px;
		font-size: 19px;
	}

	&.has-open-link-group {
		color: $color-haribo-blue;
	}
}

.m-header__search-button,
.m-header__overlay-toggle {
	outline: none;
	-webkit-tap-highlight-color: transparent;

	svg {
		fill: $color-haribo-red;
	}

	@include hover-focus() {
		svg {
			fill: $color-haribo-blue;
		}
	}
}

.m-header__search-button {
	padding-top: 4px;
	padding-bottom: 4px;

	.m-header__magnifier-icon {
		width: 27px;
		height: 27px;
	}
}

.m-header__overlay-toggle {
	padding-top: 6px;
	padding-bottom: 6px;

	@include mq($from: large) {
		display: none;
	}

	.m-header__burger-icon {
		width: 27px;
		height: 22px;
	}
}

// brand characters for haribo and maoam

$character-animation-distance: 30px;

.m-header__bear-small,
.m-header__fruitman-small {
	width: auto;
	height: $spacing-softblue;
	padding: 0;
	position: absolute;
	top: -15px;
	pointer-events: none;

	@include mq($until: medium) {
		display: none;
	}

	@include mq($from: medium) {
		opacity: 0;
		transform: translateX($character-animation-distance);
		@include transition(transform, opacity);

		@include is-rtl() {
			transform: scaleX(-1) translateX($character-animation-distance);
		}
	}
}

.m-header__bear-small {
	right: -12px;

	@include is-rtl() {
		left: -12px;
		right: auto;
	}
}

.m-header__fruitman-small {
	right: -23px;

	@include is-rtl() {
		left: -23px;
		right: auto;
	}
}

.m-header--haribo .m-header__bear-small,
.m-header--maoam .m-header__fruitman-small {
	opacity: 1;
	transform: translateX(0);

	@include is-rtl() {
		transform: scaleX(-1) translateX(0);
	}
}

// handling of the visibility for the brand characters on hover on links

.m-header--maoam[data-hovered-link-context="haribo"] {
	.m-header__fruitman-small {
		opacity: 0;
		transform: translateX($character-animation-distance);

		@include is-rtl() {
			transform: scaleX(-1) translateX($character-animation-distance);
		}
	}

	.m-header__bear-small {
		opacity: 1;
		transform: translateX(0);

		@include is-rtl() {
			transform: scaleX(-1) translateX(0);
		}
	}
}

.m-header--haribo[data-hovered-link-context="maoam"] {
	.m-header__fruitman-small {
		opacity: 1;
		transform: translateX(0);

		@include is-rtl() {
			transform: scaleX(-1) translateX(0);
		}
	}

	.m-header__bear-small {
		opacity: 0;
		transform: translateX($character-animation-distance);

		@include is-rtl() {
			transform: scaleX(-1) translateX($character-animation-distance);
		}
	}
}

// overlay styles

.m-header__overlay-ui-wrapper {
	display: flex;
	align-items: center;
	position: relative;
	top: $spacing-lilac;
	z-index: 2;

	@include mq($from: medium, $until: large) {
		width: 100%;
	}

	@include mq($from: medium) {
		padding-left: 30px;
		padding-right: 30px;
		top: $spacing-orange;
	}

	@include mq($from: large) {
		padding-left: 0;
		padding-right: 0;
		top: $spacing-softblue;
	}

	@include sticky-wrapper();
}

.m-header__overlay-headline {
	color: $color-haribo-yellow;

	@include mq($from: large) {
		display: none;
	}
}

.m-header__overlay-back-button {
	display: flex;
	align-items: center;
	outline: none;
	color: $color-haribo-yellow;
	pointer-events: all;
	-webkit-tap-highlight-color: transparent;
	@include transition(opacity 0.4s, transform 0.4s);

	@include mq($from: large) {
		display: none;
	}

	.c-rounded-icon-button {
		margin-right: 10px;

		@include is-rtl() {
			order: -1;
			margin-left: 10px;
			margin-right: 0;
			transform: scaleX(-1);
		}

		@include mq($until: medium) {
			width: 36px;
			height: 36px;
		}

		svg {
			color: $color-haribo-blue;
		}
	}

	.c-rounded-icon-button::after {
		background-color: $color-haribo-yellow;
	}

	@include hover-focus() {
		.c-rounded-icon-button::after {
			transform: scale(1.2);
		}

		svg {
			color: $color-haribo-red;
		}
	}
}

.m-header__overlay-close-button {
	display: flex;
	width: 46px;
	height: 46px;
	outline: none;
	pointer-events: all;
	-webkit-tap-highlight-color: transparent;

	@include mq($until: large) {
		margin-left: auto;

		@include is-rtl() {
			margin-left: 0;
			margin-right: auto;
		}
	}

	@include mq($from: medium) {
		width: 50px;
		height: 50px;
	}

	@include mq($from: large) {
		width: 60px;
		height: 60px;
		margin-top: -$spacing-pink;
		margin-left: 0;

		svg {
			width: 28px;
			height: 28px;
		}
	}

	@include mq($from: xlarge) {
		margin-top: -$spacing-smokeblue-light;
	}
}

.m-header__overlay-links-wrapper {
	display: none;
	align-items: flex-start;
	position: relative;
	margin-top: $spacing-salmon;

	@include mq($from: medium) {
		margin-top: $spacing-retro-green;
	}

	@include mq($from: large) {
		margin-top: 220px;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-indianred;
	}
}

.m-header__overlay-link-group {
	padding-bottom: $spacing-purple;
}

// margin to main overlay links
.m-header__overlay-meta-links,
.m-header__overlay-link + .m-header__overlay-meta-link {
	margin-top: $spacing-yellow;

	@include mq($from: medium) {
		margin-top: $spacing-lightblue;
	}

	@include mq($from: large) {
		margin-top: $spacing-purple;
	}
}

.m-header__overlay-link,
.m-header__overlay-meta-link {
	display: block;
	width: fit-content;
	outline: none;
	color: $color-white;
	@include transition(color);

	@include hover-focus() {
		color: $color-haribo-yellow;
	}
}

.m-header__overlay-link {
	margin-top: $spacing-smokeblue-light;

	@include mq($from: large) {
		margin-top: $spacing-olive;
	}

	&.has-open-link-group {
		color: $color-haribo-yellow;
	}
}

.m-header__overlay-meta-link {
	margin-top: $spacing-olive;
}

.m-header__overlay-images {
	position: relative;

	@include mq($until: large) {
		display: none;
	}
}

.m-header__overlay-image {
	width: 100%;
	max-height: 0; // to avoid scrollbars and overflow
	overflow: hidden;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	@include transition(opacity 0.4s, max-height 0s 0.4s);

	&.has-hovered-parent-link,
	&.is-selected-link-image {
		max-height: 1000px;
		overflow: visible;
		opacity: 1;
		z-index: 2;
		@include transition(opacity 0.4s, max-height 0s 0s);
	}

	&.is-selected-link-image {
		&.is-hidden {
			opacity: 0;
		}
	}
}

.m-header .m-search {
	display: none;
	background-color: transparent;
}

.m-header__bear-big,
.m-header__fruitman-big {
	width: 180px;
	position: fixed;
	bottom: 5px;
	pointer-events: none;

	@include mq($from: large) {
		display: none;
	}
}

.m-header__bear-big {
	right: -30px;

	@include is-rtl() {
		left: -30px;
		right: auto;
		transform: scaleX(-1);
	}
}

.m-header__fruitman-big {
	right: -5px;

	@include is-rtl() {
		left: -5px;
		right: auto;
		transform: scaleX(-1);
	}
}

// open and close state related elements

$overlay-open-duration: 1s;
$overlay-close-duration: 0.6s;

.m-header__overlay-backdrop {
	width: 140vmax;
	height: 140vmax;
	background-color: $color-haribo-blue;
	background-image: radial-gradient(circle at 27% 50%, #137FD9, #005CA9 56%);
	border-radius: 0 0 0 100%;
	opacity: 0;
	position: fixed;
	top: 0;
	right: 0;
	z-index: -1;
	transform: scale(0);
	transform-origin: top right;
	pointer-events: none;
	@include transition(transform $overlay-close-duration, opacity $overlay-close-duration/2 $transition-default-easing $overlay-close-duration/2);

	@include is-rtl() {
		background-image: radial-gradient(circle at 73% 50%, #137FD9, #005CA9 56%);
		border-radius: 0 0 100% 0;
		left: 0;
		right: auto;
		transform-origin: top left;
	}
}

// backdrop to prevent appearance of main page on iOS when bounce scroll
.m-header__overlay-backdrop-white-fixed {
	display: none;
	width: 100%;
	height: 100vh;
	background-color: $color-white;
	opacity: 0;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	pointer-events: none;
}

.m-header__overlay {
	display: none;
	width: 100%;
	min-height: 100vh; // fallback;
	min-height: 100dvh;
	visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
	@include z-index(navigation-overlay-until-large);

	@include mq($from: large) {
		@include z-index(navigation-overlay-from-large);
	}

	@include transition(visibility 0s $transition-default-easing $overlay-close-duration);
}

.m-header__overlay-inner {
	opacity: 0;
	@include transition(opacity $overlay-close-duration/2 $transition-default-easing 0s);
}

.m-header.has-displayed-overlay {
	.m-header__overlay {
		display: block;
	}

	.m-header__overlay-backdrop-white-fixed {
		display: block;
	}
}

.m-header.has-open-overlay {
	.m-header__overlay-backdrop {
		visibility: visible;
		opacity: 1;
		transform: scale(1);
		@include transition(transform $overlay-open-duration, opacity $overlay-open-duration/3);
	}

	.m-header__overlay-backdrop-white-fixed {
		opacity: 1;
		@include transition(opacity 0.2s $overlay-open-duration);
	}

	.m-header__overlay {
		overflow: visible;
		visibility: visible;
		pointer-events: all;
		@include transition(visibility 0s);
	}

	.m-header__overlay-inner {
		opacity: 1;
		@include transition(opacity $overlay-open-duration/2 $transition-default-easing $overlay-open-duration/3);
	}

	.m-header__logo-link,
	.m-header__main-links {
		@include mq($until: large) {
			// to remove it from keyboard focus
			visibility: hidden;
			@include transition(visibility 0s $overlay-open-duration);
		}
	}
}

.m-header.has-visible-search {
	.m-search {
		display: block;
		animation: fade-in 0.4s both;
	}

	.m-header__search-button svg {
		fill: $color-haribo-blue;
	}

	.m-header__bear-big,
	.m-header__fruitman-big {
		display: none;
	}

	.m-header__overlay-headline {
		display: none;
	}
}

.m-header.has-visible-navigation {
	.m-header__overlay-links-wrapper {
		display: flex;
		animation: fade-in 0.4s both;
	}
}

.m-header.has-visible-search,
.m-header.has-visible-navigation {
	.m-header__meta-link,
	.m-header__main-link {
		&.is-active:not(.has-open-link-group) {
			color: $color-haribo-red;

			@include hover-focus() {
				color: $color-haribo-blue;
			}
		}
	}
}

// special and complicated stuff regarding menus on different viewports

@mixin link-group-normal-state() {
	display: block;
	opacity: 1;
	transform: scale(1);
	animation: current-link-group-out 0.4s both;
}

// ---------------------------------------------------------------- menu for large/desktop viewports

@include mq($from: large) {
	.m-header__overlay-link-group {
		display: none;
		flex: 0 0 auto;
		max-width: 250px;
		opacity: 0;
		margin-right: 100px;
		transform-origin: left 30px;
		@include transition(margin-left 0.4s);

		@include is-rtl() {
			margin-left: 100px;
			margin-right: 0;
			transform-origin: right 30px;
			@include transition(margin-right 0.4s);
		}

		&.is-open-link-group {
			@include link-group-normal-state();
		}
	}

	.m-header__overlay-images {
		flex: 0 0 auto;
		padding-bottom: $spacing-purple;
		margin-left: auto;
		@include transition(width 0.4s, margin-right 0.4s);

		@include is-rtl() {
			margin-left: 0;
			margin-right: auto;
			@include transition(width 0.4s, margin-left 0.4s);
		}
	}

	.m-header {
		&[data-current-level="1"] .m-header__overlay-link-group[data-level="1"],
		.m-header__overlay-link-group.is-current-link-group {
			margin-right: 0;
			transform: scale(1.4);
			animation: current-link-group-in 0.4s both;

			@include is-rtl() {
				animation: current-link-group-in-rtl 0.4s both;
			}
		}

		&[data-current-level="2"] {
			.m-header__overlay-images {
				width: percentage(10/$cols-xl);
				margin-right: percentage(2/$cols-xl);

				@include is-rtl() {
					margin-left: percentage(2/$cols-xl);
					margin-right: auto;
				}
			}
		}

		&[data-current-level="3"] {
			.m-header__overlay-images {
				width: percentage(8/$cols-xl);
				margin-right: 0;

				@include is-rtl() {
					margin-left: 0;
					margin-right: auto;
				}
			}
		}
	}

	// yellow hover icon
	.m-header__overlay-link-group.is-current-link-group {
		.m-header__overlay-link {
			position: relative;

			&::before {
				content: "";
				width: 55px;
				height: 26px;
				background-image: url("data:image/svg+xml,%3Csvg width='55' height='26' viewBox='0 0 55 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23FFEB06'%3E%3Cpath d='M43.897 25.36a3.029 3.029 0 01-4.258-.535 3.048 3.048 0 01.533-4.27l.935-.735c2.732-2.17 4.795-3.994 6.174-5.452.62-.655 1.083-1.216 1.383-1.663l.134-.21-.076-.107c-.246-.34-.616-.758-1.107-1.238l-.217-.21c-1.512-1.433-3.87-3.234-7.051-5.369a3.047 3.047 0 01-.834-4.22 3.03 3.03 0 014.21-.837l.865.586C51.651 5.917 55 9.218 55 12.462c0 3.26-3.541 7.003-11.103 12.897z'/%3E%3Cpath d='M24.897 25.36a3.029 3.029 0 01-4.258-.535 3.048 3.048 0 01.533-4.27l.935-.735c2.732-2.17 4.795-3.994 6.174-5.452.62-.655 1.083-1.216 1.383-1.663l.134-.21-.076-.107c-.246-.34-.616-.758-1.107-1.238l-.217-.21c-1.512-1.433-3.87-3.234-7.051-5.369a3.047 3.047 0 01-.834-4.22 3.03 3.03 0 014.21-.837l.865.586C32.651 5.917 36 9.218 36 12.462c0 3.26-3.541 7.003-11.103 12.897z' opacity='.572'/%3E%3Cpath d='M4.897 25.36a3.029 3.029 0 01-4.258-.535 3.048 3.048 0 01.533-4.27l.935-.735c2.732-2.17 4.795-3.994 6.174-5.452.62-.655 1.083-1.216 1.383-1.663l.134-.21-.076-.107c-.246-.34-.616-.758-1.107-1.238l-.217-.21c-1.512-1.433-3.87-3.234-7.051-5.369a3.047 3.047 0 01-.834-4.22 3.03 3.03 0 014.21-.837l.865.586C12.651 5.917 16 9.218 16 12.462c0 3.26-3.541 7.003-11.103 12.897z' opacity='.168'/%3E%3C/g%3E%3C/svg%3E%0A");
				background-repeat: no-repeat;
				background-position: center right;
				background-size: 80%;
				opacity: 0;
				position: absolute;
				top: 4px;
				right: calc(100% + 8px);
				transform: translateX(-10px);
				pointer-events: none;
				@include transition(opacity 0.3s, transform 0.3s);

				@include is-rtl() {
					top: 0;
					left: calc(100% + 8px);
					right: auto;
					transform: scaleX(-1) translateX(-10px);
				}
			}

			[data-whatintent="mouse"] &:hover,
			[data-whatinput="keyboard"] &:focus {
				&::before {
					opacity: 1;
					transform: translateX(0);
				}
			}

			[dir="rtl"][data-whatintent="mouse"] &:hover,
			[dir="rtl"][data-whatinput="keyboard"] &:focus {
				&::before {
					transform: scaleX(-1) translateX(0);
				}
			}
		}
	}
}


@include mq($from: xlarge) {
	.m-header__overlay-link-group {
		max-width: 300px;
		margin-right: 150px;

		@include is-rtl() {
			margin-left: 150px;
			margin-right: auto;
		}
	}

	.m-header__overlay-link-group[data-level="2"] {
		margin-left: percentage(1/$cols-xl);

		@include is-rtl() {
			margin-left: 150px;
			margin-right: percentage(1/$cols-xl);
		}
	}

	.m-header {
		&[data-current-level="3"] {
			.m-header__overlay-images {
				margin-right: percentage(1/$cols-xl);

				@include is-rtl() {
					margin-left: percentage(1/$cols-xl);
					margin-right: auto;
				}
			}
		}
	}
}

// ---------------------------------------------------------------- slide menu for mobile devices (phones, tablets)

@include mq($until: large) {
	.m-header__overlay {
		overflow: hidden !important;
	}

	.m-header__overlay-links-wrapper {
		width: 100%;
		@include transition(transform 0.4s);
	}

	.m-header__overlay-link-group {
		display: none;
		flex: 0 0 auto;
		width: 100%;
		height: 0;
		padding-left: 6.6%;
		padding-right: 6.6%;
		@include transition(transform 0.4s, opacity 0.4s);

		@include mq($from: medium) {
			padding-left: 15.3%;
			padding-right: 9.6%;

			@include is-rtl() {
				padding-left: 9.6%;
				padding-right: 15.3%;
			}
		}

		&[data-level="1"],
		&.is-open-link-group {
			display: block;
		}

		&.is-current-link-group {
			height: auto;
		}
	}

	.m-header__overlay-link {
		&.has-open-link-group {
			color: $color-white;
		}
	}

	.m-header__overlay-headline {
		width: 0;
		opacity: 0;
		transform: translateX(-30px);
		@include transition(transform, opacity);

		@include is-rtl() {
			transform: translateX(30px);
		}
	}

	.m-header {
		&[data-current-level="1"] {
			.m-header__overlay-headline {
				width: auto;
				opacity: 1;
				color: $color-haribo-yellow;
				transform: translateX(0);
			}

			.m-header__overlay-back-button {
				opacity: 0;
				transform: translateX(30px);
				pointer-events: none;
				@include transition(opacity 0s, transform 0s);

				@include is-rtl() {
					transform: translateX(-30px);
				}
			}

			.m-header__overlay-link-group[data-level="1"] {
				height: auto;
			}
		}

		&[data-current-level="2"] .m-header__overlay-links-wrapper {
			transform: translateX(-100%);

			@include is-rtl() {
				transform: translateX(100%);
			}
		}

		&[data-current-level="3"] .m-header__overlay-links-wrapper {
			transform: translateX(-200%);

			@include is-rtl() {
				transform: translateX(200%);
			}
		}
	}
}

// Cluster Links

.m-header {
	.m-header__cluster {
		pointer-events: none;
		font-size: 18px !important;
		text-decoration: underline;
	}

	.m-header__clusterchild {
		padding-left: 20px;
		margin-top: 5px !important;
	}
}

// animations

@keyframes current-link-group-in {
	0% {
		opacity: 0;
		transform: translateX(-30px) scale(1.4);
	}
}

@keyframes current-link-group-in-rtl {
	0% {
		opacity: 0;
		transform: translateX(30px) scale(1.4);
	}
}

@keyframes current-link-group-out {
	0% {
		transform: scale(1.4);
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
}

// ---------------------------------------------------------------- context variations

/**
 * handle offset if m-rerouting-popup is visually visible above the header (because it is fixed)
 * --rerouting-popup-height variable should be available inline on body
 */
body.has-displayed-rerouting-popup .m-header:not(.has-open-overlay) {
	.m-header__logo-wrapper,
	.m-header__navigation-wrapper {
		transform: translateY(var(--rerouting-popup-height));
	}
}

body.has-displayed-rerouting-popup .m-header.has-hidden-navigation-bar {
	.m-header__navigation-wrapper {
		@include hide-navigation-bar-animation();
	}
}
