$dial-radio-group-option-padding: 8px;

$dial-radio-group-diameter-s: 88px;
$dial-radio-group-inner-diameter-s: $dial-radio-group-diameter-s / 2.2;

$dial-radio-group-diameter-l: 104px;
$dial-radio-group-inner-diameter-l: $dial-radio-group-diameter-l / 2.2;

@import "variant-mixin";

.c-dial-radio-group {
	display: flex;
	width: fit-content;
}

.c-dial-radio-group__knob {
	display: flex;
	flex: 0 0 auto;
	width: $dial-radio-group-diameter-s;
	height: $dial-radio-group-diameter-s;
	background-image: linear-gradient(100deg, #063269 0%,#0B6EC0 100%);
	border-radius: 50%;
	box-shadow: inset 2px 2px 5px 0 rgba(0, 0, 0, 0.38);
	position: relative;
	margin-top: 10px;
	margin-bottom: 10px;

	// center
	&::after {
		content: "";
		width: $dial-radio-group-inner-diameter-s;
		height: $dial-radio-group-inner-diameter-s;
		background-image: linear-gradient(135deg, #063269 0%,#0B6EC0 100%);
		border-radius: 50%;
		box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.25);
		position: absolute;
		top: ($dial-radio-group-diameter-s / 2) - ($dial-radio-group-inner-diameter-s / 2);
		left: ($dial-radio-group-diameter-s / 2) - ($dial-radio-group-inner-diameter-s / 2);
		z-index: 3;
		pointer-events: none;
	}
}

.c-dial-radio-group__knob-inner {
	width: 65%;
	height: 65%;
	background-color: $color-white;
	border-radius: 50%;
	box-shadow: 0 0 15px 3px rgba(0,0,0,0.3);
	position: relative;
	z-index: 2;
	margin: auto;
	@include transition(transform 0.4s);
	cursor: pointer;

	// triangle
	&::after {
		$triangle-height: 12px;
		$triangle-width: 14px;
		content: "";
		width: 0;
		height: 0;
		border-width: 0 ($triangle-width / 2) $triangle-height ($triangle-width / 2);
		border-style: solid;
		border-color: transparent transparent $color-white transparent;
		position: absolute;
		bottom: calc(100% - 2px);
		left: calc(50% - #{$triangle-width / 2});
	}
}

.c-dial-radio-group__option {
	flex: 0 0 auto;
	padding: $dial-radio-group-option-padding;
	outline: none;
	white-space: nowrap;
	position: relative;
	z-index: 4;
	cursor: pointer;
}

.c-dial-radio-group__input {
	position: absolute;
	top: 0;
	left: 0;
}

.c-dial-radio-group__label {
	color: $color-haribo-blue;
}

.c-dial-radio-group__options-left,
.c-dial-radio-group__options-right {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.c-dial-radio-group__options-left {
	align-items: flex-end;

	.c-dial-radio-group__option {
		margin-right: -$dial-radio-group-option-padding;
	}
}

.c-dial-radio-group__options-right {
	align-items: flex-start;
	order: 2;

	.c-dial-radio-group__option {
		margin-left: -$dial-radio-group-option-padding;
	}
}

[data-whatinput="keyboard"] .c-dial-radio-group:focus-within {
	@include keyboard-focus();
}

.c-dial-radio-group--big {
	@include dial-radio-group--big();
}
