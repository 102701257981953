.m-content-box__quote .c-content-shape__inner {
	padding-top: $spacing-orange;
	padding-bottom: $spacing-royalblue;

	@include mq($from: medium) {
		padding-top: $spacing-lilac;
		padding-bottom: $spacing-lightblue;
	}

	@include mq($from: large) {
		display: flex;
		align-items: flex-start;
		padding-top: $spacing-purple;
		padding-bottom: $spacing-lightblue;
		padding-left: 3%;
		padding-right: 5%;

		@include is-rtl() {
			padding-left: 5%;
			padding-right: 3%;
		}
	}

	@include mq($from: xlarge) {
		padding-top: $spacing-ocher;
		padding-bottom: $spacing-petrol;
	}
}

.m-content-box__quote-image {
	@include svg-mask(b);
	width: 64%;

	@include mq($from: medium) {
		flex: 0 0 auto;
		margin-left: -5px;

		@include is-rtl() {
			margin-left: 0;
			margin-right: -5px;
		}
	}

	@include mq($from: large) {
		width: 48%;
		margin-right: 4%;

		@include is-rtl() {
			margin-left: 4%;
			margin-right: 0;
		}
	}
}

.m-content-box__quote-content {
	margin-top: $spacing-pink;

	@include mq($from: medium) {
		margin-top: $spacing-lilac;
	}

	@include mq($from: large) {
		align-self: center;
		padding-top: $spacing-yellow;
		margin-top: 0;
	}
}

.m-content-box__quote-description {
	margin-top: $spacing-olive;

	@include mq($from: medium) {
		margin-top: $spacing-pink;
	}

	@include mq($from: large) {
		margin-top: $spacing-olive;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-orange;
	}
}

.m-content-box__quote-link {
	margin-top: $spacing-yellow;

	@include mq($from: medium) {
		margin-top: $spacing-purple;
	}

	@include mq($from: large) {
		margin-top: $spacing-yellow;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-lightblue;
	}
}
