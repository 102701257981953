.m-gallery {
	--headline-color: #{$color-haribo-blue};
	color: var(--headline-color);

	@include has-background-color() {
		--headline-color: #{$color-white};
	}

	@include has-background-color() {
		padding-top: $spacing-purple;
		padding-bottom: $spacing-purple;
		background-image: radial-gradient(circle at 71% 30%, var(--gradient-color), var(--background-color) 63%);

		@include mq($from: medium) {
			padding-top: $spacing-petrol;
			padding-bottom: $spacing-petrol;
		}

		@include mq($from: xlarge) {
			padding-top: $spacing-softblue;
			padding-bottom: $spacing-softblue;
		}
	}

	&.m-gallery--no-bg-gradient {
		background-image: none;
	}
}

.m-gallery__headline {
	margin-bottom: $spacing-orange;

	@include mq($from: medium) {
		margin-bottom: $spacing-purple;
	}

	@include mq($from: large) {
		max-width: percentage(21/$cols-l);
		margin-bottom: $spacing-lilac;
	}

	@include mq($from: xlarge) {
		max-width: percentage(14/$cols-l);
		margin-bottom: $spacing-purple;
	}
}

.m-gallery__images {
	.c-image-component__caption {
		margin-top: $spacing-pink;

		@include parent-has-background-color() {
			color: $color-white;
		}

		@include mq($from: medium) {
			margin-top: $spacing-yellow;
		}

		@include mq($from: large) {
			max-width: percentage(21/$cols-l);
		}

		@include mq($from: xlarge) {
			max-width: percentage(14/$cols-l);
			margin-top: $spacing-lilac;
		}
	}

	.c-media-slider__pagination {
		margin-left: auto;

		@include is-rtl() {
			margin-left: 0;
			margin-right: auto;
		}
	}

	.has-selected-first-slide {
		.c-media-slider__button-prev {
			opacity: 0;
			pointer-events: none;
		}
	}

	.has-selected-last-slide {
		.c-media-slider__button-next {
			opacity: 0;
			pointer-events: none;
		}
	}
}
