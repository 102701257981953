.c-media-slider {
	position: relative;
}

.c-media-slider__aspect-ratio-overlay {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
}

.c-media-slider__aspect-ratio-overlay-inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	padding: $spacing-olive;

	@include mq($from: medium) {
		padding-left: 15px;
		padding-right: 15px;
	}

	@include mq($from: xlarge) {
		padding: $spacing-pink 20px;
	}
}

.c-media-slider__item-overlay {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
}

.c-media-slider__link {
	display: flex;
	align-items: flex-end;

	[data-whatintent="mouse"] &:hover,
	[data-whatinput="keyboard"] &:focus {
		.c-media-slider__link-label {
			@include icon-text-link-wave-arrow-right-hover();
		}
	}

	[dir="rtl"][data-whatintent="mouse"] &:hover,
	[dir="rtl"][data-whatinput="keyboard"] &:focus {
		.c-media-slider__link-label {
			@include icon-text-link-wave-arrow-right-hover(rtl);
		}
	}
}

.c-media-slider__link-label {
	color: $color-white;
	margin-bottom: 15px;
	margin-left: 20px;
	pointer-events: none;
	@include transition(color);

	@include is-rtl() {
		margin-left: 0;
		margin-right: 20px;
	}

	@include mq($from: medium) {
		margin-left: 25px;

		@include is-rtl() {
			margin-right: 25px;
		}
	}

	@include mq($from: large) {
		margin-bottom: 35px;
		margin-left: 55px;

		@include is-rtl() {
			margin-right: 55px;
		}
	}

	@include mq($from: xlarge) {
		margin-bottom: 55px;
		margin-left: 75px;

		@include is-rtl() {
			margin-right: 75px;
		}
	}

	.c-icon-text-link__label {
		text-shadow: 0 0 8px $color-black;
	}

	.is-zoomable & {
		@include mq($until: large) {
			margin-left: 75px;

			@include is-rtl() {
				margin-left: 0;
				margin-right: 75px;
			}
		}
	}
}

.c-media-slider__arrow-buttons {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: auto;
	margin-bottom: auto;

	@include is-rtl() {
		flex-direction: row-reverse;
	}
}


.c-media-slider__button-prev,
.c-media-slider__button-next {
	display: none;
	width: 30px;
	height: 30px;
	color: $color-haribo-red;
	position: relative;
	pointer-events: all;
	@include transition(opacity);

	@include mq($from: medium) {
		display: flex;
		width: 30px;
		height: 30px;
	}

	@include mq($from: large) {
		width: 50px;
		height: 50px;
	}

	@include mq($from: xlarge) {
		width: 60px;
		height: 60px;
	}

	// circle background
	&::after {
		content: "";
		width: 100%;
		height: 100%;
		background-color: $color-white;
		border-radius: 50%;
		box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
		position: absolute;
		top: 0;
		left: 0;
		@include transition(transform);
	}

	svg {
		width: 100%;
		height: 100%;
		padding: 23%;
		z-index: 2;
		margin: auto;
	}

	@include hover-focus() {
		&::after {
			transform: scale(1.2);
		}
	}
}

.c-media-slider__button-prev {
	svg {
		margin-right: 5%;
	}
}

.c-media-slider__button-next {
	svg {
		margin-left: 5%;
	}
}

.c-media-slider__pagination {
	display: flex;
	align-items: center;
	width: fit-content;
	height: 20px;
	padding-left: 8px;
	padding-right: 8px;
	background-color: $color-white;
	border-radius: 10px;

	@include mq($from: large) {
		height: 26px;
		padding-left: 10px;
		padding-right: 10px;
		border-radius: 13px;
	}
}

.c-media-slider__pagination:first-child {
	margin-top: auto;
}

.c-media-slider__pagination-item {
	width: 6px;
	height: 6px;
	background-color: rgba($color-haribo-red, 0.2);
	border-radius: 50%;
	margin-left: 3px;
	margin-right: 3px;
	pointer-events: all;
	@include transition(opacity, transform);


	@include mq($from: large) {
		width: 8px;
		height: 8px;
		margin-left: 5px;
		margin-right: 5px;
	}

	&.is-active {
		background-color: $color-haribo-red;
		transform: scale(1.5);

		@include mq($from: large) {
			transform: scale(1.5);
		}
	}
}

.c-media-slider .c-image-component__caption {
	margin-top: $spacing-olive;
}

.c-media-slider__extra {
	pointer-events: none;
}

.c-media-slider--stage {
	.c-media-slider__aspect-ratio-overlay-inner {
		@include mq($from: large) {
			padding-bottom: $spacing-orange;
		}
	}

	.c-media-slider__button-prev,
	.c-media-slider__button-next {
		width: 50px;
		height: 50px;

		@include mq($from: xlarge) {
			width: 60px;
			height: 60px;
		}
	}

	.c-media-slider__arrow-buttons {
		position: absolute;
		top: 50%;
		margin-top: -25px;

		@include mq($until: medium) {
			display: none;
		}

		@include mq($from: medium) {
			padding-left: 25px;
			padding-right: 25px;
		}

		@include mq($from: large) {
			padding-left: 15px;
			padding-right: 15px;
		}

		@include mq($from: large) {
			padding-left: 20px;
			padding-right: 20px;
		}

		@include mq($from: xlarge) {
			margin-top: -30px;
		}
	}
}

.c-media-slider__zoomable-images {
	position: absolute;
	bottom: 10px;
	left: 10px;
	z-index: 2;
	pointer-events: all;

	@include is-rtl() {
		left: auto;
		right: 10px;
	}

	@include mq($from: large) {
		display: none;
	}

	.c-image-zoom {
		display: none;

		&.is-visible {
			display: block;
		}
	}
}

.c-media-slider.has-single-slide {
	.c-media-slider__zoomable-images .c-image-zoom {
		display: block;
	}
}

.c-media-slider.is-dragging {
	.c-media-slider__slides > * {
		pointer-events: none;
	}
}

.c-media-slider__zoom-overlay {
	@include mq($from: large) {
		display: none;
	}
}

// before flickity is ready, show only the first slide
html.js .c-media-slider [data-slides-wrapper]:not(.flickity-enabled) > *:nth-child(n+2) {
	display: none;
}

.c-media-slider:not([data-initialized]) {
	.c-media-slider__pagination,
	.c-media-slider__button-prev,
	.c-media-slider__button-next {
		display: none;
	}

	.c-media-slider__slides > *:not(:first-child) {
		display: none;
	}
}
