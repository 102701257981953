.m-raffle {
	position: relative;

	&.has-bg-color {
		background-image: radial-gradient(circle at 40% 55%, var(--gradient-color), var(--background-color) 97%);
	}
}

.m-raffle:empty {
	display: none;
}

.m-raffle__aspect-ratio-helper {
	&::before {
		padding-top: 75%;
	}
}

.m-raffle__content {
	display: flex;
	flex-direction: column;

	@include mq($from: large) {
		flex-direction: row;
		align-items: flex-start;
	}
}

.m-raffle__steps {
	padding-top: 0;
	padding-bottom: $spacing-lilac;
	position: relative;
	z-index: 2;

	@include mq($until: large) {
		order: 2;
		padding-top: $spacing-lilac;

		.has-both-images & {
			padding-top: 0;
		}
	}

	@include mq($from: large) {
		align-self: center;
		width: percentage(15/$cols-l);
		padding-top: $spacing-purple;
		padding-bottom: $spacing-purple;
		margin-left: percentage(1/$cols-l);
	}

	@include mq($from: xlarge) {
		width: percentage(16/$cols-xl);
		padding-top: $spacing-lightblue;
		padding-bottom: $spacing-lightblue;
		margin-left: percentage(1/$cols-xl);
	}
}

.m-raffle__visual {
	position: relative;
	z-index: 2;

	@include mq($until: large) {
		display: none;
	}

	@include mq($from: large) {
		align-self: flex-start;
		width: percentage(10/$cols-l);
		max-width: 400px;
		margin-top: -10%;
		margin-bottom: $spacing-purple;
		margin-left: percentage(3/$cols-l);
		margin-right: -(percentage(3/$cols-l));
		// adjust as fit, it also acts as placeholder against layout shift
		aspect-ratio: 4 / 7.3; // stylelint-disable-line property-no-unknown
	}

	@include mq($from: xlarge) {
		width: percentage(9/$cols-xl);
		max-width: 430px;
		margin-bottom: $spacing-lightblue;
		margin-left: percentage(1/$cols-l);
		margin-right: -(percentage(2/$cols-xl));
	}

	.has-both-images & {
		@include mq($until: large) {
			display: block;
			z-index: 3;
			margin-top: -30%;
			margin-bottom: $spacing-lilac;
			aspect-ratio: 4 / 3; // stylelint-disable-line property-no-unknown
		}

		@include mq($from: medium, $until: large) {
			width: 480px;
			margin-top: -170px;
		}
	}
}

.m-raffle__image {
	width: 100%;
	position: relative;

	@include mq($until: large) {
		width: 70%;
	}

	img {
		object-fit: cover;
	}
}

.m-raffle__bear-image {
	@include reset-aspect-ratio-styles();

	width: 100%;
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 2;
	aspect-ratio: 1; // stylelint-disable-line property-no-unknown

	@include mq($until: large) {
		width: 50%;
	}

	img {
		object-fit: contain;
	}
}

// as a placeholder for spacing between modules
.has-both-images .m-raffle__module-spacer {
	content: "";
	width: 100%;
	padding-bottom: 23%;
	background-color: $color-white;
	margin-top: -2px;

	@include mq($from: medium, $until: large) {
		height: 120px;
		padding-bottom: 0;
	}

	@include mq($from: large) {
		display: none;
	}
}

.m-raffle__step {
	width: 100%;
	color: $color-white;
	animation-name: step-reveal-animation;
	animation-duration: 1s;
	animation-timing-function: $animation-default-easing;
	animation-fill-mode: forwards;
}

.m-raffle__headline,
.m-raffle__copy {
	color: $color-haribo-blue;

	.has-bg-color & {
		color: $color-white;
	}
}

.m-raffle__headline + * {
	margin-top: $spacing-lilac;

	@include mq($from: large) {
		margin-top: $spacing-lightblue;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-lightpink;
	}
}

.m-raffle__headline + .m-raffle__copy {
	margin-top: $spacing-yellow;
}

.m-raffle__button {
	background-color: $color-haribo-blue;
	outline: none;
	margin-top: $spacing-lilac;

	@include mq($from: large) {
		margin-top: $spacing-lilac;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-lightblue;
	}

	.c-button__label {
		color: $color-white;
	}

	.has-bg-color & {
		background-color: $color-white;

		.c-button__label {
			color: $color-haribo-blue;
		}
	}
}

.m-raffle__button[disabled] {
	background-color: $color-haribo-blue;
	opacity: 0.5;
	pointer-events: none;

	.has-bg-color & {
		background-color: $color-white;
	}
}

.m-raffle__captcha {
	margin-top: $spacing-yellow;

	@include mq($from: large) {
		margin-top: $spacing-lilac;
	}
}

.m-raffle__cover-wave {
	display: none;
	width: 100%;
	fill: $color-white;
	position: relative;
	top: -2px;
	z-index: 2;

	.has-bg-color & {
		display: block;
	}
}

.m-raffle__background-image,
.m-raffle__background-image-portrait {
	@include reset-aspect-ratio-styles();
	width: 100%;
	position: absolute;
	left: 0;
	z-index: 1;
	pointer-events: none;

	.c-image-component__image-wrapper img {
		object-fit: cover;
	}
}

.m-raffle__background-image {
	height: 100%;
	top: 0;

	@include mq($until: large) {
		display: none;
	}

	.c-image-component__image-wrapper img {
		object-position: bottom right;
	}
}

.m-raffle__background-image-portrait {
	height: 100%;
	bottom: 0;

	@include mq($from: large) {
		display: none;
	}

	.has-both-images & {
		height: calc(100% - 25vw);

		@include mq($from: medium) {
			height: calc(100% - 130px);
		}
	}

	.c-image-component__image-wrapper img {
		object-position: top center;
	}
}

@keyframes step-reveal-animation {
	0% {
		opacity: 0;
		transform: translateX(-20px);
	}

	20% {
		opacity: 0;
		transform: translateX(-20px);
	}

	100% {
		opacity: 1;
	}
}

@import "components/IntroStep";
@import "components/QuestionStep";
@import "components/VoteResultStep";
@import "components/FormStep";
@import "components/ConfirmationStep";
@import "components/IncorrectStep";
@import "components/RaffleChoice";
