.c-file-input__button-wrapper {
	display: block;
	width: 100%;
	position: relative;
	cursor: pointer;
}

.c-file-input__button {
	@include property-breakpoint-map(height, $input-height-map);
	background-color: $color-haribo-blue;
	color: $color-white;
	pointer-events: none; // The button should act like a span (and just case a label click)
	@include transition(background-color);

	.c-button__icon {
		height: 60%;
	}
}

.c-file-input__input {
	width: 100%;
	height: 100%;
	opacity: 0.0;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;

	&:focus + .c-file-input__button {
		background-color: $color-haribo-dark-blue;
	}
}

// List
.c-file-input__file-list {
	position: relative;
	margin-top: $spacing-lilac;
	@include property-breakpoint-map(padding-left, $input-side-padding-map, 1, -$input-border-size);
	@include property-breakpoint-map(padding-right, $input-side-padding-map, 1, -$input-border-size);

	@include mq($from: xlarge) {
		margin-top: $spacing-royalblue;
	}
}

.c-file-input__file {
	padding: $spacing-olive 0;
	position: relative;

	& + & {
		border-top: solid 2px rgba($color-haribo-blue, 0.2);
	}
}

.c-file-input__delete {
	display: block;
	width: 28px;
	height: 28px;
	background-color: $color-haribo-grey-4;
	border-radius: 50%;
	position: absolute;
	top: 5px;
	right: 0;
}

.c-file-input__delete-icon {
	width: 12px;
	height: 12px;
	opacity: 0.35;
	position: relative;
	margin: 0 auto;
}
