.m-stage-product--pillow {
	.m-stage-product__module-headline-wrapper {
		@include mq($from: medium) {
			width: percentage(6/$cols-m);
		}

		@include mq($from: large) {
			width: percentage(12/$cols-l);
			padding-top: 170px;
			padding-bottom: 210px;
		}

		@include mq($from: xlarge) {
			padding-top: 130px;
			padding-bottom: 210px;
		}
	}

	.m-stage-product__image-links-wrapper {
		position: relative;

		@include mq($until: medium) {
			width: 100%;
			bottom: 0;
			margin-top: $spacing-brown;
		}

		@include mq($from: medium) {
			width: percentage(10/$cols-m);
			bottom: -40px;
			right: 50px;
			margin-top: 100px;
		}

		@include mq($from: large) {
			width: percentage(16/$cols-l);
			bottom: -50px;
			right: 45px;
			margin-top: 150px;
		}

		@include mq($from: xlarge) {
			width: percentage(16/$cols-l);
			bottom: -30px;
			right: 45px;
			margin-top: 300px;
		}
	}

	.m-stage-product__pin {
		width: 1px;
		height: 1px;
		position: absolute;

		.m-stage-product__image-link {
			position: absolute;
			bottom: 100%;
			right: 100%;
			margin-right: -12px;

			&.m-stage-product__image-link--speech-bubble-left {
				margin-left: -12px;
				margin-right: 0;
			}
		}
	}

	.m-stage-product__image {
		transform: rotate(15deg);

		img {
			filter: drop-shadow(20px 15px 25px rgba($color-black, 0.23));
		}
	}

	.m-stage-product__pin--ingredients {
		top: -11%;
		left: 38%;

		@include mq($from: medium) {
			top: -2%;
			left: 36%;
		}

		@include mq($from: large) {
			top: -3%;
			left: 38%;
		}

		@include mq($from: xlarge) {
			top: -3%;
			left: 38%;
		}
	}

	.m-stage-product__image-link--ingredients {
		transform: rotate(-21deg);

		@include mq($from: medium) {
			transform: rotate(-19deg);
		}

		@include mq($from: large) {
			transform: rotate(-6deg);
		}
	}

	.m-stage-product__pin--shop {
		top: 10%;
		left: 80%;

		@include mq($from: medium) {
			top: 27%;
			left: 91%;
		}

		@include mq($from: large) {
			top: 20%;
			left: 90%;
		}

		@include mq($from: xlarge) {
			top: 13%;
			left: 89%;
		}
	}

	.m-stage-product__image-link--shop {
		top: -65px;
		left: 59%;
		transform: rotate(11deg);

		@include mq($from: medium) {
			transform: rotate(10deg);
		}

		@include mq($from: large) {
			transform: rotate(17deg);
		}
	}
}
