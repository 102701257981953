@import "variant-mixin";

.c-toggle-circle {
	--toggle-circle-diamter: 38px;

	display: flex;
	align-items: center;
	width: fit-content;
	position: relative;
}

.c-toggle-circle__chrome {
	display: flex;
	width: var(--toggle-circle-diamter);
	height: var(--toggle-circle-diamter);
	position: relative;
}

.c-toggle-circle__input {
	opacity: 0;
	z-index: 3;
	cursor: pointer;
}

.c-toggle-circle__label {
	color: $color-haribo-blue;
	margin-left: 10px;
}

.c-toggle-circle__circle {
	width: 100%;
	height: 100%;
	background-clip: content-box;
	border: 5px solid $color-white;
	border-radius: 50%;
	box-shadow: inset 2px 2px 5px 0 rgba(0, 0, 0, 0.44);
}

.c-toggle-circle__ring {
	border: 3px solid $color-black;
	border-radius: 50%;
	opacity: 0;
	z-index: 2;
	@include transition(opacity);
}

.c-toggle-circle__input:checked {
	~ .c-toggle-circle__ring {
		opacity: 1;
	}
}

[data-whatinput="keyboard"] .c-toggle-circle:focus-within {
	.c-toggle-circle__chrome {
		@include keyboard-focus();
	}
}

$colors: (
	"white": $color-white,
	"yellow": $color-haribo-yellow,
	"orange": $color-haribo-orange,
	"green": $color-haribo-green,
	"pink": $color-haribo-pink,
	"blue": $color-haribo-blue,
	"red": $color-haribo-red,
	"black": $color-black
);

@each $key, $color in $colors {
	.c-toggle-circle__circle--#{$key} {
		background-color: $color;
	}

	.c-toggle-circle__ring--#{$key} {
		@if $key == "white" {
			border-color: $color-haribo-grey-3;
		} @else {
			border-color: $color;
		}
	}
}

.c-toggle-circle--big {
	@include toggle-circle--big();
}
