$result-step-l-cols: 15;
$result-step-xl-cols: 16;

.m-quiz-result__content {
	@include mq($from: large) {
		display: flex;
		align-items: center;
		margin-top: 0;
	}
}

.m-quiz-result__text {
	@include mq($from: large) {
		flex: 0 0 auto;
		width: percentage(10/$result-step-l-cols);
	}

	@include mq($from: xlarge) {
		width: percentage(10/$result-step-xl-cols);
	}

	.m-raffle__copy {
		margin-top: $spacing-yellow;
	}
}

.m-quiz-result__image {
	display: block;

	@include mq($until: large) {
		width: 85%;
		max-width: 350px;
		margin-top: $spacing-lilac;
		margin-left: auto;
		margin-right: auto;
	}

	@include mq($from: large) {
		width: 45.3%;
		max-width: 400px;
		margin-left: 6.6%;
		margin-right: -16%;
	}

	@include mq($from: xlarge) {
		width: 36%;
		margin-left: 4%;
		margin-right: -3%;
	}
}

.m-raffle__arrow-link {
	margin-top: $spacing-lilac;

	@include mq($from: large) {
		margin-top: $spacing-lightblue;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-lightpink;
	}
}
