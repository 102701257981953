.m-product-info__images {
	.m-product-info__box-headline {
		@include mq($until: medium) {
			margin-bottom: $spacing-pink;
		}

		@include mq($from: medium) {
			margin-bottom: $spacing-orange;
		}
	}

	.c-content-shape__inner {
		@include mq($from: xlarge) {
			padding-left: percentage(2/15);
			padding-right: percentage(2/15);
		}
	}
}

.m-product-info__image-list {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	position: relative;
}

.m-product-info__image-list-item {
	width: 28.8%;
	position: relative;
	margin-top: $spacing-lilac;

	@include mq($from: medium) {
		width: 25%;
		margin-top: $spacing-royalblue;
	}

	@include mq($from: xlarge) {
		width: 27.2%;
		margin-top: $spacing-lightgreen;
	}
}

.m-product-info__image-list-item:nth-child(3n+1),
.m-product-info__image-list-item:nth-child(3n+2) {
	margin-right: 6.6%;

	@include is-rtl() {
		margin-left: 6.6%;
		margin-right: 0;
	}

	@include mq($from: medium) {
		margin-right: 12.3%;

		@include is-rtl() {
			margin-left: 12.3%;
			margin-right: 0;
		}
	}

	@include mq($from: xlarge) {
		margin-right: 9.2%;

		@include is-rtl() {
			margin-left: 9.2%;
			margin-right: 0;
		}
	}
}

.m-product-info__image-list-item:nth-child(-n+3) {
	margin-top: 0;
}

.m-product-info__image-list-image {
	display: flex;
	justify-content: center;
	color: $color-haribo-blue;

	[fill="#000000"],
	[fill="#000"] {
		fill: currentColor;
	}

	> .o-aspect-ratio {
		width: 100%;
	}

	img {
		border-radius: 50%;
	}
}

.m-product-info__image-list-text {
	width: 100%;
	color: $color-haribo-blue;
	font-weight: bold;
	text-align: center;
}

.m-product-info__image-list-svg {
	svg {
		width: 100%;
		max-width: 55px;
		height: auto;

		@include mq($from: medium) {
			max-width: 75px;
		}

		@include mq($from: xlarge) {
			max-width: 90px;
		}
	}
}

.m-product-info__image-list-svg + .m-product-info__image-list-text {
	line-height: 1.1;
	position: absolute;
	bottom: -10px;
	left: 0;
	z-index: 2;

	&::after {
		content: attr(data-text);
		width: 100%;
		font-size: 1em;
		text-align: center;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		-webkit-text-stroke: 6px white;
	}
}
