.m-stage-b {
	// navigation UI
	.c-media-slider__aspect-ratio-overlay,
	.c-media-slider__aspect-ratio-overlay-inner {
		height: 100%;
	}

	.l-main & {
		background-color: transparent;
	}
}

// ------------------------------------------------------------- for both m050 and m051 slide types

$max-slide-height: calc(100vh - 60px); // compensate bottom phone UI

.m-stage-b__slide {
	width: 100%;
	min-height: 530px;
	overflow: hidden;
	position: relative;
	@include aspect-ratio-helper("30x35");

	@include mq($from: medium) {
		@include aspect-ratio-helper("40x47");
		max-height: 900px;
	}

	@include mq($from: large) {
		@include aspect-ratio-helper("20x11");
		max-height: $max-slide-height;
	}

	@include mq($from: xlarge) {
		min-height: 700px;
		@include aspect-ratio-helper("9x5");
	}
}

.m-stage-b__slide-inner {
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;

	@include mq($from: large) {
		display: flex;
	}
}

.m-stage-b__text {
	width: 87%;
	color: $color-white;
	position: relative;
	z-index: 3;
	margin-top: $spacing-salmon;
	margin-left: auto;
	margin-right: auto;

	@include mq($from: medium) {
		width: 81%;
		margin-top: $spacing-brown;
	}

	@include mq($from: large) {
		align-self: flex-start;
		width: 36%;
		margin-top: $spacing-cadetblue;
		margin-bottom: $spacing-yellow;
		margin-left: 11.6%;
		margin-right: auto;

		@include is-rtl() {
			margin-left: auto;
			margin-right: 11.6%;
		}
	}

	@include mq($from: xlarge) {
		width: 35%;
		margin-top: 15%;
		margin-left: 9.5%;

		@include is-rtl() {
			margin-left: auto;
			margin-right: 9.5%;
		}
	}

	@include mq($from: $module-max-width) {
		width: 32%;
		margin-left: 8.4%;

		@include is-rtl() {
			margin-left: auto;
			margin-right: 8.4%;
		}
	}
}

// override aspect ratio for visual container and the image component inside it
.m-stage-b__visual.o-aspect-ratio,
.m-stage-b__visual .o-aspect-ratio {
	@include mq($until: large) {
		@include aspect-ratio-helper("4x3");
	}
}

.m-stage-b__visual {
	align-self: flex-end;
	width: 90%;
	position: relative;
	margin-left: auto;

	@include is-rtl() {
		margin-left: 0;
	}

	@include mq($from: medium) {
		width: 87%;
	}

	@include mq($from: large) {
		flex: 0 0 auto;
		width: 53%;
		margin-left: 0;
	}

	@include mq($from: xlarge) {
		width: 56.2%;
	}

	.o-aspect-ratio {
		height: 100%;

		img {
			object-fit: cover;
		}
	}
}

.m-stage-b__link {
	margin-top: $spacing-orange;

	@include mq($from: large) {
		margin-top: $spacing-lilac;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-purple;
	}
}

@import "components/StageProductImage";
@import "components/StageShape";
@import "components/StageVideoBackground";

.m-stage-b.u-color-bg-maoam {
	.m-stage-b__slide-inner {
		background-color: $color-maoam-yellow !important // override inline styles;
	}

	.m-stage-b__text {
		color: $color-maoam-blue;

		.c-icon-text-link__icon {
			fill: $color-maoam-orange;
		}
	}
}
