.c-input-choice--checkbox .c-input-choice__text {
	width: 75%;

	@include mq($until: large) {
		width: 90%;
	}
}

.c-input-choice__checkbox-mark {
	width: 22px;
	height: 22px;
	top: calc(50% - 10px);
	left: calc(50% - 11px);

	@include mq($until: large) {
		transform: scale(0.8);
	}
}
