$form-label-height-map: (
	small: 23px,
	medium: 24px,
	large: 28px,
	xlarge: 28px
);
$form-label-margin-bottom-map: (
	small: $spacing-smokeblue-light,
	medium: $spacing-smokeblue-light,
	large: $spacing-smokeblue,
	xlarge: $spacing-smokeblue
);

.c-form-label {
	display: block;
	@include property-breakpoint-map(height, $form-label-height-map); // Give label a fixed height even if font style is fluid
	color: $color-haribo-blue;
	@include font-style("copy-a1");
	@include property-breakpoint-map(margin-left, $input-side-padding-map);
	@include property-breakpoint-map(margin-bottom, $form-label-margin-bottom-map);
	letter-spacing: -0.5px;

	@include is-rtl() {
		margin-left: 0;
		@include property-breakpoint-map(margin-right, $input-side-padding-map);
	}

	// code tag: sfJobApplication
	&--long {
		height: auto !important;
		@include font-style("copy-a1--long");
	}
}

.c-form-label.is-required {
	&::after {
		content: "*";
	}
}

.c-form-error {
	display: block;
	color: $color-haribo-red;
	@include property-breakpoint-map(margin-left, $input-side-padding-map);
	@include property-breakpoint-map(margin-right, $input-side-padding-map);
	margin-top: $spacing-olive;

	.c-input-choice + &,
	.c-radio-group + & {
		margin-left: 0;
		margin-right: 0;
	}
}

.m-sf-job-application.m-form {
	hr {
		border: 1px solid #005CA9C7;
		color: #005CA9C7;
		margin: 25px 0;
	}
}


.c-form-field-description {
	display: block;
	height: auto;
	color: $color-haribo-blue;
	@include font-style("copy-a1");
	@include property-breakpoint-map(margin-left, $input-side-padding-map);
	@include property-breakpoint-map(margin-bottom, $form-label-margin-bottom-map);
	letter-spacing: -0.5px;

	@include is-rtl() {
		margin-left: 0;
		@include property-breakpoint-map(margin-right, $input-side-padding-map);
	}
}
