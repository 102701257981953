.m-jump-links__wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	column-gap: 35px;

	@include mq($from: large) {
		column-gap: 100px;
	}

	@include mq($from: xlarge) {
		column-gap: 115px;
	}
}

.m-jump-links[data-count="3"] {
	.m-jump-links__wrapper {
		@include mq($until: large) {
			justify-content: space-between;
		}
	}
}

.m-jump-links__link {
	@include mq($until: large) {
		width: 25%;
		min-width: 75px;
		max-width: 130px;
	}

	@include mq($from: large) {
		width: 120px;
	}

	@include mq($from: xlarge) {
		width: 165px;
	}
}

.m-jump-links__image {
	width: 100%;
}

.m-jump-links__text {
	color: $color-haribo-blue;
	text-align: center;
	margin-top: $spacing-pink;
	@include transition(color);

	@include hover-focus(".m-jump-links__link") {
		color: $color-haribo-red;
	}

	@include mq($until: large) {
		font-size: 0.85rem;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-yellow;
	}
}
