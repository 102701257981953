.m-age-gate {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh; // fallback;
	min-height: 100dvh;
	overflow: hidden;
	background-image: radial-gradient(circle at 71% 96%, #1177CC, $color-haribo-blue 64%);
	position: absolute;
	top: 0;
	left: 0;
	@include z-index(age-gate);

	~ .l-main,
	~ .m-header {
		display: none;
	}

	.l-main & {
		display: none;
	}
}

.m-age-gate__logo-wrapper {
	@include property-breakpoint-map(margin-top, $padding-top-aligned-with-main-navigation);

	@include mq($from: large) {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}
}

.m-age-gate__logo {
	@extend .m-header__logo;
}

.m-age-gate__content-wrapper {
	display: flex;
	flex: 1 1 auto;
	margin-top: 30px;
	margin-bottom: 30px;
}

.m-age-gate__content {
	width: percentage(12/$cols-s);
	color: $color-white;
	margin: 5vh auto auto;

	@include mq($from: medium) {
		width: 100%;
		margin-top: auto;
	}

	@include mq($from: xlarge) {
		width: percentage(21/$cols-xl);
	}
}

.m-age-gate__content:nth-child(2) {
	display: none;
	animation-duration: 0.8s;
}

.m-age-gate__headline {
	@include mq($from: large) {
		text-align: center;
	}
}

.m-age-gate__copy {
	margin-top: $spacing-yellow;

	@include mq($from: large) {
		width: 70%;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}
}

.m-age-gate__buttons {
	display: flex;
	align-items: center;
	margin-top: $spacing-lilac;

	@include mq($from: large) {
		width: fit-content;
		margin-left: auto;
		margin-right: auto;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-purple;
	}
}

.m-age-gate__button-confirm {
	color: $color-haribo-blue;
	margin-left: 15px;

	@include is-rtl() {
		margin-left: 0;
		margin-right: 15px;
	}
}

.m-age-gate__button-dismiss {
	background-color: #689AC8;
	color: $color-white;
}

.m-age-gate__button-confirm,
.m-age-gate__button-dismiss {
	min-width: 100px;
	text-align: center;

	.c-button__label {
		width: 100%;
	}
}

.m-age-gate__bear {
	width: 122px;
	transform: scaleX(-1);

	@media screen and (max-height: 500px) {
		display: none;
	}

	@include mq($from: medium) {
		width: 157px;
	}

	@include mq($from: large) {
		position: absolute;
		bottom: 0;
		left: 0;

		@include is-rtl() {
			left: auto;
			right: 0;
		}
	}

	@include mq($from: xlarge) {
		width: 243px;
	}
}

.m-age-gate.is-dismissed {
	.m-age-gate__content:nth-child(1) {
		display: none;
	}

	.m-age-gate__content:nth-child(2) {
		display: block;
	}
}
