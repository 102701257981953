.m-announcement-block {
	display: flex;
	flex-direction: column;
	min-height: 100vh; // fallback;
	min-height: 100dvh;
}

.m-announcement-block__main {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding-top: 40px;
	padding-bottom: 20px;

	@include mq($from: medium) {
		padding-top: 50px;
		padding-bottom: 30px;
	}
}

.m-announcement-block__main-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;
}

.m-announcement-block__image {
	width: 140px;
	margin-bottom: $spacing-orange;

	@include mq($from: medium) {
		width: 200px;
		margin-bottom: $spacing-royalblue;
	}
}

.m-announcement-block__headline {
	color: $color-haribo-blue;
	text-align: center;

	@include parent-has-background-color() {
		color: $color-white;
	}

	@include mq($from: medium) {
		width: 70%;
	}
}

.m-announcement-block__copy {
	max-width: 650px;
	color: $color-haribo-blue;
	text-align: center;
	margin-top: $spacing-olive;

	@include parent-has-background-color() {
		color: $color-white;
	}

	@include mq($from: medium) {
		width: 70%;
	}
}

.m-announcement-block__links {
	margin-top: $spacing-orange;

	@include mq($from: medium) {
		display: flex;
		align-items: center;
		margin-top: $spacing-purple;
	}
}

.m-announcement-block__link {
	display: block;
	background-color: $color-haribo-blue;
	color: $color-white;
	text-align: center;

	@include parent-has-background-color() {
		background-color: $color-white;
		color: $color-haribo-blue;
	}

	@include mq($from: medium) {
		margin-left: 10px;
		margin-right: 10px;
	}
}

.m-announcement-block__link + .m-announcement-block__link {
	@include mq($until: medium) {
		margin-top: $spacing-maroon;
	}
}

.m-announcement-block__footer {
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: $color-haribo-blue;
	position: relative;

	@include mq($from: medium) {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}

.m-announcement-block__logo {
	width: 173px;
	margin-top: 60px;
	margin-left: auto;
	margin-right: auto;

	@include mq($from: medium) {
		width: 193px;
	}

	@include mq($from: xlarge) {
		width: 240px;
	}
}

.m-announcement-block__copyright {
	color: $color-white;
	text-align: center;

	@include mq($from: medium) {
		text-align: right;
	}
}

[class*="u-color-bg"] + .m-announcement-block__footer {
	background-color: $color-white;

	.m-announcement-block__copyright {
		color: $color-black;
	}
}
