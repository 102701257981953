$box-width-s: 50px;
$box-height-s: 30px;
$needle-width-s: 12px;
$offset-s: 2px;

$box-width-m: 85px;
$box-height-m: 52px;
$needle-width-m: 18px;
$offset-m: 6px;

$box-width-xl: 105px;
$box-height-xl: 64px;
$needle-width-xl: 22px;
$offset-xl: 6px;

.c-map {
	position: relative;
}

.c-map__svg-wrapper {
	circle {
		// opacity: 1;
	}

	svg {
		width: 100%;
		height: 100%;
	}
}

.c-map__count-pin {
	position: absolute;
}

.c-map__count-pin-chrome {
	display: flex;
	align-items: center;
	justify-content: center;
	width: $box-width-s;
	height: $box-height-s;
	background-color: $color-white;
	border-radius: $box-height-s / 2;
	color: $color-haribo-red;
	font-size: 16px;
	font-weight: bold;
	filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.3));
	position: absolute;
	bottom: calc(100% + #{$needle-width-s});
	cursor: pointer;
	@include transition(background-color);

	@include mq($from: medium) {
		width: $box-width-m;
		height: $box-height-m;
		border-radius: $box-height-m / 2;
		font-size: 34px;
		bottom: calc(100% + #{$needle-width-m});
	}

	@include mq($from: xlarge) {
		width: $box-width-xl;
		height: $box-height-xl;
		border-radius: $box-height-xl / 2;
		bottom: calc(100% + #{$needle-width-xl});
	}

	@include hover-focus() {
		background-color: $color-haribo-yellow;
	}

	&::after {
		content: "";
		width: 0;
		height: 0;
		border-style: solid;
		position: absolute;
		top: calc(100% - 1px);
		z-index: 2;
	}
}

.c-map__count-pin--left {
	.c-map__count-pin-chrome {
		left: -(($box-width-s / 2) - ($needle-width-s / 2) - $offset-s);

		@include mq($from: medium) {
			left: -(($box-width-m / 2) - ($needle-width-m / 2) - $offset-m);
		}

		@include mq($from: xlarge) {
			left: -(($box-width-xl / 2) - ($needle-width-xl / 2) - $offset-xl);
		}

		&::after {
			border-width: $needle-width-s $needle-width-s 0 0;
			border-color: $color-white transparent transparent transparent;
			left: calc(50% - (#{$needle-width-s} / 2) - #{$offset-s});
			@include transition(border-color);

			@include mq($from: medium) {
				border-width: $needle-width-m $needle-width-m 0 0;
				left: calc(50% - (#{$needle-width-m} / 2) - #{$offset-m});
			}

			@include mq($from: xlarge) {
				border-width: $needle-width-xl $needle-width-xl 0 0;
				left: calc(50% - (#{$needle-width-xl} / 2) - #{$offset-xl});
			}
		}

		@include hover-focus() {
			&::after {
				border-color: $color-haribo-yellow transparent transparent transparent;
			}
		}
	}
}

.c-map__count-pin--right {
	.c-map__count-pin-chrome {
		left: -(($box-width-s / 2) + ($needle-width-s / 2) + $offset-s);

		@include mq($from: medium) {
			left: -(($box-width-m / 2) + ($needle-width-m / 2) + $offset-m);
		}

		@include mq($from: xlarge) {
			left: -(($box-width-xl / 2) + ($needle-width-xl / 2) + $offset-xl);
		}

		&::after {
			border-width: 0 $needle-width-s $needle-width-s 0;
			border-color: transparent $color-white transparent transparent;
			left: calc(50% - (#{$needle-width-s} / 2) + #{$offset-s});
			@include transition(border-color);

			@include mq($from: medium) {
				border-width: 0 $needle-width-m $needle-width-m 0;
				left: calc(50% - (#{$needle-width-m} / 2) + #{$offset-m});
			}

			@include mq($from: medium) {
				border-width: 0 $needle-width-xl $needle-width-xl 0;
				left: calc(50% - (#{$needle-width-xl} / 2) + #{$offset-xl});
			}
		}

		@include hover-focus() {
			&::after {
				border-color: transparent $color-haribo-yellow transparent transparent;
			}
		}
	}
}
