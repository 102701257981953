.m-rerouting-popup {
	width: 100%;
	background-color: $color-haribo-blue;
	position: fixed;
	top: 0;
	left: 0;
	@include z-index(rerouting-popup);
}

.m-rerouting-popup.is-not-displayed {
	display: none;
}

.m-rerouting-popup__inner {
	padding-top: 20px;
	padding-bottom: 20px;

	@include mq($from: large) {
		display: flex;
		align-items: center;
		padding-top: 40px;
		padding-bottom: 40px;
	}
}

.m-rerouting-popup__controls {
	display: flex;
	margin-top: 10px;

	@include mq($from: large) {
		flex: 0 0 auto;
		margin-top: 0;
		margin-right: 40px;

		@include is-rtl() {
			margin-left: 40px;
			margin-right: 0;
		}
	}
}

.m-rerouting-popup__text {
	padding-right: 40px;
	color: $color-white;
	line-height: 1.3;

	@include is-rtl() {
		padding-left: 40px;
		padding-right: 0;
	}

	@include mq($from: medium) {
		max-width: 500px;
	}

	@include mq($from: large) {
		flex: 1 1 auto;
		margin-right: auto;

		@include is-rtl() {
			margin-left: auto;
			margin-right: 0;
		}
	}
}

.m-rerouting-popup__select {
	flex: 1;

	@include mq($from: medium) {
		flex: 0 1 auto;
	}

	@include mq($from: large) {
		max-width: 300px;
	}

	@include mq($from: xlarge) {
		max-width: 420px;
	}
}

.m-rerouting-popup__button {
	flex: 0 0 auto;
	color: $color-haribo-blue;
	margin-left: 20px;

	@include is-rtl() {
		margin-left: 0;
		margin-right: 20px;
	}
}

.m-rerouting-popup__close {
	background-color: transparent;
	border-radius: 0;
	color: $color-white;
	position: absolute;
	top: 0;
	right: 0;

	@include is-rtl() {
		left: 0;
		right: initial;
	}

	@include mq($from: large) {
		flex: 0 0 auto;
		position: relative;
		top: auto;
		right: auto;
		margin-left: auto;
		margin-right: -12px;

		@include is-rtl() {
			left: 0;
			right: initial;
			margin-left: -12px;
			margin-right: auto;
		}
	}

	&::after {
		display: none;
	}
	@include transition(color);
	@include property-breakpoint-map(width, map-remove($input-height-map, "xlarge"));
	@include property-breakpoint-map(height, map-remove($input-height-map, "xlarge"));

	@include hover-focus() {
		color: $color-haribo-yellow;
	}
}
