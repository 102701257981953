.c-select-input {
	position: relative;
}

.c-select-input__select-wrapper {
	display: inline-block;
	position: relative;
}

.c-select-input__select {
	@include property-breakpoint-map(padding-left, $input-side-padding-map);
	@include property-breakpoint-map(padding-right, $input-side-padding-map, 2);
	cursor: pointer;

	@include is-rtl() {
		@include property-breakpoint-map(padding-right, $input-side-padding-map);
		@include property-breakpoint-map(padding-left, $input-side-padding-map, 2);
	}
}

.c-select-input__arrow {
	width: 16px;
	color: $color-haribo-blue;
	position: absolute;
	bottom: calc(50% - 11px);
	@include property-breakpoint-map(right, $input-side-padding-map);
	margin-right: -1px;
	pointer-events: none;

	@include is-rtl() {
		@include property-breakpoint-map(left, $input-side-padding-map);
		right: auto;
	}
}

.c-select-input--block {
	.c-select-input__select-wrapper,
	.c-select-input__select {
		width: 100%;
	}
}

[data-whatintent="mouse"] .c-select-input__select:hover,
.c-select-input__select:focus {
	border-color: $color-haribo-blue;
	outline: none;
}

// Disabled state
.c-select-input__select[disabled] {
	background-color: rgba($color-haribo-grey-4, 0.5);
	border-color: transparent !important;
	color: rgba($color-haribo-blue, 0.3);
	cursor: default;
	pointer-events: none;

	+ .c-select-input__arrow {
		color: rgba($color-haribo-blue, 0.3);
	}
}

// Variants

.c-select-input--light-bg {
	.c-select-input__select {
		background-color: rgba($color-white, 0.2);
	}

	.c-text-input__input {
		color: $color-white;
	}

	.c-select-input__arrow {
		color: $color-white;
	}

	option {
		color: $color-haribo-blue;
	}
}

[data-whatintent="mouse"] .c-select-input--light-bg .c-select-input__select:hover,
.c-select-input--light-bg .c-select-input__select:focus {
	border-color: $color-haribo-yellow;
}

.c-select-input--full {
	.c-select-input__select-wrapper {
		width: 100%;
	}
}

.c-select-input--revert-xl-breakpoint {
	@include mq($from: xlarge) {
		.c-text-input__input {
			height: map-get($input-height-map, large);
			padding-left: map-get($input-side-padding-map, large);
			padding-right: map-get($input-side-padding-map, large) * 2;
			border-radius: map-get($input-height-map, large) * 0.5;
			font-size: map-get($input-font-sizes, large);

			@include is-rtl() {
				padding-left: map-get($input-side-padding-map, large) * 2;
				padding-right: map-get($input-side-padding-map, large);
			}
		}

		.c-select-input__arrow {
			right: map-get($input-side-padding-map, large);

			@include is-rtl() {
				left: map-get($input-side-padding-map, large);
				right: auto;
			}
		}
	}
}

// Error styling
.c-select-input.has-error .c-select-input__select {
	border-color: $color-haribo-red;
}
