.c-content-box-frame__wrapper {
	@include mq($from: large) {
		display: flex;
		align-items: flex-start;
	}
}

.c-content-box-frame__navigation-wrapper {
	@include mq($until: large) {
		display: none;
	}
}

.c-content-box-frame__content-wrapper {
	direction: ltr;
	overflow: hidden;

	.c-content-shape__svg {
		width: 100%;
		margin-left: 0;
	}
}

.c-content-box-frame__content-wrapper > * {
	@include is-rtl() {
		direction: rtl;
	}
}

.c-content-box-frame__navigation {
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	padding-right: 20px;
	position: relative;

	@include is-rtl() {
		padding-left: 20px;
		padding-right: 0;
	}
}

.c-content-box-frame__navigation-button {
	opacity: 0.3;
	color: $color-black;
	line-height: 1.1;
	position: relative;
	@include transition(opacity);

	@include hover-focus() {
		opacity: 1;
	}
}

.c-content-box-frame__arrow {
	height: 100%;
	opacity: 0;
	position: absolute;
	top: 0;
	right: calc(100% + 15px);
	transform: translateX(-15px);
	pointer-events: none;
	@include transition(opacity 0.5s, transform 0.5s);

	@include is-rtl() {
		left: calc(100% + 15px);
		right: auto;
		transform: scaleX(-1) translateX(-15px);
	}
}

.c-content-box-frame__navigation-icon {
	width: 45px;
	height: 45px;
	opacity: 0;
	color: $color-haribo-yellow;
	position: absolute;
	top: 50%;
	left: calc(100% + 15px);
	transform: translateY(-50%);
	pointer-events: none;
	@include transition(opacity 0.5s);

	@include is-rtl() {
		left: auto;
		right: calc(100% + 15px);
	}

	@include mq($from: xlarge) {
		width: 65px;
		height: 65px;
	}
}

.c-content-box-frame {
	.c-icon-text-link--wave-arrow-right .c-icon-text-link__icon {
		width: 55px;

		@include mq($from: large) {
			width: 65px;
		}

		@include mq($from: xlarge) {
			width: 90px;
		}
	}

	.c-content-box-frame__arrow {
		@include mq($from: large) {
			width: 40px;
		}

		@include mq($from: xlarge) {
			width: 50px;
		}
	}
}

.c-content-box-frame__navigation-button.is-active {
	opacity: 1;

	.c-content-box-frame__arrow {
		opacity: 1;
		transform: translateX(0);

		@include is-rtl() {
			transform: scaleX(-1) translateX(0);
		}
	}

	.c-content-box-frame__navigation-icon {
		opacity: 1;
	}
}

.c-content-box-frame--small-navigation {
	.c-content-box-frame__navigation-wrapper {
		@include mq($from: large) {
			width: percentage(7/$cols-l);
			margin-right: percentage(1/$cols-l);

			@include is-rtl() {
				margin-left: percentage(1/$cols-l);
				margin-right: 0;
			}
		}

		@include mq($from: xlarge) {
			width: percentage(7/$cols-xl);
			margin-right: percentage(1/$cols-xl);

			@include is-rtl() {
				margin-left: percentage(1/$cols-xl);
				margin-right: 0;
			}
		}
	}

	.c-content-box-frame__content-wrapper {
		@include mq($from: large) {
			width: percentage(18/$cols-l);
		}

		@include mq($from: xlarge) {
			width: percentage(18/$cols-xl);
		}
	}

	.c-content-box-frame__navigation {
		@include mq($from: xlarge) {
			margin-left: percentage(1/7);

			@include is-rtl() {
				margin-left: 0;
				margin-right: percentage(1/7);
			}
		}
	}

	.c-content-box-frame__navigation-button {
		&:not(:first-child) {
			@include mq($from: large) {
				margin-top: $spacing-maroon;
			}
		}
	}
}

.c-content-box-frame--big-navigation {
	.c-content-box-frame__navigation-wrapper {
		@include mq($from: large) {
			width: percentage(8/$cols-l);
			margin-right: percentage(2/$cols-l);

			@include is-rtl() {
				margin-left: percentage(2/$cols-l);
				margin-right: 0;
			}
		}

		@include mq($from: xlarge) {
			width: percentage(8/$cols-xl);
			margin-right: percentage(3/$cols-xl);

			@include is-rtl() {
				margin-left: percentage(3/$cols-xl);
				margin-right: 0;
			}
		}
	}

	.c-content-box-frame__content-wrapper {
		@include mq($from: large) {
			width: percentage(16/$cols-l);
		}

		@include mq($from: xlarge) {
			width: percentage(15/$cols-xl);
		}
	}

	.c-content-box-frame__navigation {
		@include mq($from: xlarge) {
			margin-left: percentage(1/8);

			@include is-rtl() {
				margin-left: 0;
				margin-right: percentage(1/8);
			}
		}
	}

	.c-content-box-frame__navigation-button {
		&:not(:first-child) {
			@include mq($from: large) {
				margin-top: 0;
			}
		}
	}

	.c-content-box-frame__arrow {
		@include mq($from: large) {
			width: 55px;
		}

		@include mq($from: xlarge) {
			width: 75px;
		}
	}
}

.c-content-box-frame__pagination {
	margin-top: $spacing-yellow;

	@include mq($from: medium) {
		margin-top: $spacing-royalblue;
	}

	@include mq($from: large) {
		display: none;
	}
}

html.no-js {
	@include mq($from: large) {
		.c-content-box-frame__wrapper {
			flex-wrap: wrap;
		}

		.c-content-box-frame__content-wrapper {
			width: 100%;
		}
	}

	.c-content-box-frame__pagination {
		display: none;
	}

	.c-content-box-frame__arrow {
		display: none;
	}

	.c-content-box-frame__navigation-button {
		opacity: 1;
		color: $color-black;
	}
}
