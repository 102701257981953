$search-top-bar-height: (
	small: 100px,
	medium: 100px,
	large: 285px,
	xlarge: 285px
);

.m-search {
	padding-bottom: $spacing-petrol;
	background-color: $color-haribo-blue;
	@include property-breakpoint-map(padding-top, $search-top-bar-height);
}

.m-search__top-bar {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: $color-haribo-blue;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
}

.m-search__top-bar::after {
	content: "";
	width: 100%;
	height: 40px;
	background-image: linear-gradient(to bottom, #00498B 4%, rgba(0, 88, 161, 0) 99%);
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 2;
	pointer-events: none;

	@include mq($from: medium) {
		height: 70px;
	}

	@include mq($from: xlarge) {
		height: 80px;
	}
}

.m-search__top-bar-wrapper {
	padding-top: $spacing-royalblue;
	padding-bottom: $spacing-orange;
	position: relative;

	@include mq($from: medium) {
		padding-top: $spacing-orange;
		padding-bottom: $spacing-orange;
	}

	@include mq($from: large) {
		padding-top: $spacing-cadetblue;
		padding-bottom: $spacing-lilac;
	}

	@include mq($from: xlarge) {
		padding-top: $spacing-cadetblue;
		padding-bottom: $spacing-orange;
	}
}

.m-search__field {
	display: flex;
	align-items: center;
	width: percentage(10/$cols-s);
	border-bottom: 2px solid #80AED4;
	position: relative;

	@include mq($from: medium) {
		width: percentage(11/$cols-m);
	}

	@include mq($from: large) {
		width: 100%;
	}
}

.m-search__input {
	flex: 1 1 auto;
	width: 100px; // Don't set to 100% because in Firefox the input can't shrink - https://jira.s-v.de/browse/DHW-533 / https://bugzilla.mozilla.org/show_bug.cgi?id=1242914
	padding-top: 0;
	padding-bottom: 0;
	padding-right: 15px;
	outline: none;
	color: $color-white;
	font-size: 30px;

	@include is-rtl() {
		padding-left: 15px;
		padding-right: 0;
	}

	@include mq($from: medium) {
		font-size: 40px;
	}

	@include mq($from: large) {
		font-size: 50px;
	}

	@include mq($from: xlarge) {
		font-size: 70px;
	}
}

// remove native X reset
.m-search__input::-webkit-search-decoration,
.m-search__input::-webkit-search-cancel-button,
.m-search__input::-webkit-search-results-button,
.m-search__input::-webkit-search-results-decoration {
	display: none;
}

.m-search__input::placeholder {
	@include transition(color);
	color: #80AED4;
}

.m-search__search-button,
.m-search__reset-button {
	display: flex;
	width: 25px;
	height: 25px;
	color: $color-white;
	@include transition(opacity, color);

	@include hover-focus() {
		color: $color-haribo-yellow;
	}

	@include mq($from: medium) {
		width: 35px;
		height: 35px;
	}

	@include mq($from: xlarge) {
		width: 50px;
		height: 50px;
	}

	svg {
		width: 100%;
		height: 100%;
	}
}

.m-search__search-button {
	opacity: 0.5;
}

.m-search__input:focus {
	~ .m-search__search-button {
		opacity: 1;
	}
}

.m-search__select-filter {
	margin-top: $spacing-orange;

	@include mq($from: medium) {
		margin-top: $spacing-purple;
	}

	@include mq($from: large) {
		display: none;
	}
}

.m-search__suggestions {
	width: 100%;
	max-height: calc(100vh - #{map-get($search-top-bar-height, small) + 40px});
	padding: $spacing-orange 25px $spacing-lilac;
	background-color: $color-haribo-dark-blue;
	border-radius: 0 0 26px 26px;
	color: $color-white;
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 3;

	@include is-rtl() {
		left: auto;
		right: 0;
	}

	@include mq($from: medium) {
		width: percentage(11/$cols-m);
		padding-left: 30px;
		padding-right: 30px;
	}

	@include mq($from: large) {
		width: percentage(21/$cols-l);
	}

	@include mq($from: xlarge) {
		width: 100%;
		padding: $color-haribo-yellow 40px $spacing-orange;
	}
}

.m-search__suggestion + .m-search__suggestion {
	margin-top: $spacing-pink;
}

.m-search__suggestion-button {
	width: 100%;
	color: $color-white;
	font-weight: initial;
	text-align: left;
	@include transition(color);

	@include is-rtl() {
		text-align: right;
	}

	/* stylelint-disable selector-max-type */
	em {
		font-style: initial;
	}
	/* stylelint-enable selector-max-type */

	&.highlighted {
		color: $color-haribo-yellow;
	}

	@include hover-focus() {
		color: $color-haribo-yellow;
	}
}

.m-search__best-spelling-suggestion-button {
	color: $color-white;
	@include transition(color);

	@include hover-focus() {
		color: $color-haribo-yellow;
	}
}

.m-search__content-wrapper {
	position: relative;
	z-index: 1;
	margin-top: $spacing-purple;
	@include mq($from: medium) {
		margin-top: $spacing-petrol;
	}

	@include mq($from: large) {
		display: flex;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-softblue;
	}
}

.m-search__no-results-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	color: $color-haribo-dark-blue;
	position: absolute;
	top: 0;
	margin: 0;
}

.m-search__no-results {
	text-align: center;
}

.m-search__filter-column {
	display: none;

	@include mq($from: large) {
		display: block;
		flex: 0 0 auto;
		width: percentage(6/$cols-l);
		margin-right: percentage(1/$cols-l);

		@include is-rtl() {
			margin-left: percentage(1/$cols-l);
			margin-right: 0;
		}
	}

	@include mq($from: xlarge) {
		width: percentage(6/$cols-xl);
		margin-left: percentage(1/$cols-xl);
		margin-right: percentage(1/$cols-xl);

		@include is-rtl() {
			margin-left: percentage(1/$cols-xl);
			margin-right: percentage(1/$cols-xl);
		}
	}
}

.m-search__filter-headline {
	color: $color-white;
}

.m-search__filter-list {
	display: none;

	@include mq($from: large) {
		display: block;
		margin-top: $spacing-lilac;
	}

	@include mq($until: xlarge) {
		margin-top: $spacing-lilac;
	}
}

.m-search__filter-button {
	display: flex;
	align-items: center;
	outline: none;
	opacity: 0.3;
	position: relative;
	@include transition(opacity);

	&.is-active {
		opacity: 1;

		.m-search__filter-arrow {
			opacity: 1;
			transform: translateY(-50%) translateX(0);

			@include is-rtl() {
				transform: scaleX(-1) translateY(-50%) translateX(0);
			}
		}
	}

	&.is-disabled {
		opacity: 0.3;
		pointer-events: none;
	}

	@include hover-focus() {
		opacity: 1;
	}
}

.m-search__filter-button + .m-search__filter-button {
	margin-top: $spacing-olive;
}

.m-search__filter-arrow {
	width: 35px;
	opacity: 0;
	color: $color-haribo-yellow;
	position: absolute;
	top: 50%;
	right: calc(100% + 12px);
	transform: translateY(-50%) translateX(-12px);
	pointer-events: none;
	@include transition(opacity 0.4s, transform 0.4s);

	@include is-rtl() {
		left: calc(100% + 12px);
		right: auto;
		transform: scaleX(-1) translateY(-50%) translateX(-12px);
	}

	@include mq($from: xlarge) {
		width: 45px;
	}
}

.m-search__filter-text {
	color: $color-white;
	@include transition(opacity);
}

.m-search__filter-count {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 33px;
	min-height: 33px;
	background-color: $color-haribo-dark-blue;
	border-radius: 50%;
	color: $color-white;
	font-size: 14px;
	margin-left: 10px;
	@include transition(opacity);

	@include is-rtl() {
		margin-left: 0;
		margin-right: 10px;
	}

	@include mq($from: xlarge) {
		min-width: 40px;
		min-height: 40px;
		font-size: 18px;
	}
}

.m-search__results {
	width: 100%;
}

.m-search__result {
	@include mq($from: medium) {
		display: flex;
	}
}

.m-search__result + .m-search__result {
	padding-top: $spacing-royalblue;
	border-top: 2px solid rgba($color-white, 0.3);
	margin-top: $spacing-purple;

	@include mq($from: large) {
		padding-top: $spacing-lilac;
		margin-top: $spacing-royalblue;
	}

	@include mq($from: xlarge) {
		padding-top: $spacing-royalblue;
		margin-top: $spacing-purple;
	}
}

.m-search__result-image-wrapper {
	width: percentage(4/$cols-s);

	@include mq($from: medium) {
		flex: 0 0 auto;
		width: percentage(3/$cols-m);
		margin-right: percentage(1/$cols-m);

		@include is-rtl() {
			margin-left: percentage(1/$cols-m);
			margin-right: 0;
		}
	}

	@include mq($from: large) {
		width: percentage(3/19);
		margin-right: percentage(1/19);

		@include is-rtl() {
			margin-left: percentage(1/19);
			margin-right: 0;
		}
	}

	@include mq($from: large) {
		width: percentage(2/18);
		margin-right: percentage(1/18);

		@include is-rtl() {
			margin-left: percentage(1/18);
			margin-right: 0;
		}
	}
}

.m-search__result-image {
	width: 100%;
	margin-bottom: $spacing-yellow;

	@include mq($from: medium) {
		margin-bottom: 0;
	}
}

.m-search__result-content-wrapper {
	color: $color-white;
}

.m-search__result-link {
	color: $color-white;
	@include transition(color);

	@include hover-focus() {
		color: $color-haribo-yellow;
	}
}

.m-search__result-copy {
	margin-top: $spacing-olive;

	@include mq($from: xlarge) {
		margin-top: $spacing-pink;
	}
}

.m-search__downloads-wrapper {
	margin-top: $spacing-lilac;

	@include mq($from: medium) {
		margin-top: $spacing-orange;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-orange;
	}
}

.m-search__downloads {
	margin-top: $spacing-olive;
}

.m-search__download {
	&:not(:last-child) {
		margin-right: 10px;
	}
}

.m-search__breadcrumbs {
	display: flex;
	align-items: center;
	margin-top: $spacing-royalblue;

	@include mq($from: medium) {
		margin-top: $spacing-orange;
	}

	@include mq($from: large) {
		margin-top: $spacing-lilac;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-royalblue;
	}

	svg {
		margin-right: 10px;

		@include is-rtl() {
			margin-left: 10px;
			margin-right: 0;
			transform: scaleX(-1);
		}
	}
}

.m-search__breadcrumb {
	font-size: 12px;

	span {
		padding-left: 5px;
		padding-right: 5px;
	}
}

.m-search__breadcrumb-link {
	color: $color-white;
	@include transition(color);

	@include hover-focus() {
		color: $color-haribo-yellow;
	}
}

.m-search__result-bc-divider {
	padding-left: 5px;
	padding-right: 5px;
}

// states

.m-search__more-button-wrapper {
	padding-top: $spacing-petrol;
	border-top: 2px solid rgba($color-white, 0.3);
	margin-top: $spacing-purple;

	@include mq($from: medium) {
		padding-top: $spacing-lightblue;
	}

	@include mq($from: large) {
		margin-top: $spacing-royalblue;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-purple;
	}
}

.m-search__more-button {
	color: $color-white;
}

.m-search__more-result-text {
	opacity: 0.3;
	color: $color-white;
}

.m-search__more-arrow {
	&::after {
		background-color: $color-white;
	}

	svg {
		color: $color-haribo-red;
	}
}

.m-search.has-results {
	@include mq($until: medium) {
		padding-top: 165px;
	}

	@include mq($from: medium, $until: large) {
		padding-top: 195px;
	}

	.m-search__top-bar-wrapper {
		@include mq($until: medium) {
			padding-bottom: $spacing-yellow;
		}

		@include mq($from: medium, $until: large) {
			padding-bottom: $spacing-royalblue;
		}
	}
}
