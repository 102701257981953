$surprise-teaser-font-column-map: (
	1: headline-h,
	2: headline-f1,
	3: headline-f1,
	4: headline-e1,
	5: headline-e1
);

@mixin surprise-teaser-fill-grid($cols: 4) {
	@for $index from 1 through $cols {
		&:last-child:nth-child(#{$cols}n - #{($cols - $index)}) {
			grid-column: span ($cols - $index + 1);

			// if the teaser has a row on its own the aspect has to be given, because there is no grid stretch behavior
			@if $index == 1 {
				padding-top: 100% / $cols;
			}

			// if the teaser has just a single cell, move the arrow under text
			@if $index == $cols {
				.m-inspiration-machine__surprise-teaser-link {
					flex-direction: column;
					align-items: flex-start;

					.c-icon-text-link__icon {
						order: 2;
						margin-top: $spacing-yellow;
					}
				}
			}

			.m-inspiration-machine__surprise-teaser-link {
				@include font-style(map-get($surprise-teaser-font-column-map, ($cols - $index + 1)));
			}
		}
	}
}

.m-inspiration-machine__surprise-teaser {
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: radial-gradient(circle at 90% 80%, var(--gradient-color) 0.2%, var(--background-color) 100%);
	color: $color-white;
	position: relative;

	@include mq($until: medium) {
		@include surprise-teaser-fill-grid(2);
	}

	@include mq($from: medium, $until: large) {
		@include surprise-teaser-fill-grid(3);
	}

	@include mq($from: large, $until: xlarge) {
		@include surprise-teaser-fill-grid(4);
	}

	@include mq($from: xlarge) {
		@include surprise-teaser-fill-grid(5);
	}
}

.m-inspiration-machine__surprise-teaser-inner {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: $spacing-yellow;

	@include mq($from: large) {
		padding: $spacing-lilac;
	}
}

.m-inspiration-machine__surprise-teaser-link {
	outline: none;
	position: static;

	&::before {
		content: "";
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

[data-whatinput="keyboard"] .m-inspiration-machine__surprise-teaser:focus-within {
	@include keyboard-focus(-3px);
}

// ------------------------------------------------------------------------------------------- error teaser

.m-inspiration-machine__error-teaser {
	padding-top: $spacing-lightblue;
	padding-bottom: $spacing-lightblue;
	overflow: hidden;
	background-image: radial-gradient(circle at 90% 80%, var(--gradient-color) 0.2%, var(--background-color) 100%);
	color: $color-white;
	position: relative;

	@include mq($from: medium) {
		padding-top: $spacing-petrol;
		padding-bottom: $spacing-petrol;
	}

	@include mq($from: large) {
		padding-top: $spacing-grey;
		padding-bottom: $spacing-grey;
	}
}

.m-inspiration-machine__error-wrapper {
	@include mq($from: medium) {
		display: flex;
		align-items: center;
	}
}

.m-inspiration-machine__error-teaser-text {
	width: percentage(12/$cols-s);
	margin-left: percentage(1/$cols-s);

	@include mq($from: medium) {
		flex: 0 0 auto;
		width: percentage(10/$cols-m);
		margin-left: percentage(1/$cols-m);
	}

	@include mq($from: large) {
		width: percentage(17/$cols-l);
		margin-left: 0;
	}

	@include mq($from: xlarge) {
		width: percentage(17/$cols-xl);
	}
}

.m-inspiration-machine__error-teaser-headline {
	color: $color-haribo-yellow;
}

.m-inspiration-machine__error-teaser-link {
	outline: none;
	position: static;
	margin-top: $spacing-orange;

	@include mq($from: medium) {
		margin-top: $spacing-lilac;
	}

	@include mq($from: large) {
		margin-top: $spacing-purple;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-petrol;
	}

	&::before {
		content: "";
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

[data-whatinput="keyboard"] .m-inspiration-machine__error-teaser:focus-within {
	@include keyboard-focus(-3px);
}

.m-inspiration-machine__error-teaser-bear {
	@include mq($until: medium) {
		display: none;
	}

	@include mq($from: medium) {
		flex: 0 0 auto;
		width: 160px;
		margin-left: 15px;
		transform: scaleX(-1);
	}

	@include mq($from: large) {
		width: 200px;
		margin-left: 45px;
	}

	@include mq($from: xlarge) {
		width: 240px;
		margin-left: 65px;
	}
}
