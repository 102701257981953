.m-teaser-list-c,
.m-teaser-list-a {
	.c-media-slider__pagination {
		height: 22px;
		margin-left: auto;

		@include is-rtl() {
			margin-left: 0;
			margin-right: auto;
		}

		@include mq($from: medium) {
			height: 20px;
		}
	}

	.c-media-slider__button-prev,
	.c-media-slider__button-next,
	.c-media-slider__pagination-item {
		z-index: 2;
	}

	.c-media-slider__button-prev,
	.c-media-slider__button-next {
		@include mq($from: large, $until: xlarge) {
			width: 30px;
			height: 30px;
		}

		@include mq($from: xlarge) {
			width: 40px;
			height: 40px;
		}
	}

	.c-media-slider__aspect-ratio-overlay-inner {
		@include mq($from: medium) {
			padding: 10px;
		}

		@include mq($from: xlarge) {
			.m-teaser-list-c__teasers--1-col & {
				padding: 15px;
			}
		}
	}
}

.m-teaser-list-c__headline {
	margin-bottom: $spacing-yellow;

	@include mq($from: medium) {
		margin-bottom: $spacing-lightblue;
	}

	@include mq($from: xlarge) {
		margin-bottom: $spacing-softblue;
	}
}

.m-teaser-list-c__teasers {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;
}

.m-teaser-list-c__colcade-col {
	@include mq($until: large) {
		display: none;
	}
}

.m-teaser-list-c__teaser {
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;

	@include mq($until: medium) {
		width: percentage(12/$cols-m);
		margin-left: auto;
		margin-right: auto;

		&:not(:last-child) {
			margin-bottom: $spacing-lightpink;
		}

		.m-teaser-list-c__teaser-headline {
			margin-top: $spacing-pink;
		}
	}
}

.m-teaser-list-c__teasers--1-col {
	display: block;

	.m-teaser-list-c__teaser {
		@include mq($from: medium) {
			width: 100%;
		}

		@include mq($from: large) {
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
	}

	.m-teaser-list-c__teaser-text {
		@include mq($from: large) {
			width: percentage(12/$cols-l);
		}

		@include mq($from: xlarge) {
			width: percentage(13/$cols-xl);
		}
	}

	.m-teaser-list-c__teaser-images {
		@include mq($from: large) {
			width: percentage(13/$cols-l);
		}

		@include mq($from: xlarge) {
			width: percentage(12/$cols-xl);
		}
	}

	.m-teaser-list-c__teaser--mirrored {
		.m-teaser-list-c__teaser-images {
			@include mq($from: large) {
				order: 1;
			}
		}
	}

	.m-teaser-list-c__teaser-headline {
		@include mq($from: medium, $until: large) {
			margin-top: $spacing-yellow;
		}
	}

	.m-teaser-list-c__teaser-download {
		@include mq($from: large, $until: xlarge) {
			margin-top: 0;
		}
	}
}

.m-teaser-list-c__teasers--2-cols,
.m-teaser-list-c__teasers--3-cols,
.m-teaser-list-a__teasers--3-cols {
	@include mq($until: large) {
		display: block;
	}

	> .m-teaser-list-c__teaser {
		@include mq($from: medium, $until: large) {
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;

			&:nth-child(odd) {
				.m-teaser-list-c__teaser-images {
					order: 1;
				}
			}

			&:not(:first-of-type) {
				margin-top: $spacing-brown;
			}
		}
	}

	.m-teaser-list-c__teaser-text {
		@include mq($from: medium, $until: large) {
			width: percentage(6/$cols-m);
		}
	}

	.m-teaser-list-c__teaser-headline {
		@include mq($from: large) {
			margin-top: $spacing-yellow;
		}

		@include mq($from: xlarge) {
			margin-top: $spacing-orange;
		}
	}

	.m-teaser-list-c__teaser-copy {
		@include mq($from: medium) {
			margin-top: $spacing-olive;
		}
	}

	.m-teaser-list-c__teaser-images {
		@include mq($from: medium, $until: large) {
			width: percentage(7/$cols-m);
		}
	}
}

.m-teaser-list-c__teasers--2-cols {
	> .m-teaser-list-c__teaser {
		@include mq($from: large) {
			width: percentage(12/$cols-l);
		}

		&:first-child {
			@include mq($from: large) {
				margin-top: $spacing-lightblue;
			}
		}
	}
}

.m-teaser-list-c__teasers--3-cols,
.m-teaser-list-a__teasers--3-cols {
	// As long colcade is not initialized and the teasers a direct child, give them some margin
	// n+7 is starting with the 4th teasers (because of the empty columns)
	> .m-teaser-list-c__teaser:nth-child(n+7) {
		@include mq($from: large) {
			margin-top: $spacing-brown;
		}
	}

	> .l-colcade__col {
		&:first-child .m-teaser-list-c__teaser:first-child {
			@include mq($from: large) {
				margin-top: $spacing-lightblue;
			}
		}

		&:nth-child(2) .m-teaser-list-c__teaser:first-child {
			@include mq($from: large) {
				margin-top: $spacing-lightblue + $spacing-lilac;
			}

			@include mq($from: xlarge) {
				margin-top: 2 * $spacing-lightblue;
			}
		}

		> .m-teaser-list-c__teaser:not(:last-child) {
			@include mq($from: large) {
				margin-bottom: $spacing-brown;
			}
		}
	}

	> .m-teaser-list-c__teaser,
	> .m-teaser-list-c__colcade-col {
		@include mq($from: large) {
			width: percentage(8/$cols-l);
		}
	}

	> .m-teaser-list-c__teaser {
		// first teaser, but fourth item because of the colcade column divs
		&:nth-child(4) {
			@include mq($from: large) {
				margin-top: $spacing-lightblue;
			}
		}

		// second teaser, but fifth item because of the colcade column divs
		&:nth-child(5) {
			@include mq($from: large) {
				margin-top: $spacing-lightblue + $spacing-lilac;
			}

			@include mq($from: xlarge) {
				margin-top: 2 * $spacing-lightblue;
			}
		}

		.m-teaser-list-c__teaser-headline {
			@include mq($from: large, $until: xlarge) {
				margin-top: $spacing-pink;
			}
		}
	}
}

.m-teaser-list-c__teaser-headline,
.m-teaser-list-c__teaser-subline {
	color: $color-haribo-blue;
}

.m-teaser-list-c__teaser-headline {
	@include mq($until: medium) {
		margin-top: $spacing-yellow;
	}
}

.m-teaser-list-c__teaser-subline {
	display: block;
	margin-top: $spacing-smokeblue-light;

	@include mq($from: large) {
		margin-top: $spacing-olive;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-pink;
	}
}

.m-teaser-list-c__teaser-images {
	order: -1;
	overflow: hidden;
	position: relative;
}

.m-teaser-list-c__teaser-downloads {
	display: flex;
	flex-wrap: wrap;
	margin-top: $spacing-orange;
	margin-bottom: -$spacing-pink;

	@include mq($from: large, $until: xlarge) {
		margin-top: $spacing-lightgreen;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-ocher;
	}
}

.m-teaser-list-c__teaser-download {
	position: relative;
	margin-bottom: $spacing-pink;

	&:not(:last-child) {
		margin-right: 10px;

		@include is-rtl() {
			margin-left: 10px;
			margin-right: 0;
		}

		@include mq($from: xlarge) {
			margin-right: 15px;

			@include is-rtl() {
				margin-left: 15px;
				margin-right: 0;
			}
		}
	}

	.c-button__icon {
		order: -1;
		width: 10px;
		margin-left: 0;
		margin-right: 10px;

		@include is-rtl() {
			margin-left: 10px;
			margin-right: 0;
		}

		@include mq($from: xlarge) {
			width: 13px;
		}
	}
}

.m-teaser-list-c__teaser-tag {
	display: inline-flex;
	height: 22px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: $color-haribo-yellow;
	border-radius: 22px;
	color: $color-haribo-blue;
	line-height: 23px;
	position: absolute;
	bottom: 10px;
	left: 10px;
	z-index: 1;

	@include is-rtl() {
		left: auto;
		right: 10px;
	}

	@include mq($from: medium) {
		height: 20px;
		border-radius: 20px;
		line-height: 21px;
	}

	@include mq($from: xlarge) {
		height: 25px;
		line-height: 26px;
	}
}

.m-teaser-list-c__video-preview-button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	margin: auto;

	.c-shaped-icon-button__inner {
		width: 40px;
		height: 40px;

		@include mq($from: xlarge) {
			width: 55px;
			height: 55px;
		}
	}
}

.m-teaser-list-c .c-filter-panel {
	margin-top: $spacing-softblue;
}

.m-teaser-list-c.m-teaser-list-c--horizontal-align {
	article.m-teaser-list-c__teaser {
		margin-top: 0 !important;
		margin-bottom: 90px !important;
	}

	.m-teaser-list-c__teasers {
		justify-content: flex-start;
		gap: 3%;
	}
}

