.m-facts {
	display: flex;

	@include mq($until: large) {
		flex-direction: column;
	}

	.l-wrapper {
		@include mq($from: large) {
			width: 100%;
			max-width: none;
			margin-left: 0;
			margin-right: 0;
		}
	}
}

.m-facts--mirrored {
	.m-facts__media {
		order: 2;
	}
}

.m-facts__text {
	--background-color: #{$color-haribo-blue};
	--gradient-color: #{lighten($color-haribo-blue, $lighten-amount)};
	background-color: $color-haribo-blue;
	background-image: radial-gradient(circle at 71% 96%, var(--gradient-color), var(--background-color) 63%);
	color: $color-white;

	@include mq($until: medium) {
		padding-top: $spacing-purple;
		padding-bottom: $spacing-ocher;
	}

	@include mq($from: medium, $until: large) {
		padding-top: $spacing-lightblue;
		padding-bottom: $spacing-petrol + $spacing-pink;
	}

	@include mq($from: large) {
		display: flex;
		align-items: center;
		width: 50%;
	}
}

.m-facts__text-inner {
	@include mq($until: large) {
		width: percentage(12/$cols-s);
		margin-left: percentage(1/$cols-s);

		@include is-rtl() {
			margin-left: 0;
			margin-right: percentage(1/$cols-s);
		}
	}

	@include mq($from: large) {
		margin-left: 12%;
		margin-right: 23%;

		@include is-rtl() {
			margin-left: 23%;
			margin-right: 12%;
		}

		.m-facts--mirrored & {
			margin-left: 23%;
			margin-right: 12%;

			@include is-rtl() {
				margin-left: 12%;
				margin-right: 23%;
			}
		}
	}

	@include mq($from: xlarge) {
		margin-left: 12%;
		margin-right: 19%;

		@include is-rtl() {
			margin-left: 19%;
			margin-right: 12%;
		}

		.m-facts--mirrored & {
			margin-left: 19%;
			margin-right: 12%;

			@include is-rtl() {
				margin-left: 12%;
				margin-right: 19%;
			}
		}
	}

	@include mq($from: $module-max-width) {
		margin-left: 13%;
		margin-right: 17%;

		@include is-rtl() {
			margin-left: 17%;
			margin-right: 13%;
		}

		.m-facts--mirrored & {
			margin-left: 17%;
			margin-right: 13%;

			@include is-rtl() {
				margin-left: 13%;
				margin-right: 17%;
			}
		}
	}
}

.m-facts__headline {
	color: $color-haribo-yellow;
}

.m-facts__media {
	order: -1;

	@include mq($until: large) {
		width: 100%;
	}

	@include mq($from: large) {
		width: 50%;
	}

	&.c-video-component {
		@include aspect-ratio-helper("4x3");
	}
}

.m-facts__copy {
	margin-top: $spacing-yellow;

	@include mq($from: medium) {
		margin-top: $spacing-orange;
	}

	@include mq($from: large) {
		margin-top: $spacing-yellow;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-purple;
	}
}

.m-facts__link {
	margin-top: $spacing-lilac;

	@include mq($from: medium) {
		margin-top: $spacing-yellow;
	}

	@include mq($from: large) {
		margin-top: $spacing-orange;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-purple;
	}
}

.m-facts__text.u-color-bg-maoam {
	color: $color-maoam-blue;

	.m-facts__headline {
		color: $color-maoam-blue;
	}

	.c-icon-text-link__icon {
		fill: $color-maoam-orange;
	}
}
