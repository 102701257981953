.m-contact {
	@include has-background-color() {
		padding-top: $spacing-purple;
		padding-bottom: $spacing-purple;

		@include mq($from: medium) {
			padding-top: $spacing-petrol;
			padding-bottom: $spacing-petrol;
		}

		@include mq($from: xlarge) {
			padding-top: $spacing-softblue;
			padding-bottom: $spacing-softblue;
		}
	}
}

.m-contact__wrapper {
	display: flex;

	@include mq($until: large) {
		flex-direction: column;
	}

	@include mq($from: large) {
		align-items: center;
	}
}

.m-contact__content {
	order: 2;
	width: 100%;
	margin-top: $spacing-orange;

	@include mq($from: medium ) {
		margin-top: $spacing-royalblue;
	}

	@include mq($from: large) {
		margin-top: 0;
	}
}

.m-contact__headline,
.m-contact__link {
	margin-left: percentage(0.5/$cols-s);

	@include is-rtl() {
		margin-left: 0;
		margin-right: percentage(0.5/$cols-s);
	}

	@include mq($from: medium) {
		margin-left: percentage(0.5/$cols-m);

		@include is-rtl() {
			margin-left: 0;
			margin-right: percentage(0.5/$cols-m);
		}
	}

	@include mq($from: large) {
		margin-left: 0;

		@include is-rtl() {
			margin-right: 0;
		}
	}
}

.m-contact__name,
.m-contact__subline,
.m-contact__options {
	margin-left: percentage(1/$cols-s);

	@include is-rtl() {
		margin-left: 0;
		margin-right: percentage(1/$cols-s);
	}

	@include mq($from: medium) {
		margin-left: percentage(1/$cols-m);

		@include is-rtl() {
			margin-left: 0;
			margin-right: percentage(1/$cols-m);
		}
	}

	@include mq($from: large) {
		margin-left: percentage(1/13);

		@include is-rtl() {
			margin-left: 0;
			margin-right: percentage(1/13);
		}
	}
}

.m-contact__headline {
	color: $color-haribo-blue;
}

.m-contact__name {
	color: $color-haribo-red;
	margin-top: $spacing-yellow;

	@include mq($from: medium) {
		margin-top: $spacing-lilac;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-lightblue;
	}
}

.m-contact__subline {
	color: $color-haribo-blue;

	@include mq($from: xlarge) {
		margin-top: $spacing-olive;
	}
}

.m-contact__options {
	margin-top: $spacing-pink;

	@include mq($from: medium) {
		margin-top: $spacing-yellow;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-pink;
	}
}

.m-contact__email,
.m-contact__telephone {
	display: block;
	width: fit-content;
	color: $color-haribo-blue;
	line-height: 1.6;

	&:hover {
		text-decoration: underline;
	}
}

.m-contact__link {
	margin-top: $spacing-royalblue;

	@include mq($from: large) {
		margin-top: $spacing-purple;
	}
}

.m-contact__image {
	width: 100%;
	@include svg-mask(f);

	@include mq($until: large) {
		max-width: 400px;
	}

	@include mq($from: large) {
		flex: 0 0 auto;
		width: percentage(12/$cols-l);
		margin-right: percentage(1/$cols-l);
		transform: scaleX(-1);

		@include is-rtl() {
			margin-left: percentage(1/$cols-l);
			margin-right: 0;
			transform: scaleX(1);
		}

		img {
			transform: scaleX(-1);

			@include is-rtl() {
				transform: scaleX(1);
			}
		}
	}

	@include mq($from: xlarge) {
		width: percentage(12/$cols-xl);
		margin-right: percentage(1/$cols-xl);

		@include is-rtl() {
			margin-left: percentage(1/$cols-xl);
			margin-right: 0;
		}
	}
}

// colors

/* stylelint-disable-next-line no-duplicate-selectors */
.m-contact {
	@include has-background-color() {
		background-image: radial-gradient(circle at 71% 96%, var(--gradient-color), var(--background-color) 63%);

		.m-contact__content {
			color: $color-white;
		}

		.m-contact__headline,
		.m-contact__email,
		.m-contact__telephone,
		.m-contact__subline {
			color: $color-white;
		}

		.m-contact__name {
			color: $color-haribo-yellow;
		}

		.c-icon-text-link__icon {
			fill: $color-haribo-yellow;
		}

		&.m-contact--no-bg-gradient {
			background-image: none;
		}
	}
}

// variant

.m-contact--mirrored {
	.m-contact__content {
		@include mq($from: large) {
			order: -1;
		}
	}

	.m-contact__image {
		@include mq($from: large) {
			margin-left: percentage(1/$cols-l);
			margin-right: 0;
			transform: none;

			@include is-rtl() {
				margin-left: 0;
				margin-right: percentage(1/$cols-l);
				transform: scaleX(-1);
			}

			img {
				transform: none;

				@include is-rtl() {
					transform: scaleX(-1);
				}
			}
		}

		@include mq($from: xlarge) {
			margin-left: percentage(1/$cols-xl);

			@include is-rtl() {
				margin-left: 0;
				margin-right: percentage(1/$cols-xl);
			}
		}
	}
}
