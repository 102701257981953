$guitar-animation-duration: 0.6s;

/**
 * the end state for the guitar for the game is defined here, the asset should fit so we do not have to use rotation
 * and the buttons are placed in the correct position, except for small and medium bp
 */
.m-wacken__guitar {
	width: percentage(16/$cols-m);
	position: absolute;
	bottom: $crop-helper-offset;
	left: 0;
	z-index: 5;
	pointer-events: none;
	@include transition(transform $guitar-animation-duration);

	@include mq($from: medium) {
		width: percentage(16/$cols-m);
		bottom: $crop-helper-offset;
		left: 0;
	}

	@include mq($from: large) {
		width: percentage(26/$cols-l);
		bottom: 15%;
		left: -(percentage(3/$cols-l));
	}

	@include mq($from: xlarge) {
		width: percentage(26/$cols-xl);
		// max-width: 1400px;
		bottom: 15%;
		left: -(percentage(2/$cols-xl));
	}
}

.m-wacken__guitar-image {
	width: 100%;
	position: relative;
	z-index: 2;
	pointer-events: none;
}

.m-wacken__guitar-glow-image {
	width: 80%;
	position: absolute;
	bottom: -30%;
	left: -10%;
	z-index: 1;
	pointer-events: none;
	animation: wacken-rotating 30s linear infinite;
}

/**
 * place the individual buttons only with the coordinates of the wrapper, place it along the shaft of the guitar
 * let flexbox do the distribution of the individual buttons
 */
.m-wacken__guitar-buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 44%;
	height: 30%;
	// border: 1px solid red; // use the red border for positioning
	position: absolute;
	top: 16%;
	left: 44%;
	z-index: 4;
	transform: rotate(-22deg);

	.is-playing & {
		pointer-events: all;
	}
}

.m-wacken__guitar-button-outer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 auto;
	width: 18%;
	min-width: 30px;
	// max-width: 90px;
	height: auto;
	position: relative;
	z-index: 1;
	aspect-ratio: 1; // stylelint-disable-line property-no-unknown
	@include transition(opacity, transform);
}

.m-wacken__guitar-button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: #A0750D;
	border: none;
	outline: none;
	transform: rotate(22deg);
	@include transition(background-color, transform);
}

.m-wacken__guitar-button-inner {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 78%;
	height: 78%;
	background-color: $color-white;
	color: $color-black;
	font-weight: bold;
	@include transition(background-color, color);

	span {
		opacity: 0;
		@include transition(opacity);
	}
}

.m-wacken__button-glow-image-wrapper {
	width: 260%;
	height: 260%;
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 2;
	transform: translate(-50%, -50%);
	pointer-events: none;
}

.m-wacken__button-glow-image {
	display: block;
	width: 100%;
	height: 100%;
	animation: wacken-rotating 12s linear infinite;
}

.m-wacken__guitar-countdown-circle {
	width: 50%;
	height: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 4;
	transform: translate(-50%, -50%);
	animation-name: wacken-count-circle;
	animation-duration: var(--duration-count-circle);
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-direction: alternate;

	/* calculate using: (2 * PI * R) */
	stroke-dasharray: 227;
	stroke-dashoffset: 0;
}

.m-wacken__guitar-countdown-circle-bg {
	width: 50%;
	height: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 3;
	transform: translate(-50%, -50%);
}

// top & bottom alignment

.m-wacken__guitar-button-outer:nth-child(odd) {
	margin-top: auto;
}

.m-wacken__guitar-button-outer:nth-child(even) {
	margin-bottom: auto;
}

// wobbly masks

.m-wacken__guitar-button:nth-child(1) {
	@include svg-mask(h);

	.m-wacken__guitar-button-inner {
		@include svg-mask(k);
	}
}

.m-wacken__guitar-button:nth-child(2) {
	@include svg-mask(i);

	.m-wacken__guitar-button-inner {
		@include svg-mask(h);
	}
}

.m-wacken__guitar-button:nth-child(3) {
	@include svg-mask(j);

	.m-wacken__guitar-button-inner {
		@include svg-mask(i);
	}
}

.m-wacken__guitar-button:nth-child(4) {
	@include svg-mask(k);

	.m-wacken__guitar-button-inner {
		@include svg-mask(j);
	}
}

// --------------------------------------- step states

[data-active-step="intro"] {
	// guitar start position
	.m-wacken__guitar {
		transform: scale(1) translate(-5%, -39%) rotate(-25deg);

		@include mq($from: medium) {
			transform: scale(1) translate(-5%, -22%) rotate(-12deg);
		}

		@include mq($from: large) {
			transform: scale(0.8) translate(-8%, -2%) rotate(-25deg);
		}

		@include mq($from: xlarge) {
			transform: scale(0.7) translate(-18%, 3%) rotate(-22deg);
		}
	}

	// hidden start state for buttons, so we can stagger them later when we go explaination
	.m-wacken__guitar-button-outer {
		opacity: 0;
		transform: scale(0.6);
	}
}

.m-wacken:not([data-active-step="intro"]) {
	// guitar active position NOT intro
	.m-wacken__guitar {
		transform: scale(1.2) translate(-2%, -43%) rotate(-28deg);

		@include mq($from: medium) {
			transform: scale(1.2) translate(0, -36%) rotate(-27deg);
		}

		@include mq($from: large) {
			transform: scale(1) translate(0, 0) rotate(0);
		}
	}
}

[data-active-step="explanation"] {
	.m-wacken__guitar-button-outer {
		// reveal buttons with stagger, this is the case when coming from intro to explanation
		@for $i from 0 through 3 {
			&:nth-child(#{$i + 1}) {
				opacity: 1;
				transform: scale(1);
				transition-delay: $guitar-animation-duration - 0.2s + (0.1s * $i);
			}
		}
	}
}

[data-active-step="game"] {
	.m-wacken__guitar-button-inner {
		span {
			@include mq($from: large) {
				opacity: 0.1;
			}
		}
	}

	.is-active .m-wacken__guitar-button {
		background-color: #20820D;
		transform: scale(1.3) rotate(22deg);
	}

	.is-active .m-wacken__guitar-button-inner {
		background-color: #38BD1F;
	}
}

[data-active-step="failure"] {
	.m-wacken__guitar-button {
		background-color: #9F9F9F;
		pointer-events: none;
	}

	.m-wacken__guitar-button-inner {
		background-color: $color-white;
	}

	.is-wrong .m-wacken__guitar-button {
		background-color: #A00F0D;
		transform: scale(1.3) rotate(22deg);
	}

	.is-wrong .m-wacken__guitar-button-inner {
		background-color: #D95C5A;
	}
}

[data-active-step="success"].m-wacken--price-type-guitar {
	.m-wacken__guitar-button {
		background-color: #20820D;
		pointer-events: none;
	}

	.m-wacken__guitar-button-inner {
		background-color: #38BD1F;
	}
}

// --------------------------------------- animations

@keyframes wacken-rotating {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@keyframes wacken-count-circle {
	to {
		stroke-dashoffset: 227;
	}
}



