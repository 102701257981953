// Extra map to have non-fluid font-style
$input-font-sizes: (
	small: map-deep-get($font-styles, headline-j3, small-font-size),
	medium: map-deep-get($font-styles, headline-j3, medium-font-size),
	large: map-deep-get($font-styles, headline-j3, large-font-size),
	xlarge: map-deep-get($font-styles, headline-j3, xlarge-font-size)
);

.c-date-input {
	display: flex;
	flex-wrap: wrap;
	position: relative;
}

.c-date-input__input {
	width: 100%;
	background-color: rgba($color-haribo-blue, 0.07);
	@include transition(border-color);
	@include property-breakpoint-map(height, $input-height-map);
	@include property-breakpoint-map(padding-left, $input-side-padding-map, 1, -$input-border-size);
	@include property-breakpoint-map(padding-right, $input-side-padding-map, 1, -$input-border-size);
	border: $input-border-size solid transparent;
	@include property-breakpoint-map(border-radius, $input-height-map, 0.5);
	color: $color-haribo-blue;
	@include property-breakpoint-map(font-size, $input-font-sizes);
	@include font-family("VAG Rounded", bold);

	&:focus {
		border-color: $color-haribo-blue;
		outline: none;
	}

	&[disabled] {
		background-color: rgba($color-haribo-grey-4, 0.5);
		border-color: transparent !important;
		color: rgba($color-haribo-blue, 0.3);
		pointer-events: none;
	}

	// code tag: sfJobApplication
	&.c-form-label--long {
		padding-top: 15px;
		background: #EDF3F9; /* Text color */
		color: rgba($color-haribo-blue, 0.3); // #005ca92b
		font-size: 19px;
		line-height: 1.2;
		font-weight: normal;
		white-space: normal;
		-webkit-mask-image: linear-gradient(to bottom, black 0%, transparent 95%);
		mask-image: linear-gradient(to bottom, black 0%, transparent 95%);
	}
}

.c-date-input__label-wrapper {
	order: -1;
}


.c-date-input__top-label-wrapper {
	display: block;
	width: 100%;
	height: auto;
	overflow: hidden;
	position: relative;
}

.c-date-input__input:focus + .c-date-input__label-wrapper,
.c-date-input.is-filled .c-date-input__label-wrapper {
	cursor: default;
	pointer-events: auto;
}

.c-date-input.is-required label::after {
	content: "*";
}

// Error styling
.c-date-input.has-error .c-date-input__input {
	border-color: $color-haribo-red;
}

// code tag: sfJobApplication
.multiline-screening-question label .c-date-input__top-label-wrapper .c-form-label--long {
	display: block;
	height: auto !important;
	color: $color-haribo-blue;
	letter-spacing: -0.5px;
	transform: none !important;
	// @include font-style("copy-a1");
	@include font-style("copy-a1--long");
}


input[type="date"] {
	&::-webkit-calendar-picker-indicator {
		opacity: 0.7;
	}
}
