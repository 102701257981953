.m-newsletter {
	padding-top: $spacing-purple;
	padding-bottom: $spacing-lightblue;
	background-image: radial-gradient(circle at 50% 87%, var(--gradient-color), var(--background-color) 63%);

	@include mq($from: medium) {
		padding-top: $spacing-petrol;
		padding-bottom: $spacing-petrol;
	}

	@include mq($from: medium) {
		padding-top: $spacing-seablue;
		padding-bottom: $spacing-seablue;
	}
}

.m-newsletter__headline {
	color: $color-white;

	@include mq($from: large) {
		padding-right: 20%;
	}
}

.m-newsletter__form-wrapper {
	position: relative;
	margin-top: $spacing-smokeblue-light;

	@include mq($from: xlarge) {
		margin-top: $spacing-olive;
	}
}

.m-newsletter__input-wrapper {
	@include mq($from: large) {
		display: flex;
		align-items: flex-start;
	}

	.c-form__input-field {
		@include mq($from: large) {
			flex: 1;
		}
	}
}

.m-newsletter__input-wrapper + .m-newsletter__checkbox {
	margin-top: $spacing-purple;

	@include mq($from: xlarge) {
		margin-top: $spacing-lightblue;
	}
}

.m-newsletter__checkbox + .m-newsletter__checkbox {
	margin-top: $spacing-yellow;

	@include mq($from: large, $until: xlarge) {
		margin-top: $spacing-pink;
	}
}

.m-newsletter__submit {
	background-color: $color-haribo-red;
	outline: none;
	color: $color-white;
}

.m-newsletter__asterisk-hint {
	color: $color-white;
	margin-top: $spacing-yellow;

	@include mq($from: large) {
		margin-top: $spacing-lilac;
	}
}

// wide breakpoints
.m-newsletter__input-wrapper .m-newsletter__submit {
	height: map-get($input-height-map, large);
	margin-top: 36px;
	margin-left: 15px;

	@include mq($until: large) {
		display: none;
	}

	@include mq($from: xlarge) {
		height: map-get($input-height-map, xlarge);
		margin-left: 20px;
	}
}

// narrow breakpoints
.m-newsletter__form > .m-newsletter__submit {
	margin-top: $spacing-purple;

	@include mq($from: medium) {
		margin-top: $spacing-lightblue;
	}

	@include mq($from: large) {
		display: none;
	}
}

.m-newsletter__loader {
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
}

.m-newsletter__confirmation {
	margin-top: $spacing-lilac;
	animation-duration: 0.4s;

	@include mq($from: xlarge) {
		margin-top: $spacing-purple;
	}
}

.m-newsletter__confirmation-text {
	color: $color-white;

	@include mq($from: large) {
		width: 80%;
	}
}

.m-newsletter__confirmation-graphic {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 90px;
	height: 90px;
	position: relative;
	margin-top: $spacing-purple;
	margin-left: auto;
	margin-right: auto;

	@include mq($from: medium) {
		width: 130px;
		height: 130px;
	}

	@include mq($from: xlarge) {
		width: 160px;
		height: 160px;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-lightblue;
	}
}

.m-newsletter__confirmation-shape {
	color: $color-haribo-red;
}

.m-newsletter__confirmation-check {
	width: 50%;
	height: 50%;
	fill: $color-white;
	z-index: 2;
}

.m-newsletter__error {
	margin-top: $spacing-orange;
	margin-bottom: $spacing-orange;

	@include mq($from: xlarge) {
		margin-top: $spacing-purple;
		margin-bottom: $spacing-purple;
	}
}

.m-newsletter__error-text {
	color: $color-haribo-red;
}

// variants & states

.m-newsletter--submitting {
	.m-newsletter__form {
		opacity: 0.4;
		pointer-events: none;
	}
}

// component overrides

/* stylelint-disable-next-line no-duplicate-selectors */
.m-newsletter {
	.c-input-choice__replacement,
	.c-text-input__input {
		background-color: rgba(0, 0, 0, 0.1);
	}

	.c-text-input__input {
		background-color: rgba(0, 0, 0, 0.1);
		color: $color-white;

		&:focus {
			border-color: $color-white;
		}
	}

	.c-text-input__label-over-input {
		color: rgba($color-white, 0.3);
	}

	.c-text-input__top-label {
		color: $color-white;
	}

	.c-input-choice__text {
		color: $color-white;

		a {
			box-shadow: none;
			text-decoration: underline;

			@include hover-focus() {
				box-shadow: none;
			}
		}
	}

	.c-input-choice__checkbox-mark {
		fill: $color-white;
	}

	.c-form-error {
		margin-left: 0;
	}

	[data-whatinput="keyboard"] & .c-input-choice__input:focus:not(:disabled) + .c-input-choice__replacement {
		box-shadow: inset 0 0 0 4px $color-white;
	}
}
