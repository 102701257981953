.m-teaser-list-a__headline {
	margin-bottom: $spacing-purple;

	@include mq($from: medium) {
		margin-bottom: $spacing-petrol;
	}

	@include mq($from: large) {
		margin-bottom: $spacing-yellow;
	}

	@include mq($from: xlarge) {
		margin-bottom: $spacing-lightblue;
	}
}

.m-teaser-list-a--no-image .m-teaser-list-a__headline {
	margin-bottom: $spacing-lightblue;

	@include mq($from: medium) {
		margin-bottom: $spacing-petrol;
	}

	@include mq($from: large) {
		margin-bottom: $spacing-lightblue;
	}

	@include mq($from: xlarge) {
		margin-bottom: $spacing-lightpink;
	}
}

.m-teaser-list-a__teasers {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;

	.m-teaser-list-a--no-image & {
		justify-content: flex-start;
	}
}

.m-teaser-list-a__colcade-col {
	@include mq($until: large) {
		display: none;
	}
}

.m-teaser-list-a__teaser {
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;

	@include mq($until: medium) {
		width: percentage(12/$cols-m);
		margin-left: auto;
		margin-right: auto;
	}
}

.m-teaser-list-a__teaser-cta-text {
	pointer-events: none;
}

.m-teaser-list-a__teaser-date {
	display: block;
	color: $color-haribo-blue;
	margin-top: $spacing-smokeblue-light;

	@include mq($from: medium) {
		margin-top: $spacing-olive;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-pink;
	}
}

.m-teaser-list-a__teaser--image {
	@include mq($until: medium) {
		&:not(:last-child) {
			margin-bottom: $spacing-lightblue;
		}

		.m-teaser-list-a__teaser-headline,
		.m-teaser-list-c__teaser-headline {
			margin-top: $spacing-pink;
		}

		.m-teaser-list-a__teaser-cta-text {
			margin-top: $spacing-orange;
		}
	}
}

.m-teaser-list-a__teaser--no-image {
	&:not(:first-child) {
		@include mq($until: medium) {
			margin-top: $spacing-purple;
		}

		@include mq($from: medium, $until: large) {
			margin-top: $spacing-lightblue !important;
		}
	}

	&:nth-child(n+4) {
		@include mq($from: large) {
			margin-top: $spacing-grey;
		}
	}

	&:not(:nth-child(3n+1)) {
		@include mq($from: large) {
			margin-left: percentage(1/$cols-l);

			@include is-rtl() {
				margin-left: 0;
				margin-right: percentage(1/$cols-l);
			}
		}
	}

	.m-teaser-list-a__teaser-cta-text {
		margin-top: $spacing-orange;

		@include mq($from: medium) {
			margin-top: $spacing-lilac !important;
		}

		@include mq($from: large) {
			margin-top: $spacing-royalblue !important;
		}

		@include mq($from: xlarge) {
			margin-top: $spacing-lightblue !important;
		}
	}
}

.m-teaser-list-a__teasers--1-col {
	display: block;

	.m-teaser-list-a__teaser {
		@include mq($from: large) {
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
	}

	.m-teaser-list-a__teaser-text {
		@include mq($from: large) {
			width: percentage(12/$cols-l);
		}

		@include mq($from: xlarge) {
			width: percentage(13/$cols-xl);
		}
	}

	.m-teaser-list-a__teaser-image {
		@include mq($from: large) {
			width: percentage(13/$cols-l);
		}

		@include mq($from: xlarge) {
			width: percentage(12/$cols-xl);
		}
	}

	.m-teaser-list-a__teaser--mirrored {
		.m-teaser-list-a__teaser-image {
			@include mq($from: large) {
				order: 1;
			}
		}
	}

	.m-teaser-list-a__teaser-headline {
		@include mq($from: medium, $until: large) {
			margin-top: $spacing-yellow;
		}
	}

	.m-teaser-list-a__teaser-cta-text {
		@include mq($from: medium) {
			margin-top: $spacing-royalblue;
		}
	}
}

.m-teaser-list-a__teasers--2-cols,
.m-teaser-list-a__teasers--3-cols {
	@include mq($until: large) {
		display: block;
	}

	> .m-teaser-list-a__teaser {
		@include mq($from: medium, $until: large) {
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;

			&:nth-child(even) {
				.m-teaser-list-a__teaser-image,
				.m-teaser-list-c__teaser-images {
					order: 1;
				}
			}

			&:not(:first-of-type) {
				margin-top: $spacing-brown;
			}
		}
	}

	.m-teaser-list-a__teaser--image .m-teaser-list-a__teaser-text {
		@include mq($from: medium, $until: large) {
			width: percentage(6/$cols-m);
		}
	}

	.m-teaser-list-a__teaser--image .m-teaser-list-a__teaser-headline {
		@include mq($from: large) {
			margin-top: $spacing-pink;
		}

		@include mq($from: xlarge) {
			margin-top: $spacing-yellow;
		}
	}

	.m-teaser-list-a__teaser-cta-text {
		@include mq($from: medium) {
			margin-top: $spacing-royalblue;
		}

		@include mq($from: xlarge) {
			margin-top: $spacing-lightblue;
		}
	}

	.m-teaser-list-a__teaser-image {
		@include mq($from: medium, $until: large) {
			width: percentage(7/$cols-m);
		}
	}
}

.m-teaser-list-a__teasers--2-cols {
	> .m-teaser-list-a__teaser {
		@include mq($from: large) {
			width: percentage(12/$cols-l);
		}

		&:first-child {
			@include mq($from: large) {
				margin-top: $spacing-lightblue;
			}
		}
	}
}

.m-teaser-list-a__teasers--3-cols {
	// As long colcade is not initialized and the teasers a direct child, give them some margin
	// n+7 is starting with the 4th teasers (because of the empty columns)
	> .m-teaser-list-a__teaser:nth-child(n+7) {
		@include mq($from: large) {
			margin-top: $spacing-brown;
		}
	}

	> .l-colcade__col {
		&:first-child .m-teaser-list-a__teaser:first-child {
			@include mq($from: large) {
				margin-top: $spacing-lightblue;
			}
		}

		&:nth-child(2) .m-teaser-list-a__teaser:first-child {
			@include mq($from: large) {
				margin-top: $spacing-lightblue + $spacing-lilac;
			}

			@include mq($from: xlarge) {
				margin-top: 2 * $spacing-lightblue;
			}
		}

		> .m-teaser-list-a__teaser:not(:last-child) {
			@include mq($from: large) {
				margin-bottom: $spacing-brown;
			}
		}
	}

	> .m-teaser-list-a__teaser,
	> .m-teaser-list-a__colcade-col {
		@include mq($from: large) {
			width: percentage(8/$cols-l);
		}
	}

	> .m-teaser-list-a__teaser--image {
		// first teaser, but fourth item because of the colcade column divs
		&:nth-child(4) {
			@include mq($from: large) {
				margin-top: $spacing-lightblue;
			}
		}

		// second teaser, but fifth item because of the colcade column divs
		&:nth-child(5) {
			@include mq($from: large) {
				margin-top: $spacing-lightblue + $spacing-lilac;
			}

			@include mq($from: xlarge) {
				margin-top: 2 * $spacing-lightblue;
			}
		}

		.m-teaser-list-a__teaser-headline {
			@include mq($from: large, $until: xlarge) {
				margin-top: $spacing-pink;
			}
		}
	}
}

.m-teaser-list-a:not(.m-teaser-list-a--shapes) .m-teaser-list-a__teasers--3-cols .m-teaser-list-a__teaser--image .m-teaser-list-a__teaser-headline,
.m-teaser-list-a:not(.m-teaser-list-a--shapes) .m-teaser-list-a__teasers--2-cols .m-teaser-list-a__teaser--image .m-teaser-list-a__teaser-headline {
	@include mq($from: large) {
		margin-top: $spacing-yellow;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-orange;
	}
}

.m-teaser-list-a__teaser .c-image-component {
	overflow: hidden;
}

.m-teaser-list-a--shapes .m-teaser-list-a__teaser--mirrored {
	@include hover-focus() {
		.c-image-component {
			img {
				transform: scaleX(-1) scale(1.1);
			}
		}
	}

	.c-image-component {
		transform: scaleX(-1);

		img {
			transform: scaleX(-1);
		}
	}
}

.m-teaser-list-a__teaser-headline {
	color: $color-haribo-blue;
}

.m-teaser-list-a__teaser-copy {
	margin-top: $spacing-pink;
}

// do not show copy if teaser is after the contact or newsletter module
.m-contact ~ .m-teaser-list-a,
.m-newsletter ~ .m-teaser-list-a {
	.m-teaser-list-a__teaser-copy {
		@include mq($until: medium) {
			display: none;
		}
	}
}

// link is outside of box so we can scale the image accordingly
.m-teaser-list-a__teaser-ghost-link {
	z-index: 2;

	[data-whatintent="mouse"] &:hover,
	[data-whatinput="keyboard"] &:focus {
		~ .m-teaser-list-a__teaser-text {
			@include icon-text-link-wave-arrow-right-hover();
		}

		~ .m-teaser-list-a__teaser-image {
			img {
				transform: scale(1.1);
			}
		}
	}

	[dir="rtl"][data-whatintent="mouse"] &:hover,
	[dir="rtl"][data-whatinput="keyboard"] &:focus {
		~ .m-teaser-list-a__teaser-text {
			@include icon-text-link-wave-arrow-right-hover(rtl);
		}
	}
}

.m-teaser-list-a__teaser-image {
	order: -1;
	overflow: hidden;
	position: relative;

	img {
		@include transition(transform);
	}
}

.m-teaser-list-a__teaser-tag {
	display: inline-flex;
	height: 22px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: $color-haribo-yellow;
	border-radius: 22px;
	color: $color-haribo-blue;
	line-height: 23px;
	position: absolute;
	bottom: 10px;
	left: 10px;
	z-index: 1;

	@include is-rtl() {
		left: auto;
		right: 10px;
	}

	@include mq($from: medium) {
		height: 20px;
		border-radius: 20px;
		line-height: 21px;
	}

	@include mq($from: xlarge) {
		height: 25px;
		line-height: 26px;
	}
}

.m-teaser-list-a--shapes .m-teaser-list-a__teaser-image .m-teaser-list-a__teaser-tag {
	display: block;
	width: fit-content;
	bottom: $spacing-yellow;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}

.m-teaser-list-a--shapes .m-teaser-list-a__teaser-text {
	@include mq($until: medium) {
		padding-left: percentage(0.5/14);
	}

	@include mq($from: large) {
		padding-left: percentage(0.5/8);
	}
}

.m-teaser-list-a--shapes .m-teaser-list-a__teasers--1-col .m-teaser-list-a__teaser-text {
	@include mq($from: medium, $until: large) {
		padding-left: percentage(0.5/14);
	}

	@include mq($from: large) {
		padding-left: 0;
	}

	@include mq($from: xlarge) {
		padding-left: 0;
	}
}

.m-teaser-list-a--ideas .m-teaser-list-a__teasers--2-cols > .m-teaser-list-a__teaser:first-child {
	margin-top: 0;
}

.m-teaser-list-a--2x2 {
	.m-teaser-list-a__teaser {
		&:nth-child(3),
		&:nth-child(4) {
			@include mq($from: large) {
				margin-top: $spacing-brown;
			}
		}

		&:nth-child(4) {
			@include mq($from: large) {
				margin-top: $spacing-brown - $spacing-lightblue;
			}
		}
	}
}

.m-teaser-list-a .c-filter-panel {
	margin-top: $spacing-softblue;
}

.m-teaser-list-a--no-vertical-offset {
	.m-teaser-list-a__teasers {
		justify-content: flex-start;
	}

	.m-teaser-list-a__teaser {
		&:not(:first-child) {
			@include mq($until: medium) {
				margin-top: $spacing-purple;
			}

			@include mq($from: medium, $until: large) {
				margin-top: $spacing-lightblue !important;
			}
		}

		&:nth-child(n+4) {
			@include mq($from: large) {
				margin-top: $spacing-grey;
			}
		}

		&:not(:nth-child(3n+1)) {
			@include mq($from: large) {
				margin-left: percentage(1/$cols-l);

				@include is-rtl() {
					margin-left: 0;
					margin-right: percentage(1/$cols-l);
				}
			}
		}
	}
}

.m-teaser-list-a.m-teaser-list-a--horizontal-align article.m-teaser-list-a__teaser {
	margin-top: 0;
}
