.m-shop-list-facts {
	color: $color-white;
}

.m-shop-list-facts__item {
	display: flex;
	align-items: flex-start;

	a {
		color: $color-white;
		text-decoration: underline;
		text-underline-offset: 2px;

		@include hover-focus() {
			text-decoration-color: $color-haribo-yellow;
		}
	}
}

.m-shop-list-facts__item + .m-shop-list-facts__item {
	margin-top: $spacing-olive;

	@include mq($from: xlarge) {
		margin-top: $spacing-pink;
	}
}

.m-shop-list-facts__icon {
	flex: 0 0 auto;
	width: 15px;
	height: 15px;
	margin-top: 0;
	margin-right: 10px;

	@include mq($from: large) {
		margin-top: -2px;
	}

	@include mq($from: xlarge) {
		width: 20px;
		height: 20px;
		margin-top: 0;
		margin-right: 13px;
	}

	.c-modal__inner & {
		margin-top: 0;

		@include mq($from: large) {
			margin-top: 1px;
		}

		@include mq($from: xlarge) {
			margin-top: 0;
		}
	}
}

.m-shop-list-facts__text {
	margin-top: -3px;

	@include mq($from: large, $until: xlarge) {
		.m-shop-list-item & {
			font-size: 13px;
		}
	}
}
