// TODO: make vue component out of it
.c-rounded-icon-button {
	display: flex;
	width: 50px;
	height: 50px;
	background-color: $color-white;
	border-radius: 50%;
	color: $color-haribo-red;
	position: relative;
	@include transition(color);

	// circle background
	&::after {
		content: "";
		width: 100%;
		height: 100%;
		background-color: $color-white;
		border-radius: 50%;
		position: absolute;
		top: 0;
		left: 0;
		@include transition(transform);
	}

	svg {
		width: 22px;
		height: 22px;
		z-index: 2;
		margin: auto;
	}

	@include hover-focus() {
		color: $color-haribo-blue;

		&::after {
			transform: scale(1.2);
		}
	}
}
