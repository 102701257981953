.c-raffle-form {
	--text-color: #{$color-white};
	--accent-color: #{$color-haribo-blue};
	--field-color: #{$color-white};
	--error-color: #{$color-haribo-yellow};
}

.c-raffle-form__form {
	display: block;

	.c-text-input__top-label {
		color: var(--text-color);
	}

	.c-text-input__input {
		color: var(--accent-color);
	}

	// autofill styles
	.c-text-input__input:-webkit-autofill {
		-webkit-text-fill-color: var(--accent-color);
	}

	.c-form-error {
		color: var(--error-color);
	}

	.c-text-input.has-error .c-text-input__input {
		border-color: var(--error-color);
	}

	.c-input-choice.has-error .c-input-choice__replacement {
		box-shadow: inset 0 0 0 4px var(--error-color);
	}

	.c-text-input__label-over-input {
		opacity: 1;
		color: var(--background-color);
	}

	.c-text-input__input:focus {
		border-color: var(--background-color);
	}
}

.c-raffle-form__email {
	width: 100%;

	.c-text-input__input {
		background-color: var(--field-color);
	}
}

.c-raffle-form__checkbox {
	margin-top: $spacing-yellow;

	@include mq($from: large) {
		margin-top: $spacing-pink;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-yellow;
	}

	.c-input-choice__text {
		width: 100%;
		color: var(--text-color);
	}

	.c-input-choice__replacement {
		background-color: var(--field-color);
	}

	a {
		box-shadow: none !important;
		text-decoration: underline;
		text-underline-offset: 3px;
	}

	svg {
		fill: var(--accent-color);
	}
}

[data-whatinput="keyboard"] .c-raffle-form .c-input-choice__input:focus:not(:disabled) {
	+ .c-input-choice__replacement {
		box-shadow: inset 0 0 0 4px var(--background-color);
	}
}

.c-raffle-form__asterisk-hint {
	color: var(--text-color);
	margin-top: $spacing-yellow;

	@include mq($from: large) {
		margin-top: $spacing-lilac;
	}
}

.c-raffle-form__button.c-button {
	background-color: $color-white;
	margin-top: $spacing-lilac;

	@include mq($from: large) {
		margin-top: $spacing-lightblue;
	}

	.c-button__label {
		color: var(--accent-color);
	}
}

.c-raffle-form__button[disabled] {
	opacity: 0.4;
}

.c-raffle-form__captcha {
	margin-top: $spacing-yellow;

	.frc-text {
		color: var(--text-color) !important;
	}
}

.c-raffle-form__error {
	color: var(--error-color);
	margin-top: $spacing-orange;
	margin-bottom: $spacing-orange;

	@include mq($from: xlarge) {
		margin-top: $spacing-purple;
		margin-bottom: $spacing-purple;
	}
}
