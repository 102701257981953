.m-content-box__overview {
	position: relative;

	@include mq($from: large) {
		height: 100%;
	}
}

.m-content-box__overview-headline {
	@include mq($from: large) {
		display: none;
	}
}

.m-content-box__overview-content {
	width: percentage(12/$cols-s);
	margin-top: $spacing-pink;
	margin-left: percentage(1/$cols-s);

	@include is-rtl() {
		margin-left: 0;
		margin-right: percentage(1/$cols-s);
	}

	@include mq($from: medium) {
		width: percentage(12/$cols-m);
		margin-top: $spacing-yellow;
		margin-left: percentage(1/$cols-m);

		@include is-rtl() {
			margin-left: 0;
			margin-right: percentage(1/$cols-m);
		}
	}

	@include mq($from: large) {
		width: percentage(12/$cols-l);
		margin-top: 0;
		margin-left: 0;

		@include is-rtl() {
			margin-right: 0;
		}
	}

	@include mq($from: xlarge) {
		width: percentage(12/$cols-xl);
	}
}

.m-content-box__overview-copy {
	margin-top: $spacing-olive;

	@include mq($from: medium) {
		margin-top: $spacing-pink;
	}

	@include mq($from: large) {
		padding-right: 30px;
		margin-top: 0;

		@include is-rtl() {
			padding-left: 30px;
			padding-right: 0;
		}
	}

	@include mq($from: xlarge) {
		padding-right: 50px;

		@include is-rtl() {
			padding-left: 50px;
			padding-right: 0;
		}
	}
}

.m-content-box__overview-link {
	margin-top: $spacing-yellow;

	@include mq($from: medium) {
		margin-top: $spacing-orange;
	}

	@include mq($from: large) {
		margin-top: $spacing-royalblue;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-lilac;
	}
}

// special layout case for overview variant

.m-content-box--overview {
	// somehow magic numbers as this has to match with the height of the image height (use devtools to read)
	@include fluid(
		min-height,
		483px,
		960px,
		map-get($breakpoints, large),
		$module-max-width + 15px
	);

	@include mq($from: large) {
		display: flex;
		flex-flow: column;
		justify-content: center;
		margin-top: 0;

		.m-content-box__headline {
			width: percentage(13/$cols-l);
			padding-right: 20px;

			@include is-rtl() {
				padding-left: 20px;
				padding-right: 0;
			}
		}

		.c-content-box-frame__navigation-wrapper {
			width: percentage(14/$cols-l);
			margin-top: $spacing-lightpink;
		}

		.c-content-box-frame__navigation {
			margin-left: percentage(1/14);

			@include is-rtl() {
				margin-left: 0;
				margin-right: percentage(1/14);
			}
		}

		.c-content-box-frame__wrapper {
			flex-wrap: wrap;
		}

		.c-content-box-frame__content-wrapper {
			width: 100%;
			margin-top: $spacing-royalblue;
		}

		.m-content-box__sections {
			margin-top: 0;
		}
	}

	@include mq($from: xlarge) {
		.c-content-box-frame__navigation-wrapper {
			width: percentage(11/$cols-xl);
			margin-top: $spacing-softblue;
		}

		.c-content-box-frame__navigation {
			margin-left: percentage(1/11);

			@include is-rtl() {
				margin-left: 0;
				margin-right: percentage(1/11);
			}
		}

		.c-content-box-frame__content-wrapper {
			margin-top: $spacing-lilac;
		}
	}

	.l-main & {
		@include mq($from: large) {
			padding-bottom: $spacing-petrol;
		}

		@include mq($from: xlarge) {
			padding-bottom: $spacing-softblue;
		}
	}
}

// position and scaling of images

.m-content-box__overview-image-wrapper-desktop {
	width: calc(50% + 100px);
	overflow: hidden;
	position: absolute;
	bottom: 0;
	right: 0;

	@include is-rtl() {
		left: 0;
		right: auto;
	}

	@include mq($until: large ) {
		display: none;
	}

	.m-content-box__overview-image {
		width: calc(100% - 100px);
		margin-left: auto;

		@include is-rtl() {
			margin-left: 0;
			margin-right: auto;
		}
	}

	.m-content-box__overview-image-shadow {
		width: calc(100% - 100px);
		left: auto;
		right: 0;

		@include is-rtl() {
			left: 0;
			right: auto;
		}
	}
}

.m-content-box__overview-image-wrapper-mobile {
	position: relative;

	@include mq($from: large) {
		display: none;
	}
}

.m-content-box__overview-image {
	position: relative;
	z-index: 2;

	@include is-rtl() {
		transform: scaleX(-1);
	}

	img {
		@include is-rtl() {
			transform: scaleX(-1);
		}
	}
}

.m-content-box__overview-image-shadow {
	position: absolute;
	top: 15px;
	left: -15px;

	@include mq($until: large) {
		@include is-rtl() {
			left: auto;
			right: -15px;
			transform: scaleX(-1);
		}
	}

	@include mq($from: large) {
		top: 55px;
		left: 30px;
		transform: rotate(3deg) scale(1.15);

		@include is-rtl() {
			left: 0;
			right: 30px;
			transform: rotate(-3deg) scaleY(1.15) scaleX(-1.15);
		}
	}
}

.m-content-box__overview-image,
.m-content-box__overview-image-shadow {
	@include svg-mask(c);

	@include mq($from: large) {
		mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='500' height='500' viewBox='0 0 500 500' preserveAspectRatio='none'%3E%3Cpath d='M500 .2c-37.1-.7-83 .8-140.2 2.6C69.7 12.2-61.6 115.2 27.6 417.3c9.2 31.3 18.6 58.7 28.1 82.7H500V.2z'/%3E%3C/svg%3E");
		mask-repeat: no-repeat;
		mask-size: 100% 100%;
	}
}

// JS timing related edge cases

html.no-js {
	.m-content-box__overview-image-wrapper {
		width: 70%;
		position: relative;
		bottom: 0;
		right: auto;
	}

	.m-content-box__overview-image,
	.m-content-box__overview-image-shadow {
		@include svg-mask(e);
	}

	.m-content-box__overview-content {
		display: block;
	}
}
