.m-stage-product-image__image {
	.c-image-component__image-wrapper {
		height: 100%;

		img {
			object-fit: cover;
			object-position: 100% 100%;
		}
	}

	.c-image-component__aspect-ratio-helper {
		display: none;
	}
}

.m-stage-product-image__landscape {
	@include mq($until: large) {
		display: none;
	}
}

.m-stage-product-image__portrait {
	@include mq($from: large) {
		display: none;
	}
}

// because the whole are reacts to click via JS if a link is given
.m-stage-product-image.has-link {
	cursor: pointer;
}
