$drag-knob-height: 65px;
$wave-wrapper-height: 200px;

$drag-stroke-width-m: 26px;
$drag-stroke-width-xl: 32px;

$history-knob-drag-scale: 1.15;
$dot-click-area-spacing: 10px;
$history-knob-inner-default-color: #B71D23;

.m-history {
	--background-color: #{$color-haribo-red};
	--gradient-color: #{lighten($color-haribo-red, $lighten-amount)};
	padding-top: $spacing-ocher;
	padding-bottom: $spacing-lightpink;
	background-color: $color-haribo-red;
	background-image: radial-gradient(circle at 42% 27%, var(--gradient-color), var(--background-color) 95%);

	@include mq($from: medium) {
		padding-top: $spacing-softblue;
		padding-bottom: $spacing-grey;
	}

	@include mq($from: large) {
		padding-top: $spacing-darkgrey;
		padding-bottom: $spacing-grey;
	}

	@include mq($from: xlarge) {
		padding-top: $spacing-hotpink;
		padding-bottom: $spacing-darkgrey;
	}
}

.m-history__entries {
	@include mq($until: large) {
		margin-top: $spacing-purple;
	}
}

@import "components/HistoryEntry";

.m-history__timeline {
	direction: ltr;
	width: 100%;
	position: relative;

	@include mq($until: large) {
		display: none;
	}
}

.m-history__entry-dot {
	width: 12px;
	height: 12px;
	background-color: $color-white;
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	cursor: pointer;

	@include mq($from: xlarge) {
		width: 16px;
		height: 16px;
	}

	&.is-highlight {
		background-color: $color-haribo-yellow;
	}

	&.is-hidden {
		visibility: hidden;
	}

	&::before {
		content: "";
		border-radius: 50%;
		position: absolute;
		top: -#{$dot-click-area-spacing};
		bottom: -#{$dot-click-area-spacing};
		left: -#{$dot-click-area-spacing};
		right: -#{$dot-click-area-spacing};
	}
}

[data-whatinput="keyboard"] .m-history__entry-dot:focus {
	outline: 3px solid $color-haribo-yellow;
	outline-offset: 10px;
	z-index: 2;
}

.m-history__knob {
	width: $drag-knob-height;
	height: $drag-knob-height;
	background-color: $color-white;
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 4;

	@include hover-focus() {
		&::after {
			transform: scale($history-knob-drag-scale);
		}
	}

	&::after {
		content: "";
		width: 100%;
		height: 100%;
		background-color: inherit;
		border-radius: 50%;
		position: absolute;
		z-index: 2;
		transform: scale(0.9);
		transform-origin: center;
		@include transition(transform);
	}
}

.m-history.is-in-drag-state {
	.m-history__knob {
		&::after {
			transform: scale($history-knob-drag-scale);
		}
	}
}

.m-history__knob-inner,
.m-history__knob-dot-hit-target {
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 3;
}

.m-history__knob-inner {
	width: 36px;
	height: 36px;
	background-color: $history-knob-inner-default-color;
	margin-top: -18px;
	margin-left: -18px;
}

.m-history__knob-dot-hit-target {
	width: 18px;
	height: 18px;
	background-color: $color-black;
	opacity: 0;
	margin-top: -9px;
	margin-left: -9px;
	pointer-events: none;
}

.m-history__knob-label {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 120px;
	height: 54px;
	padding: 0;
	background-color: $color-white;
	border-radius: 27px;
	opacity: 0;
	color: $color-haribo-red;
	line-height: 1;
	text-align: center;
	position: absolute;
	bottom: calc(100% + 18px);
	left: 50%;
	margin-left: -60px;
	pointer-events: none;
	@include transition(opacity 0.3s $transition-default-easing 1s, background-color);

	&.is-visible {
		opacity: 1;
		transition-delay: 0s;
	}

	&:empty {
		display: none;
	}

	&.is-highlighted {
		background-color: $color-haribo-yellow;

		&::after {
			border-top-color: $color-haribo-yellow;
		}
	}

	// triangle
	&::after {
		content: "";
		width: 0;
		height: 0;
		border-top: 10px solid $color-white;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		position: absolute;
		top: calc(100% - 1px);
		z-index: 2;
		@include transition(border-color);
	}
}

.m-history__first-year,
.m-history__last-year {
	$bar-width: 18px;

	width: $bar-width;
	height: $drag-knob-height - 6;
	background-color: $color-white;
	border-radius: 9px;
	opacity: 0;
	position: absolute;
	z-index: 3;
	pointer-events: none;
	@include transition(opacity);

	span {
		min-width: 100px;
		color: $color-white;
		text-align: center;
		position: absolute;
		top: calc(100% + #{$spacing-olive});
		left: 0;
		margin-left: -50px + ($bar-width / 2);
		pointer-events: none;
	}

	&.is-visible {
		opacity: 1;
	}
}

.m-history__first-year {
	left: 0;
}

.m-history__last-year {
	right: 0;
}

.m-history__select-menu-wrapper {
	@include mq($from: large) {
		display: none;
	}
}

.m-history__mobile-pagination-wrapper {
	direction: ltr;
	display: flex;
	align-items: center;
	margin-top: $spacing-purple;

	@include mq($from: large) {
		display: none;
	}
}

.m-history__left-pagination,
.m-history__right-pagination {
	display: flex;
	align-items: center;

	&.is-hidden {
		display: none;
	}

	svg {
		fill: var(--background-color);
	}

	@include hover-focus() {
		.c-rounded-icon-button::after {
			transform: scale(1.2);
		}
	}
}

.m-history__pagination-text {
	color: $color-white;
}

.m-history__left-pagination {
	.m-history__pagination-text {
		margin-left: 12px;
	}
}

.m-history__right-pagination {
	margin-left: auto;

	.m-history__pagination-text {
		margin-right: 12px;
	}
}

.m-history__wave {
	width: 100%;
	height: 12vw;
	overflow: hidden;
	position: relative;

	path {
		stroke: rgba(0,0,0,0.15);
		stroke-width: $drag-stroke-width-m;
		fill: none;
		stroke-linecap: square;

		@include mq($from: xlarge) {
			stroke-width: $drag-stroke-width-xl;
		}
	}
}

.no-js .m-history {
	.m-history__timeline {
		display: none;
	}
}

.m-history.u-color-bg-maoam {
	.m-history__year,
	.m-history__subline,
	.m-history__copy,
	.m-history__first-year-label,
	.m-history__last-year-label {
		color: $color-maoam-blue;
	}

	.m-history__knob-inner {
		background-color: $history-knob-inner-default-color;
	}

	.m-history__first-year,
	.m-history__last-year {
		background-color: $color-maoam-orange;
	}

	.m-history__wave path {
		stroke: $color-maoam-blue;
	}

	.m-history__knob-label {
		color: $history-knob-inner-default-color !important;

		&.is-highlighted {
			background-color: $color-maoam-orange;
			color: $color-white !important;

			&::after {
				border-top-color: $color-maoam-orange;
			}
		}
	}

	.m-history__entry.is-highlight {
		.m-history__year {
			color: $color-maoam-orange;
		}
	}

	.m-history__mobile-pagination-wrapper {
		.c-rounded-icon-button {
			&::after {
				background-color: $color-maoam-blue;
			}
		}

		.m-history__pagination-text {
			color: $color-maoam-blue;
		}
	}

	.m-history__select-menu {
		.c-select-input__select {
			background-color: rgba($color-maoam-blue, 0.2);
			color: $color-maoam-blue;
		}

		svg {
			fill: $color-maoam-blue;
		}
	}
}
