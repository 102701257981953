.m-section-text {
	--headline-color: #{$color-haribo-red};
	--text-color: #{$color-black};

	@include has-background-color() {
		--headline-color: #{$color-white};
		--text-color: #{$color-white};

		padding-top: $spacing-purple;
		padding-bottom: $spacing-purple;
		background-image: radial-gradient(circle at 71% 30%, var(--gradient-color), var(--background-color) 63%);

		@include mq($from: medium) {
			padding-top: $spacing-petrol;
			padding-bottom: $spacing-petrol;
		}

		@include mq($from: xlarge) {
			padding-top: $spacing-softblue;
			padding-bottom: $spacing-softblue;
		}
	}

	&.m-section-text--no-bg-gradient {
		background-image: none;
	}
}

.m-section-text__inner {
	@include mq($from: large) {
		width: percentage(20/$cols-l);
		margin-left: percentage(1/$cols-l);

		@include is-rtl() {
			margin-left: 0;
			margin-right: percentage(1/$cols-l);
		}
	}

	@include mq($from: xlarge) {
		width: percentage(16/$cols-xl);
		margin-left: percentage(1/$cols-xl);

		@include is-rtl() {
			margin-left: 0;
			margin-right: percentage(1/$cols-xl);
		}
	}
}

.m-section-text__header {
	@include mq($from: large) {
		width: percentage(21/$cols-l);
	}

	@include mq($from: xlarge) {
		width: percentage(17/$cols-xl);
	}
}

.m-section-text__headline {
	color: var(--headline-color);
}

.m-section-text__date,
.m-section-text__subline {
	display: block;
	color: var(--text-color);

	@include mq($from: large) {
		margin-left: percentage(1/20);

		@include is-rtl() {
			margin-left: 0;
			margin-right: percentage(1/20);
		}
	}

	@include mq($from: xlarge) {
		margin-left: percentage(1/16);

		@include is-rtl() {
			margin-left: 0;
			margin-right: percentage(1/16);
		}
	}
}

.m-section-text__subline {
	margin-top: $spacing-olive;
}

.m-section-text__headline + .m-section-text__date,
.m-section-text__headline + .m-section-text__subline {
	margin-top: $spacing-pink;
}

.m-section-text__header + .m-section-text__inner {
	margin-top: $spacing-orange;

	@include mq($from: medium) {
		margin-top: $spacing-purple;
	}

	@include mq($from: large) {
		margin-top: $spacing-lightblue;
	}
}

.m-section-text__body {
	color: var(--text-color);

	.c-icon-text-link__icon {
		@include parent-has-background-color() {
			fill: $color-haribo-yellow;
		}
	}
}

.m-section-text__body ul,
.m-section-text__body ol,
.m-section-text__body blockquote {
	@include mq($from: medium) {
		padding-left: percentage(1/$cols-m);

		@include is-rtl() {
			padding-left: 0;
			padding-right: percentage(1/$cols-m);
		}
	}

	@include mq($from: large) {
		padding-left: percentage(1/20);

		@include is-rtl() {
			padding-left: 0;
			padding-right: percentage(1/20);
		}
	}

	@include mq($from: xlarge) {
		padding-left: percentage(1/16);

		@include is-rtl() {
			padding-left: 0;
			padding-right: percentage(1/16);
		}
	}
}

.m-section-text__body ul,
.m-section-text__body ol {
	margin-left: 1px;

	@include is-rtl() {
		margin-left: 0;
		margin-right: 1px;
	}

	@include mq($from: medium) {
		margin-left: 4px;

		@include is-rtl() {
			margin-left: 0;
			margin-right: 4px;
		}
	}
}

.m-section-text__link {
	margin-top: $spacing-orange;

	@include mq($from: medium) {
		margin-top: $spacing-lightblue;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-petrol;
	}
}

.m-section-text__footer {
	display: flex;
	margin-top: $spacing-yellow;

	@include mq($until: medium) {
		flex-wrap: wrap;
	}

	@include mq($from: medium) {
		align-items: flex-end;
		margin-top: $spacing-orange;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-lilac;
	}
}

.m-section-text__share-headline {
	line-height: 1;

	@include mq($until: medium) {
		flex-shrink: 0;
		width: 100%;
		margin-bottom: $spacing-pink;
	}

	@include mq($from: medium) {
		margin-right: 40px;

		@include is-rtl() {
			margin-left: 40px;
			margin-right: 0;
		}
	}
}

.m-section-text__share-icon {
	width: 37px;
	height: 37px;
	color: var(--headline-color);

	@include mq($from: medium) {
		width: 27px;
		height: 27px;
	}

	svg {
		width: 100%;
		height: 100%;
	}

	&:not(:last-child) {
		margin-right: 20px;

		@include is-rtl() {
			margin-left: 20px;
			margin-right: 0;
		}
	}
}

.m-section-text .c-rich-text.fs-copy-a3 blockquote {
	color: var(--headline-color);
}

// stylelint-disable
#e-sfdescription {
	b, strong {
		font-weight: 700 !important;
	}

	i, em {
		font-style: italic !important;
	}

	h1, h2, h3, h4, h5, h6 {
		b, strong {
			font-weight: 400;
		}

		i, em {
			font-style: inherit;
		}
	}
}
