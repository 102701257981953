.c-places-geo-sort-list {
	position: relative;
}

.c-places-geo-sort-list__search {
	display: flex;
	align-items: center;
	border-bottom: 2px solid #005CA999;
	position: relative;

	&:has(.c-places-geo-sort-list__search-input:focus) {
		border-bottom: 2px solid $color-haribo-blue;
	}
}

.c-places-geo-sort-list__search-input {
	display: block;
	width: 100%;
	outline: none;
	color: $color-haribo-blue;
	line-height: 1;
}

.c-places-geo-sort-list__search-input::placeholder {
	@include transition(color);
	color: $color-haribo-blue;
	opacity: 0.6;
}

.c-places-geo-sort-list__buttons {
	display: flex;
	align-items: center;
	column-gap: 5px;
	z-index: 2;

	@include mq($from: large) {
		column-gap: 10px;
	}

	> * {
		flex: 0 0 auto;
		width: 20px;
		height: 20px;

		@include mq($from: large) {
			width: 30px;
			height: 30px;
		}

		svg {
			width: 100%;
			height: 100%;
		}
	}
}

.c-places-geo-sort-list__search-icon {
	display: none;
	color: $color-haribo-blue;
	@include transition(color);
}

.c-places-geo-sort-list__detect-icon {
	display: none;
	color: $color-haribo-blue;
	@include transition(color);

	.has-geo-support & {
		display: block;
	}
}

.c-places-geo-sort-list__close-button {
	padding: 3%;
	color: $color-haribo-blue;

	@include transition(color);

	@include hover-focus() {
		color: $color-haribo-red;
	}
}

.c-places-geo-sort-list__search-input:focus ~ .c-places-geo-sort-list__buttons {
	.c-places-geo-sort-list__search-icon {
		color: $color-haribo-dark-blue;
	}

	&::placeholder {
		color: $color-black;
	}
}

.c-places-geo-sort-list__search-input:placeholder-shown ~ .c-places-geo-sort-list__buttons {
	.c-places-geo-sort-list__search-icon {
		display: block;
	}

	.c-places-geo-sort-list__close-button {
		display: none;
	}
}

.c-places-autocomplete__results {
	margin-top: $spacing-purple;

	@include mq($from: medium) {
		margin-top: $spacing-petrol;
	}

	@include mq($from: large) {
		margin-top: $spacing-softblue;
	}

	&:first-child {
		margin-top: 0;
	}
}

.c-places-autocomplete__fallback-text {
	display: none;
	text-align: center;

	[data-total-sort="0"] & {
		display: block;
	}
}


// override google CSS classes

.has-places-autocomplete-field {
	.pac-container {
		padding: 8px;
	}

	.pac-icon {
		display: none;
	}

	.pac-item {
		display: flex;
		align-items: center;
		padding-top: 10px;
		padding-bottom: 10px;
		border-top: dotted 1px $color-haribo-grey-1;
		color: $color-haribo-grey-2;
		@include font-style("copy-c");

		&:first-child {
			border: none;
		}
	}

	.pac-item-query {
		padding-right: 10px;
		border-right: dotted 1px $color-haribo-grey-1;
		color: $color-haribo-blue;
		margin-right: 10px;
		@include font-style("headline-l");
	}

	.pac-matched {
		background-color: transparent;
	}
}
