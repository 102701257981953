.c-tooltip {
	$arrow-size: 10px;

	display: block !important;
	max-width: 240px;
	@include z-index(tooltip);

	@include mq($from: large) {
		max-width: 310px;
	}

	.tooltip-inner {
		padding: $spacing-yellow;
		background: $color-white;
		box-shadow: 0 6px 24px 0 rgba($color-black, 0.44);
		color: $color-black;

		@include mq($from: large) {
			padding: $spacing-orange;
		}
	}

	.tooltip-arrow {
		width: 0;
		height: 0;
		border-style: solid;
		border-color: $color-white;
		position: absolute;
		z-index: 1;
	}
	//
	&[x-placement^="top"] {
		margin-bottom: $arrow-size + $spacing-smokeblue;

		.tooltip-arrow {
			border-width: $arrow-size $arrow-size 0 $arrow-size;
			border-bottom-color: transparent !important;
			border-left-color: transparent !important;
			border-right-color: transparent !important;
			bottom: -$arrow-size;
			left: calc(50% - #{$arrow-size});
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&[x-placement^="bottom"] {
		margin-top: $arrow-size + $spacing-smokeblue;

		.tooltip-arrow {
			border-width: 0 $arrow-size $arrow-size $arrow-size;
			border-top-color: transparent !important;
			border-left-color: transparent !important;
			border-right-color: transparent !important;
			top: -$arrow-size;
			left: calc(50% - #{$arrow-size});
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&[x-placement^="right"] {
		margin-left: $arrow-size + $spacing-smokeblue;

		.tooltip-arrow {
			border-width: $arrow-size $arrow-size $arrow-size 0;
			border-top-color: transparent !important;
			border-bottom-color: transparent !important;
			border-left-color: transparent !important;
			top: calc(50% -  #{$arrow-size});
			left: -$arrow-size;
			margin-left: 0;
			margin-right: 0;
		}
	}

	&[x-placement^="left"] {
		margin-right: $arrow-size + $spacing-smokeblue;

		.tooltip-arrow {
			border-width: $arrow-size 0 $arrow-size $arrow-size;
			border-top-color: transparent !important;
			border-bottom-color: transparent !important;
			border-right-color: transparent !important;
			top: calc(50% -  #{$arrow-size});
			right: -$arrow-size;
			margin-left: 0;
			margin-right: 0;
		}
	}

	&[aria-hidden="true"] {
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.15s, visibility 0.15s;
	}

	&[aria-hidden="false"] {
		visibility: visible;
		opacity: 1;
		transition: opacity 0.15s;
	}
}

.c-tooltip__headline {
	margin-bottom: $spacing-olive;

	@include mq($from: large) {
		margin-bottom: $spacing-pink;
	}
}

.c-tooltip__image {
	margin-top: $spacing-pink;

	@include mq($from: large) {
		margin-top: $spacing-yellow;
	}
}
