@mixin dial-radio-group--big() {
	.c-dial-radio-group__knob {
		width: $dial-radio-group-diameter-l;
		height: $dial-radio-group-diameter-l;

		// center
		&::after {
			width: $dial-radio-group-inner-diameter-l;
			height: $dial-radio-group-inner-diameter-l;
			top: ($dial-radio-group-diameter-l / 2) - ($dial-radio-group-inner-diameter-l / 2);
			left: ($dial-radio-group-diameter-l / 2) - ($dial-radio-group-inner-diameter-l / 2);
		}
	}
}
