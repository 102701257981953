$pre-label-dates-width: (
	medium: 130px,
	large: 19.2%,
	xlarge: 220px
);

$pre-label-numbering-width: (
	medium: 50px,
	large: 60px,
	xlarge: 80px
);

$item-right-margin: (
	small: percentage(2/$cols-s),
	medium: percentage(1/$cols-m),
	large: percentage(2/$cols-l),
	xlarge: percentage(2/$cols-xl)
);

.m-accordion__headline {
	color: $color-haribo-blue;
}

.m-accordion__copy {
	color: $color-haribo-blue;
	margin-top: $spacing-pink;

	@include mq($from: medium) {
		margin-top: $spacing-yellow;
	}

	@include mq($from: large) {
		max-width: 75%;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-lilac;
	}
}

.m-accordion__places-search {
	margin-top: $spacing-purple;

	@include mq($from: medium) {
		margin-top: $spacing-petrol;
	}

	@include mq($from: large) {
		margin-top: $spacing-softblue;
	}
}

.m-accordion__list {
	margin-top: $spacing-royalblue;

	@include mq($from: medium) {
		margin-top: $spacing-seablue;
	}

	@include mq($from: large) {
		margin-top: $spacing-petrol;
	}

	&:first-child {
		margin-top: 0;
	}
}

.m-accordion__fallback-text {
	margin-top: $spacing-pink;

	@include mq($from: medium) {
		margin-top: $spacing-yellow;
	}

	@include mq($from: xlarge) {
		margin-top: $spacing-lilac;
	}
}

.m-accordion__item {
	position: relative;

	.m-accordion__item-arrow {
		position: absolute;
		top: $spacing-pink;
		right: 1px;
		@include transition(transform);

		@include is-rtl() {
			left: 1px;
			right: auto;
		}

		@include mq($from: medium) {
			top: $spacing-yellow;
		}

		@include mq($from: xlarge) {
			top: $spacing-lilac;
		}
	}
}

.m-accordion__item + .m-accordion__item {
	border-top: 2px solid rgba($color-haribo-blue, 0.3);
}

// no border for first filtered element
.m-accordion__item.is-filtered {
	border-top: none;

	~ .is-filtered {
		border-top: 2px solid rgba($color-haribo-blue, 0.3);
	}
}

.m-accordion__toggle {
	padding-top: $spacing-pink;
	padding-bottom: $spacing-yellow;
	position: relative;

	@include mq($from: medium) {
		display: flex;
		align-items: flex-start;
		padding-top: $spacing-yellow;
		padding-bottom: $spacing-orange;
	}

	@include mq($from: large) {
		padding-bottom: $spacing-lilac;
	}

	@include mq($from: xlarge) {
		padding-top: $spacing-lilac;
		padding-bottom: $spacing-purple;
	}
}

.m-accordion__item-button {
	background-color: transparent;
	outline: none;
	z-index: 3;

	@include hover-focus() {
		~ .m-accordion__item-arrow {
			&::after {
				transform: scale(1.2);
			}
		}

		~ .m-accordion__label {
			color: $color-black;
		}
	}
}

.m-accordion__pre-label {
	color: $color-haribo-red;

	@include mq($from: medium) {
		flex: 0 0 auto;
	}
}

.m-accordion__label {
	padding-right: 15px;
	color: $color-haribo-blue;
	margin-top: $spacing-olive;
	@include transition(color);

	@include is-rtl() {
		padding-left: 15px;
		padding-right: 0;
	}

	@include mq($from: medium) {
		width: 100%;
		margin-top: 0;
	}
}

.m-accordion__post-label {
	color: $color-haribo-blue;
	margin-top: $spacing-smokeblue-light;

	@include mq($from: medium) {
		flex: 0 0 auto;
		width: percentage(3/$cols-m);
		margin-top: 2px;
		margin-left: 20px;

		@include is-rtl() {
			margin-left: 0;
			margin-right: 20px;
		}
	}

	@include mq($from: large) {
		width: percentage(5/$cols-l);
		margin-top: 8px;
		margin-left: percentage(1/$cols-l);

		@include is-rtl() {
			margin-left: 0;
			margin-right: percentage(1/$cols-l);
		}
	}

	@include mq($from: xlarge) {
		width: percentage(4/$cols-xl);
	}
}

// adjust margin of second last item, the one before the absolute positioned arrow
.m-accordion__toggle > *:nth-last-child(2) {
	@include property-breakpoint-map(margin-right, $item-right-margin);

	@include is-rtl() {
		margin-right: 0;
		@include property-breakpoint-map(margin-left, $item-right-margin);
	}
}

.m-accordion__content {
	overflow: hidden;
	transition: height ease-in-out 300ms, opacity linear 300ms;
}

.m-accordion__content-inner {
	padding-bottom: $spacing-orange;

	@include mq($from: medium) {
		padding-bottom: $spacing-lilac;
	}

	@include mq($from: large) {
		padding-bottom: $spacing-purple;
	}

	@include mq($from: xlarge) {
		padding-bottom: $spacing-lightblue;
	}
}

.m-accordion__more-link-wrapper {
	margin-top: $spacing-purple;

	@include mq($from: medium) {
		display: flex;
		justify-content: center;
		margin-top: $spacing-petrol;
	}
}

// variants

.m-accordion--pre-label-numbered {
	.m-accordion__pre-label {
		@include property-breakpoint-map(width, $pre-label-numbering-width);
	}
}

.m-accordion--pre-label-date {
	.m-accordion__pre-label {
		@include property-breakpoint-map(width, $pre-label-dates-width);
	}

	.m-accordion__content-inner {
		@include property-breakpoint-map(margin-left, $pre-label-dates-width);
		@include property-breakpoint-map(margin-right, $item-right-margin);

		@include is-rtl() {
			@include property-breakpoint-map(margin-left, $item-right-margin);
			@include property-breakpoint-map(margin-right, $pre-label-dates-width);
		}
	}
}

.m-accordion--event,
.m-accordion--tourstop {
	.m-accordion__post-label {
		@include mq($until: medium) {
			margin-right: 0;
		}
	}
}

.m-accordion--faq {
	.m-accordion__pre-label {
		@include mq($until: medium) {
			display: none;
		}
	}

	.m-accordion__label {
		@include mq($until: medium) {
			margin-top: 0;
		}
	}

	.m-accordion__content-inner {
		@include property-breakpoint-map(margin-left, $pre-label-numbering-width);
		@include property-breakpoint-map(margin-right, $item-right-margin);

		@include is-rtl() {
			@include property-breakpoint-map(margin-left, $item-right-margin);
			@include property-breakpoint-map(margin-right, $pre-label-numbering-width);
		}
	}
}

// specific override for all variants
.m-accordion .m-accordion__content .m-accordion__content-inner {
	@include mq($until: medium) {
		margin-right: 0;

		@include is-rtl() {
			margin-left: 0;
		}
	}
}

//open state

// Don't set display to none to have the possibility of a transition. Remember to reset margin of the item as well.
html.js .m-accordion__item:not(.is-active) .m-accordion__content {
	height: 0;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	transition: height ease-in-out 300ms, opacity linear 300ms 0ms, visibility linear 10ms 300ms;
}

.m-accordion__item.is-active {
	.m-accordion__item-arrow {
		transform: rotate(180deg);
	}
}

// override of modules inside the content area

$accordion-sub-module-spacing: (
	small: $spacing-ocher,
	medium: $spacing-petrol,
	large: $spacing-lightpink,
	xlarge: $spacing-softblue
);

/* stylelint-disable-next-line no-duplicate-selectors */
.m-accordion__content-inner {

	// override nested wrappers
	.l-wrapper {
		width: 100%;
	}

	// Set a basic margin-top for all modules
	> *:not(:first-child) {
		@include property-breakpoint-map(margin-top, $accordion-sub-module-spacing);
	}

	// Set padding to 0 on all modules, that don't have a background color
	> *:not([class*="u-color-bg-"]) {
		padding-top: 0;
		padding-bottom: 0;
	}

	// M009
	.m-section-text {
		.m-section-text__inner {
			width: 100%;
		}

		.c-rich-text li {
			margin-left: 20px;

			@include is-rtl() {
				margin-left: 0;
				margin-right: 20px;
			}
		}
	}
}
