/**
 * NOTE:
 * The styles are mainly based on M053-Raffle and reusing the same classes in the templates.
 * Only M065-Quiz related styles are added here for differences between the two modules.
 * Overrides are used if needed.
 */

.m-quiz__pagination-indicator {
	--height: 100px;
	--border-radius: calc(var(--height) / 2);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 70px;
	height: var(--height);
	padding-right: 18px;
	background-color: rgba($color-white, 0.3);
	border-radius: 0 var(--border-radius) var(--border-radius) 0;
	opacity: 0;
	color: $color-white;
	position: absolute;
	top: calc(50% - var(--height) / 2);
	left: 0;
	transform: translateX(-40px);
	animation-name: pagination-slide-in;
	animation-duration: 0.3s;
	animation-timing-function: $animation-default-easing;
	animation-delay: 0.8s;
	animation-fill-mode: forwards;

	@include mq($until: large) {
		display: none;
	}

	@include mq($from: xlarge) {
		--height: 130px;
		width: 80px;
	}
}

@keyframes pagination-slide-in {
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

@import "components/QuestionsStep";
@import "components/ResultStep";
